import React from 'react'
import { inject, Observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import styles from './SpecialCommissionEvent.module.css'
import { filterEmptyPrismicRepeatersContents, parsePrismicHtml } from '../../lib/survive-prismic'
import { ResponsiveImage } from '../../components/Shared/Templating'
import { EventTitles } from '../../components/Shared/EventTitles'
import { MediaSectionGrid } from '../../components/Shared/MediaSection'
import { linkResolverNormalizer } from '../../lib/link-resolver'
import { WrappedVideo } from './WrappedVideo'

export const SpecialCommission = inject('rootStore')(({ data, rootStore }) => {
  const {
    navigation: { currentRouteLang },
  } = rootStore
  const {
    items,
    primary: {
      project_author,
      project_name,
      project_image,
      project_image_caption,
      project_description,
      project_video_id,
      project_embed_media_html,
      project_video_source_type,
      project_video_caption,
    },
  } = data

  const _items = items.filter(
    _item =>
      (!isEmpty(_item.project_gallery_image) && _item.project_gallery_image.url !== null) ||
      _item.project_gallery_video_id !== null,
  )

  const artworks = _items.filter(_item => _item.project_gallery_media_type === 'Opera / foto')
  const behindTheScenes = _items.filter(
    _item => _item.project_gallery_media_type === 'Allestimento',
  )

  const _artworks = filterEmptyPrismicRepeatersContents(artworks, 'project_gallery_image.url')
  const _project_embed_media_html = filterEmptyPrismicRepeatersContents(
    project_embed_media_html,
    'text',
  )
  return (
    <Observer>
      {() => (
        <div className={styles.project}>
          <div className={styles.projectContent}>
            <div className={styles.projectHeader}>
              <EventTitles
                author={project_author[0]?.text}
                title={project_name}
                titleClass="pageTitle"
              />
              <div className={styles.coverImage}>
                <ResponsiveImage img={project_image} additionalClassName={styles.img} />
                {project_image_caption?.length > 0 && (
                  <div
                    className={`captionText ${styles.projectCoverImageCaption}`}
                    dangerouslySetInnerHTML={{
                      __html: parsePrismicHtml(project_image_caption, linkResolverNormalizer),
                    }}
                  />
                )}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: parsePrismicHtml(project_description, linkResolverNormalizer),
                }}
                className={`bodyTextBig ${styles.description}`}
              />

              {project_video_id !== null ||
                (_project_embed_media_html?.length > 0 && (
                  <div className={styles.videoWrapper}>
                    <WrappedVideo
                      type={project_video_source_type}
                      uid={project_video_id}
                      html={_project_embed_media_html}
                    />
                    {project_video_caption?.length > 0 && (
                      <div
                        className={`${styles.descriptionVideoCaption} captionText`}
                        dangerouslySetInnerHTML={{
                          __html: parsePrismicHtml(project_video_caption, linkResolverNormalizer),
                        }}
                      />
                    )}
                  </div>
                ))}
            </div>

            {_artworks?.length > 0 && (
              <div className={styles.projectMedia}>
                <MediaSectionGrid
                  media={_artworks}
                  contentType={'specialCommission'}
                  titleLabel={'worksSectionTitle'}
                  lang={currentRouteLang}
                  itemsPerRow={4}
                />
              </div>
            )}
            {behindTheScenes?.length > 0 && (
              <div className={styles.projectMedia}>
                <MediaSectionGrid
                  media={behindTheScenes}
                  contentType={'specialCommission'}
                  titleLabel={'behindTheScenesSectionTitle'}
                  lang={currentRouteLang}
                  itemsPerRow={4}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Observer>
  )
})
