import React from 'react'
import styles from '../../routes/SingleMagazine.module.css'
import { Credits } from '../../components/SingleEvent/Credits'

export const CreditsBlock = ({ primary: { credits } }, index) => {
  return (
    <div className={`${styles.contentsWrapper}`} key={index}>
      <Credits credits={credits} credits_part2={[]} hasTitle={true} />
    </div>
  )
}
