import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import PrismicDOM from 'prismic-dom'

export function filterEmptyPrismicContents(field, key) {
  if (!isEmpty(field) && field[key]) {
    return field
  }

  return undefined
}

export function filterEmptyPrismicRepeatersContents(field, key) {
  if (!isEmpty(field) && field.length) {
    return field.filter(f => get(f, key))
  }

  return []
}

export function checkLinkValidity(link) {
  if (!isEmpty(link) && link.link_type !== 'Any') {
    return link?.url
  }

  return undefined
}

export function parsePrismicHtml(field, linkResolver, serializer) {
  return PrismicDOM.RichText.asHtml(field, linkResolver, serializer)
}

export function parsePrismicText(text) {
  return PrismicDOM.RichText.asText(text)
}
