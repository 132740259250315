import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import Carousel from 'nuka-carousel'
import isEmpty from 'lodash/isEmpty'
import { formatVivaticketDate, customFormattedDate } from '../../lib/date-utils'
import { scrollViewVertically } from '../../lib/scroll'
import { Title } from '../../components'
import styles from './Timeline.module.css'

const POMO_RED = '#ff440a'
const OPACITY_GRAY = '#d8d8d8'
const WHITE = '#fff'
const BLACK = '#000'

const TITLE = { it: 'Seleziona l’ora', en: 'Select the time' }

const Rectangle = ({ onClick, time, fill, last }) => {
  const width = 50
  const height = 30
  const offset = last ? 0 : 20
  const strokeWidth = 2

  const x1 = width * 0.5
  const x2 = width * 1.5

  const transform = `translate(${strokeWidth},${strokeWidth})`

  return (
    <>
      <svg
        viewBox={`0 0 ${width + offset + strokeWidth * 2} ${height + strokeWidth * 2}`}
        className={styles.rect}
        width={width + offset + strokeWidth * 2}
        height={height + strokeWidth * 2}
        onClick={onClick}
      >
        <g>
          <line x1={x1} x2={x2} y1={height / 2} y2={height / 2} strokeWidth={2} stroke="black" />
        </g>
        <g fill={fill} transform={transform}>
          <rect
            width={width}
            height={height}
            stroke={fill}
            strokeWidth={strokeWidth}
            fill={'white'}
          />
          <text x={width / 2} y={(height + 6) / 2} fontSize={12} textAnchor="middle">
            {time}
          </text>
        </g>
      </svg>
    </>
  )
}

const TimelineContent = observer(({ data, onClick, performanceLabel }) => {
  // needed to align left the cell
  const slideWidth = data.length === 1 ? '54px' : '74px'

  return (
    <>
      <div className={styles.timelineWrapper}>
        <Carousel
          slidesToShow={4}
          slideWidth={slideWidth}
          withoutControls
          swiping
          slidesToScroll="auto"
          initialSlideHeight={34}
        >
          {data.map(({ idEvento, time, hasGuidedTour, outOfStock }, i) => {
            const squareFill = hasGuidedTour ? (outOfStock ? OPACITY_GRAY : POMO_RED) : WHITE
            const fill = outOfStock ? OPACITY_GRAY : BLACK
            const last = data.length - 1 === i

            return (
              <div key={i} className={styles.time}>
                <Rectangle time={time} fill={fill} onClick={() => onClick(idEvento)} last={last} />
                {hasGuidedTour && (
                  <div className={styles.square} style={{ backgroundColor: squareFill }} />
                )}
              </div>
            )
          })}
        </Carousel>
      </div>
    </>
  )
})

export const Timeline = inject('rootStore')(({ rootStore, data }) => {
  const {
    navigation: { previousRouteLang: currentRouteLang },
    tickets: { setPerformanceAndFetchData },
  } = rootStore

  const onClick = id => setPerformanceAndFetchData(id)

  if (isEmpty(data)) return null

  const measuredTitleEl = useCallback(node => {
    if (node !== null) {
      const { y } = node.getBoundingClientRect()
      scrollViewVertically(y)
    }
  }, [])

  const selectedDate = formatVivaticketDate(data[0].dataInizio)
  const formattedDate = customFormattedDate(selectedDate, currentRouteLang, 'dddd DD MMMM YYYY')

  return (
    <div ref={measuredTitleEl}>
      <Title text={TITLE[currentRouteLang]} />
      <div className={styles.label}>{formattedDate}</div>
      <TimelineContent data={data} onClick={onClick} />
    </div>
  )
})
