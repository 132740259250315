import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { PageTitle } from '../../components/Shared/Titles'
import { StickyBlock } from '../../components/Shared/StickyBlock'
import styles from './ServicePage.module.css'
import { linkResolverNormalizer } from '../../lib/link-resolver'
import { parsePrismicHtml } from '../../lib/survive-prismic'

export const ServicePage = inject('rootStore')(
  observer(({ service }) => {
    if (isEmpty(service)) {
      return null
    }

    const { title, description, paragraphs } = service.data

    return (
      <div>
        <PageTitle title={title} />
        <div
          className={`${styles.descriptionSection} typographicTitle bold`}
          dangerouslySetInnerHTML={{
            __html: parsePrismicHtml(description, linkResolverNormalizer),
          }}
        />

        {paragraphs.map((p, i) => {
          return (
            <div className={styles.container} key={i}>
              <StickyBlock
                title={p.paragraph_title}
                image={p.paragraph_image}
                caption={p.paragraph_image_caption}
                text={p.paragraph_text}
                opening_times={p.opening_times}
                service_info={p.service_info}
                renderAsPrismic={true}
                modifierClass={styles.paragraph}
                index={i}
              />
            </div>
          )
        })}
      </div>
    )
  }),
)
