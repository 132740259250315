import React from 'react'
import { inject, observer } from 'mobx-react'
import DayPicker, { LocaleUtils } from 'react-day-picker'
import dayjs from 'dayjs'
import { capitalize, isEmpty } from 'lodash'
import { translator } from '../../lib/translator'
import { customFormattedDate } from '../../lib/date-utils'
import { Squares } from './Squares'
import { Title } from '../../components'
import styles from './Calendar.module.css'
import { MONTHS } from '../../lib/const'

function formatDate(d, currentRouteLang) {
  const day = dayjs()
    .locale(currentRouteLang)
    .day(d)
    .format('ddd')
  return capitalize(day.charAt(0))
}

function formateCustomDate(d, currentRouteLang) {
  return customFormattedDate(d, currentRouteLang, 'DD/MM/YYYY')
}

const renderDay = (day, { scheduledTours, currentRouteLang }) => {
  const formatDay = formateCustomDate(day, currentRouteLang)
  const guidedDay = scheduledTours.filter(
    d => formatDay === formateCustomDate(d.Data, currentRouteLang),
  )
  const numberOfGuidedTours = guidedDay.length
  const date = day.getDate()

  return (
    <>
      {numberOfGuidedTours > 0 && <Squares count={numberOfGuidedTours} />}
      <div>{date}</div>
    </>
  )
}

const Legend = ({ currentRouteLang }) => {
  return (
    <div className={styles.legend}>
      <div className={styles.legendSquare} />
      <div className={styles.legendBlock}>
        <div className={styles.legendTitle}>
          {translator('guidedTourSectionTitle')(currentRouteLang)}
        </div>
        <div className={styles.legendSection}>
          {translator('guidedToursSectionDays')(currentRouteLang)}
        </div>
      </div>
    </div>
  )
}

@inject('rootStore')
@observer
export class Calendar extends React.Component {
  state = {
    selectedDay: '',
  }

  detachPastEvents = times => {
    const isAPastPerformance = p =>
      dayjs(`${p.dataInizio}${p.time}`, 'DD/MM/YYYYHH:mm').isAfter(dayjs())
    return times ? times.filter(isAPastPerformance) : []
  }

  formatWeekdayShort = (d, locale) => formatDate(d, locale)

  onDayClick = date => {
    const {
      navigation: { previousRouteLang: currentRouteLang },
      tickets: {
        setPerformanceId,
        setShowTimesIds,
        groupedPerformances,
        cart: { clear },
      },
    } = this.props.rootStore
    const formattedDate = formateCustomDate(date, currentRouteLang)
    const performances = this.detachPastEvents(groupedPerformances[formattedDate])
    setShowTimesIds(performances.map(p => p.idEvento))
    this.setState({
      selectedDay: date,
    })
    setPerformanceId(undefined)
    clear()
  }

  render() {
    const {
      navigation: { previousRouteLang: currentRouteLang },
      tickets: { scheduledTours },
    } = this.props.rootStore
    const { data } = this.props
    const { selectedDay } = this.state
    return (
      !isEmpty(data) && (
        <>
          <div className={styles.wrapper}>
            <Title text={translator('ticketSelectDate')(currentRouteLang)} />
            <DayPicker
              localeUtils={{ ...LocaleUtils, ...this.formatWeekdayShort }}
              modifiers={{
                events: data,
              }}
              onDayClick={this.onDayClick}
              selectedDays={selectedDay}
              numberOfMonths={3}
              renderDay={day => renderDay(day, { scheduledTours, currentRouteLang })}
              fromMonth={new Date()}
              months={MONTHS[currentRouteLang]}
            />
            <Legend />
          </div>
        </>
      )
    )
  }
}
