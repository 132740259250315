import React from 'react'
import styles from './ArchiveLightbox.module.css'
import { ArchiveList, ArchiveLabel } from '../../components'

const capitalize = c => (c ? c.charAt(0).toUpperCase() + c.slice(1) : '')

const normalize = fn => input =>
  Array.isArray(input) ? input.map(i => fn(i)).join(', ') : fn(input)

export const ArchiveCollectionContents = data => {
  const {
    title,
    year,
    format,
    type,
    authors,
    company,
    materials,
    dimensions,
    project,
    projectYear,
    isPresentInMuseum,
  } = data

  return (
    <>
      <p>
        <strong>{normalize(capitalize)(title || project)}</strong>
      </p>
      {Array.isArray(type) ? ArchiveList('Tipologia')(type) : ArchiveLabel('Tipologia')(type)}
      {ArchiveList('Autore')(authors)}
      {ArchiveLabel('Azienda')(company)}
      {ArchiveLabel('Materiali')(materials)}
      {ArchiveLabel('Formato')(format)}
      {ArchiveLabel('Dimensione')(dimensions)}
      {ArchiveLabel('Anno')(year || projectYear)}
      {isPresentInMuseum && <span className={styles.label}>In Mostra</span>}
    </>
  )
}

export default ArchiveCollectionContents
