import React from 'react'
import isEmpty from 'lodash/isEmpty'
import styles from './NewsItemsPreview.module.css'
import { SingleNewsItemPreview } from './SingleNewsItemPreview'

class NewsItemsPreview extends React.Component {
  render() {
    const { results, options } = this.props

    return (
      <div className={styles.newsGrid}>
        {!isEmpty(results) ? results.map(n => SingleNewsItemPreview(n, options)) : null}
      </div>
    )
  }
}

export default NewsItemsPreview
