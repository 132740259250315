import {
  event,
  magazine,
  homepage,
  containerPage,
  magazineSeries,
  magazineAuthor,
  magazineHomepage,
} from 'triennale-prismic-api-wrapper'

import { splitPathOut } from './utils/path-utils'
import { withFilters } from './filters-utils'

export const fetchWithFilters = async (self, lang, pagination = null, options = {}, type) => {
  const FILTERS = withFilters(lang)(self, pagination, options, type)
  const data = await self.fetchFilteredData(FILTERS)
  return data
}

export const handlerFirstLevel = {
  home: async (self, _, lang) => {
    const TYPE = homepage
    const data = await self.fetchSingle(TYPE, lang)
    return data
  },
  magazine: async (self, _, lang) => {
    const TYPE = magazineHomepage
    const data = await self.fetchSingle(TYPE, lang)
    return data
  },
  default: async (self, uids, lang) => {
    const TYPE = containerPage
    const data = await self.fetchDataByUids(TYPE, uids, lang)
    return data
  },
}

export const handlerSecondLevel = route => {
  switch (route) {
    case 'magazine':
      return async (self, uids, lang) => {
        const TYPE = magazine
        const _uids = splitPathOut(uids.shift(), '_')(['magazine'])
        const data = await self.fetchDataByUids(TYPE, _uids, lang)
        return data
      }
    case 'serie':
    case 'series':
      return async (self, _, lang) => {
        const contentTypeId = 'magazine_series'
        const data = await self.fetchAnythingByType(contentTypeId, lang)
        return data
      }
    case 'collaboratori':
    case 'contributors':
      return async (self, _, lang) => {
        const contentTypeId = 'magazine_author'
        const data = await self.fetchAnythingByType(contentTypeId, lang)
        return data
      }
    case 'eventi':
    case 'events':
      return async (self, uids, lang) => {
        const TYPE = event
        const _uids = splitPathOut(uids.shift(), '_')(['eventi', 'events'])
        const data = await self.fetchDataByUids(TYPE, _uids, lang)
        return data
      }
    default:
      return async (self, uids, lang) => {
        const data = await self.fetchAnythingByUids(uids, lang)
        return data
      }
  }
}

export const handlerThirdLevel = route => {
  switch (route) {
    case 'serie':
    case 'series':
      return async (self, uid, lang) => {
        const TYPE = magazineSeries
        const data = await self.fetchDataByUids(TYPE, uid, lang)
        return data
      }
    case 'collaboratori':
    case 'contributors':
      return async (self, uid, lang) => {
        const TYPE = magazineAuthor
        const data = await self.fetchDataByUids(TYPE, uid, lang)
        return data
      }
    default:
      return null
  }
}

export const handlerUpdateURL = ({ navigation }, { pathname, search }, previousSearch) => {
  const { location } = navigation.router
  if (
    search === previousSearch ||
    (location.pathname === `/${pathname}` && location.search === `?${search}`)
  ) {
    return false
  }

  navigation.router.push(`/${pathname}?${search}`)
  return true
}
