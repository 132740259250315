import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import mariCalendar from 'images/calendario-mari.png'
import { PageTitle } from '../../components/Shared/Titles'
import { PartnersSectionExpanded } from '../../components/Shared/PartnerBlock'
import styles from './VisitPage.module.css'
import { translator } from '../../lib/translator'
import { StickyImage, StickyBlock } from '../../components/Shared/StickyBlock'
import { OpeningHoursPicker } from '../../components/Shared/OpeningHoursPicker'
import { OpeningHoursResult } from '../../components/Shared/OpeningHoursResult'
import { PriceTableSection } from '../../components/Shared/PriceTableSection'
import { Link } from '../../components'
import { linkResolver, linkResolverNormalizer } from '../../lib/link-resolver'
import { parsePrismicHtml, filterEmptyPrismicRepeatersContents } from '../../lib/survive-prismic'
import { withIframesResizer } from '../../components/Shared/withIframesResizer'
import { IframeWrapper } from '../../components/Shared/IframeWrapper'

const mariCalendarAlt = {
  it: 'Enzo Mari, Formosa, 1963 | Danese Milano',
  en: 'Enzo Mari, Formosa, 1963 | Danese Milano',
}

const mariCalendarCaption = {
  it: '<p>Enzo Mari<br/>Formosa, 1963<br/>Danese Milano</p>',
  en: '<p>Enzo Mari<br/>Formosa, 1963<br/>Danese Milano</p>',
}

export const VisitPageLayout = inject('rootStore')(
  observer(({ rootStore, visit, forwardedRef }) => {
    const { currentRouteLang } = rootStore.navigation

    if (isEmpty(visit)) {
      return null
    }

    const {
      title,
      description,
      partners,
      additional_info,
      price_lists,
      embed_media_html,
      paragraphs,
    } = visit.data

    return (
      <div>
        <div className={styles.header}>
          <PageTitle title={title} />
          <div ref={forwardedRef}>
            <IframeWrapper html={embed_media_html} />
          </div>
          <div
            className={`${styles.descriptionSection} typographicTitle bold`}
            dangerouslySetInnerHTML={{
              __html: parsePrismicHtml(description, linkResolverNormalizer),
            }}
          />
        </div>

        <section className={styles.openingHoursSection}>
          <div className={styles.wrapper}>
            <div className={`${styles.left} bodyText`}>
              <h1 className={`${styles.leftTitle}`}>
                {translator('openingHoursSectionTitle')(currentRouteLang)}
              </h1>
              <div className={`${styles.leftInner}`}>
                <OpeningHoursPicker />

                {additional_info.map((service, i) => {
                  const {
                    service_name,
                    service_opening_times,
                    service_opening_replace_text,
                    link,
                    text,
                  } = service

                  const _service_opening_replace_text = filterEmptyPrismicRepeatersContents(
                    service_opening_replace_text,
                    'text',
                  )

                  return (
                    <div className={`${styles.serviceRow}`} key={i}>
                      <div className={styles.rowLeft}>
                        <p className={styles.rowLeftInner}>
                          {link.uid ? (
                            <Link
                              className={`link ${styles.serviceLink}`}
                              to={linkResolver({
                                contentType: link.type,
                                uid: link.uid,
                                lang: currentRouteLang,
                              })}
                            >
                              {service_name}
                            </Link>
                          ) : (
                            service_name
                          )}
                        </p>
                      </div>
                      <div className={`${styles.rowRight}`}>
                        {service_opening_times !== null ? (
                          <OpeningHoursResult key={i} openingTimes={service_opening_times.data} />
                        ) : _service_opening_replace_text.length > 0 ? (
                          <div
                            className={`${styles.openingReplaceText} bodyTextBig`}
                            dangerouslySetInnerHTML={{
                              __html: parsePrismicHtml(
                                _service_opening_replace_text,
                                linkResolverNormalizer,
                              ),
                            }}
                          />
                        ) : (
                          <div className="bodyTextBig">
                            {translator('unavailable')(currentRouteLang)}
                          </div>
                        )}
                        {text && (
                          <div
                            className={styles.infoText}
                            dangerouslySetInnerHTML={{
                              __html: parsePrismicHtml(text, linkResolverNormalizer),
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className={styles.right}>
              <div className={styles.stickyItem}>
                <StickyImage
                  image={{
                    url: mariCalendar,
                    alt: mariCalendarAlt[currentRouteLang],
                    dimensions: { width: 504, height: 378 },
                  }}
                  caption={mariCalendarCaption[currentRouteLang]}
                  renderAsPrismic={false}
                />
              </div>
            </div>
          </div>
        </section>

        <div className={styles.pricesSection}>
          {price_lists?.length > 0 &&
            price_lists.map((price_list, i) =>
              PriceTableSection({ table: price_list, lang: currentRouteLang, index: i }),
            )}
        </div>

        <div>
          {paragraphs.map((p, i) => {
            return (
              <div className={styles.container} key={i}>
                <StickyBlock
                  title={p.paragraph_title}
                  image={p.paragraph_image}
                  caption={p.paragraph_image_caption}
                  text={p.paragraph_text}
                  opening_times={p.opening_times}
                  service_info={p.service_info}
                  renderAsPrismic={true}
                  modifierClass={styles.paragraph}
                  index={i}
                />
              </div>
            )
          })}
        </div>

        <div className={styles.mapWrapper}>
          <iframe
            title={
              currentRouteLang === 'it'
                ? 'Mappa per raggiungere Triennale Milano'
                : 'Map to get to Triennale Milano'
            }
            className={styles.map}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.8794144328567!2d9.171412950689245!3d45.472232978998385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c1459375a287%3A0x3f0d509b465c002b!2sLa+Triennale+di+Milano!5e0!3m2!1sen!2sit!4v1549793468878"
            width="600"
            height="800"
            frameBorder="0"
            style={{ border: 0 }}
          />
        </div>

        <PartnersSectionExpanded partners={partners} lang={currentRouteLang} />
      </div>
    )
  }),
)

export const VisitPage = withIframesResizer(VisitPageLayout)
