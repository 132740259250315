import { types as t } from 'mobx-state-tree'

export const LanguageModel = t.model({
  id: t.maybeNull(t.string),
  lang: t.maybeNull(t.string),
  type: t.maybeNull(t.string),
  uid: t.maybeNull(t.string),
})

export const PrismicModel = t
  .model('PrismicModel', {
    alternate_languages: t.maybeNull(t.array(LanguageModel)),
    data: t.frozen(),
    first_publication_date: t.maybeNull(t.string),
    href: t.string,
    id: t.string,
    lang: t.string,
    last_publication_date: t.maybeNull(t.string),
    linked_documents: t.frozen(),
    slugs: t.array(t.string),
    tags: t.maybeNull(t.array(t.string)),
    type: t.string,
    uid: t.string,
  })
  .actions(self => ({}))
  .views(self => ({}))
