import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import drop from 'lodash/drop'
import head from 'lodash/head'
import take from 'lodash/take'
import styles from './MagazineFocusArea.module.css'
import { MagazineFocusListCards } from './MagazineFocusListCards'

@inject('rootStore')
@observer
export class MagazineFocusArea extends React.Component {
  render() {
    const {
      rootStore: {
        magazineHomepage: { results },
      },
    } = this.props
    if (isEmpty(results) && isEmpty(results.data)) return null
    const getFocus = m => m.data.focus
    const focusMagazine = head(results).data.magazines.results.filter(getFocus)
    return (
      !isEmpty(focusMagazine) && (
        <div className={styles.magazineFocusArea}>
          <div className={styles.focusAreaLeft}>
            <div className={styles.stickyElement}>
              <MagazineFocusListCards results={take(focusMagazine)} options={{ typeOfList: -1 }} />
            </div>
          </div>
          <div className={styles.focusAreaRight}>
            <MagazineFocusListCards results={drop(focusMagazine)} options={{ typeOfList: -1 }} />
          </div>
        </div>
      )
    )
  }
}
