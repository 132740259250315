import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  HomeExpositiveSection,
  HomePerformativeSection,
  HomePublicProgramSection,
  HomeNewsSection,
  HomeMagazineSection,
  HomeArchiveSection,
} from '../components'

@inject('rootStore')
@observer
export class Home extends React.Component {
  componentDidMount() {
    this.recursiveCheckData()
  }

  componentWillUnmount() {
    this.props.rootStore.ui.resetLogoColor()
  }

  recursiveCheckData() {
    const { homePage, ui } = this.props.rootStore

    if (!homePage.results.length) {
      setTimeout(() => this.recursiveCheckData(), 200)
    } else {
      ui.setLogoColor(homePage.results[0].data.logo_color)
    }
  }

  render() {
    const { homePage } = this.props.rootStore

    if (!homePage.results.length) {
      return null
    }

    const { data } = homePage.results[0]
    const { /* is_news_block_visible,  */ archive_preview } = data
    return (
      <>
        <HomeExpositiveSection />
        <HomePerformativeSection />
        <HomePublicProgramSection />
        {/* TODO: fix it */}
        {/* {is_news_block_visible === 'Si' && <HomeNewsSection />} */}
        <HomeNewsSection />
        <HomeMagazineSection />
        <HomeArchiveSection archive={archive_preview} />
      </>
    )
  }
}
