import React from 'react'
import { inject, Observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { HeroSerieContributor } from '../HeroSerieContributor'
import { MagazineListCards } from '../MagazineListCards'
import { BtnMagazineHome } from '../BtnMagazineHome'
import { Divider } from '../../../components'
import { composeUidOut } from '../../../lib/utils/uid-utils'

export const SingleSerie = inject('rootStore')(({ rootStore }) => {
  const {
    getBy,
    navigation: {
      router: {
        location: { pathname },
      },
    },
  } = rootStore
  const uid = composeUidOut(pathname)(['magazine', 'serie', 'series'])
  const singleSerie = getBy('magazineSeries')({ uid })
  const magazines = singleSerie?.data?.magazines
  return (
    <Observer>
      {() => (
        <>
          <BtnMagazineHome />
          {!isEmpty(singleSerie) && <HeroSerieContributor data={singleSerie?.data} />}
          <Divider margin={'both'} />
          {magazines && <MagazineListCards results={magazines} options={{ typeOfList: 0 }} />}
        </>
      )}
    </Observer>
  )
})
