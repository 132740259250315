import React from 'react'

function getInitialIframesDimensions(iframes) {
  return iframes.reduce((acc, { width, height }) => {
    const ratio = width.indexOf('%') > -1 ? undefined : height / width
    return [...acc, { width, height, ratio }]
  }, [])
}

export function withIframesResizer(WrappedComponent, maxWrapperWidth) {
  return class extends React.Component {
    containerRef = React.createRef()

    state = {
      dims: [],
    }

    componentDidMount() {
      setTimeout(() => {
        window.addEventListener('resize', this.handleIframes, false)
        this.initIframesResize()
        this.handleIframes()
      }, 100)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleIframes, false)
    }

    initIframesResize = () => {
      if (this.containerRef.current) {
        const iframes = Array.from(this.containerRef.current.querySelectorAll('iframe'))
        this.setState({ dims: getInitialIframesDimensions(iframes) })
      }
    }

    handleIframes = _ => {
      if (this.containerRef.current && this.state.dims.length) {
        const iframes = Array.from(this.containerRef.current.querySelectorAll('iframe'))
        const { width: currentWrapperWidth } = this.containerRef.current.getBoundingClientRect()
        const maxWidth = Math.min(currentWrapperWidth, maxWrapperWidth || 1000000)
        this.updateIframesDimensions(iframes, maxWidth)
      }
    }

    updateIframesDimensions = (iframes, wrapperWidth) => {
      iframes.map((iframe, i) => {
        const { width, height, ratio } = this.state.dims[i]
        iframe.style.width = ratio ? `${wrapperWidth}px` : width
        iframe.style.height = `${ratio ? wrapperWidth * ratio : height}px`
      })
    }

    render() {
      return <WrappedComponent {...this.props} forwardedRef={this.containerRef} />
    }
  }
}
