import React from 'react'
import { inject, observer } from 'mobx-react'
import noop from 'lodash/noop'
import { translator } from '../../lib/translator'
import {
  ARCHIVE_PHOTO_KEY,
  ARCHIVE_HISTORICAL_KEY,
  ARCHIVE_MEDIA_KEY,
  ARCHIVE_PERMANENT_COLLECTION,
} from '../../lib/const'
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg'
import {
  PhotoArchiveContents,
  HistoricalArchiveContents,
  AudiovideoArchiveContents,
} from './ArchiveLightboxContents'
import noImageIt from '../../images/no_image_it.svg'
import noImageEn from '../../images/no_image_en.svg'
import styles from './ArchiveLightbox.module.css'
import { LoaderOval, ArchiveImgWithFallback, ArchiveCollectionContents } from '../../components'

@inject('rootStore')
@observer
class ArchiveLightbox extends React.Component {
  handleNextClick = nextId => e => {
    const { updateFn, isLoadMorePossible, resultsArr } = this.props
    const nextResultIndex = resultsArr.findIndex(r => r.id === nextId)

    if (isLoadMorePossible && nextResultIndex === -1) {
      const currentResultsArrLength = resultsArr.length
      updateFn(() => {
        this.buildOpenLightbox(resultsArr[currentResultsArrLength].id)()
      })
    } else {
      this.buildOpenLightbox(nextId)(e)
    }
  }

  buildOpenLightbox = id => e => {
    if (e) e.preventDefault()
    const { setActiveItemId } = this.props.rootStore.ui.archiveLightbox
    setActiveItemId(id)
  }

  closeLightbox = e => {
    e.preventDefault()

    const { resetActiveItemId } = this.props.rootStore.ui.archiveLightbox
    resetActiveItemId()
  }

  get archiveInfoLayouts() {
    const additionalCollections = this.props.rootStore.filteredArchives.reduce(
      (acc, { collection_filter_archives_slug }) => ({
        ...acc,
        [collection_filter_archives_slug]: ArchiveCollectionContents,
      }),
      {},
    )
    return {
      ...additionalCollections,
      [ARCHIVE_PHOTO_KEY]: PhotoArchiveContents,
      [ARCHIVE_HISTORICAL_KEY]: HistoricalArchiveContents,
      [ARCHIVE_MEDIA_KEY]: AudiovideoArchiveContents,
      [ARCHIVE_PERMANENT_COLLECTION]: ArchiveCollectionContents,
    }
  }

  render() {
    const { rootStore, isLoadMorePossible, loadState } = this.props
    const {
      ui,
      navigation,
      archive: { archiveId, result },
    } = rootStore
    const { currentRouteLang } = navigation
    const { archiveLightbox, archiveSingleTriennale } = ui
    const { activeItemId } = archiveLightbox
    const { activeTab } = archiveSingleTriennale

    if (!result || !this.archiveInfoLayouts) {
      return null
    }

    const { current, next, prev } = result
    const { title, pictureFileUrl, videoPosterFileUrl, videoFileUrl } = current

    //! Need Refactor Help
    const ArchiveInfo = activeTab
      ? this.archiveInfoLayouts[activeTab]
      : archiveId === 'global'
      ? this.archiveInfoLayouts[current.archive]
      : this.archiveInfoLayouts[archiveId]

    return (
      <div className={`${styles.lightbox} ${activeItemId ? styles.lightboxIsOpen : ''}`}>
        <div className={`${styles.lightboxInner}`}>
          <button
            className={`${styles.lightboxCloseBtn}`}
            onClick={this.closeLightbox}
            aria-label={currentRouteLang === 'it' ? 'Chiudi la lightbox' : 'Close the lightbox'}
          >
            <CloseIcon className={styles.xIcon} aria-hidden="true" />
          </button>
          <div className={`${styles.inner}`}>
            <div className={`${styles.contents}`}>
              <div className={`${styles.right}`}>
                <div className={`${styles.media}`}>
                  <div className={`${styles.mediaContent}`}>
                    <div className={`${styles.mediaContentInner}`}>
                      {loadState && loadState === 'loading' ? (
                        <div className={styles.loaderWrapper}>
                          <div className={styles.loaderWrapperInner}>
                            <LoaderOval isCompact={true} />
                          </div>
                        </div>
                      ) : videoFileUrl ? (
                        <video controls poster={videoPosterFileUrl} onError={noop}>
                          <source src={videoFileUrl} type="video/mp4" />
                        </video>
                      ) : pictureFileUrl ? (
                        <ArchiveImgWithFallback
                          src={pictureFileUrl}
                          title={title}
                          hasAdditionalClass={true}
                        />
                      ) : (
                        <img
                          className={styles.placeholder}
                          src={currentRouteLang === 'it' ? noImageIt : noImageEn}
                          alt={translator('noImgText')(currentRouteLang)}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className={`${styles.navigation}`}>
                  <div className={`${styles.navigationInner}`}>
                    <div className={`${styles.navigationPrev}`}>
                      {prev && (
                        <button
                          className={`${styles.navigationBtn} link backLink internalLink regular verySmallText`}
                          onClick={this.buildOpenLightbox(prev.id)}
                        >
                          {translator('lightboxPrev')(currentRouteLang)}
                        </button>
                      )}
                    </div>
                    <div className={`${styles.navigationNext}`}>
                      {(next || isLoadMorePossible) && (
                        <button
                          className={`${styles.navigationBtn} link internalLink regular verySmallText`}
                          onClick={this.handleNextClick(next && next.id)}
                        >
                          {translator('lightboxNext')(currentRouteLang)}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${styles.left} verySmallText`}>
                <div className={styles.descriptionWrapper}>{ArchiveInfo(current)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ArchiveLightbox
