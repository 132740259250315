import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styles from './ArchivePagesBlock.module.css'
import { ARCHIVE_API_URL } from '../../lib/const'
import { ArchivePagesBlockItem } from '../../components'

const ArchivePagesBlock = ({ pages }) => {
  const [counters, setCounters] = useState([])

  useEffect(() => {
    async function fetchData() {
      const { data: counters } = await axios.get(ARCHIVE_API_URL)
      setCounters(counters)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!pages.length || !counters.length) {
    return null
  }

  return (
    <>
      <div className={styles.block}>
        {pages.map((p, i) => (
          <ArchivePagesBlockItem
            page={p}
            counter={counters.find(({ id }) => id === p.id).count}
            key={i}
          />
        ))}
      </div>
    </>
  )
}

export default ArchivePagesBlock
