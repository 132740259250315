import React from 'react'
import { observer, inject } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import styles from './EventTitles.module.css'
import { transformTextFieldNewlinesToBr } from '../../lib/utils'

const EventTitles = inject('rootStore')(
  observer(props => {
    const {
      author,
      title,
      titleClass = '',
      typeOfPost,
      width,
      magazineSeries,
      rootStore: {
        ui: { labelsColor },
      },
    } = props

    if (!title || title.length === 0) {
      return null
    }
    return author !== null && !isEmpty(author) && typeOfPost !== 'magazine' ? (
      <>
        {magazineSeries?.[0]?.value?.slug && (
          <h2
            className={`${titleClass} bold ${styles.author} ${width}`}
            style={{ color: labelsColor, textTransform: 'uppercase' }}
          >
            {magazineSeries?.[0]?.value?.slug}
          </h2>
        )}
        <h1
          className={`${titleClass} ${styles.title} ${width}`}
          dangerouslySetInnerHTML={{ __html: transformTextFieldNewlinesToBr(title) }}
        />
        <h2
          className={`${titleClass} bold ${styles.author} ${width}`}
          style={{ color: labelsColor }}
          dangerouslySetInnerHTML={{ __html: transformTextFieldNewlinesToBr(author) }}
        />
      </>
    ) : (
      <>
        <h1
          className={`${titleClass} ${styles.title} ${width}`}
          dangerouslySetInnerHTML={{ __html: transformTextFieldNewlinesToBr(title) }}
        />
      </>
    )
  }),
)

export { EventTitles }
