import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { isEmpty } from 'lodash'
import styles from './ZoneTicketsAmount.module.css'
import { translator } from '../../lib/translator'
import { customFormattedDate, formatVivaticketDate } from '../../lib/date-utils'
import { ReductionCounter } from './ReductionCounter'
import { GoForwardBtn } from './GoForwardBtn'
import { ReactComponent as ArrowDownIcon } from '../../images/icons/arrow-down.svg'
import { isASubscriptionOrExhibition } from '../../temp/ticketing-utils'
import { Modal, Title } from '../../components'
import { scrollViewVertically } from '../../lib/scroll'

const RELATIVES_CONTENTS = {
  it: `
  <p>
  Gentile cliente,
  <br />
  sull’evento selezionato è stata attivata la possibilità di acquisto per utenti congiunti.
  <br />
  In base alla dichiarazione effettuata il sistema permetterà la selezione di posti
  contigui, diversamente obbligherà al mantenimento del distanziamento necessario per
  termini di legge.
  <br />
  Il sottoscritto dichiara, sotto la propria responsabilità, che:
  </p>
  <ul>
    <li>
      selezionando l’opzione congiunti sta acquistando biglietti destinati a componenti del
      medesimo nucleo familiare e/o a congiunti;
    </li>
    <li>non sussiste altra ragione per applicare il distanziamento sociale;</li>
    <li>
      non è sottoposto ad alcun provvedimento di quarantena e di non essere risultato
      positivo al Covid19.
    </li>
  </ul>
  `,
  en: `
  <p>
  Dear customer,
  <br />
  the purchase option for joint-relative users has been activated on the selected event.
  <br />
  On the basis of the declaration made, the system will allow the selection of contiguous places, otherwise it will oblige the maintenance of the distance according to applicable law.
  <br />
  The undersigned declares, under his own responsibility, that:
  </p>
  <ul>
    <li>
      by selecting the joint-relative option, is purchasing tickets for members of the same family and / or relatives;
    </li>
    <li>there is no other reason to apply the social distancing;</li>
    <li>
    is not subject to any quarantine measure and not to have tested positive for Covid19.
    </li>
  </ul>
  `,
}

const Relatives = inject('rootStore')(({ rootStore, zoneId, show }) => {
  const {
    navigation: { currentRouteLang },
    tickets: {
      cart: { updateRelatives },
    },
  } = rootStore
  const [relative, setRelative] = useState(null)
  const [isShow, setShow] = useState(show)
  const handleSubmit = () => {}
  const handleOptionChange = e => {
    setRelative(e.target.value)
    updateRelatives(zoneId, e.target.value)
    setTimeout(() => {
      setShow(false)
      setRelative(null)
    }, 200)
  }
  useEffect(() => {
    setShow(show)
  }, [show])
  return (
    <>
      <Modal show={isShow}>
        <div
          dangerouslySetInnerHTML={{
            __html: RELATIVES_CONTENTS[currentRouteLang],
          }}
        />
        <form onSubmit={handleSubmit}>
          <input
            type="radio"
            id="relative"
            name="relative"
            checked={relative === '1'}
            value="1"
            onChange={handleOptionChange}
          />
          {` `}
          <label htmlFor="relative">{translator('ticketJointRelative')(currentRouteLang)}</label>
          <br />
          <input
            type="radio"
            id="noRelative"
            name="relative"
            checked={relative === '0'}
            value="0"
            onChange={handleOptionChange}
          />
          {` `}
          <label htmlFor="noRelative">
            {translator('ticketJointNonRelative')(currentRouteLang)}
          </label>
        </form>
      </Modal>
    </>
  )
})

const Reductions = inject('rootStore')(
  observer(({ rootStore, vcode, zone }) => {
    const { selectedPerformance, cart } = rootStore.tickets
    const { availability, reductions, zone_id } = zone
    const { pcode } = selectedPerformance

    return (
      <div className={styles.seatsWrapper}>
        <ul className={styles.seatList}>
          {reductions.map((reduction, j) => {
            const nTickets =
              cart.data.find(d => d.reductionId === reduction.id && d.zoneId === zone_id)
                ?.nTickets || 0
            return (
              <ReductionCounter
                zoneId={zone_id}
                key={j}
                reduction={reduction}
                availability={availability}
                pcode={pcode}
                vcode={vcode}
                nTickets={nTickets}
              />
            )
          })}
        </ul>
      </div>
    )
  }),
)

const Availability = inject('rootStore')(
  ({
    data,
    isASubscriptionOrExhibitionTicket,
    rootStore: {
      navigation: { currentRouteLang },
    },
  }) => {
    const isLastSeats = data => data < 4 && data > 0
    const isSoldOut = data => data === 0

    return (
      <>
        {isSoldOut(data) && <span className="bold">{translator('soldOut')(currentRouteLang)}</span>}
        {!isASubscriptionOrExhibitionTicket && isLastSeats(data) && (
          <span className="bold">
            {translator('only')(currentRouteLang)} {data} {translator('seats')(currentRouteLang)}
          </span>
        )}
      </>
    )
  },
)

const DropDownArrow = () => {
  return (
    <div className={`${styles.hintIcon}`}>
      <ArrowDownIcon className={styles.expandIcon} height="12" aria-hidden="true" />
    </div>
  )
}

const ZoneContent = ({
  zone,
  showId,
  currentRouteLang,
  ticketByShowId,
  amountByZone,
  nonRelativeGap,
}) => {
  const [isExpandend, setIsExpanded] = useState(false)

  const { zone_id, description, eng_description, availability } = zone

  const ticket = ticketByShowId
  const isASubscriptionOrExhibitionTicket = isASubscriptionOrExhibition(ticket)

  const buildExpandZones = e => {
    e.preventDefault()
    setIsExpanded(!isExpandend)
  }

  const showRelatives = amountByZone > 1

  return (
    <li className={`${styles.listItem} ${isExpandend ? styles.listItemIsExpanded : ''}`}>
      <button
        className={`${styles.listItemToggle} bodyTextBig`}
        onClick={buildExpandZones}
        disabled={availability === 0}
        onKeyDown={() => {}}
      >
        <div className={`${styles.zoneName} bold`}>
          <span>{currentRouteLang === 'it-it' ? description : eng_description}</span>
        </div>
        <div className={`${styles.availability}`}>
          <Availability
            data={availability}
            isASubscriptionOrExhibitionTicket={isASubscriptionOrExhibitionTicket}
          />
          {availability !== 0 && <DropDownArrow />}
        </div>
      </button>
      <Reductions
        zone={zone}
        vcode={ticket?.vcode}
        showId={showId}
        currentRouteLang={currentRouteLang}
      />
      {nonRelativeGap && !isASubscriptionOrExhibitionTicket && (
        <Relatives zoneId={zone_id} show={showRelatives} />
      )}
    </li>
  )
}

const Zone = inject('rootStore')(
  observer(
    ({
      rootStore: {
        navigation: { currentRouteLang },
        tickets: {
          cart,
          ticketByShowId,
          selectedPerformance: { nonRelativeGap },
        },
      },
      zone,
    }) => {
      const getNTicketsAmountByZone =
        cart.amountByZone.find(d => d.zoneId === zone.zone_id)?.amount || 0

      return (
        <ZoneContent
          currentRouteLang={currentRouteLang}
          zone={zone}
          ticketByShowId={ticketByShowId}
          amountByZone={getNTicketsAmountByZone}
          nonRelativeGap={nonRelativeGap}
        />
      )
    },
  ),
)

@inject('rootStore')
@observer
export class ZoneTicketsAmount extends React.Component {
  constructor(props) {
    super(props)
    this.titleEl = React.createRef()
  }

  setARandomReductionToCart() {
    const {
      tickets: {
        selectedPerformance,
        ticketByShowId: { vcode },
        cart,
        setNextStep,
      },
    } = this.props.rootStore
    const { pcode } = selectedPerformance
    const zones = selectedPerformance?.zone ?? []
    if (isEmpty(zones)) return
    const { zone_id, reductions, availability } = zones[0]
    const { id, price, presale, commission, iva, ...reduction } = reductions[0]
    cart.addItem({
      ...reduction,
      vcode,
      pcode,
      zoneId: zone_id,
      reductionId: id,
      price: 0,
      availability,
    })
    setNextStep(1)
  }

  componentDidMount() {
    const node = this.titleEl.current
    if (node !== null) {
      const { y } = node.getBoundingClientRect()
      scrollViewVertically(y)
    }
  }

  componentDidUpdate() {
    const { data, rootStore } = this.props
    const { barcode } = rootStore.tickets.subscription
    !isEmpty(data?.zone) && barcode && this.setARandomReductionToCart()
  }

  onClickHandler = () => {
    const {
      tickets: { setNextStep },
    } = this.props.rootStore
    setNextStep(1)
  }

  render() {
    const { data, rootStore } = this.props
    const {
      tickets: { cart, showId, subscriptionTickets },
      navigation: { currentRouteLang },
    } = rootStore
    const { selectedReductions } = cart

    if (!data) return null

    const selectedDate = formatVivaticketDate(data?.dataInizio)
    const formattedDate = customFormattedDate(selectedDate, currentRouteLang, 'dddd DD MMMM YYYY')
    const isASubscription = subscriptionTickets.map(s => s.id_show).indexOf(showId) >= 0
    const zones = data?.zone || []

    return (
      <div ref={this.titleEl}>
        {!isEmpty(zones) ? (
          <>
            <Title text={translator('selectTicket')(currentRouteLang)} margin={0} />
            {!isASubscription && (
              <div className={styles.label}>{`${formattedDate} ${data.time}`}</div>
            )}
            <ul className={`${styles.list}`}>
              {zones.map((zone, i) => (
                <Zone key={i} zone={zone} />
              ))}
            </ul>
          </>
        ) : (
          <p>{translator('loadingMessage')(currentRouteLang)}</p>
        )}
        <GoForwardBtn
          onClick={this.onClickHandler}
          label={translator('goToCartBtn')(currentRouteLang)}
          disabled={isEmpty(selectedReductions)}
        />
      </div>
    )
  }
}
