import React from 'react'
import { inject, observer } from 'mobx-react'
import { news as type } from '../lib/prismic/news'
import { NotFound } from './NotFound'
import { LoaderOval, CurrentContentList } from '../components'
import { haveRouteSecondLevel } from '../lib/utils'
import NewsItemsPreview from '../components/newsComponents/NewsItemsPreview'
import { NEWS_FILTERS, NEWS_FILTERS_KEYS } from '../lib/const'

const switcherState = secondLevel => news => {
  return {
    notFound: <NotFound />,
    rest: secondLevel ? null : (
      <CurrentContentList
        contentType={'news'}
        type={type}
        renderComponent={NewsItemsPreview}
        filters={NEWS_FILTERS}
        filtersKeys={NEWS_FILTERS_KEYS}
        filterTypesToExclude={['type']}
        titleLabel="newsPageTitle"
      />
    ),
    loading: <LoaderOval />,
  }
}

export const News = inject('rootStore')(
  observer(({ rootStore }) => {
    const {
      appState,
      news,
      navigation: { currentRouteName },
    } = rootStore
    const renderComponent = switcherState(haveRouteSecondLevel(currentRouteName))
    return renderComponent(news)[appState]
  }),
)
