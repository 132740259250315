import React from 'react'

const capitalize = c => (c ? c.charAt(0).toUpperCase() + c.slice(1) : '')

const normalize = fn => input =>
  Array.isArray(input) ? input.map(i => fn(i)).join(', ') : fn(input)

const ArchiveLabel = label => value => {
  if (!value) return
  return (
    <p>
      <b>{label}:</b> {normalize(capitalize)(value)}
    </p>
  )
}

export default ArchiveLabel
