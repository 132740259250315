import React from 'react'
import { inject } from 'mobx-react'
import { magazine } from '../../lib/prismic/magazine'
import { SectionTitlePlainWithLink } from '../Shared/Titles'
import { translator } from '../../lib/translator'
import styles from './Home.module.css'
import { CurrentContent } from '../../components'
import { MagazineListCards } from '../Magazine/MagazineListCards'

const HomeMagazineSection = inject('rootStore')(({ rootStore }) => {
  const {
    navigation: { currentRouteLang },
  } = rootStore

  return (
    <section className={styles.section}>
      <SectionTitlePlainWithLink
        title={'Magazine'}
        cta={translator('ctaMagazineSection')(currentRouteLang)}
        border={true}
        link={{
          href: currentRouteLang === 'it' ? `/magazine` : `/en/magazine`,
          title: 'Magazine',
        }}
      />
      <CurrentContent
        type={magazine}
        contentType="magazine"
        renderComponent={MagazineListCards}
        chronological
        limit={4}
        typeOfList={3}
      />
    </section>
  )
})

export default HomeMagazineSection
