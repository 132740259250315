import React from 'react'
import { inject, Observer } from 'mobx-react'
import { ReactComponent as LogoT } from 'images/Triennale_Logo_T.svg'
import styles from './Logo.module.css'

const EXCLUDED_COLOR_VALUES = ['white', '#fff', '#ffffff', 'transparent', '', null, undefined]
const DEFAULT_COLOR = 'black'

const Logo = inject('rootStore')(({ rootStore }) => {
  const {
    ui: { logoColor },
  } = rootStore
  return (
    <Observer>
      {() => (
        <a
          className={`outline ${styles.link}`}
          href="https://archivi.triennale.org"
        >
          <LogoT
            style={{ color: EXCLUDED_COLOR_VALUES.includes(logoColor) ? DEFAULT_COLOR : logoColor }}
            className={styles.img}
          />
        </a>
      )}
    </Observer>
  )
})

export default Logo
