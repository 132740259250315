import React from 'react'

export const WithOnlineLabelFilters = ({
  children,
  currentLang,
  isOnlineEvent,
  textClasses,
  style,
}) => {
  return (
    <>
      {currentLang === 'en' && isOnlineEvent && (
        <span className={textClasses} style={{ paddingRight: '0.3rem', ...style }}>
          Online
        </span>
      )}
      {children}
      {currentLang === 'it' && isOnlineEvent && (
        <span className={textClasses} style={{ paddingLeft: '0.3rem', ...style }}>
          online
        </span>
      )}
    </>
  )
}
