import React from 'react'
import { inject, observer } from 'mobx-react'
import { FiltersBlock } from '../Shared/Filters/FiltersBlock'

@inject('rootStore')
@observer
class BasicFilters extends React.Component {
  componentDidMount() {
    document.documentElement.classList.add('hasFilters')
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('hasFilters')
  }

  tooglePanel = () => {
    const { id, rootStore } = this.props
    const { ui } = rootStore
    ui.toggleFilter(id)
  }

  closePanel = () => {
    const { id, rootStore } = this.props
    const { ui } = rootStore
    ui.hideFilter(id)
  }

  render() {
    const { id, filters, filterTypesToExclude, keys, type } = this.props
    const { ui } = this.props.rootStore
    const { setOffset } = ui
    const { isFiltersPanelOpen, offset } = ui[id]

    return (
      <FiltersBlock
        closePanel={this.closePanel}
        setOffset={setOffset}
        filters={filters}
        filterTypesToExclude={filterTypesToExclude}
        keys={keys}
        isOpen={isFiltersPanelOpen}
        offset={offset}
        id={id}
        type={type}
      />
    )
  }
}

export default BasicFilters
