import React from 'react'
import styles from './RegularEvent.module.css'
import { BuyTicketLink } from '../../components/Shared/BuyTicketLink'
import { checkLinkValidity } from '../../lib/survive-prismic'
import { translator } from '../../lib/translator'
import { getFormattedDate } from '../../lib/date-utils'

export const MultipleDatesWithPrice = ({ dates, eventData, lang }) => {
  return (
    <div className={styles.specificDatesBlock}>
      {dates.map((specific_date, i) => {
        const formattedDate = getFormattedDate(specific_date.date_time, {
          lang,
          withDayOfWeek: true,
          withHours: true,
        })

        const _registration_link = checkLinkValidity(specific_date.registration_link)

        return (
          <div key={i} className={styles.specificDateBlock}>
            <span className={`${styles.specificDateDate} smallText bold`}>{formattedDate}</span>
            {eventData && (
              <div className={styles.button}>
                <BuyTicketLink eventData={eventData} />
              </div>
            )}

            {_registration_link && (
              <div className={styles.button}>
                <a
                  className={`button smallText`}
                  href={_registration_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="external link"
                >
                  {translator('register')(lang)}
                </a>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
