import React from 'react'
import { textWrapper } from './HeroCard.module.css'

export const HeroCard = ({ background_color, text_color, children }) => {
  return (
    <div
      className={textWrapper}
      style={{
        backgroundColor: background_color !== null ? background_color : 'transparent',
        color: text_color !== null ? text_color : 'black',
      }}
    >
      {children}
    </div>
  )
}
