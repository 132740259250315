import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { composeUid } from '../../lib/utils/uid-utils'
import { ResponsiveImage } from '../../components/Shared/Templating'
import { FormattedDateEvent } from '../../components/Shared/FormattedDateEvent'
import { PageTitle } from '../../components/Shared/Titles'
import { StickyBlock, SingleEventPreviewStickyBlock } from '../../components/Shared/StickyBlock'
import styles from './ContainerPage.module.css'
import { InternationalParticipationsSection } from '../../components/ContainerPage/InternationalParticipation'
import { PriceTableSection } from '../../components/Shared/PriceTableSection'
import { BuyTicketLink } from '../../components/Shared/BuyTicketLink'
import { RelatedMagazinesSection } from '../../components/Shared/RelatedMagazinesSection'
import { IframeWrapper } from '../../components/Shared/IframeWrapper'
import { fixPath } from '../../lib/ssr'
import { Caption } from '../Shared/Paragraphs'

@inject('rootStore')
@observer
export class ContainerPage extends React.Component {
  state = {
    page: undefined,
  }

  componentDidMount() {
    const {
      getBy,
      navigation: {
        router: { location },
      },
    } = this.props.rootStore
    const pathname = fixPath(location.pathname)
    const page = getBy('containerPages')({ uid: composeUid(pathname) })
    this.setState({ page })
    this.recursiveCheckData()
  }

  recursiveCheckData() {
    if (isEmpty(this.state.page)) {
      setTimeout(() => this.recursiveCheckData(), 200)
    } else {
      const { ui } = this.props.rootStore
      ui.setBodyBgColor(this.state.page.data.background_color)
      const passive = window.Modernizr.passiveeventlisteners ? { passive: true } : false
      window.addEventListener('scroll', this.checkScroll, passive)
    }
  }

  componentWillUnmount() {
    this.props.rootStore.ui.resetBodyBgColor()
    const passive = window.Modernizr.passiveeventlisteners ? { passive: true } : false
    window.removeEventListener('scroll', this.checkScroll, passive)
  }

  checkScroll = e => {
    const { resetBodyBgColor, setBodyBgColor } = this.props.rootStore.ui
    const scrollTop = (document.documentElement || document.body).scrollTop

    if (scrollTop > 0) {
      resetBodyBgColor()
    } else {
      setBodyBgColor(this.state.page.data.background_color)
    }
  }

  render() {
    const {
      currentRouteLang,
      router: {
        location: { pathname },
      },
    } = this.props.rootStore.navigation

    if (isEmpty(this.state.page)) {
      return null
    }

    const {
      title,
      related_contents,
      related_magazines,
      start_date,
      end_date,
      abstract_image,
      abstract_media_caption,
      abstract_text,
      abstract_embed_media_html,
      abstract_links,
      show_id,
      international_participations,
      price_lists,
      cover_image_desktop,
      cover_image_mobile,
      cover_embed_media_html,
      cover_image_caption,
    } = this.state.page.data

    return (
      <>
        <div className={styles.header}>
          <PageTitle title={title} border={false} />
          {FormattedDateEvent(start_date, end_date, currentRouteLang, `bold letterSpaced`)}
        </div>
        <div className={styles.wrapper}>
          <div className={styles.mediaWrapper}>
            {cover_embed_media_html?.length > 0 && cover_embed_media_html[0].text !== '' ? (
              <IframeWrapper html={cover_embed_media_html} caption={cover_image_caption} />
            ) : (
              <>
                <ResponsiveImage
                  img={cover_image_desktop}
                  additionalClassName={`${styles.desktopHeroImg} ${
                    cover_image_mobile ? styles.desktopHeroImgHasMobile : ''
                  }`}
                />
                <ResponsiveImage
                  img={cover_image_mobile}
                  additionalClassName={styles.mobileHeroImg}
                />
                {cover_image_caption && cover_image_caption?.length > 0 && (
                  <Caption caption={cover_image_caption} />
                )}
              </>
            )}
          </div>

          <div className={styles.bodyWrapper}>
            {/* Abstract */}
            <StickyBlock
              image={abstract_image}
              caption={abstract_media_caption}
              text={abstract_text}
              links={abstract_links}
              html={abstract_embed_media_html}
              isAbstract={true}
              renderAsPrismic={true}
              modifierClass={styles.section}
              index={0}
            />
            {show_id && <BuyTicketLink showId={show_id} />}
            {/* Children events */}
            {related_contents.map((event, i) => {
              const { data, uid } = event
              return SingleEventPreviewStickyBlock(
                data,
                uid,
                {
                  renderAsPrismic: true,
                  lang: currentRouteLang,
                  modifierClass: styles.section,
                },
                i,
              )
            })}
            <InternationalParticipationsSection
              data={international_participations}
              lang={currentRouteLang}
              path={pathname}
            />
            {price_lists?.length > 0 && (
              <div className={styles.pricing}>
                {price_lists.map((price_list, i) =>
                  PriceTableSection({ table: price_list, lang: currentRouteLang, index: i }),
                )}
              </div>
            )}
            <RelatedMagazinesSection
              related_magazines={related_magazines}
              lang={currentRouteLang}
            />
          </div>
        </div>
      </>
    )
  }
}
