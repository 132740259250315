import React from 'react'
import { SectionTitlePlain } from '../../components/Shared/Titles'
import { RelatedEventsSection } from '../../components/Shared/RelatedEventsSection'
import { filterEmptyPrismicRepeatersContents } from '../../lib/survive-prismic'
import { PromotionsCard } from '../../components/Shared/Cards'
import { translator } from '../../lib/translator'
import { RelatedMagazinesSection } from '../../components/Shared/RelatedMagazinesSection'

export const RelatedBlock = ({ related_events, related_magazines, promotions, lang }) => {
  const filteredPromotions = filterEmptyPrismicRepeatersContents(promotions, 'data')

  return (
    <>
      <RelatedEventsSection related_events={related_events} lang={lang} />
      <RelatedMagazinesSection
        related_magazines={related_magazines}
        lang={lang}
        additionalClasses={'avoidFlex'}
      />
      {filteredPromotions?.length > 0 && (
        <section>
          <SectionTitlePlain
            title={translator('currentPromotionsSectionTitle')(lang)}
            border={true}
          />
          <PromotionsCard
            results={filteredPromotions}
            options={{
              lang: lang,
              areFiltersClickable: false,
              cssNamespace: 'noGrid',
            }}
          />
        </section>
      )}
    </>
  )
}
