import React from 'react'
import { Link } from '../../../components'
import styles from './listSeries.module.css'
import { linkResolver } from '../../../lib/link-resolver'

const CardContent = ({ data, uid, isFocused }) => {
  const {
    data: { name, abstract },
  } = data
  const { text: titleSerie } = name?.[0]
  const { text: abstractDescription } = abstract?.[0]
  return (
    <div key={uid}>
      <div className={styles.inner}>
        <h2>{titleSerie}</h2>
        <p>{abstractDescription}</p>
      </div>
    </div>
  )
}

export const ListSeriesItem = ({ data, uid, options }) => {
  const { isFocused, lang } = options || {}
  const { background_color } = data
  return (
    <div
      className={`${isFocused ? styles.focusItem : styles.gridItem}`}
      style={{
        backgroundColor: background_color,
      }}
    >
      <Link
        to={linkResolver({
          contentType: 'magazine_series',
          uid,
          lang,
        })}
      >
        <CardContent data={data} currentRouteLang={lang} isFocused={isFocused} />
      </Link>
    </div>
  )
}
