import React from 'react'
import { inject } from 'mobx-react'
import styles from '../../components/Ongoing/SinglePostPreviewLayout.module.css'
import { EventLocation } from '../../components/SingleEvent/EventLocation'

const EventDate = inject('rootStore')(
  ({ rootStore, dateString, data, location, location_map_link, width }) => {
    const {
      ui: { labelsColor },
    } = rootStore

    return (
      <div>
        {dateString && (
          <div className={`${styles.smallEventDates} bold`} style={{ color: labelsColor }}>
            <p className={width}>{dateString}</p>
          </div>
        )}
        {data?.additional_info && (
          <div className={`${styles.smallEventAdditionalInfo} bold`} style={{ color: labelsColor }}>
            <p className={width}>{data.additional_info}</p>
          </div>
        )}
        {location && <EventLocation location={location} link={location_map_link} width={width} />}
      </div>
    )
  },
)

export default EventDate
