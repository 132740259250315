import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter, Redirect } from 'react-router-dom'
import shuffle from 'lodash/shuffle'
import { archivePreviewData } from '../lib/fake-data/archive-preview'
import { PageTitlePlain } from '../components/Shared/Titles'
import { translator } from '../lib/translator'
import styles from './NotFound.module.css'
import { isSnap } from '../lib/static-site'

@inject('rootStore')
@observer
export class NotFound extends React.Component {
  componentDidMount() {
    const {
      ui: { setBodyBgColor },
    } = this.props.rootStore
    setBodyBgColor('#a8a9bc')
    if (isSnap && !/404|sitemap\.html/.test(window.location.pathname)) {
      throw new Error('Page not found during Snap crawl!')
    }
  }

  componentWillUnmount() {
    const {
      ui: { resetBodyBgColor },
    } = this.props.rootStore
    resetBodyBgColor()
  }

  render() {
    const {
      navigation: { currentRouteLang },
      appState,
    } = this.props.rootStore

    const { image_showcase_images } = archivePreviewData
    const oneRandomItem = shuffle(image_showcase_images)[0]
    const {
      caption,
      image: { url, alt },
    } = oneRandomItem

    return appState === 'notFound' ? (
      <>
        <PageTitlePlain title={translator('NotFoundMessage')(currentRouteLang)} />
        <div className={styles.wrapper}>
          <div className={styles.stickyLeft}>
            <img className={styles.img} src={url} alt={alt} />
          </div>
          <div className={styles.right}>
            <figcaption
              className={styles.caption}
              dangerouslySetInnerHTML={{
                __html: caption,
              }}
            />
          </div>
        </div>
      </>
    ) : null
  }
}

export const CaptureRouteNotFound = withRouter(({ children, location }) => {
  return location && location.state && location.state.notFoundError ? (
    <NotFound location={location} />
  ) : (
    children
  )
})

export const RouteNotFound = () => <Redirect to={{ state: { notFoundError: true } }} />
