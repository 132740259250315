export const collezioneData = {
  it: {
    data: {
      title: [
        {
          text: 'Collezione',
        },
      ],
      description: [
        {
          type: 'paragraph',
          text:
            'La collezione include 1.600 oggetti di design italiano, dal 1927 a oggi, oltre 300 modelli della collezione Giovanni Sacchi, oltre 3.000 disegni di Alessandro Mendini, oltre 100 disegni originali di importanti designer italiani e internazionali costituenti il Fondo Gianni Veneziano, oltre 500 artefatti grafici, fondi fotografici, 60 tra capi e cartamodelli della Collezione Nanni Strada, 1.265 tra libri, materiali e documenti, 566 disegni e progetti grafici dalla Collezione Sirio Galli e materiali grafici sul design italiano degli anni Settanta-Novanta.',
          spans: [],
        },
      ],
    },
  },
  en: {
    data: {
      title: [
        {
          text: 'Collection',
        },
      ],
      description: [
        {
          type: 'paragraph',
          text:
            'La collezione include 1.600 oggetti di design italiano, dal 1927 a oggi, oltre 300 modelli della collezione Giovanni Sacchi, oltre 3.000 disegni di Alessandro Mendini, oltre 100 disegni originali di importanti designer italiani e internazionali costituenti il Fondo Gianni Veneziano, oltre 500 artefatti grafici, fondi fotografici, 60 tra capi e cartamodelli della Collezione Nanni Strada, 1.265 tra libri, materiali e documenti, 566 disegni e progetti grafici dalla Collezione Sirio Galli e materiali grafici sul design italiano degli anni Settanta-Novanta.',
          spans: [],
        },
      ],
    },
  },
}
