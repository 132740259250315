import React, { useEffect, useState } from 'react'
import { inject, Observer } from 'mobx-react'
import { event as eventType } from '../../lib/prismic/event'
import { linkResolver } from '../../lib/link-resolver'
import { SectionTitlePlainWithLink } from '../Shared/Titles'
import styles from './Home.module.css'
import { MagazineListCards } from '../Magazine/MagazineListCards'
import { filterEmptyPrismicRepeatersContents } from '../../lib/survive-prismic'
import { removeDuplicates } from '../../lib/filters-utils'

const HomePerformativeSection = inject('rootStore')(({ rootStore }) => {
  const {
    navigation: { currentRouteLangCode },
  } = rootStore
  const {
    performances,
    title_performances,
    cta_performances,
    cta_link_performances,
  } = rootStore.homePage.results[0].data
  const [currentPerformances, setCurrentPerformances] = useState([])
  const { getCurrent: getCurrentPerformances, fields: EVENT } = eventType
  const eventFilters = {
    [EVENT.TYPE]: ['Concerto', 'Danza', 'Performance', 'Spettacolo', 'Teatro', 'Dj set'],
  }
  const filteredPerformances = filterEmptyPrismicRepeatersContents(performances, 'id')

  async function getPerformances() {
    const current = (await getCurrentPerformances(eventFilters, {}, currentRouteLangCode)).results
    setCurrentPerformances(current)
  }

  useEffect(() => {
    getPerformances()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Performances = ({ pinned, chronological }) => {
    const filtered = removeDuplicates(pinned.concat(chronological), 'id')
    const results = filtered.slice(0, 3)
    return <MagazineListCards results={results} options={{ typeOfList: 1, limit: 1 }} />
  }

  return (
    <Observer>
      {() => (
        <section className={styles.section}>
          {title_performances[0] && cta_performances[0] && (
            <SectionTitlePlainWithLink
              title={title_performances[0].text}
              cta={cta_performances[0].text}
              border={true}
              link={{
                href:
                  cta_link_performances && cta_link_performances.link_type === 'Web'
                    ? cta_link_performances.url
                    : linkResolver(cta_link_performances),
                title: cta_performances[0].text,
              }}
            />
          )}
          <Performances pinned={filteredPerformances} chronological={currentPerformances} />
        </section>
      )}
    </Observer>
  )
})

export default HomePerformativeSection
