import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from '../../components'
import styles from './FooterNav.module.css'
import footerNav from '../../data/footer-nav'
import { Newsletter } from '../Shared/Newsletter'

@inject('rootStore')
@observer
class FooterNav extends React.Component {
  buildList = items => {
    const { isCurrentRoute, isParentOfCurrentRoute } = this.props.rootStore.navigation

    return (
      <ul className={`${styles.list} verySmallText regular`}>
        {items.map((item, i) => {
          const { label, link, isExternal, isComponent, props } = item

          if (isExternal) {
            return (
              <li key={i} className={styles.item}>
                <a
                  className={`outline externalLink ${styles.link}`}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {label}
                </a>
              </li>
            )
          } else {
            return (
              <li key={i} className={styles.item}>
                {isComponent && label === 'Newsletter' ? (
                  <Newsletter {...props} />
                ) : (
                  <Link
                    to={link}
                    className={`outline ${styles.link}${
                      isParentOfCurrentRoute({ path: link }) || isCurrentRoute({ path: link })
                        ? ` ${styles.linkActive}`
                        : ''
                    }`}
                  >
                    <span>{label}</span>
                  </Link>
                )}
              </li>
            )
          }
        })}
      </ul>
    )
  }

  render() {
    const { navigation } = this.props.rootStore
    const { isParentOfCurrentRoute, isCurrentRoute, currentRouteLang } = navigation

    if (!footerNav) {
      return null
    }

    return (
      <nav className={styles.nav}>
        <ul className={`${styles.list} verySmallText regular`}>
          {footerNav[currentRouteLang].map((item, i) => {
            const { items, primary } = item
            const { label, link, isDead } = primary

            const hasChildren = items.length > 0

            return (
              <li key={i} className={styles.item}>
                {isDead ? (
                  <div className={`verySmallText bold`}>
                    <span>{label}</span>
                  </div>
                ) : (
                  <Link
                    to={link}
                    className={`outline ${styles.link}${
                      isParentOfCurrentRoute({ path: link }) || isCurrentRoute({ path: link })
                        ? ` ${styles.linkActive}`
                        : ''
                    }`}
                  >
                    <span>{label}</span>
                  </Link>
                )}
                {hasChildren && this.buildList(items)}
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }
}

export default FooterNav
