import { types as t, getRoot } from 'mobx-state-tree'
import qs from 'query-string'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import { find, pull, pickBy, map } from 'lodash/fp'
import { FilterModel } from './filter'
import { PrismicModel } from './prismic'
import { dateToStringWithLocale } from '../lib/date-utils'

export const DataModel = t
  .model('DataModel', {
    filter: t.optional(FilterModel, {}),
    totalPages: t.optional(t.number, 1),
    results: t.optional(t.array(PrismicModel), []),
    dataState: t.optional(
      t.enumeration('dataState', ['rest', 'loading', 'error', 'notFound']),
      'loading',
    ),
  })
  .actions(self => {
    const setResults = results => {
      self.results = results
    }
    const injectFilterToStore = filters => {
      Object.keys(filters).map(key => {
        typeof filters[key] === 'string' && !(key === 'start_date' || key === 'end_date')
          ? self.filter.replace(key, [filters[key]])
          : self.filter.replace(key, filters[key])
      })
    }
    const setSingleFilter = ({ type, value }) => {
      self.filter = { ...self.filter, [type]: value }
      self.urlUpdate()
    }
    const toggleFilter = ({ type, value }) => {
      const filter = { type, value }

      if (type === 'start_date') {
        // Caution: Time related and date picker filters are exclusive: if I select one option all the others are deselected
        self.resetFilterByType('time_related')
        self.resetDateAndUpdate()
      } else if (type === 'end_date') {
        self.resetSingleDateAndUpdate(type)
      } else {
        if (!self.isFilterActive(filter)) {
          // Caution: Time related and date picker filters are exclusive: if I select one option, all the others are deselected
          if (type === 'time_related') {
            self.resetFilterByType(type)
            self.resetDate()
          }
          self.addFilterItem(filter)
        } else {
          self.removeFilterItem(filter)
        }
      }
      self.urlUpdate()
    }
    const addFilterItem = ({ type, value }) => {
      self.filter[type].push(value)
    }
    const removeFilterItem = ({ type, value }) => {
      self.filter[type] = pull(value)(self.filter[type].toJSON())
    }
    const resetFilterByType = type => {
      self.filter[type] = []
    }
    const resetAllFilter = () => {
      self.filter = {}
      self.urlUpdate()
    }
    const setSingleDateAndUpdate = day => {
      self.filter.start_date = day
        ? dateToStringWithLocale(day, { locale: 'en-gb', format: 'prismic' })
        : undefined
      self.urlUpdate()
    }
    const setDateAndUpdate = range => {
      self.filter.start_date = range.from
        ? dateToStringWithLocale(range.from, { locale: 'en-gb', format: 'prismic' })
        : undefined
      self.filter.end_date = range.to
        ? dateToStringWithLocale(range.to, { locale: 'en-gb', format: 'prismic' })
        : undefined
      self.urlUpdate()
    }
    const resetSingleDateAndUpdate = type => {
      self.filter[type] = undefined
      self.urlUpdate()
    }
    const resetDate = () => {
      self.filter.start_date = undefined
      self.filter.end_date = undefined
    }
    const resetDateAndUpdate = () => {
      self.resetDate()
      self.urlUpdate()
    }
    return {
      setResults,
      toggleFilter,
      addFilterItem,
      removeFilterItem,
      resetFilterByType,
      resetAllFilter,
      injectFilterToStore,
      setDateAndUpdate,
      resetSingleDateAndUpdate,
      resetDate,
      resetDateAndUpdate,
      setSingleFilter,
      setSingleDateAndUpdate,
    }
  })
  .views(self => {
    const getActiveFilters = () => {
      return pickBy(v => !isEmpty(v))(self.filter.toJSON())
    }
    const getActiveFiltersValues = () => {
      const filters = self.getActiveFilters()
      const transformWithValue = k => ({ label: k, values: filters[k] })
      return map(transformWithValue)(Object.keys(filters))
    }
    const isFilterActive = ({ type, value }) => {
      if (type === 'start_date' || type === 'end_date') {
        return true
      }
      return Boolean(find(f => f === value)(self.filter[type]))
    }
    const haveFiltersActive = () => {
      return Object.keys(self.filter).reduce((have, key) => {
        if (!isEmpty(self.filter[key])) {
          have = true
        }
        return have
      }, false)
    }
    const urlUpdate = () => {
      const {
        navigation: { router },
      } = getRoot(self)
      router.push({ search: `?${qs.stringify(getActiveFilters())}` })
    }
    const getResultsOrderedByTitle = () => {
      return orderBy(self.results, author => author.data.name[0].text)
    }
    return {
      getActiveFilters,
      getActiveFiltersValues,
      getResultsOrderedByTitle,
      haveFiltersActive,
      isFilterActive,
      urlUpdate,
    }
  })
