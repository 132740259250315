import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { reaction } from 'mobx'
import { Calendar } from './Calendar'
import { ZoneTicketsAmount } from './ZoneTicketsAmount'
import { EventTicketHeader } from './EventTicketHeader'
import { Timeline } from './Timeline'
import { LoaderOval } from '../../components'
import { fbEvent } from '../../lib/facebook'
@inject('rootStore')
@observer
export class TicketShowDate extends React.Component {
  async setPerformance() {
    const {
      tickets: { setPerformanceAndFetchData, ticketByShowId },
    } = this.props.rootStore
    await setPerformanceAndFetchData(ticketByShowId?.performance[0].idEvento)
  }

  async componentDidMount() {
    const {
      tickets: { availableTickets, fetchPrismicAndVivaticketEvents },
    } = this.props.rootStore
    isEmpty(availableTickets) && (await fetchPrismicAndVivaticketEvents())
    fbEvent('InitiateCheckout')
    reaction(
      () => ({
        subscriptionTickets: this.props.rootStore.tickets.subscriptionTickets,
        showId: this.props.rootStore.tickets.showId,
      }),
      async ({ subscriptionTickets, showId }) => {
        const isASubscription = subscriptionTickets.map(s => s.id_show).indexOf(showId) >= 0
        isASubscription && (await this.setPerformance())
      },
    )
  }

  render() {
    const { rootStore } = this.props
    const {
      tickets: { calendarEvents, timeSelectedByPerformancesIds, selectedPerformance },
    } = rootStore

    return !isEmpty(calendarEvents) || !isEmpty(selectedPerformance) ? (
      <>
        <EventTicketHeader />
        <Calendar data={calendarEvents} />
        <Timeline data={timeSelectedByPerformancesIds} />
        <ZoneTicketsAmount data={selectedPerformance} />
      </>
    ) : (
      <LoaderOval isSmall={true} />
    )
  }
}
