import React from 'react'
import { inject, observer } from 'mobx-react'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import styles from './OngoingFilterDayPicker.module.css'
import {
  WEEKDAYS_LONG,
  MONTHS,
  WEEKDAYS_SHORT,
  FIRST_DAY_OF_WEEK,
  LABELS,
} from '../../lib/date-picker-locales'
import { stringToDateWithLocale } from '../../lib/date-utils'
import { translator } from '../../lib/translator'

@inject('rootStore')
@observer
export class OngoingFilterDayPicker extends React.Component {
  state = {
    selectedDay: '',
  }

  handleResetClick = () => {
    const { resetDateAndUpdate } = this.props.contentType
    resetDateAndUpdate()
  }

  handleDayClick = day => {
    this.setState({
      selectedDay: day,
    })
  }

  render() {
    const { contentType, rootStore, closePanel } = this.props
    const { navigation } = rootStore
    const { currentRouteLang } = navigation
    const { setSingleDateAndUpdate } = contentType
    const { selectedDay } = this.state

    const _start_date = stringToDateWithLocale(selectedDay, {
      locale: 'en-gb',
      format: 'prismic',
    })

    return (
      <>
        <div style={{ width: 'fit-content' }}>
          <DayPicker
            className={styles.dayPicker}
            locale={currentRouteLang}
            months={MONTHS[currentRouteLang]}
            weekdaysLong={WEEKDAYS_LONG[currentRouteLang]}
            weekdaysShort={WEEKDAYS_SHORT[currentRouteLang]}
            firstDayOfWeek={FIRST_DAY_OF_WEEK[currentRouteLang]}
            labels={LABELS[currentRouteLang]}
            selectedDays={[selectedDay, { from: selectedDay }]}
            onDayClick={this.handleDayClick}
            modifiers={{ start: _start_date }}
            fromMonth={new Date()}
          />
          <div style={{ width: '100%' }}>
            <button className={styles.cancelButton} onClick={this.handleResetClick}>
              {translator('cancel')(currentRouteLang)}
            </button>
            <button
              className={styles.confirmButton}
              style={{
                cursor: _start_date ? 'pointer' : 'default',
                opacity: _start_date ? 1 : 0.3,
              }}
              disabled={!_start_date}
              onClick={() => {
                setSingleDateAndUpdate(selectedDay)
                closePanel()
              }}
            >
              {translator('confirm')(currentRouteLang)}
            </button>
          </div>
        </div>
      </>
    )
  }
}
