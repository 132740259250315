import React from 'react'
import { inject, Observer } from 'mobx-react'
import { news } from '../../lib/prismic/news'
import { SectionTitlePlainWithLink } from '../Shared/Titles'
import { translator } from '../../lib/translator'
import styles from './Home.module.css'
import { CurrentContent, NewsItemsPreview } from '../../components'
import { useBreakpoints } from '../../hooks'

const HomeNewsSection = inject('rootStore')(({ rootStore }) => {
  const {
    navigation: { currentRouteLang },
  } = rootStore

  const { isXs, isSm } = useBreakpoints()

  return (
    <Observer>
      {() => (
        <section className={styles.section}>
          <SectionTitlePlainWithLink
            title={'News'}
            cta={translator('ctaNewsSection')(currentRouteLang)}
            border={true}
            link={{
              href: currentRouteLang === 'it' ? `/news` : `/en/news`,
              title: 'News',
            }}
          />
          <CurrentContent
            type={news}
            contentType="news"
            renderComponent={NewsItemsPreview}
            limit={isXs || isSm ? 2 : 4}
            areFiltersClickable={false}
          />
        </section>
      )}
    </Observer>
  )
})

export default HomeNewsSection
