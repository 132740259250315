import React from 'react'
import { observer, inject } from 'mobx-react'
import ReCAPTCHA from 'react-google-recaptcha'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import { postNotification, postOrder } from '../../temp/ticketing-api'
import { translator } from '../../lib/translator'
import { scrollToTop } from '../../lib/scroll'
import styles from './PaymentInfoForm.module.css'
import { Link } from '../layoutComponents'
import { linkResolver } from '../../lib/link-resolver'
import { transformMailAddressIntoLink } from '../../lib/utils'
import { GOOGLE_CAPTCHA_KEY } from '../../lib/const'
import { totalAmount } from '../../lib/tickets-utils'
import { GoForwardBtn } from './GoForwardBtn'
import { fbEvent } from '../../lib/facebook'

// Add ITA/ENG Handler. Split Privacy in 2 components. PrivacyIT/PrivacyEN
const Privacy = ({ handleOnChange, lang }) => {
  return (
    <>
      <div className={`${styles.disclaimer} bold`}>
        Ai sensi e per gli effetti degli articoli 6, 7 e 13 del Reg.UE 2016/679 dichiaro di aver
        preso visione dell’
        <Link
          className={``}
          target="_blank"
          to={linkResolver({
            uid: 'legal_privacy',
            lang,
          })}
        >
          informativa relativa al trattamento dei dati personali
        </Link>
      </div>
      <fieldset className="">
        <label className="checkbox subfield">
          <input onClick={handleOnChange('agreementB')} name="agreementB" type="checkbox" />
          <div>
            <b>Non perderti il meglio di Triennale Milano</b>
            <br />
            <span>
              esprimo il consenso al trattamento dei dati per la finalità B), ovvero marketing
              diretto attraverso iscrizione alla newsletter;
            </span>
          </div>
        </label>
        <br />
        <label className="checkbox subfield">
          <input onClick={handleOnChange('agreementC')} name="agreementC" type="checkbox" />
          <div>
            <b>Ricevi solo le notizie che ti interessano di più</b>
            <br />
            <span>
              esprimo il consenso al trattamento dei dati per la finalità C), ovvero marketing
              profilazione al fine di proporle comunicazioni commerciali più vicine alle sue
              preferenze
            </span>
          </div>
        </label>
        <label className="checkbox subfield">
          <input onClick={handleOnChange('agreementD')} name="agreementD" type="checkbox" />
          <div>
            <b>Promozioni e offerte dai nostri Partner pensate per te</b>
            <br />
            <span>
              esprimo il consenso al trattamento dei dati per la finalità D), ovvero cessione del
              dato per finalità di marketing di terze parti. I soggetti terzi a cui il dato verrà
              ceduto per tale attività sono partner e sponsor del Titolare del trattamento e/o delle
              altre società del Gruppo.
            </span>
          </div>
        </label>
      </fieldset>
    </>
  )
}

@inject('rootStore')
@observer
export class PaymentInfoForm extends React.Component {
  state = {
    recaptcha: '',
    firstname: '',
    lastname: '',
    email: '',
    emailConfirmation: '',
    phone: '',
    loading: false,
    postOrder: true,
  }

  recaptchaRef = React.createRef()

  componentDidMount() {
    const {
      tickets: { customer, isSubscriptionAccount },
    } = this.props.rootStore
    if (isSubscriptionAccount) {
      Object.keys(customer).forEach(k =>
        this.setState(() => ({
          [k]: customer[k],
        })),
      )
    }
    scrollToTop()
  }

  setValue = key => e => {
    const { value } = e.target
    this.setState(() => ({
      [key]: value,
    }))
  }

  setCheckboxValue = key => e => {
    const { checked } = e.target
    const agreement = checked ? 'Yes' : 'No'

    this.setValue(key)({ target: { value: agreement } })
  }

  setCustomerStore = state => {
    const { setData } = this.props.rootStore.tickets.customer
    setData(state)
  }

  // checkForErrors() {
  //   const { isEmailValid, doEmailsMatch } = this.props.rootStore.tickets.customer

  //   if (!isEmailValid && !doEmailsMatch) {
  //     return [INVALID_EMAIL_ERROR, NO_EMAIL_MATCH_ERROR]
  //   } else if (!isEmailValid) {
  //     return [INVALID_EMAIL_ERROR]
  //   } else if (!doEmailsMatch) {
  //     return [NO_EMAIL_MATCH_ERROR]
  //   }
  //   return undefined
  // }

  isSubmitBtnDisabled = () => {
    const { firstname, lastname, email, emailConfirmation, phone, recaptcha, loading } = this.state

    return (
      !firstname || !lastname || !email || !emailConfirmation || !phone || !recaptcha || loading
    )
  }

  validateCaptcha = value => {
    this.setState({ recaptcha: value })
  }

  validateAndSend = async e => {
    e.preventDefault()
    const { tickets } = this.props.rootStore
    const {
      ticketByShowId: { name, type },
      selectedPerformance: { dataInizio, time },
      cart,
      cart: { selectedReductions },
      customer,
      subscription,
      subscription: { barcode },
    } = tickets
    this.setCustomerStore(this.state)
    // const errors = this.checkForErrors()
    this.setState({
      loading: true,
    })
    // if (errors) {
    //   this.setState({ errors, loading: false })
    // } else {
    // }
    const getReduction = r => `${r.nTickets}X - ${r.explanation}`
    const { data } = await postOrder({ cart: cart.data, customer, subscription })
    if (isEmpty(data)) {
      this.setState({
        postOrder: false,
        loading: false,
      })
    }
    !barcode &&
      fbEvent('Purchase', {
        currency: 'EUR',
        value: totalAmount(selectedReductions) / 100,
      })
    await postNotification({
      payment_code: data.payment_code,
      show: {
        title: name,
        date: dataInizio,
        time: time,
        type: type,
      },
      customer,
      seats: selectedReductions.map(getReduction),
      barcodes: flatten(data.barcodes).join(', '),
      amount: !barcode ? totalAmount(selectedReductions) / 100 : 0,
    })
    window.location.replace(data.redirectUrl)
  }

  render() {
    const { navigation } = this.props.rootStore
    const { currentRouteLang } = navigation
    const { firstname, lastname, email, emailConfirmation, phone, postOrder, loading } = this.state
    const { errors } = this.state

    return (
      <div className={styles.wrapper}>
        <p className={`${styles.title} bodyTextBig bold`}>Dati personali</p>
        <form className={styles.paymentInfoForm} onSubmit={this.validateAndSend} noValidate>
          <div className={styles.paymentInfoFormContent}>
            <div className={`${styles.nameSurname} bodyText`}>
              <label>
                {/* <span className={`bold`}>{translator('nameInputLabel')(currentRouteLang)}*</span> */}
                <input
                  type="text"
                  value={firstname}
                  onChange={this.setValue('firstname')}
                  placeholder={`${translator('nameInputLabel')(currentRouteLang)}*`}
                />
              </label>
              <label>
                {/* <span className={`bold`}>{translator('surnameInputLabel')(currentRouteLang)}*</span> */}
                <input
                  type="text"
                  value={lastname}
                  onChange={this.setValue('lastname')}
                  placeholder={`${translator('surnameInputLabel')(currentRouteLang)}*`}
                />
              </label>
            </div>

            <div
              className={`${styles.email} bodyText bold ${
                errors && errors.find(error => error.field === 'email') ? styles.hasError : ''
              }`}
            >
              <label>
                {/* <span>{translator('emailInputLabel')(currentRouteLang)}*</span> */}
                <input
                  type="email"
                  value={email}
                  onChange={this.setValue('email')}
                  placeholder={`${translator('emailInputLabel')(currentRouteLang)}*`}
                />
              </label>
            </div>

            <div
              className={`${styles.email} bodyText bold ${
                errors && errors.find(error => error.field === 'emailConfirmation')
                  ? styles.hasError
                  : ''
              }`}
            >
              <label>
                {/* <span>{translator('emailConfirmationInputLabel')(currentRouteLang)}*</span> */}
                <input
                  type="email"
                  value={emailConfirmation}
                  onChange={this.setValue('emailConfirmation')}
                  placeholder={`${translator('emailConfirmationInputLabel')(currentRouteLang)}*`}
                />
              </label>
            </div>

            <div
              className={`${styles.phone} bodyText bold ${
                errors && errors.find(error => error.field === 'phone') ? styles.hasError : ''
              }`}
            >
              <label>
                {/* <span>{translator('phoneInputLabel')(currentRouteLang)}*</span> */}
                <input
                  type="tel"
                  value={phone}
                  onChange={this.setValue('phone')}
                  placeholder={`${translator('phoneInputLabel')(currentRouteLang)}*`}
                />
              </label>
            </div>

            {errors && (
              <div className={`${styles.errors} verySmallText`}>
                {errors.map(({ messageLabel }, i) => (
                  <p key={i}>{translator(messageLabel)(currentRouteLang)}</p>
                ))}
              </div>
            )}

            <div
              className={styles.disclaimer}
              dangerouslySetInnerHTML={{
                __html: transformMailAddressIntoLink(
                  translator('paymentInfoFormDisclaimer')(currentRouteLang),
                  'biglietteria@triennale.org',
                ),
              }}
            />

            <ReCAPTCHA
              hl={currentRouteLang}
              ref={this.recaptchaRef}
              sitekey={GOOGLE_CAPTCHA_KEY}
              onChange={this.validateCaptcha}
            />
            <Privacy handleOnChange={this.setCheckboxValue} lang={currentRouteLang} />
          </div>
          <div className={`${styles.submitWrapper}`}>
            <GoForwardBtn
              label={
                !loading
                  ? translator('submitInputLabel')(currentRouteLang)
                  : translator('submitInputLoadingLabel')(currentRouteLang)
              }
              onClick={this.validateAndSend}
              disabled={this.isSubmitBtnDisabled()}
            />
          </div>
          {/* TMP Message should be updated */}
          {!postOrder && <p>Ops! There was an error.</p>}
        </form>
      </div>
    )
  }
}
