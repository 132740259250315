import React from 'react'
import { inject, Observer } from 'mobx-react'
import styles from '../../routes/SingleMagazine.module.css'
import { getFormattedDate } from '../../lib/date-utils'
import { MagazineAuthor } from './MagazineAuthor'

export const PublishingInfo = inject('rootStore')(({ rootStore, data, publication_date, uid }) => {
  const {
    navigation: { currentRouteLang },
    ui: { labelsColor },
  } = rootStore

  const authorRelatedInfo = () => {
    let authors = []
    data.map(author => {
      authors.push({
        name: author?.data?.name?.[0]?.text,
        uid: author?.uid,
        link: true,
        lang: currentRouteLang,
      })
    })
    return authors
  }

  return (
    <Observer>
      {() => (
        <>
          <div className={`${styles.publishingInfo} smallText bold`}>
            {data &&
              authorRelatedInfo().map(d => {
                return (
                  <MagazineAuthor
                    key={d.uid}
                    uid={d.uid}
                    name={d?.name}
                    link={d?.link}
                    lang={currentRouteLang}
                    isPreview={false}
                  />
                )
              })}
            {publication_date && (
              <span className={`${styles.smallEventDates} bold`} style={{ color: labelsColor }}>
                {getFormattedDate(publication_date, {
                  withHours: false,
                  lang: currentRouteLang,
                })}
              </span>
            )}
          </div>
        </>
      )}
    </Observer>
  )
})
