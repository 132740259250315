import React, { useEffect } from 'react'
import { Observer, inject } from 'mobx-react'
import { PageTitlePlain } from '../components/Shared/Titles'
import { translator } from '../lib/translator'
import { LoaderOval } from '../components'
import { TicketSubscription } from '../components/ticketsComponents/TicketSubscription'
import { ListTicketsEvents } from '../components/ticketsComponents/ListTicketEvents'

export const Tickets = inject('rootStore')(({ rootStore }) => {
  const {
    tickets: {
      setShowId,
      fetchPrismicAndVivaticketEvents,
      availableTickets,
      isSubscriptionAccount,
      subscribedUserAvailableTickets,
    },
    navigation: { currentRouteLang },
  } = rootStore

  useEffect(() => {
    setShowId(undefined)
    fetchPrismicAndVivaticketEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Observer>
      {() => (
        <>
          <PageTitlePlain title={translator('ticketsTitle')(currentRouteLang)} />
          <TicketSubscription />
          {!availableTickets ? (
            translator('loadingMessage')(currentRouteLang)
          ) : availableTickets.length ? (
            <ListTicketsEvents
              tickets={isSubscriptionAccount ? subscribedUserAvailableTickets : availableTickets}
            />
          ) : (
            <LoaderOval isSmall={true} />
          )}
        </>
      )}
    </Observer>
  )
})
