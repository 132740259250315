import React from 'react'
import { Observer } from 'mobx-react'
import styles from './RegularEvent.module.css'
import { filterEmptyPrismicRepeatersContents } from '../../lib/survive-prismic'
import { computeEventDates } from '../../lib/date-utils'
import { ContentFiltersBlock } from '../../components/Shared/ContentFilters/ContentFiltersBlock'
import { Descriptions } from '../../components/SingleEvent/Descriptions'
import { DescriptionVideos } from '../../components/SingleEvent/DescriptionVideos'
import { Credits } from '../../components/SingleEvent/Credits'
import { Quotes } from '../../components/SingleEvent/Quotes'
import { AbstractLinks } from '../../components/SingleEvent/AbstractLinks'
import StickyHeroTextTitle from './StickyHeroTextTitle'
import EventMultiDate from './EventMultiDate'
import EventInformationSecondary from './EventInformationSecondary'

export const StickyHeroText = ({ event, currentRouteLang }) => {
  const {
    data,
    data: {
      start_date,
      end_date,
      specific_dates,
      description,
      description_part2,
      credits_others,
      credits_international_participation,
      description_videos,
      description_links,
      type,
      event_online,
      quotes,
      information,
      information_secondary,
      only_month,
      additional_info,
    },
  } = event

  const { dateString, hasMultidate, specificDates } = computeEventDates(
    start_date,
    end_date,
    specific_dates,
    currentRouteLang,
    only_month,
  )

  const _information = filterEmptyPrismicRepeatersContents(information, 'text')
  const _information_secondary = filterEmptyPrismicRepeatersContents(information_secondary, 'text')

  return (
    <Observer>
      {() => (
        <div className={styles.hero}>
          <div className={styles.heroText}>
            <div className={styles.titleBlock}>
              <div className={styles.filter_big}>
                <ContentFiltersBlock
                  areFiltersClickable={false}
                  type={type}
                  contentType={'events'}
                  currentLang={currentRouteLang}
                  isOnlineEvent={event_online}
                />
              </div>
              <StickyHeroTextTitle
                dateString={dateString}
                event={event}
                information={_information}
                additional_info={additional_info}
              />
            </div>
            <Quotes quotes={quotes} />
            <Descriptions description={description} description_part2={description_part2} />
            <AbstractLinks links={description_links} linkKey="description_link" />
            {_information_secondary && _information_secondary.length > 0 && (
              <EventInformationSecondary _info={_information_secondary} lang={currentRouteLang} />
            )}
            <Credits credits={credits_others} credits_part2={credits_international_participation} />
            <DescriptionVideos videos={description_videos} />
            {hasMultidate && (
              <EventMultiDate dates={specificDates} data={data} lang={currentRouteLang} />
            )}
          </div>
        </div>
      )}
    </Observer>
  )
}
