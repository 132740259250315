import React from 'react'
import { observer, inject } from 'mobx-react'
import styles from '../../routes/Tickets.module.css'
import { BuyTicketButton } from '../Shared/BuyTicketButton'
import { EventLocation } from '../SingleEvent/EventLocation'
import { computeEventDates } from '../../lib/date-utils'
import { EventTitles } from '../Shared/EventTitles'
import { LOCATION_DEFAULT } from '../../lib/const'
import { ContentFiltersBlock } from '../Shared/ContentFilters/ContentFiltersBlock'
import { capitalizeString } from '../../lib/utils'

export const TicketEvent = inject('rootStore')(
  observer(props => {
    const {
      id_show,
      url,
      name,
      description,
      editName,
      prismicEventId,
      rootStore: {
        navigation: { currentRouteLang },
        events,
        ui: { labelsColor },
      },
    } = props

    const prismicEvent = events.prismicEventById(prismicEventId)
    const {
      author,
      title,
      location,
      location_map_link,
      start_date,
      end_date,
      specific_dates,
      type,
    } = prismicEvent?.data || {}

    const { dateString } = computeEventDates(start_date, end_date, specific_dates, currentRouteLang)
    return (
      <li className={styles.item}>
        <div className={styles.info1}>
          {title ? (
            <>
              <div>
                <ContentFiltersBlock
                  areFiltersClickable={false}
                  type={type}
                  currentLang={currentRouteLang}
                  contentType={'events'}
                />
                <EventTitles
                  author={author}
                  title={title}
                  titleClass="previewTitleSmall bold removeLineHeight"
                />
              </div>
              <div className={styles.dateTime}>
                {dateString && (
                  <div
                    className={'smallText bold'}
                    style={{ color: labelsColor, fontSize: '18px' }}
                  >
                    {dateString}
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="previewTitleSmall bold">{capitalizeString(editName || name)}</div>
              {description && <div className={styles.description}>{description}</div>}
            </>
          )}
        </div>
        <div className={styles.info2}>
          <div className={styles.info2Inner}>
            <EventLocation
              location={location || LOCATION_DEFAULT.name}
              link={
                location_map_link && location_map_link.url
                  ? location_map_link
                  : LOCATION_DEFAULT.maps
              }
            />
          </div>
        </div>
        <div className={styles.buy}>
          <BuyTicketButton uid={id_show} url={url} />
        </div>
      </li>
    )
  }),
)
