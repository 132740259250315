import React from 'react'
import isEmpty from 'lodash/isEmpty'
import styles from './DescriptionVideos.module.css'
import { filterEmptyPrismicRepeatersContents, parsePrismicHtml } from '../../lib/survive-prismic'
import { linkResolverNormalizer } from '../../lib/link-resolver'
import { IframeWithFallback } from '../Shared/IframeWithFallback'
import { withIframesResizer } from '../Shared/withIframesResizer'

function remapParams({
  description_embed_media_html,
  description_video_source_type,
  description_video_id,
}) {
  return {
    type: description_video_source_type,
    uid: description_video_id,
    html: description_embed_media_html,
  }
}

class WrappedVideoLayout extends React.Component {
  render() {
    const { video, forwardedRef } = this.props
    return (
      <div ref={forwardedRef}>
        <IframeWithFallback {...remapParams(video)} />
      </div>
    )
  }
}

const WrappedVideo = withIframesResizer(WrappedVideoLayout, 730)

export class DescriptionVideos extends React.Component {
  render() {
    const { videos } = this.props
    const haveHTML = !isEmpty(
      filterEmptyPrismicRepeatersContents(videos, 'description_embed_media_html')?.[0]
        ?.description_embed_media_html,
    )
    return (
      haveHTML && (
        <div className={styles.descriptionVideos}>
          {videos.map((video, i) => (
            <div className={styles.descriptionVideo} key={i}>
              <WrappedVideo video={video} />
              <div
                className={`${styles.descriptionVideoCaption} captionText`}
                dangerouslySetInnerHTML={{
                  __html: parsePrismicHtml(video.description_video_caption, linkResolverNormalizer),
                }}
              />
            </div>
          ))}
        </div>
      )
    )
  }
}
