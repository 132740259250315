export const archivioFotograficoData = {
  it: {
    data: {
      title: [
        {
          text: 'Archivio fotografico',
        },
      ],
      description: [
        {
          type: 'paragraph',
          text:
            'L’archivio fotografico conserva circa 30.000 fotografie che documentano le Esposizioni Internazionali e le mostre prodotte e ospitate da Triennale.',
          spans: [],
        },
      ],
    },
  },
  en: {
    data: {
      title: [
        {
          text: 'Photographic Archive',
        },
      ],
      description: [
        {
          type: 'paragraph',
          text:
            '[ENG] L’archivio fotografico conserva circa 30.000 fotografie che documentano le Esposizioni Internazionali e le mostre prodotte e ospitate da Triennale.',
          spans: [],
        },
      ],
    },
  },
}
