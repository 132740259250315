import PrismicDOM from 'prismic-dom'
import find from 'lodash/fp/find'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import toObject from 'dayjs/plugin/toObject'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/it'
import { WEEKDAYS_LONG } from './date-picker-locales'
import { filterEmptyPrismicRepeatersContents } from './survive-prismic'
import { FormattedDateEvent } from '../components/Shared/FormattedDateEvent'

dayjs.extend(isBetween)
dayjs.extend(customParseFormat)
dayjs.extend(toObject)

const MONTHS = {
  it: [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'agosto',
    'settembre',
    'ottobre',
    'novembre',
    'dicembre',
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
}

const getTodayName = () =>
  dayjs()
    .locale('it')
    .format('dddd')

const setHourMinute = (h, m) =>
  dayjs()
    .locale('it')
    .set('h', h)
    .set('m', m)
    .set('s', 0)

export const isSameYear = date => {
  return new Date(date).getYear() === new Date().getYear()
}

export const isToday = date => {
  return (
    date.getFullYear() === new Date().getFullYear() &&
    date.getMonth() === new Date().getMonth() &&
    date.getDate() === new Date().getDate()
  )
}

export const isPastDay = date => {
  return new Date(date).getDay() < new Date().getDay()
}

const getOpenHoursRange = ({ openTime, closeTime }, separator = '.') => {
  const openHourMinute = openTime.split(separator)
  const closeHourMinute = closeTime.split(separator)

  return {
    openDate: setHourMinute(openHourMinute[0], openHourMinute[1]),
    closeDate: setHourMinute(closeHourMinute[0], closeHourMinute[1]),
  }
}

export function getLongEpochFromDate(date) {
  return new Date(date).valueOf()
}

export function getDateFromLongEpoch(epoch) {
  return new Date(epoch)
}

export function getToday() {
  const now = new Date()
  return now
    .toISOString()
    .split('T')
    .shift()
}

export function formatVivaticketDate(vivaTicketDate) {
  return vivaTicketDate
    .split('/')
    .reverse()
    .join('-')
}

function getFormatInLang({ lang, withDayOfWeek, withHours, removeMonth, removeYear }) {
  let format = ''

  if (lang === 'it') {
    format = withDayOfWeek ? 'dddd D' : 'D'
    if (!removeMonth) {
      format += ' MMMM'
    }
    if (!removeYear) {
      format += ' YYYY'
    }
    if (withHours) {
      format += `, ore HH.mm`
    }
  } else if (lang === 'en') {
    format = withDayOfWeek ? 'dddd, ' : ''
    if (!removeMonth) {
      format += ' MMMM'
    }
    format += ' D'
    if (!removeYear) {
      format += ' YYYY'
    }
    if (withHours) {
      format += `, h.mma`
    }
  }

  return format
}

export function getFormattedDate(
  prismicDate,
  options = {
    lang: 'it',
    withDayOfWeek: false,
    withHours: false,
    removeMonth: false,
    removeYear: false,
  },
) {
  const { lang, removeYear } = options

  if (!prismicDate || !lang) {
    return null
  }

  return dayjs(PrismicDOM.Date(prismicDate))
    .locale(lang)
    .format(getFormatInLang({ ...options, removeYear: removeYear || false }))
}

export function getFormattedDateRange(start, end, lang) {
  const startObj = start && dayjs(start).toObject()
  const endObj = end && dayjs(end).toObject()

  const formattedStartMonth = MONTHS[lang][startObj.months]
  const formattedEndMonth = MONTHS[lang][endObj.months]

  const isSameYear = startObj.years === endObj.years
  const isSameMonth = startObj.months === endObj.months
  const isTheSameDay = startObj.date === endObj.date
  const isStartDateToday = isToday(new Date(start))
  const isPastStartDate = new Date(start) < new Date()
  const isPastEvent = new Date(start) < new Date() && new Date(end) < new Date()

  if (lang === 'it') {
    if (isTheSameDay && isSameMonth && isSameYear) {
      return `${startObj.date} ${startObj.months} ${startObj.years}`
    } else if ((isStartDateToday || isPastStartDate) && !isPastEvent) {
      return `Fino al ${endObj.date} ${formattedEndMonth} ${endObj.years}`
    } else if (isSameMonth && isSameYear) {
      return `${startObj.date} – ${endObj.date} ${formattedStartMonth}, ${endObj.years}`
    } else if (isSameYear) {
      return `${startObj.date} ${formattedStartMonth} – ${endObj.date} ${formattedEndMonth} ${endObj.years}`
    } else {
      return `${startObj.date} ${formattedStartMonth} ${startObj.years} – ${endObj.date} ${formattedEndMonth} ${endObj.years}`
    }
  } else if (lang === 'en') {
    if (isTheSameDay && isSameMonth && isSameYear) {
      return `${startObj.months} ${startObj.date} ${startObj.years}`
    } else if ((isStartDateToday || isPastStartDate) && !isPastEvent) {
      return `Until ${endObj.date} ${formattedEndMonth} ${endObj.years}`
    } else if (isSameMonth && isSameYear) {
      return `${formattedStartMonth} ${startObj.date} – ${endObj.date}, ${endObj.years}`
    } else if (isSameYear) {
      return `${formattedStartMonth} ${startObj.date} – ${formattedEndMonth} ${endObj.date} ${endObj.years}`
    } else {
      return `${formattedStartMonth} ${startObj.date} ${startObj.years} – ${formattedEndMonth} ${endObj.date} ${endObj.years}`
    }
  }
  return null
}

export function getFormattedDateMonth(start, end, lang) {
  const isSameMonth = (a, b) => dayjs(a).isSame(b, 'month')
  const isSameYear = (a, b) => dayjs(a).isSame(b, 'year')
  const formattedDateYear = date =>
    dayjs(date)
      .locale(lang)
      .format('MMMM, YYYY')
  const formattedDate = date =>
    dayjs(date)
      .locale(lang)
      .format('MMMM')
  return isSameMonth(start, end)
    ? `${formattedDateYear(start)}`
    : isSameYear(start, end)
    ? `${formattedDate(start)} – ${formattedDateYear(end)}`
    : `${formattedDateYear(start)} – ${formattedDateYear(end)}`
}

export function getSelectedDayOpeningHours(openingHoursCalendar, selectedDay) {
  const currentDay = selectedDay.getDay()
  const currentDayAsString = WEEKDAYS_LONG['it'][currentDay]

  const particularDays = filterEmptyPrismicRepeatersContents(
    openingHoursCalendar.particular_days,
    'date',
  )
  let override = particularDays.find(
    day => day.date !== null && isSameDay(new Date(day.date), selectedDay),
  )

  const standardOpeningHoursForDay = openingHoursCalendar.week_days.find(
    day => day.week_day === currentDayAsString,
  )

  return {
    standard: (standardOpeningHoursForDay || {}).opening_times,
    override: override ? override.opening_times : undefined,
  }
}

export function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

export const nowIsOpen = ({ openTime, closeTime }, separator = '.') => {
  const { openDate, closeDate } = getOpenHoursRange({ openTime, closeTime }, separator)
  return dayjs()
    .locale('it')
    .isBetween(openDate, closeDate)
}

export const hoursRange = weekDays => {
  const isDay = d => d.week_day.toLowerCase() === getTodayName()
  return find(isDay)(weekDays)
}

const localeFormat = {
  it: 'DD-MM-YYYY',
  'en-gb': 'MM-DD-YYYY',
  prismic: 'YYYY-MM-DD',
}

export function stringToDateWithLocale(dateAsString, { locale, format }) {
  if (!dateAsString) {
    return null
  }

  return dayjs(dateAsString, localeFormat[format])
    .locale(locale)
    .toDate()
}

export function dateToStringWithLocale(date, { locale, format }) {
  if (!date) {
    return null
  }

  return dayjs(date)
    .locale(locale)
    .format(localeFormat[format])
}

export function engDateStringToItLocale(engDateString) {
  return dayjs(engDateString)
    .locale('it')
    .format(localeFormat['it'])
}

export function computeEventDatesWithTicketingFallback(
  start_date,
  end_date,
  specific_dates,
  lang,
  performance,
) {
  if (!start_date && !end_date && !specific_dates && performance && performance.length) {
    return { dateString: performance[0].date, hasMultidate: false }
  }

  return computeEventDates(start_date, end_date, specific_dates, lang)
}

export function computeEventDates(start_date, end_date, specific_dates, lang, only_month) {
  const _specific_dates = filterEmptyPrismicRepeatersContents(specific_dates, 'date_time')

  let dateString = FormattedDateEvent(start_date, end_date, lang, '', only_month)

  let hasMultidate = false
  if (_specific_dates.length) {
    if (_specific_dates.length === 1) {
      dateString = getFormattedDate(specific_dates[0].date_time, {
        lang,
        withHours: true,
        removeYear: false,
      })
    } else {
      hasMultidate = true
    }
  }

  return {
    dateString,
    hasMultidate,
    specificDates: Boolean(_specific_dates.length) && _specific_dates,
  }
}

export function convertHour(currentHourString, lang, separator = ':') {
  if (lang === 'it') {
    return currentHourString
  }

  if (lang === 'en') {
    const hourBits = currentHourString.split(separator).map(b => Number(b))

    let hours = hourBits[0] > 12 ? hourBits[0] - 12 : hourBits[0]
    let minutes = hourBits[1]
    let amOrPm = 'am'

    if (minutes < 10) {
      minutes = `0${String(minutes)}`
    }

    if (hourBits[0] >= 12) {
      amOrPm = 'pm'
    }

    if (hourBits[0] === 0) {
      hours = 12
    }

    if (isNaN(hours) || isNaN(minutes) || !hours || !minutes) {
      return -1
    }

    return `${hours}:${minutes}${amOrPm}`
  }

  return null
}

export function customFormattedDate(date, lang, format) {
  return dayjs(date)
    .locale(lang)
    .format(format)
}

export const formatSpecificDate = (date, { lang }) => {
  if (!date || !lang) {
    return null
  }

  // IT

  if (!isSameYear(date) && !isToday(date) && lang === 'it') {
    return dayjs(date)
      .locale(lang)
      .format('D MMMM YYYY, ore HH:mm')
  }
  if (isSameYear(date) && isToday(date) && lang === 'it') {
    return `Oggi, ore ${dayjs(date)
      .locale(lang)
      .format('HH:mm YYYY')}`
  }

  if (isSameYear(date) && !isToday(date) && lang === 'it') {
    return dayjs(date)
      .locale(lang)
      .format('D MMMM YYYY, ore HH:mm')
  }

  // EN

  if (!isSameYear(date) && !isToday(date) && lang === 'en') {
    return dayjs(date)
      .locale(lang)
      .format('D MMMM YYYY, h.mma')
  }

  if (isSameYear(date) && isToday(date) && lang === 'en') {
    return `Today, ${dayjs(date)
      .locale(lang)
      .format('h.mm a')}`
  }
  if (isSameYear(date) && !isToday(date) && lang === 'en') {
    return dayjs(date)
      .locale(lang)
      .format('D MMMM YYYY, h.mma')
  } else {
    return null
  }
}
