import React from 'react'
import styles from './ImageShowcase.module.css'
import { ArchiveImgWithFallback } from '../../components'

export const ImageShowcase = ({ images }) => {
  return (
    <div className={styles.imageSectionContainer}>
      {images?.length > 0 &&
        images.map((imageSection, i) => (
          <figure className={styles.imageSection} key={i}>
            <div className={styles.inner}>
              <img src={imageSection.image.url} alt={imageSection.caption || ''} />
              <figcaption
                className={`captionText ${styles.caption}`}
                dangerouslySetInnerHTML={{
                  __html: imageSection.caption,
                }}
              />
            </div>
          </figure>
        ))}
    </div>
  )
}

export const ImageShowcaseArchive = ({ data }) => {
  return (
    <div className={styles.imageSectionContainer}>
      {data?.length > 0 &&
        data.map((d, i) => (
          <figure className={styles.imageSection} key={i}>
            <div className={styles.inner}>
              <ArchiveImgWithFallback title={d.title} src={d.src} hasAdditionalClass={false} />
              <div className={`captionText ${styles.caption}`}>
                <span>{d.title}</span>
                {d.authors?.length > 0 && <span> - {d.authors.join(', ')}</span>}
                {d.company && <span> - {d.company}</span>}
                {d.projectYear && <span> - {d.projectYear}</span>}
              </div>
            </div>
          </figure>
        ))}
    </div>
  )
}
