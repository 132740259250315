import React from 'react'
import { Caption } from './Paragraphs'
import { filterEmptyPrismicRepeatersContents } from '../../lib/survive-prismic'

export class IframeWrapper extends React.Component {
  render() {
    const { html, caption } = this.props
    const _html = filterEmptyPrismicRepeatersContents(html, 'text')

    return (
      <>
        {_html?.length > 0 && (
          <div
            dangerouslySetInnerHTML={{
              __html: _html[0].text,
            }}
          />
        )}
        {caption && caption?.length > 0 && <Caption caption={caption} />}
      </>
    )
  }
}
