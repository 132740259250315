import { types as t } from 'mobx-state-tree'
import { validateEmail } from '../lib/utils'

export const CustomerModel = t
  .model('CustomerModel', {
    firstname: '',
    lastname: '',
    email: '',
    emailConfirmation: '',
    phone: '',
    agreementB: 'No',
    agreementC: 'No',
    agreementD: 'No',
  })
  .actions(self => ({
    setCustomer(key, value) {
      self[key] = value
    },
    resetCustomer(key) {
      self[key] = ''
    },
    setName(name) {
      self.firstname = name
    },
    resetName() {
      self.firstname = ''
    },
    setSurname(surname) {
      self.lastname = surname
    },
    resetSurname() {
      self.lastname = ''
    },
    setEmail(email) {
      self.email = email
    },
    resetEmail() {
      self.email = ''
    },
    setEmailConfirmation(email) {
      self.emailConfirmation = email
    },
    resetEmailConfirmation() {
      self.email = ''
    },
    setNewsletterFlag(state) {
      self.newsletterFlag = state
    },
    resetNewsletterFlag() {
      self.newsletterFlag = false
    },
  }))
  .views(self => ({
    get queryString() {
      const { firstname, lastname, email } = self
      return `firstname=${firstname}&lastname=${lastname}&email=${email}`
    },
    get isEmailValid() {
      return validateEmail(self.email)
    },
    get doEmailsMatch() {
      return self.email === self.emailConfirmation
    },
  }))
