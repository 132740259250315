import React from 'react'
import { inject, observer } from 'mobx-react'
import { computePrice } from '../../lib/tickets-utils'
import styles from './ReductionCounter.module.css'
import { capitalizeString } from '../../lib/utils'
import { fbEvent } from '../../lib/facebook'

@inject('rootStore')
@observer
export class ReductionCounter extends React.Component {
  add = (e, item) => {
    const { reduction, rootStore, nTickets } = this.props
    const { addItem } = rootStore.tickets.cart
    const { maxbuy, explanation, presale, price, iva } = reduction
    e.preventDefault()
    if (nTickets <= maxbuy) {
      addItem(item)
      fbEvent('AddToCart', {
        content_name: explanation,
        currency: 'EUR',
        value: (presale + price + iva) / 100,
      })
    }
  }

  subtract = (e, item) => {
    e.preventDefault()
    const { rootStore, nTickets } = this.props
    const { subtractItem } = rootStore.tickets.cart

    if (nTickets > 0) {
      subtractItem(item)
    }
  }

  get computedPrice() {
    const { reduction, rootStore } = this.props
    const { currentRouteLang } = rootStore.navigation
    const { commission, iva, presale, price } = reduction
    return computePrice([commission, iva, presale, price], currentRouteLang)
  }

  render() {
    const { zoneId, reduction, availability, pcode, vcode, nTickets } = this.props
    const { description, id: reductionId, maxbuy, presale, commission, iva, price } = reduction

    const item = {
      vcode,
      pcode,
      reductionId,
      zoneId,
      price: price + presale + commission + iva,
      maxbuy,
      availability,
    }

    return (
      <>
        <div className={`${styles.wrapper}`}>
          <div className={styles.reductionType}>
            <div className={styles.description}>{capitalizeString(description)}</div>
          </div>
          <div className={styles.rightButton}>
            <div>
              <div>{this.computedPrice}</div>
            </div>
            <div className={`${styles.buttonWrapper}`}>
              <button className={`${styles.btn}`} onClick={e => this.subtract(e, item)}>
                -
              </button>
              <div className={styles.inputSeats}>
                <span className={`${styles.seatAmount}`}>{nTickets}</span>
              </div>
              <button className={`${styles.btn}`} onClick={e => this.add(e, item)}>
                +
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}
