import React from 'react'
import { inject, observer } from 'mobx-react'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import styles from './FilterDayPicker.module.css'
import {
  WEEKDAYS_LONG,
  MONTHS,
  WEEKDAYS_SHORT,
  FIRST_DAY_OF_WEEK,
  LABELS,
} from '../../../lib/date-picker-locales'
import { stringToDateWithLocale } from '../../../lib/date-utils'
import { translator } from '../../../lib/translator'

@inject('rootStore')
@observer
export class FilterDayPicker extends React.Component {
  handleDayClick = day => {
    const { contentType } = this.props
    const {
      filter: { start_date, end_date },
    } = contentType
    const { setDateAndUpdate, resetFilterByType } = contentType

    const range = DateUtils.addDayToRange(day, {
      from: stringToDateWithLocale(start_date, { locale: 'en-gb', format: 'prismic' }),
      to: stringToDateWithLocale(end_date, { locale: 'en-gb', format: 'prismic' }),
    })

    resetFilterByType('time_related')
    setDateAndUpdate(range)
  }

  handleResetClick = () => {
    const { resetDateAndUpdate } = this.props.contentType
    resetDateAndUpdate()
  }

  render() {
    const { contentType, rootStore, closePanel } = this.props
    const { navigation } = rootStore
    const { currentRouteLang } = navigation
    const {
      filter: { start_date, end_date },
    } = contentType

    const _start_date = stringToDateWithLocale(start_date, { locale: 'en-gb', format: 'prismic' })
    const _end_date = stringToDateWithLocale(end_date, { locale: 'en-gb', format: 'prismic' })

    return (
      <>
        <div style={{ width: 'fit-content' }}>
          <DayPicker
            className={styles.dayPicker}
            locale={currentRouteLang}
            months={MONTHS[currentRouteLang]}
            weekdaysLong={WEEKDAYS_LONG[currentRouteLang]}
            weekdaysShort={WEEKDAYS_SHORT[currentRouteLang]}
            firstDayOfWeek={FIRST_DAY_OF_WEEK[currentRouteLang]}
            labels={LABELS[currentRouteLang]}
            selectedDays={[_start_date, { from: _start_date, to: _end_date }]}
            onDayClick={this.handleDayClick}
            modifiers={{ start: _start_date, end: _end_date }}
          />
          <div style={{ width: '100%' }}>
            <button
              style={{
                width: 'calc(50% - 10px)',
                marginRight: '20px',
                height: '60px',
                border: '2px solid black',
                cursor: 'pointer',
                background: '#F6F6F6',
                fontSize: '14px',
                fontWeight: 700,
                color: 'black',
              }}
              onClick={this.handleResetClick}
            >
              {translator('cancel')(currentRouteLang)}
            </button>
            <button
              style={{
                width: 'calc(50% - 10px)',
                height: '60px',
                border: '2px solid black',
                cursor: _start_date || _end_date ? 'pointer' : 'default',
                background: 'black',
                opacity: _start_date || _end_date ? 1 : 0.3,
                fontWeight: 700,
                color: '#f6f6f6',
              }}
              disabled={!_start_date}
              onClick={closePanel}
            >
              {translator('confirm')(currentRouteLang)}
            </button>
          </div>
        </div>
      </>
    )
  }
}
