import React from 'react'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'

const capitalize = c => (c ? c.charAt(0).toUpperCase() + c.slice(1) : '')

const normalize = fn => input =>
  Array.isArray(input) ? input.map(i => fn(i)).join(', ') : fn(input)

const ArchiveList = label => data => {
  if (isEmpty(compact(data))) return
  return (
    <p>
      {label ? <b>{label}:</b> : null}
      {compact(data).map((d, i) => (
        <span key={i}> {normalize(capitalize)(d)}</span>
      ))}
    </p>
  )
}

export default ArchiveList
