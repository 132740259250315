import React from 'react'
import { magazine as type } from '../lib/prismic/magazine'
import { MagazineListCards } from '../components/Magazine/MagazineListCards'
import { HeroMagazine } from '../components/Magazine/HeroMagazine'
import { MagazineFocusArea } from '../components/Magazine/MagazineFocusArea'
import { BtnMagazineHome } from '../components/Magazine/BtnMagazineHome'
import { Divider, CurrentContent } from '../components'

export const MagazineHome = () => {
  return (
    <>
      <BtnMagazineHome isHome />
      <HeroMagazine />
      <MagazineFocusArea />
      <Divider margin={'bottom'} />
      <CurrentContent
        type={type}
        contentType={'magazine'}
        renderComponent={MagazineListCards}
        typeOfList={2}
      />
    </>
  )
}
