import React from 'react'
import styles from './Slider.module.css'

export const Track = ({ source, target, getTrackProps, disabled }) => {
  return (
    <div
      className={styles.track}
      style={{
        backgroundColor: disabled ? '#999' : '#b28900',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  )
}
