import React from 'react'
import { inject, Observer } from 'mobx-react'
import styles from './BuyTicketLink.module.css'
import { translatorUp } from '../../lib/translator'
import { checkLinkValidity } from '../../lib/survive-prismic'

const TICKETS_URL_PATH_ROOT = {
  it: '/biglietti',
  en: '/en/tickets',
}

export const BuyTicketLink = inject('rootStore')(({ rootStore, eventData }) => {
  const {
    navigation: { currentRouteLang },
  } = rootStore
  const handleClick = e => {
    e.stopPropagation()
  }

  const { registration_link, booking_link, show_id: showId, buy_ticket_link } = eventData
  const _registration_link = checkLinkValidity(registration_link)
  const _booking_link = checkLinkValidity(booking_link)
  const _buy_ticket_link = checkLinkValidity(buy_ticket_link)
  const translator = translatorUp(currentRouteLang)

  if (showId || _registration_link || _booking_link || _buy_ticket_link) {
    return (
      <Observer>
        {() => (
          <object className={styles.buyTicketWrapper}>
            <a
              className={`button smallText internalLink ${styles.buyTicket}`}
              href={
                showId
                  ? `${TICKETS_URL_PATH_ROOT[currentRouteLang]}/${showId}`
                  : _registration_link || _booking_link || _buy_ticket_link
              }
              onClick={handleClick}
              target={showId ? '_self' : '_blank'}
              rel="noopener noreferrer"
            >
              {_registration_link && `${translator('register')}`}
              {_booking_link && `${translator('booking')}`}
              {(showId || _buy_ticket_link) && `${translator('buyTicket')}`}
            </a>
          </object>
        )}
      </Observer>
    )
  } else return null
})
