import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './ContainerPagesBlock.module.css'
import { Link } from '../../components'
import { linkResolver } from '../../lib/link-resolver'
import { FormattedDateEvent } from './FormattedDateEvent'

export class ContainerPagesBlock extends React.Component {
  render() {
    const { pages } = this.props

    if (!pages.length) {
      return null
    }

    return (
      <div className={styles.block}>
        {pages.map((p, i) => (
          <ContainerPagesBlockItem page={p} key={i} />
        ))}
      </div>
    )
  }
}

@inject('rootStore')
@observer
export class ContainerPagesBlockItem extends React.Component {
  render() {
    const { page, rootStore } = this.props
    const { currentRouteLang } = rootStore.navigation
    const { uid, data } = page
    const { start_date, end_date, title } = data

    return (
      <article className={styles.article}>
        <Link
          className={`outline ${styles.link}`}
          to={linkResolver({
            contentType: 'container_page',
            uid,
            lang: currentRouteLang,
          })}
        >
          <h1 className={`${styles.title}`} dangerouslySetInnerHTML={{ __html: title[0].text }} />
          <time className={`${styles.date} bold`}>
            {FormattedDateEvent(start_date, end_date, currentRouteLang)}
          </time>
        </Link>
      </article>
    )
  }
}
