import React from 'react'
import { translator } from '../../lib/translator'
import { SectionTitlePlain } from '../../components/Shared/Titles'
import { MagazineListCards } from './MagazineListCards'

export const RelatedEvents = ({ data, lang }) => {
  return (
    <>
      <SectionTitlePlain title={translator('relatedEventsSectionTitle')(lang)} border={true} />
      <MagazineListCards
        results={data}
        options={{
          typeOfList: 0,
          limit: 4,
        }}
      />
    </>
  )
}
