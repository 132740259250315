import React from 'react'
import Select from 'react-select'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import makeAnimated from 'react-select/animated'
import { filtersStyle } from './lib/filtersStyle'
import { AGGREGATED_FILTERS } from './lib/filters'

const ArrowDown = ({ color }) => {
  return (
    <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.16016 1.58008L17.0002 16.4201L31.8402 1.58008" stroke={color} strokeWidth="4" />
    </svg>
  )
}

export const OngoingFiltersSelect = ({
  options,
  setSingleFilter,
  currentRouteLang,
  type,
  getActiveFiltersValues,
  labelsColor,
}) => {
  const filters = getActiveFiltersValues()
  const filtersByLang = AGGREGATED_FILTERS[currentRouteLang]

  const getKeyByValue = (obj, value) => {
    const categories = Object.keys(obj)
    return categories.filter(key => {
      return isEqual(obj[key], value)
    })
  }
  const filterOptionValue = () => getKeyByValue(filtersByLang, filters[0].values)[0]

  const getOption = () => {
    const haveType = f => !isEmpty(f.find(f => f.label === 'type'))
    const performancesFilters = '?type=Spettacolo&type=Concerto&type=Performance'
    const performancesValue = {
      value: 'Performance',
      label: 'Performance',
      color: 'white',
      isFixed: true,
      filter: ['Spettacolo', 'Concerto', 'Performance', 'Dj set'],
    }
    if (window.location.search === performancesFilters) {
      return performancesValue
    }
    if (haveType(filters)) {
      return options.filter(e => {
        const test = filterOptionValue()
        return e.value === test
      })[0]
    } else {
      return {
        value: currentRouteLang === 'it' ? 'Tutto' : 'All',
        label: currentRouteLang === 'it' ? 'Tutto' : 'All',
        color: 'white',
        isFixed: true,
        filter: [],
      }
    }
  }

  const setFilters = (value, type) => {
    const filterOption = { type, value: value.filter }
    setSingleFilter(filterOption)
  }

  const publicProgramFilter =
    '?type=Conferenza' +
    '&type=Festival' +
    '&type=Incontro' +
    '&type=Laboratorio' +
    '&type=Presentazione%20volume' +
    '&type=Proiezione' +
    '&type=Speciale' +
    '&type=Triennale%20Estate' +
    '&type=Visita%20Guidata'
  const publicProgramValue = {
    value: currentRouteLang === 'it' ? 'Eventi' : 'Events',
    label: currentRouteLang === 'it' ? 'Eventi' : 'Events',
    color: 'white',
    isFixed: true,
    filter: [
      'Conferenza',
      'Festival',
      'Incontro',
      'Laboratorio',
      'Presentazione volume',
      'Proiezione',
      'Speciale',
      'Triennale Estate',
      'Visita Guidata',
    ],
  }

  return (
    <div>
      <Select
        closeMenuOnSelect={true}
        components={{
          ...makeAnimated(),
          DropdownIndicator: () => {
            return <ArrowDown color={labelsColor} />
          },
        }}
        defaultValue={
          window.location.search === publicProgramFilter ? publicProgramValue : getOption()
        }
        options={options}
        styles={filtersStyle(labelsColor)}
        onChange={value => {
          setFilters(value, type)
        }}
      />
    </div>
  )
}
