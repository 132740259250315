import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './SearchToggleMobile.module.css'
import { ReactComponent as SearchIcon } from '../../images/icons/search.svg'

@inject('rootStore')
@observer
class SearchToggleMobile extends React.Component {
  toggleSearchbar = e => {
    e.preventDefault()
    const { toggleSearchPanel } = this.props.rootStore.ui
    toggleSearchPanel()
  }

  render() {
    return (
      <button className={styles.iconWrapper} onClick={this.toggleSearchbar}>
        <SearchIcon />
      </button>
    )
  }
}

export default SearchToggleMobile
