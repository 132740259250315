import React from 'react'
import { inject } from 'mobx-react'
import styles from './ContentFiltersBlock.module.css'
import { WithOnlineLabelFilters } from './WithOnlineLabelFilters'

const NotClickableCategory = inject('rootStore')(
  ({ rootStore, label, currentLang, isOnlineEvent }) => {
    const {
      ui: { labelsColor },
    } = rootStore
    return (
      <div
        className={`outline bold ${styles.filterLink} ${styles.notClickable}`}
        style={{ color: labelsColor }}
      >
        <WithOnlineLabelFilters isOnlineEvent={isOnlineEvent} currentLang={currentLang}>
          <span>{label}</span>
        </WithOnlineLabelFilters>
      </div>
    )
  },
)

export { NotClickableCategory }
