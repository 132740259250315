import React from 'react'
import { inject, Observer } from 'mobx-react'
import styles from '../../routes/SingleMagazine.module.css'
import { linkResolver } from '../../lib/link-resolver'
import { Link } from '../../components'

export const MagazineAuthor = inject('rootStore')(
  ({ rootStore, uid, name, lang, link = true, isPreview }) => {
    const {
      ui: { labelsColor },
    } = rootStore
    return (
      <Observer>
        {() => {
          return (
            <>
              {name && (
                <div className={`${isPreview ? styles.titleAuthor : styles.titleAuthorBig} bold`}>
                  {link ? (
                    <Link
                      to={linkResolver({
                        contentType: 'magazine_author',
                        uid: uid,
                        lang,
                      })}
                      style={{ color: labelsColor }}
                    >
                      {name}
                    </Link>
                  ) : (
                    <span style={{ color: labelsColor }}>{name}</span>
                  )}
                  <br />
                </div>
              )}
            </>
          )
        }}
      </Observer>
    )
  },
)
