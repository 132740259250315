import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { BasicFilters, CurrentContent } from '../../components'
import { translator } from '../../lib/translator'
import { PageTitlePlain } from '../../components/Shared/Titles'
import { CurrentFilters } from '../../components/Shared/Filters/CurrentFilters'

const CurrentContentList = inject('rootStore')(
  observer(props => {
    const {
      rootStore,
      rootStore: {
        navigation: { currentRouteLang },
      },
      contentType,
      type,
      renderComponent,
      filters,
      filtersKeys,
      filterTypesToExclude,
      titleLabel,
      resultsPerPage,
      typeOfList,
      filtersPrismic,
    } = props
    const hasFiltersUi = !isEmpty(filters)
    return (
      <>
        {hasFiltersUi && (
          <BasicFilters
            id={contentType}
            filters={filters}
            filterTypesToExclude={filterTypesToExclude}
            keys={filtersKeys}
            type={rootStore[contentType]}
          />
        )}
        {contentType !== 'magazine' && (
          <header style={{ position: 'relative' }}>
            <PageTitlePlain title={translator(titleLabel)(currentRouteLang)} />
          </header>
        )}

        {hasFiltersUi && <CurrentFilters id={contentType} />}
        <CurrentContent
          type={type}
          contentType={contentType}
          renderComponent={renderComponent}
          areFiltersClickable={false}
          resultsPerPage={resultsPerPage}
          typeOfList={typeOfList}
          filtersPrismic={filtersPrismic}
        />
      </>
    )
  }),
)

export default CurrentContentList
