import React from 'react'
import Helmet from 'react-helmet'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { homepage } from '../../lib/prismic/homepage'
import { fixPath } from '../../lib/ssr'

const pagesThatDontExistInBackendMeta = {
  '/whats-on': { title: 'In programma', description: '' },
  '/en/whats-on': { title: "What's on", description: '' },
  '/press': { title: 'Comunicati stampa', description: '' },
  '/en/press': { title: 'Press releases', description: '' },
  '/news': { title: 'News', description: '' },
  '/en/news': { title: 'News', description: '' },

  '/whats-on/': { title: 'In programma', description: '' },
  '/en/whats-on/': { title: "What's on", description: '' },
  '/press/': { title: 'Comunicati stampa', description: '' },
  '/en/press/': { title: 'Press releases', description: '' },
  '/news/': { title: 'News', description: '' },
  '/en/news/': { title: 'News', description: '' },
}

const initialState = {
  defaultMeta: {
    it: {
      title: 'Triennale Milano',
      image: '',
      description: '',
    },
    en: {
      title: 'Triennale Milano',
      image: '',
      description: '',
    },
  },
}

@inject('rootStore')
@observer
class Meta extends React.Component {
  state = initialState

  componentDidMount() {
    this.fetchPrismicMeta()
  }

  async fetchPrismicMeta() {
    try {
      const metaIt = await homepage.get()
      const metaEn = await homepage.get('en-gb')

      const fetchedDefaultMeta = {
        defaultMeta: {
          it: {
            title: 'Triennale Milano',
            image: metaIt.results[0].data.meta_image.url,
            description: metaIt.results[0].data.meta_description[0].text,
          },
          en: {
            title: 'Triennale Milano',
            image: metaEn.results[0].data.meta_image.url,
            description: metaEn.results[0].data.meta_description[0].text,
          },
        },
      }

      this.setState(fetchedDefaultMeta)
    } catch (error) {
      console.error('Unable to fetch metadata from Prismic')
    }
  }

  render() {
    const { defaultMeta } = this.state
    const {
      currentView,
      navigation: { currentRouteLang, router },
    } = this.props.rootStore

    let meta = {}
    if (currentView && currentView.data) {
      const { title, meta_image, meta_description } = currentView.data

      if (title && title.length) {
        // Exception for homepage
        if (title[0].text !== 'Homepage') {
          meta.title = `${title[0].text} | Triennale Milano`
        } else {
          meta.title = 'Triennale Milano'
        }
      } else {
        const pagesThatDontExistInBackend = pagesThatDontExistInBackendMeta[currentView.pathname]

        if (pagesThatDontExistInBackend) {
          meta.title = `${pagesThatDontExistInBackend.title} | Triennale Milano`
        } else {
          // extreme ratio, last resource
          meta.title = defaultMeta[currentRouteLang].title
        }
      }

      if (meta_image && !isEmpty(meta_image)) {
        meta.image = meta_image.url
      } else {
        meta.image = defaultMeta[currentRouteLang].image
      }

      if (meta_description && meta_description.length) {
        meta.description = meta_description[0].text
      } else {
        meta.description = defaultMeta[currentRouteLang].description
      }
    }

    const url = process.env.NODE_ENV === 'development' ? '' : 'https://www.triennale.org'

    return (
      <Helmet htmlAttributes={{ lang: currentRouteLang }}>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:image" content={meta.image} />
        <meta property="og:url" content={`${url}${fixPath(router.location.pathname)}`} />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
      </Helmet>
    )
  }
}

export default Meta
