import React from 'react'
import { inject, observer } from 'mobx-react'
import { searchHints } from 'triennale-prismic-api-wrapper'
import { linkResolver } from '../../lib/link-resolver'
import { Link } from '../../components'
import styles from './SearchHints.module.css'

@inject('rootStore')
@observer
class SearchHints extends React.Component {
  state = {
    hints: [],
  }

  inputRef = React.createRef()

  async componentDidMount() {
    const { currentRouteLangCode } = this.props.rootStore.navigation
    const data = await searchHints.get(currentRouteLangCode)
    this.setState({ hints: data.results[0].data.links })
  }

  render() {
    const { currentRouteLang } = this.props.rootStore.navigation
    const { hints } = this.state

    return (
      hints &&
      hints.length && (
        <div className={styles.hints}>
          <p className={styles.hintsTitle}>
            {currentRouteLang === 'it' ? 'Forse stavi cercando...' : 'Were you looking for...'}
          </p>
          {hints.map((hint, i) => {
            const { type, uid } = hint.link
            return (
              <Link
                className={`outline ${styles.link}`}
                to={linkResolver({ contentType: type, uid, lang: currentRouteLang })}
                key={i}
              >
                <span>{hint.label}</span>
              </Link>
            )
          })}
        </div>
      )
    )
  }
}

export default SearchHints
