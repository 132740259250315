import React from 'react'
import { inject, observer } from 'mobx-react'
import { reaction } from 'mobx'
import noImageIt from '../../images/no_image_it.svg'
import noImageEn from '../../images/no_image_en.svg'
import styles from './ArchiveLightbox.module.css'
import LoaderOval from '../layoutComponents/LoaderOval'

@inject('rootStore')
@observer
class ArchiveImgWithFallback extends React.Component {
  state = {
    src: null,
    additionalClass: undefined,
    loaded: false,
  }

  componentDidMount() {
    this.updateSrc()

    reaction(() => this.props.src, _ => this.updateSrc())
  }

  updateSrc = () => {
    const { src } = this.props
    this.setState({ src, additionalClass: undefined, loaded: false })
  }

  updateSrcWithPlaceholder = () => {
    const { currentRouteLang } = this.props.rootStore.navigation
    this.setState({
      loaded: false,
      src: currentRouteLang === 'it' ? noImageIt : noImageEn,
      additionalClass: styles.placeholder,
    })
  }

  notify = () => {
    this.setState({ loaded: true })
  }

  render() {
    const { src, additionalClass, loaded } = this.state
    const { title, hasAdditionalClass } = this.props

    return (
      <React.Fragment>
        <img
          src={src}
          alt={title}
          className={`${styles.img} ${hasAdditionalClass ? additionalClass : ''} ${
            loaded ? styles.imgIsVisible : ''
          }`}
          onError={this.updateSrcWithPlaceholder}
          onLoad={this.notify}
        />
        <div className={`${styles.hold} ${loaded ? '' : styles.holdIsVisible}`}>
          <LoaderOval isCompact={true} />
        </div>
      </React.Fragment>
    )
  }
}

export default ArchiveImgWithFallback
