import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { navLinks } from '../../lib/prismic/navLinks'
import { Link } from '../../components'
import { getLink } from '../../lib/link-resolver'
import styles from './secondaryMenu.module.css'

@inject('rootStore')
@observer
export class SecondaryMenu extends Component {
  state = { navLinks: [] }
  async componentDidMount() {
    const {
      rootStore: {
        navigation: { currentRouteLangCode },
      },
    } = this.props

    const uid = currentRouteLangCode === 'it-it' ? 'magazine_nav_links' : 'en_magazine_nav_links'
    const fetchMagazineNavLinks = await navLinks.getByUids([uid], currentRouteLangCode)
    const routesMagazineLinks = fetchMagazineNavLinks.results[0]?.data.body.map(link => {
      const {
        primary: { link_label },
      } = link
      return { label: link_label, route: getLink(link.primary) }
    })

    this.setState({ navLinks: routesMagazineLinks })
  }

  render() {
    const { navLinks } = this.state
    return (
      <div className={styles.menu}>
        <nav className={styles.navLinks}>
          {navLinks?.map(({ label, route }, i) => {
            return (
              <Link key={i} to={route || '#'}>
                {label} →
              </Link>
            )
          })}
        </nav>
      </div>
    )
  }
}
