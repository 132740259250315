import React from 'react'
import { inject, observer } from 'mobx-react'
import noop from 'lodash/noop'
import { getFormattedDate } from '../../lib/date-utils'
import { Link } from '../../components'
import { linkResolver } from '../../lib/link-resolver'
import { ResponsiveImage } from '../Shared/Templating'
import styles from '../../components/Ongoing/SingleMagazinePreviewLayout.module.css'
import { EventTitles } from '../Shared/EventTitles'
import { filterEmptyPrismicRepeatersContents, parsePrismicHtml } from '../../lib/survive-prismic'

function setHover(e, ctx) {
  if (!e.target || (e.target && !Array.from(e.target.classList).includes('location'))) {
    ctx.setState({ hovered: true })
  }
}

function unsetHover(ctx) {
  ctx.setState({ hovered: false })
}

@inject('rootStore')
@observer
export class SingleMagazinePreviewLayout extends React.Component {
  state = {
    hovered: false,
  }

  buildSetHover = e => setHover(e, this)
  buildUnsetHover = _ => unsetHover(this)

  render() {
    const { hovered } = this.state
    const {
      rootStore: {
        navigation: { currentRouteLang, currentRoute },
        ui: { labelsColor },
      },

      magazine: {
        uid,
        first_publication_date,

        data: {
          title,
          cover_image,
          cover_image_focus_on,
          author,
          preview_abstract,
          magazine_series,
        },
        background_color,
        text_color,
      },
      isFocus = false,
      isHero = false,
    } = this.props

    const image =
      isFocus && cover_image_focus_on.url && cover_image_focus_on.url !== ''
        ? cover_image_focus_on
        : cover_image

    // const _author = filterEmptyPrismicRepeatersContents(author, 'text')
    const _preview_abstract = filterEmptyPrismicRepeatersContents(preview_abstract, 'text')

    return (
      <article className={`${styles.magazine} ${isHero ? styles.magazineHero : ''}`}>
        <Link
          onMouseOver={this.buildSetHover}
          onMouseOut={this.buildUnsetHover}
          onFocus={noop}
          onBlur={noop}
          to={linkResolver({ contentType: 'magazine', lang: currentRouteLang, uid })}
          className={`outline ${styles.magazineLink} ${hovered ? styles.hovered : ''}`}
        >
          <div className={styles.magazineCover}>
            {image.url && (
              <div className={styles.magazineCoverInner}>
                <div className={styles.magazineCoverInnerCrop}>
                  <ResponsiveImage img={image} additionalClassName={styles.img} />
                </div>
              </div>
            )}
          </div>

          <div
            className={styles.magazineHeader}
            style={{
              backgroundColor:
                isHero && background_color !== null ? background_color : 'transparent',
              color: isHero && text_color !== null ? text_color : 'black',
            }}
          >
            <div className={styles.magazineHeaderInner}>
              <div className={styles.magazineTitlesWrapper}>
                <EventTitles title={title} author={author} magazineSeries={magazine_series} />
              </div>
              <div className={styles.magazineInfo}>
                <div className={`${styles.magazineDates} bold`} style={{ color: labelsColor }}>
                  {getFormattedDate(first_publication_date, {
                    lang: currentRouteLang,
                  })}
                </div>
              </div>
              {_preview_abstract?.length > 0 &&
                (currentRoute.name === 'magazine|it' || currentRoute.name === 'magazine|en') && (
                  <div
                    className={`${styles.previewAbstract} bodyText`}
                    dangerouslySetInnerHTML={{
                      __html: parsePrismicHtml(preview_abstract, linkResolver),
                    }}
                  />
                )}
            </div>
          </div>
        </Link>
      </article>
    )
  }
}
