import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/fp/map'
import { Filter } from './Filter'
import { translator } from '../../../lib/translator'
import styles from './Filters.module.css'
import { dateToStringWithLocale } from '../../../lib/date-utils'

@inject('rootStore')
@observer
export class CurrentFilters extends React.Component {
  getFilterValues(filtersValues, label, values) {
    let _values = values
    const { currentRouteLang } = this.props.rootStore.navigation
    const localeAndFormat = currentRouteLang === 'it' ? 'it' : 'en-gb'
    const endDateObj = Object.values(filtersValues).find((el, i) => el.label === 'end_date')
    const startDateObj = Object.values(filtersValues).find((el, i) => el.label === 'start_date')

    if (label === 'end_date') {
      if (endDateObj.values === startDateObj.values) {
        _values = []
      } else {
        _values = [
          `${translator('end_date')(currentRouteLang)} - ${dateToStringWithLocale(values, {
            locale: localeAndFormat,
            format: localeAndFormat,
          })}`,
        ]
      }
    } else if (label === 'start_date') {
      _values = [
        `${translator(
          endDateObj && endDateObj.values !== startDateObj.values ? 'start_date' : 'only_date',
        )(currentRouteLang)} - ${dateToStringWithLocale(values, {
          locale: localeAndFormat,
          format: localeAndFormat,
        })}`,
      ]
    }

    return _values
  }

  render() {
    const {
      navigation: { currentRouteLang },
    } = this.props.rootStore
    const { getActiveFiltersValues, isFilterActive, toggleFilter } = this.props.rootStore[
      this.props.id
    ]
    const filtersValues = getActiveFiltersValues()
    const FiltersLabels = ({ label, values }) => {
      const filterValues = this.getFilterValues(filtersValues, label, values)

      const filter = (value, index) => {
        return (
          <Filter
            key={`${index}-${label}`}
            type={label}
            value={value}
            toggleFilter={toggleFilter}
            isFilterActive={isFilterActive}
          />
        )
      }
      return filterValues.map(filter)
    }
    return !isEmpty(filtersValues) ? (
      <div className={styles.currentFiltersWrapper}>
        <span className={`bodyText`}>{translator('activeFiltersTitle')(currentRouteLang)}: </span>
        {map(FiltersLabels)(filtersValues)}
      </div>
    ) : null
  }
}
