import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { Link } from '../../components'
import { PageTitle } from '../../components/Shared/Titles'
import { transparencyNav } from '../../store/transparency-nav'
import styles from './TransparencyPage.module.css'
import { linkResolverNormalizer } from '../../lib/link-resolver'
import { parsePrismicHtml } from '../../lib/survive-prismic'

const TransparencySideMenu = lang => {
  return (
    <ul className={styles.list}>
      {transparencyNav[lang].map((item, i) => {
        return (
          <li key={i} className={styles.item}>
            <Link
              className={`outline ${styles.link}${
                window.location.pathname === item.link ? ` ${styles.linkActive}` : ''
              }`}
              to={item.link}
            >
              {item.label}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export const TransparencyPage = inject('rootStore')(
  observer(({ rootStore: { navigation: { currentRouteLang } }, transparency }) => {
    if (isEmpty(transparency)) {
      return null
    }

    const { title, text } = transparency.data

    return (
      <>
        <PageTitle title={title} border={false} />
        <div className={styles.grid}>
          <div className={`bodyText ${styles.right}`}>{TransparencySideMenu(currentRouteLang)}</div>
          <div
            className={`bodyText ${styles.left}`}
            dangerouslySetInnerHTML={{
              __html: parsePrismicHtml(text, linkResolverNormalizer),
            }}
          />
        </div>
      </>
    )
  }),
)
