import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './AbstractLinks.module.css'
import { Link } from '../../components'
import { linkResolver } from '../../lib/link-resolver'
import { parsePrismicHtml } from '../../lib/survive-prismic'

const INTERNAL_LINK_KEY = 'Link interno al sito'
const EXTERNAL_LINK_KEY = 'Sito web esterno'
const DOWNLOAD_KEY = 'Documento scaricabile'
const FORM_KEY = 'Form'

const LINKS_TYPES = {
  [EXTERNAL_LINK_KEY]: { type: 'externalWebsite' },
  [INTERNAL_LINK_KEY]: { type: 'internalLink' },
  [DOWNLOAD_KEY]: { type: 'download' },
  [FORM_KEY]: { type: 'form' },
}

@inject('rootStore')
@observer
export class AbstractLinks extends React.Component {
  render() {
    const { links, linkKey } = this.props
    const _links = links.filter(link => link[`${linkKey}_link`].url || link[`${linkKey}_link`].uid)

    const { currentRouteLang } = this.props.rootStore.navigation

    return (
      <>
        {_links?.length > 0 && (
          <div className={styles.descriptionLinks}>
            {_links.map((link, i) => {
              const linkTypeKey = link[`${linkKey}_type`]
              const linkType = LINKS_TYPES[linkTypeKey]
              const linkField = link[`${linkKey}_link`]
              const fontSizeClass = 'bodyTextBig'
              const linkClasses = `${fontSizeClass} link ${styles.abstractLink} ${
                styles[linkType.type]
              }`

              return linkTypeKey === INTERNAL_LINK_KEY ? (
                <Link
                  key={i}
                  to={linkResolver({
                    contentType: linkField.type,
                    uid: linkField.uid,
                    lang: currentRouteLang,
                  })}
                  className={linkClasses}
                >
                  <div
                    className={styles.abstractLinkLabel}
                    dangerouslySetInnerHTML={{
                      __html: parsePrismicHtml(link[`${linkKey}_label`]),
                    }}
                  />
                </Link>
              ) : (
                <div key={i}>
                  <a
                    href={linkField.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={linkClasses}
                  >
                    <div
                      className={styles.abstractLinkLabel}
                      dangerouslySetInnerHTML={{
                        __html: parsePrismicHtml(link[`${linkKey}_label`]),
                      }}
                    />
                  </a>
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }
}
