import React, { useState } from 'react'
import { Observer, inject } from 'mobx-react'
import { translator } from '../../lib/translator'
import styles from '../../routes/Tickets.module.css'

export const TicketSubscriptionForm = inject('rootStore')(({ rootStore, error }) => {
  const {
    tickets: {
      fetchSubscriptionInfo,
      subscription: { resetError },
    },
    navigation: { currentRouteLang },
  } = rootStore
  const [barcode, setBarcode] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubscribeSubmit = (fetchSubscriptionInfo, barcode) => async e => {
    e.preventDefault()
    setLoading(true)
    await fetchSubscriptionInfo(barcode)
    setLoading(false)
  }

  const handleChange = setBarcode => e => {
    setBarcode(e.target.value)
    resetError()
  }

  return (
    <Observer>
      {() => (
        <>
          <h2>{translator('subscriptionFormTitle')(currentRouteLang)}</h2>
          <form
            className={`${styles.subscribeForm}`}
            onSubmit={onSubscribeSubmit(fetchSubscriptionInfo, barcode)}
          >
            <input
              onChange={handleChange(setBarcode)}
              value={barcode}
              type="text"
              name="q"
              aria-label={translator('subscriptionCode')(currentRouteLang)}
              placeholder={translator('subscriptionCode')(currentRouteLang)}
            />
            <button className={`button smallText`} disabled={!barcode || loading}>
              {loading ? translator('loadingMessage')(currentRouteLang) : ' →'}
            </button>
            {error && <div className={styles.subscriptionError}>{error}</div>}
            <div className={`smallText ${styles.disclaimer}`}>
              {translator('subscriptionFormDisclaimer')(currentRouteLang)}
            </div>
          </form>
        </>
      )}
    </Observer>
  )
})
