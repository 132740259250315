import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import xorWith from 'lodash/xorWith'
import compact from 'lodash/compact'
import { pickBy } from 'lodash/fp'
import qs from 'query-string'
import { translator } from './translator'
import { isPrerenderedVersion } from './static-site'
import { filterEmptyPrismicRepeatersContents } from './survive-prismic'

export const parseRoute = (route, delimiter = '|') => {
  const routeSplit = route.name.split(delimiter)
  const lang = routeSplit.pop()
  return {
    name: routeSplit.join(delimiter),
    lang,
  }
}

export const haveRouteSecondLevel = routeName => routeName.indexOf('|') > 0
export const haveRouteThirdLevel = routeName => routeName.replace(/[^|]/g, '').length === 2

export const getActiveFilters = f => pickBy(v => !isEmpty(v))(f)

const subtractLight = (color, amount) => {
  let cc = parseInt(color, 16) - amount
  let c = cc < 0 ? 0 : cc
  c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`
  return c
}

export const colorDarken = (color, amount) => {
  color = color.indexOf('#') >= 0 ? color.substring(1, color.length) : color
  amount = parseInt((255 * amount) / 100)
  return (color = `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(
    color.substring(2, 4),
    amount,
  )}${subtractLight(color.substring(4, 6), amount)}`)
}

// from https://stackoverflow.com/a/20413768
export function isRetina() {
  return (
    ((window.matchMedia &&
      (window.matchMedia(
        'only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)',
      ).matches ||
        window.matchMedia(
          'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)',
        ).matches)) ||
      (window.devicePixelRatio && window.devicePixelRatio >= 2)) &&
    /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
  )
}

// This functions is being called in two different moments and places:
//  - during images download/upload script execution
//  - at runtime on contents that are dynamically loaded from Prismic (i.e. not in static html page)
export function fixImageFilename(filename) {
  let [fixedFilename, queryString] = filename.replace(/%2F/g, '-').split(/\?/)
  const { w, h } = qs.parse(queryString || '')

  if (w && h) {
    const filenameParts = fixedFilename.split(/(.+)\.([^.]+)$/)
    fixedFilename = `${filenameParts[1]}_w${w}_h${h}.${filenameParts[2]}`
  }
  // S3 images filenames need to be encoded,
  // but first we have to decode them in order to avoid double encoding
  return encodeURIComponent(decodeURIComponent(fixedFilename))
}

export function convertImageUrl(url) {
  if (isPrerenderedVersion && /prismic/.test(url)) {
    const parts = url.split('/')
    const filename = fixImageFilename(parts[parts.length - 1])

    const bucket = process.env.AWS_S3_BUCKET
    let baseUrl = `https://s3-eu-west-1.amazonaws.com/${bucket}`
    switch (process.env.APP_ENV) {
      case 'production':
        baseUrl += '/images'
        break
      case 'staging':
        baseUrl += '/images-staging'
        break
      default:
        baseUrl += '/images-development'
        break
    }
    return `${baseUrl}/${filename}`
  }
  return url
}

export function updateSrc(originalUrl) {
  return function(e) {
    if (e.target.src !== originalUrl) {
      e.target.src = originalUrl
      e.target.srcset = originalUrl
      e.target.sizes = 'auto'
    }
  }
}

export const handlerGaPageView = location => {
  const { search, pathname } = location
  return search && search !== '?' ? pathname + search : pathname
}

export const transformTextFieldNewlinesToBr = field => {
  if (typeof field === 'string') {
    return field
  } else if (filterEmptyPrismicRepeatersContents(field, 'text').length > 0) {
    return field[0].text.replace('\n', '<br>')
  }

  return null
}

export const isObjectNull = obj => {
  return Object.keys(obj).reduce((is, key) => {
    if (!isEmpty(typeof obj[key] !== 'boolean' ? compact(obj[key]) : obj[key])) {
      is = false
    }
    return is
  }, true)
}

const currencyDelimiter = {
  it: ',',
  en: '.',
}

export const computePrice = (priceComponents, lang) => {
  const delimiter = currencyDelimiter[lang]
  const priceAsString = priceComponents.reduce((acc, next) => acc + Number(next || 0), 0).toString()

  if (priceAsString.length < 2) {
    return translator('priceError')(lang)
  } else if (priceAsString.length === 2) {
    return `0${delimiter}${priceAsString}€`
  }

  return `${priceAsString.substr(0, priceAsString.length - 2)}${delimiter}${priceAsString.substr(
    priceAsString.length - 2,
  )}€`
}

// from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export function validateEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

// the Seat model provided from the VivaTicket transaction endpoint
// is different from the Seat model needed for the website UI and to query
// the transaction endpoint, and thus the MST store. It needs to be normalized.
export function convertTransactionSeatsToStoreSeats(transactionSeats) {
  const storeSeats = transactionSeats.reduce((acc, next) => {
    const seat = {
      reductionId: next.reduction_id,
      zoneId: next.zone_id,
      amount: 1,
    }

    const seatIsInArray = acc.find(
      s => s.reductionId === seat.reductionId && s.zoneId === seat.zoneId,
    )

    seatIsInArray
      ? (acc = acc.map(s => {
          if (s.reductionId === seat.reductionId && s.zoneId === seat.zoneId) {
            s.amount += 1
          }
          return s
        }))
      : acc.push(seat)
    return acc
  }, [])

  return storeSeats
}

// from https://stackoverflow.com/a/37066038
export function isArrayEqual(x, y) {
  return isEmpty(xorWith(x, y, isEqual))
}

export const capitalizeString = string => {
  return string
    .split(' ')
    .map(s => `${s.charAt(0).toUpperCase()}${s.slice(1).toLowerCase()}`)
    .join(' ')
}

export function transformMailAddressIntoLink(message, addressToTransform) {
  return message.replace(
    addressToTransform,
    `<a href="mailto:${addressToTransform}" target="_blank" rel="noopener noreferrer">${addressToTransform}</a>`,
  )
}

export const convertArrayToObject = (array, key) => {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    }
  }, initialValue)
}
