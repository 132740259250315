import React from 'react'
import styles from './LoaderOval.module.css'
import loader from '../../images/loader.gif'

const LoaderOval = ({ isCompact }) => {
  return (
    <div className={`${styles.loader} ${isCompact ? styles.loaderCompact : ''}`}>
      <img src={loader} alt="loader" width="150" />
    </div>
  )
}

export default LoaderOval
