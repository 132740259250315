import React from 'react'
import noop from 'lodash/noop'
import styles from './NewsItemsPreview.module.css'
import { htmlSerializer } from '../Shared/Templating'
import { getFormattedDate } from '../../lib/date-utils'
import { parsePrismicHtml } from '../../lib/survive-prismic'

export const SingleNewsItemPreview = ({ data: { text }, uid, first_publication_date }, options) => {
  const { lang } = options

  return (
    <div key={uid} className={styles.gridItem}>
      <div className={styles.inner}>
        <div className={`smallText ${styles.date} bold`}>
          {getFormattedDate(first_publication_date, {
            withHours: false,
            lang,
          })}
        </div>

        <div
          className={`smallText ${styles.newsBody}`}
          dangerouslySetInnerHTML={{
            __html: parsePrismicHtml(text, noop, htmlSerializer),
          }}
        />
      </div>
    </div>
  )
}

export default SingleNewsItemPreview
