import React from 'react'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'

import styles from './ArchiveLightbox.module.css'

const capitalize = c => (c ? c.charAt(0).toUpperCase() + c.slice(1) : '')

const normalize = fn => input =>
  Array.isArray(input) ? input.map(i => fn(i)).join(', ') : fn(input)

const List = label => data => {
  if (isEmpty(compact(data))) return
  return (
    <p>
      {label ? <b>{label}:</b> : null}
      {compact(data).map((d, i) => (
        <span key={i}> {normalize(capitalize)(d)}</span>
      ))}
    </p>
  )
}

const Label = label => value => {
  if (!value) return
  return (
    <p>
      <b>{label}:</b> {normalize(capitalize)(value)}
    </p>
  )
}

export const AudiovideoArchiveContents = ({
  title,
  year,
  productionCompany,
  genre,
  duration,
  authors,
  chromatism,
}) => {
  return (
    <>
      <p>
        <strong>{normalize(capitalize)(title)}</strong>
      </p>
      {List('Autore')(authors)}
      {Label('Anno')(year)}
      {Label('Produzione')(productionCompany)}
      {Label('Genere')(genre)}
      {Label('Durata')(duration)}
      {Label('Colore')(chromatism)}
    </>
  )
}

export const PhotoArchiveContents = ({
  title,
  description,
  classification,
  materials,
  photoAuthor,
  size,
  shelfMark,
}) => {
  const Author = ({
    photoAuthorName,
    photoAuthorCollectiveName,
    photoAuthorLifeDates,
    photoAuthorRole,
  }) => {
    const authorName = photoAuthorName || photoAuthorCollectiveName
    const isAuthorAnonymous = authName => authName === 'Non identificato'
    return (
      <>
        <b>Autore: </b>
        {isAuthorAnonymous(authorName)
          ? authorName
          : `${authorName} ${
              photoAuthorLifeDates ? `(${photoAuthorLifeDates})` : ''
            } ${photoAuthorRole}`}
      </>
    )
  }

  return (
    <>
      <p>
        <b>{title}</b>
      </p>
      <p>{description}</p>
      {!isEmpty(photoAuthor) && <p>{photoAuthor.map(Author)}</p>}
      {List('Classificazione')(classification)}
      {List('Materiali')(materials)}
      {Label('Misura')(size)}
      {Label('Collocazione')(shelfMark)}
    </>
  )
}

export const HistoricalArchiveContents = ({ title, description, hierarchyIndex, type, code }) => {
  return (
    <>
      <p>
        <strong>
          {hierarchyIndex}. {title}
        </strong>
      </p>
      <p>{description}</p>
      {Label('Tipologia')(type)}
      {Label('Collocazione')(code)}
    </>
  )
}

export const CollezioniArchiveContents = data => {
  const {
    title,
    year,
    format,
    type,
    authors,
    company,
    materials,
    dimensions,
    project,
    projectYear,
    isPresentInMuseum,
  } = data

  return (
    <>
      <p>
        <strong>{normalize(capitalize)(title || project)}</strong>
      </p>
      {Array.isArray(type) ? List('Tipologia')(type) : Label('Tipologia')(type)}
      {List('Autore')(authors)}
      {Label('Azienda')(company)}
      {Label('Materiali')(materials)}
      {Label('Formato')(format)}
      {Label('Dimensione')(dimensions)}
      {Label('Anno')(year || projectYear)}
      {isPresentInMuseum && <span className={styles.label}>In Mostra</span>}
    </>
  )
}
