import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RouteNotFound } from '../../routes/NotFound'
import { routes as allRoutes } from '../../routes'
import styles from './Main.module.css'

class Main extends React.Component {
  render() {
    return <main className={styles.main}>{buildFirstLevelSwitch(allRoutes)}</main>
  }
}

const redirect = [
  ['/it', '/'],
  ['/eventi', '/whats-on'],
  ['/en/events', '/en/whats-on'],
  ['/visita', '/visita/organizza-la-tua-visita'],
  ['/en/visit', '/en/visit/plan-your-visit'],
  ['/chi-siamo', '/chi-siamo/storia-e-mission'],
  ['/en/about', '/en/about/history-and-mission'],
  ['/sostieni', '/sostieni/erogazioni-liberali'],
  ['/en/support', '/en/support/donations'],
  ['/partecipa', '/partecipa/famiglie-e-bambini'],
  ['/en/take-part', '/en/take-part/families-and-kids'],

  ['/en/mostra', '/en/whats-on'],
  ['/en/design_museum', '/en/about/history-and-mission'],
  ['/en/categoria_mostra/in_corso', '/en/whats-on'],
  ['/en/categoria_mostra/future', '/en/whats-on'],
  ['/en/evento/', '/en/whats-on'],
  ['/en/visita/biglietti-e-info-pratiche-visita', '/en/visit/plan-your-visit'],

  ['/sostieni/erogazioni-liberali', '/sostieni/donazioni'],

  ['/mostra', '/whats-on'],
  ['/design_museum', '/chi-siamo/storia-e-mission'],
  ['/categoria_mostra/in_corso', '/whats-on'],
  ['/categoria_mostra/future', '/whats-on'],
  ['/evento/', '/whats-on'],
  ['/visita/biglietti-e-info-pratiche-visita', '/visita/organizza-la-tua-visita'],

  ['/magazine_it', '/magazine'],
  ['/en/magazine_en', '/en/magazine'],
]

function buildFirstLevelSwitch(routes) {
  return (
    <Switch>
      {redirect.map((r, i) => (
        <Redirect key={i} from={r[0]} to={r[1]} exact={true} />
      ))}
      {routes.map(route => buildRoute(route))}
      <RouteNotFound />
    </Switch>
  )
}

function buildSwitch(routes) {
  return (
    <Switch>
      {routes.map(route => buildRoute(route))}
      <RouteNotFound />
    </Switch>
  )
}

function buildRoute(route) {
  const MaybeNestedRoute = withNestedRoutes(route)

  if (route.exact) {
    return <Route exact path={route.path} component={MaybeNestedRoute} key={route.name} />
  }

  return <Route path={route.path} component={MaybeNestedRoute} key={route.name} />
}

function withNestedRoutes(route) {
  return class extends React.Component {
    render() {
      const RouteComponent = route.component
      const hasChildrenRoutes = route.routes && route.routes.length

      return (
        <>
          <RouteComponent {...this.props} />
          {hasChildrenRoutes ? buildSwitch(route.routes) : null}
        </>
      )
    }
  }
}

export default Main
