import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from '../layoutComponents'
import styles from './MainNav.module.css'
import { ReactComponent as ChevronRightIcon } from '../../images/icons/chevron-right.svg'

@inject('rootStore')
@observer
class MainNav extends React.Component {
  toggleAccordion = id => e => {
    const { toggleActiveAccordionItem } = this.props.rootStore.ui
    e.preventDefault()
    toggleActiveAccordionItem(id)
  }

  // easier to duplicate parent buildList code than to make recursive
  buildChildList = (items, childListItemIndex) => {
    const { navigation, ui } = this.props.rootStore
    const { isCurrentRoute, isParentOfCurrentRoute } = navigation
    const { activeAccordionItem } = ui

    return (
      <ul
        className={`${styles.list}${
          activeAccordionItem === childListItemIndex ? ` ${styles.listVisible}` : ''
        }`}
      >
        {items.map((item, i) => {
          const { label, link } = item

          return (
            <li key={i} className={`navText`}>
              <Link
                to={link}
                className={`outline 
                  ${styles.link}
                  ${isParentOfCurrentRoute({ path: link }) ? ` ${styles.linkChildActive}` : ''}
                  ${isCurrentRoute({ path: link }) ? ` ${styles.linkActive}` : ''}
                `}
              >
                <span>{label}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    )
  }

  render() {
    const { mainNav, navigation, ui } = this.props.rootStore
    const { isCurrentRoute, currentRouteName } = navigation

    if (!mainNav) {
      return null
    }

    const isArchiveChild = ['triennale-archives', 'archive-results'].includes(currentRouteName)

    return (
      <nav className={styles.mainNav}>
        <div className={styles.mainNavInner}>
          <ul className={styles.list}>
            {mainNav.map((item, i) => {
              const { items, primary } = item
              const { label, link, isDead, isPromo, animationType, isExternal } = primary
              const isArchive = /doppia riga/i.test(animationType)
              const slug = link
              const hasChildren = items.length > 0

              return (
                <li key={i} className={`navText`}>
                  {isExternal ? (
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`outline ${styles.link}`}
                    >
                      <span dangerouslySetInnerHTML={{ __html: label }} />
                    </a>
                  ) : (
                    <Link
                      to={{ pathname: isDead ? '#0' : slug }}
                      className={`outline ${styles.link}${
                        isCurrentRoute({ path: slug }) || (isArchive && isArchiveChild)
                          ? ` ${styles.linkActive}`
                          : ''
                      }${isPromo ? ` ${styles.promoLink}` : ''} ${
                        isArchive ? ` ${styles.archiveLink}` : ''
                      }`}
                      onClick={isDead ? this.toggleAccordion(i) : null}
                    >
                      <span dangerouslySetInnerHTML={{ __html: label }} />
                      {hasChildren && (
                        <ChevronRightIcon
                          className={
                            !ui.isAccordionActive(i) ? styles.chevronIcon : styles.chevronIconActive
                          }
                        />
                      )}
                    </Link>
                  )}
                  {hasChildren && this.buildChildList(items, i)}
                </li>
              )
            })}
          </ul>
        </div>
      </nav>
    )
  }
}

export default MainNav
