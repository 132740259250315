import React from 'react'
import { inject, observer } from 'mobx-react'
import { collezioneData } from '../lib/temp/collezione'
import { archivioFotograficoData } from '../lib/temp/archivio-fotografico'
import { archivioAudiovisivoData } from '../lib/temp/archivio-audiovisivo'
import { archivioStoricoData } from '../lib/temp/archivio-storico'
import { PageTitlePlain } from '../components/Shared/Titles'
import styles from './SingleArchive.module.css'
import { SearchBox } from '../components'
import { parsePrismicHtml } from '../lib/survive-prismic'

function archiveIdTranslator(archiveId, lang) {
  const ARCHIVE_IDS_TRANSLATION = {
    collections: 'collezioni',
    'photographic-archive': 'archivio-fotografico',
    'audiovisual-archive': 'archivio-audiovisivo',
    'historical-archive': 'archivio-storico',
  }

  return lang === 'it' ? archiveId : ARCHIVE_IDS_TRANSLATION[archiveId]
}

@inject('rootStore')
@observer
export class SingleArchive extends React.Component {
  render() {
    const { rootStore, match } = this.props
    const { navigation } = rootStore
    const { currentRouteLang } = navigation
    const { resource } = match.params

    const dataSwitcher = {
      collezioni: collezioneData,
      'archivio-fotografico': archivioFotograficoData,
      'archivio-audiovisivo': archivioAudiovisivoData,
      'archivio-storico': archivioStoricoData,
    }

    const archiveId = archiveIdTranslator(resource, currentRouteLang)
    const data = dataSwitcher[archiveId][currentRouteLang].data

    return (
      <div>
        <PageTitlePlain title={data.title[0].text} />
        <SearchBox archiveId={archiveId} />
        <div
          className={`${styles.description} bold typographicTitle`}
          dangerouslySetInnerHTML={{ __html: parsePrismicHtml(data.description) }}
        />
      </div>
    )
  }
}
