import cloneDeep from 'lodash/cloneDeep'
import uniqBy from 'lodash/uniqBy'

import { getThisWeekendInterval } from 'triennale-prismic-api-wrapper/src/query'
import {
  fetchList,
  getCurrentOrFuture,
  getDataSlice,
  getByIntervalInDateRange,
  getPaginatedData,
  getTotalPages,
  searchInText,
  sortBySpecificOrStartDate,
} from 'triennale-prismic-api-wrapper/src/fetcher'
import { getEventsByPromotionId } from 'triennale-prismic-api-wrapper/src/fetchers/promotionsFetcher'
import {
  getEventsByUids,
  getContainerPagesData,
} from 'triennale-prismic-api-wrapper/src/fetchers/eventsFetcher'
import { getOngoing } from 'triennale-prismic-api-wrapper/src/fetchers/ongoingFetcher'
import { event as contentTypeId } from 'triennale-prismic-api-wrapper/src/constants/contentTypes'
import { event as fields } from 'triennale-prismic-api-wrapper/src/constants/fields'
import { event as queries } from 'triennale-prismic-api-wrapper/src/queries'

let contentType

const getFocusEvents = async (focusEventsRefs, filters, lang) => {
  return (await fetchList(focusEventsRefs, async ({ value: { uid } }) => {
    if (!uid) {
      return null
    }
    const options = {
      shouldFetchRelatedContents: false,
      shouldFetchContainerPage: true,
      shouldFetchPriceLists: false,
    }
    const [focusEvent] = (await getEventsByUids([uid], lang, options)) || []
    if (!focusEvent) {
      return null
    }
    // If we are filtering by a certain type
    if (filters[fields.TYPE] && !filters[fields.TYPE].includes(focusEvent.data.type)) {
      return null
    }
    // Cloning in order to not modify cached value
    const updatedFocusEvent = cloneDeep(focusEvent)
    updatedFocusEvent.data.focus = true
    return updatedFocusEvent
  })).filter(e => e)
}

const sortByDate = sortBySpecificOrStartDate

const getEventsPaginatedData = async (query, lang, pagination = null, onlyFutureDates = false) => {
  const data = await getPaginatedData(query, lang, pagination, sortByDate(onlyFutureDates))
  return getContainerPagesData(data, lang)
}

const getEventsPaginatedDataUnsorted = async (query, lang, pagination = null, orderings) => {
  const data = await getPaginatedData(query, lang, pagination, undefined, orderings)
  return getContainerPagesData(data, lang)
}

const getAllFilters = (filters = {}, options = {}) => {
  const allFilters = { ...filters }
  if (options.ongoing_whitelist) {
    allFilters[fields.HIDE_FROM_ONGOING] = 'No'
  }
  return allFilters
}

contentType = {
  contentTypeId,
  fields,
  queries,
  sortByDate: sortByDate(false),
  getCurrent: async (filters, options, lang, pagination = null) => {
    const allFilters = getAllFilters(filters, options)

    // Get focus events = require(ongoing
    const ongoingPage = (await getOngoing(lang))[0].data
    // Temporary: do not include focusEvents if some filter is active
    const focusEvents =
      Object.keys(filters || {}).length === 0
        ? await getFocusEvents(ongoingPage.focus_events, allFilters, lang)
        : []

    let currentEvents = (await getCurrentOrFuture(queries, allFilters, lang)).results
    currentEvents = sortByDate(true)(currentEvents)
    const results = uniqBy([...focusEvents, ...currentEvents], 'uid')
    return getContainerPagesData(
      {
        total_pages: getTotalPages(results, pagination),
        results: getDataSlice(results, pagination),
      },
      lang,
    )
  },
  getFuture: (filters, options, lang, pagination = null) => {
    const allFilters = getAllFilters(filters, options)
    return getEventsPaginatedData(queries.getFuture(allFilters), lang, pagination, true)
  },
  getPast: (filters, options, lang, pagination = null, orderings = '[my.event.end_date desc]') => {
    const allFilters = getAllFilters(filters, options)
    // orderings
    return getEventsPaginatedDataUnsorted(queries.getPast(allFilters), lang, pagination, orderings)
  },
  getThisWeekend: async (filters, options, lang, pagination = null) => {
    const { startDate, endDate } = getThisWeekendInterval()
    const allFilters = getAllFilters(filters, options)
    const data = await getByIntervalInDateRange(
      queries,
      startDate,
      endDate,
      allFilters,
      lang,
      pagination,
      sortByDate(true),
    )
    return getContainerPagesData(data, lang)
  },
  getFromDateRange: (startDate, endDate) => async (filters, options, lang, pagination = null) => {
    const allFilters = getAllFilters(filters, options)
    const data = await getByIntervalInDateRange(
      queries,
      startDate,
      endDate,
      allFilters,
      lang,
      pagination,
      sortByDate(false),
    )
    return getContainerPagesData(data, lang)
  },
  getByUids: getEventsByUids,
  searchInText: async (keyword, lang, pagination = null) => {
    const data = await searchInText(contentTypeId)(keyword, lang, pagination)
    return getContainerPagesData(data, lang)
  },
  getByPromotionId: getEventsByPromotionId,
}

export { contentType as event }
