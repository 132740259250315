import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { event as eventType } from '../lib/prismic/event'

import { TICKETS_SERVER_URL, EXHIBITION_TYPE } from './const'
import { translator } from './translator'

// the Seat model provided from the VivaTicket transaction endpoint
// is different from the Seat model needed for the website UI and to query
// the transaction endpoint, and thus the MST store. It needs to be normalized.
export function convertTransactionSeatsToStoreSeats(transactionSeats) {
  const storeSeats = transactionSeats.reduce((acc, next) => {
    const seat = {
      reductionId: next.reduction_id,
      zoneId: next.zone_id,
      amount: 1,
    }

    const seatIsInArray = acc.find(
      s => s.reductionId === seat.reductionId && s.zoneId === seat.zoneId,
    )

    seatIsInArray
      ? (acc = acc.map(s => {
          if (s.reductionId === seat.reductionId && s.zoneId === seat.zoneId) {
            s.amount += 1
          }
          return s
        }))
      : acc.push(seat)
    return acc
  }, [])

  return storeSeats
}

const currencyDelimiter = {
  it: ',',
  en: '.',
}

export const isASubscriptionOrExhibition = ticket =>
  ticket.performance.findIndex(p => p.abb === '1' || p.subscription === '1') >= 0

export const isAnExhibition = ticket => EXHIBITION_TYPE.indexOf(ticket.data.type) >= 0

export const getListByKeyFromSeats = transactions => key =>
  transactions
    .reduce((acc, t) => {
      t.seats.forEach(seat => acc.push(seat[key]))
      return acc
    }, [])
    .join(', ')

export const amountPerPerfomance = performance => {
  const { price, presale, commission, iva, nTickets } = performance
  return (price + presale + commission + iva) * nTickets
}

export const totalAmount = transactions =>
  transactions.reduce((acc, curr) => acc + amountPerPerfomance(curr), 0)

export const computePrice = (priceComponents, lang = 'it') => {
  const delimiter = currencyDelimiter[lang]
  const priceAsString = priceComponents.reduce((acc, next) => acc + Number(next || 0), 0).toString()
  if (priceAsString === '0') return translator('priceFree')(lang)
  if (priceAsString.length < 2) {
    return translator('priceError')(lang)
  } else if (priceAsString.length === 2) {
    return `0${delimiter}${priceAsString}€`
  }

  return `${priceAsString.substr(0, priceAsString.length - 2)}${delimiter}${priceAsString.substr(
    priceAsString.length - 2,
  )}€`
}

export const fetchCurrentPrismicEvents = async () => {
  const { results: eventsData } = await eventType.getCurrent()
  return eventsData
}

export const fetchVivaTicketSubscription = async barcode => {
  const {
    data: { data },
  } = await axios.get(`${TICKETS_SERVER_URL}/api/subscriptions/${barcode}`)
  return !isEmpty(data) ? data : []
}

export const createOrderTicket = async params => {
  const {
    data: { data },
  } = await axios.get(`${TICKETS_SERVER_URL}/api/orders`, {
    params,
  })
  return !isEmpty(data) ? data : []
}

export const getTransSubscriptionTicket = async (barcode, transaction, params) => {
  const {
    data: { data },
  } = await axios.get(`${TICKETS_SERVER_URL}/api/subscriptions/${barcode}/${transaction}`, {
    params,
  })
  return !isEmpty(data) ? data : []
}

export const fetchVivaTicket = async () => {
  const ticketsResp = await axios.get(`${TICKETS_SERVER_URL}/api/events`)
  const { data } = ticketsResp
  return !isEmpty(data.events)
    ? data.events.reduce((acc, event) => {
        const { performs } = event
        !isEmpty(performs) &&
          performs.map(p => {
            acc.push({
              ...p,
              vcode: event.vcode,
            })
          })
        return acc
      }, [])
    : []
}

export const getCurrentEventIdsWithTicket = async () => {
  const events = await fetchCurrentPrismicEvents()
  const tickets = await fetchVivaTicket()
  const isTicketValid = id => tickets.map(t => t.id_show).indexOf(id) >= 0
  const subscriptionTicketsIds = tickets
    .filter(t => isASubscriptionOrExhibition(t))
    .map(t => t.id_show)
  const eventsTicketsIds = events
    .filter(r => r.data.show_id !== null && isTicketValid(r.data.show_id))
    .map(e => e.data.show_id)
  return [...eventsTicketsIds, ...subscriptionTicketsIds]
}
