import React from 'react'
import {
  AllArchivesPreviewSection,
  CollectionArchivesPreviewSection,
} from '../Shared/ArchivePreviewSection'

export const ArchivePreview = ({ type }) => {
  return {
    'Non mostrare': null,
    'Mostra anteprima di tutti gli archivi': <AllArchivesPreviewSection />,
    "Mostra anteprima dell'archivio delle collezioni": <CollectionArchivesPreviewSection />,
  }[type || 'Non mostrare']
}
