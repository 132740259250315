import React from 'react'
import { inject, Observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { Link } from '../../../components'
import styles from './../Series/listSeries.module.css'
import { filterEmptyPrismicRepeatersContents } from '../../../lib/survive-prismic'
import { linkResolver } from '../../../lib/link-resolver'

const ListContributorsItem = ({ itemData, options, currentRouteLang }) => {
  const {
    uid,
    data: { name, abstract },
  } = itemData

  const _name = filterEmptyPrismicRepeatersContents(name, 'text')
  const _abstract = filterEmptyPrismicRepeatersContents(abstract, 'text')

  return (
    <div key={uid} className={styles.gridItem}>
      <Link
        to={linkResolver({
          contentType: 'magazine_author',
          lang: currentRouteLang,
          uid,
        })}
      >
        <div className={styles.inner}>
          <h2>{_name[0]?.text}</h2>
          <p>{_abstract[0]?.text}</p>
        </div>
      </Link>
    </div>
  )
}

export const ListContributors = inject('rootStore')(({ rootStore, options }) => {
  const {
    magazineAuthor: { results },
    navigation: { currentRouteLang },
  } = rootStore

  const CreateEmptyPositionalElements = length => {
    if (length % 4 !== 0) {
      const elementsLength = 4 - (length % 4)
      const emptyElementsArray = Array.from(Array(elementsLength).fill())
      return emptyElementsArray.map((_, i) => {
        return <div key={i} className={[styles.gridItem, styles.empty].join(' ')} />
      })
    }
    return null
  }

  return (
    <Observer>
      {() => (
        <div className={styles.grid}>
          {!isEmpty(results) ? (
            <>
              {results.map((n, i) => {
                return (
                  <ListContributorsItem
                    key={i}
                    itemData={n}
                    options={options}
                    currentRouteLang={currentRouteLang}
                  />
                )
              })}
              {CreateEmptyPositionalElements(results.length)}
            </>
          ) : null}
        </div>
      )}
    </Observer>
  )
})
