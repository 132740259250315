import find from 'lodash/find'
import get from 'lodash/get'
import { CATEGORY_LIST } from './const'

const APP_TRANSLATION = {
  default: { it: '', en: '' },
  allCategories: { it: 'Tutte le categorie', en: 'All categories' },
  onGoingPageTitle: { it: 'In programma', en: "What's on" },
  historyPageTitle: { it: 'Eventi passati', en: 'Past events' },
  publicProgram: { it: 'In programma', en: 'Public program' },
  pressPageTitle: { it: 'Comunicati stampa', en: 'Press releases' },
  magazinePageTitle: { it: 'Scopri', en: 'Discover' },
  newsPageTitle: { it: 'News', en: 'News' },
  activeFiltersTitle: { it: 'Filtri attivi', en: 'Active filters' },
  filterOngoingTitleDate1: {
    it: 'Scopri il programma di Triennale Milano per ',
    en: "Discover Triennale's program of ",
  },
  filterOngoingTitleDate2: {
    it: 'per la categoria',
    en: 'by category',
  },
  filterOngoingTitle: {
    it: 'Scopri il programma di Triennale Milano per',
    en: "Discover Triennale Milano's program by",
  },
  filterHistoryTitle: {
    it: 'Scopri gli eventi passati di Triennale Milano per',
    en: "Discover Triennale Milano's past events by",
  },
  cancel: { it: 'Annulla', en: 'Cancel' },
  confirm: { it: 'Conferma', en: 'Confirm' },
  partnerSectionTitle: { it: '', en: '' }, // { it: 'Grazie a', en: 'Thanks to' },
  currentEventsSectionTitle: { it: 'In programma', en: "What's on" },
  currentNewsSectionTitle: { it: 'News', en: 'News' },
  currentPromotionsSectionTitle: { it: 'Promozioni', en: 'Promotions' },
  publicProgramSectionTitle: { it: 'Public Program', en: 'Public Program' },
  publicProgramSectionCta: { it: 'Scopri tutti gli eventi', en: 'Discover all events' },

  goToEvent: { it: 'Scopri di più', en: 'Read more' },
  internationalParticipationsSectionTitle: {
    it: 'Partecipazioni Internazionali',
    en: 'International Participations',
  },
  showMoreMediaCtaPart1: { it: 'Vedi', en: 'Show' },
  showMoreMediaCtaPart2Singular: { it: 'altra', en: 'more' },
  showMoreMediaCtaPart2Plural: { it: 'altre', en: 'more' },

  eventRoute: { it: '/eventi', en: '/en/events' },

  'A cura di': { it: 'A cura di', en: 'Curated by' },
  'In collaborazione con': { it: 'In collaborazione con', en: 'In collaboration with' },
  'Progetto di allestimento': { it: 'Progetto di allestimento', en: 'Exhibition design' },
  'Progetto grafico': { it: 'Progetto grafico', en: 'Graphic design' },
  Catalogo: { it: 'Catalogo', en: 'Catalogue' },
  'Mostra prodotta da': { it: 'Mostra prodotta da', en: 'Exhibition produced by' },
  'Mostra promossa da': { it: 'Mostra promossa da', en: 'Exhibition promoted by' },
  Direzione: { it: 'Direzione', en: 'Direction' },

  Ideazione: { it: 'Ideazione', en: 'Concept' },
  Regia: { it: 'Regia', en: 'Direction' },
  Con: { it: 'Con', en: 'With' },
  Produzione: { it: 'Produzione', en: 'Production' },
  Coproduzione: { it: 'Coproduzione', en: 'Coproduction' },

  worksSectionTitle: { it: 'Highlights', en: 'Highlights' },
  behindTheScenesSectionTitle: { it: 'Allestimento', en: 'Installation images' },
  creditsSectionTitle: { it: 'Crediti', en: 'Credits' },
  secondaryInfoSectionTitle: { it: 'Info', en: 'Info' },

  relatedEventsSectionTitle: { it: 'Eventi correlati', en: 'Related events' },
  relatedMagazinesSectionTitle: { it: 'Articoli correlati', en: 'Related articles' },

  paymentInfoFormDisclaimer: {
    it:
      "Problemi con l’acquisto? Scrivi a biglietteria@triennale.org. Una volta concluso l'acquisto, non è più possibile modificare i dettagli.",
    en:
      "Problemi con l’acquisto? Scrivi a biglietteria@triennale.org. Una volta concluso l'acquisto, non è più possibile modificare i dettagli.",
  },
  subscriptionFormTitle: {
    it: 'Vuoi prenotare una mostra o uno spettacolo?',
    en: 'Do you want to book a show or an exhibition?',
  },
  subscriptionFormDisclaimer: {
    it: "Il codice abbonamento è il barcode che trovi all'interno dell'email di acquisto",
    en: "Il codice abbonamento è il barcode che trovi all'interno dell'email di acquisto",
  },
  subscriptionCode: {
    it: 'Codice Abbonamento',
    en: 'Subsciption Barcode',
  },
  displayAll: { it: 'Visualizza tutto', en: 'Display all' },
  reserveTicket: { it: 'Prenota', en: 'Reserve' },
  buyTicket: { it: 'Acquista', en: 'Buy' },
  buyTicketNow: { it: 'Acquista ora', en: 'Buy now' },

  deleteTicket: { it: 'Cancella', en: 'Delete' },
  register: { it: 'Registrati', en: 'Register' },
  booking: { it: 'Prenota', en: 'Booking' },

  allArchivesPreviewLinkTitle: {
    it: "Link all'Archivio Digitale",
    en: 'Link to the Digital Archive',
  },
  collectionsArchivePreviewLinkTitle: {
    it: "Link all'Archivio della Collezione",
    en: 'Link to the Collection Archive',
  },
  allArchivesPreviewSection: { it: 'Archivi e collezione', en: 'Archives and collection' },
  collectionsArchivePreviewSection: {
    it: 'Collezione',
    en: 'Collection',
  },
  backTicketsPage: {
    it: 'Torna alla pagina biglietti',
    en: 'Back to tickets page',
  },
  archivePreviewSection: { it: 'Archivi', en: 'Archives' },
  howToReachUsSectionTitle: { it: 'Come raggiungerci', en: 'How to reach us' },

  guidedToursSectionTitle: { it: 'Visite guidate', en: 'Guided tours' },
  guidedToursPriceLabel: { it: 'Prezzo aggiuntivo', en: 'Additional cost' },

  guidedToursSectionDays: {
    en: 'In follows days is it possible to have a guided tour',
    it: 'Nella seguente data è possibile effettuare una visità giudata',
  },
  guidedTourSectionTitle: { it: 'Visita guidata', en: 'Guided tour' },

  selectTicket: { it: 'Seleziona i biglietti', en: 'Select your tickets' },

  promotionsSectionTitle: { it: 'Promozioni', en: 'Promotions' },
  openingHoursSectionTitle: { it: 'Orari', en: 'Opening hours' },

  freeEventLabel: { it: 'Ingresso gratuito', en: 'Free admission' },

  'serviceName-Store': { it: 'Negozio', en: 'Store' },
  'serviceName-Terrazza': { it: 'Terrazza Triennale', en: 'Terrazza Triennale' },
  'serviceName-Caffè e pizzeria': { it: 'Caffè e pizzeria', en: 'Café and pizzeria' },
  'serviceName-Giardino': { it: 'Giardino', en: 'Garden' },
  'serviceName-Caffè in giardino': { it: 'Caffè in giardino', en: 'Garden café' },
  'serviceName-Biblioteca': { it: 'Biblioteca', en: 'Library' },
  'serviceName-Generali': { it: 'Generale', en: 'General' },

  closedLabel: { it: 'Chiuso', en: 'Closed' },
  openLabel: { it: 'Aperto', en: 'Open' },
  loadMoreButton: { it: 'Carica altri contenuti', en: 'Load more contents' },
  loadMoreButton_events: { it: 'Carica altro', en: 'Load more' },
  loadMoreButton_news: { it: 'Carica altro', en: 'Load more' },
  loadMoreButton_press: { it: 'Carica altro', en: 'Load more' },
  more: { it: 'altro', en: 'more' },
  filterButton: { it: 'Cosa cerchi?', en: 'What are you looking for?' },

  checkOpeningHoursMessage: { it: 'Controlla gli orari', en: 'Check the opening hours' },
  noResultsMessage: { it: 'Nessun risultato', en: 'No results' },
  errorMessage: {
    it: "C'è stato un errore di comunicazione con il server. Per favore, riprova più tardi!",
    en: 'There was en error communicating with the server. Please retry later!',
  },
  errorResultsMessage: { it: 'Ops! Si è verificato un errore', en: 'Ops! An error occurred' },
  NotFoundMessage: { it: 'Errore 404! Pagina non trovata', en: '404 Error. Page not found' },

  duration: { it: 'Durata', en: 'Duration' },
  price: { it: 'Prezzo', en: 'Price' },
  minutes: { it: 'minuti', en: 'minutes' },
  calendarSectionTitle: { it: 'Calendario', en: 'Calendar' },
  calendarTitleFilters: { it: 'Calendario →', en: 'Calendar →' },
  calendarTitleFiltersChangeDate: { it: 'Cambia data→', en: 'Change date→' },
  searchResultsPageTitle: { it: 'Risultati della ricerca per: ', en: 'Search results for: ' },

  eventsSectionTitle: { it: 'Eventi', en: 'Events' },
  containerPagesSectionTitle: { it: 'Festival', en: 'Festival' },

  event: { it: 'Eventi', en: 'Events' },
  exhibition: { it: 'Mostre', en: 'Exhibitions' },
  subscription: { it: 'Abbonamenti', en: 'Subscriptions' },
  container_page: { it: 'Festival', en: 'Festival' },
  press: { it: 'Comunicati Stampa', en: 'Press Releases' },
  news: { it: 'News', en: 'News' },
  pages: { it: 'Pagine', en: 'Pages' },
  promotion: { it: 'Promozioni', en: 'Promotions' },

  permanentEvent: { it: 'Permanente', en: 'Permanent' },
  monthEvent: { it: 'Per tutto il mese di ', en: 'Throughout the month of ' },
  start_date: { it: 'Data di inizio', en: 'Start date' },
  end_date: { it: 'Data di fine', en: 'End date' },
  only_date: { it: 'In data', en: 'Date' },

  dateFilterTitle: { it: 'Seleziona una data', en: 'Select a date' },
  promotionRelatedToCaption: {
    it: 'Questa promozione vale per',
    en: 'This promotion applies to',
  },

  searchLabel: { it: 'Cerca', en: 'Search' },

  exploreCTA: { it: 'Scopri di più', en: 'Read more' },

  promotionsTitle: { it: 'Promozioni', en: 'Promotions' },
  ticketsTitle: { it: 'Acquista', en: 'Buy' },

  plannedEvents: { it: 'Eventi in programma', en: 'Planned events' },
  plannedPromotions: { it: 'Promozioni in programma', en: 'Planned promotions' },

  newsletterHead: {
    it: 'Resta con noi',
    en: 'Keep in touch',
  },
  newsletterPress: {
    it: 'Newsletter giornalisti',
    en: 'Press newsletter',
  },
  newsletterSchool: {
    it: 'Newsletter scuole',
    en: 'School Newsletter',
  },
  newsletterSubscribe: { it: 'Iscriviti alla newsletter', en: 'Subscribe to newsletter' },
  newsletterSubscribePress: {
    it: 'Iscriviti alla newsletter giornalisti',
    en: 'Subscribe to press newsletter',
  },

  pressReleasePdfLink: { it: 'Comunicato stampa', en: 'Press release' },
  pressReleaseKitLink: { it: 'Press kit', en: 'Press kit' },

  goToNewsCTA: { it: 'Vedi tutte le news', en: 'See all news' },
  opensAt: { it: 'Apre alle', en: 'Opens at' },
  openingHoursFor: { it: 'Orari di apertura per', en: 'Opening hours for' },
  specialOpening: { it: 'Orario straordinario', en: 'Special opening' },

  archivesTitle: { it: 'Archivi e collezione', en: 'Archives and collection' },
  contentsLabel: { it: 'contenuti', en: 'contents' },
  triennaleExpoSectionTitle: {
    it: 'Esposizioni internazionali',
    en: 'International exhibits',
  },

  triennaleExpoSectionCaption: {
    it: 'Esplora i contenuti',
    en: 'Explore the contents',
  },

  librarySectionTitle: { it: 'Info biblioteca', en: 'Library info' },

  archiveSearchResultsTitle: {
    it: 'Risultati ricerca',
    en: 'Search results',
  },

  noImgText: {
    it: 'Nessuna immagine',
    en: 'No image',
  },

  lightboxPrev: {
    it: 'Precedente',
    en: 'Previous',
  },

  lightboxNext: {
    it: 'Prossima',
    en: 'Next',
  },

  archiveSearchInputPlaceholderMasculinePlural: {
    it: 'Cerca negli',
    en: 'Browse our',
  },

  archiveSearchInputPlaceholderMasculineSingular: {
    it: 'Cerca nel',
    en: 'Browse our',
  },

  archiveSearchInputPlaceholderFemininePlural: {
    it: 'Cerca nelle',
    en: 'Browse our',
  },

  archiveFullTextSearch: {
    freeText: { it: 'Ricerca Libera', en: 'Free Search' },
    title: { it: 'Titolo', en: 'Title' },
    author: { it: 'Autore', en: 'Author' },
    year: { it: 'Anno', en: 'Year' },
    company: { it: 'Azienda', en: 'Company' },
  },

  archiveOptionTypesTitle: {
    archives: { it: 'Collezioni', en: 'Collections' },
    isPresentInMuseum: { it: '', en: '' },
    timerange: { it: 'Anni', en: 'Years' },
    expoId: { it: 'Edizione Triennale', en: 'Triennale Edition' },
  },
  noDatesForTicketMessage: {
    it: 'Spiacenti, non ci sono date disponibili per questo spettacolo',
    en: 'Sorry, no dates are available for this show',
  },
  noTicketsMessage: {
    it: 'Non ci sono biglietti acquistabili in questo momento',
    en: 'Currently, no shows have tickets to be purchased',
  },
  noZonesForTicketMessage: {
    it: 'Non ci sono settori disponibili per questo spettacolo',
    en: 'Currently, no zones are available for this show',
  },
  loadingMessage: {
    it: 'Caricamento...',
    en: 'Loading...',
  },
  noReductionsForTicketMessage: {
    it: 'Non ci sono tipologie di biglietto disponibili per questo spettacolo',
    en: 'Currently, no ticket types are available for this show',
  },
  availableSeats: {
    it: 'disponibili',
    en: 'available',
  },
  only: { en: 'Only', it: 'Solo' },
  seats: { en: 'seats', it: 'posti' },
  soldOut: { en: 'Sold out', it: 'Esaurito' },
  ticketsDateTimeBtn: {
    it: 'Procedi',
    en: 'Proceed',
  },
  goToCartBtn: {
    it: 'Vai al carrello',
    en: 'Go to the cart',
  },
  availableGuidedTour: {
    it: 'visita disponibili',
    en: 'available guided tour',
  },
  ticketsZoneTicketsAmountBtn: {
    it: 'Procedi',
    en: 'Proceed',
  },
  marketingSingleTicket: {
    it: 'Visita tutte le mostre con un unico biglietto',
    en: 'Visit all exhibitions with a single ticket',
  },
  priceError: {
    it: 'Errore',
    en: 'Error',
  },

  priceFree: {
    it: 'Gratis',
    en: 'Free',
  },

  ticketsTotalLabel: {
    it: 'Totale',
    en: 'Total',
  },

  promocodeInputMessage: {
    it: 'Premi invio per confermare',
    en: 'Press submit to confirm',
  },

  promocodeSuccessMessage: {
    it: '',
    en: '',
  },

  promocodeErrorMessage: {
    it: '',
    en: '',
  },

  nameInputLabel: {
    it: 'Nome',
    en: 'Name',
  },

  surnameInputLabel: {
    it: 'Cognome',
    en: 'Surname',
  },

  emailInputLabel: {
    it: 'Email',
    en: 'Email',
  },
  emailConfirmationInputLabel: {
    it: 'Conferma email',
    en: 'Confirm email',
  },
  phoneInputLabel: {
    it: 'Telefono',
    en: 'Phone',
  },
  submitInputLabel: {
    it: 'Procedi',
    en: 'Proceed',
  },
  submitInputLoadingLabel: {
    it: 'Stai per essere reindirizzato al pagamento...',
    en: 'Loading...Please wait!',
  },

  invalidEmailErrorMessage: {
    it: "C'è un errore nel campo email",
    en: 'There is an error in the email field',
  },
  noEmailMatchErrorMessage: {
    it: 'La mail e la conferma non corrispondono',
    en: "The two emails don't match",
  },

  dateStepBreadcrumbLabel: { it: 'Data', en: 'Date' },
  ticketStepBreadcrumbLabel: { it: 'Biglietti', en: 'Tickets' },
  paymentBreadcrumbLabel: { it: 'Pagamento', en: 'Payment' },

  ticketErrorTitle: {
    it: 'Errore',
    en: 'Error',
  },
  ticketErrorMessage: {
    it:
      "Si è verificato un errore nell'elaborazione di questa richiesta. Per favore, riprova più tardi",
    en: 'The request could not be fulfilled. Please, come back later and retry',
  },

  thankYouSuccessTitle: {
    it: 'Biglietti – Grazie',
    en: 'Tickets – Thank you',
  },

  thankYouErrorTitle: {
    it: 'Biglietti – Errore',
    en: 'Tickets – Error',
  },

  thankYouErrorMessage: {
    it: 'Siamo spiacenti, non è stato possibile processare il tuo ordine. Per favore, riprova',
    en: 'Sorry, your order could not be processed. Please, try again later',
  },

  ticketJointRelative: {
    it: 'Dichiaro di effettuare un acquisto per utenti congiunti',
    en: 'I declare to make a purchase for joint-relative users',
  },

  ticketJointNonRelative: {
    it: 'Dichiaro di effettuare un acquisto per utenti non congiunti',
    en: 'I declare to make a purchase for non-joint-relative users',
  },

  ticketMailLoadMessage: {
    it:
      "Attenzione! Rimanere su questa pagina e attendere il messaggio di conferma dell'email di riepilogo",
    en: 'Warning! Please do not close this page until you see a confirmation message',
  },
  ticketSelectDate: {
    it: 'Seleziona la data',
    en: 'Select the date',
  },
  ticketMailSuccessMessage: {
    it: 'Una mail di riepilogo ti è stata inviata correttamente',
    en: 'Please check your inbox for the confirmation mail',
  },
  ticketMailErrorMessage: {
    it:
      'C’è stato un errore nell’invio della mail di riepilogo. Scrivi a biglietteria@triennale.org e fornisci i tuoi dati e il seguente codice associato al tuo pagamento:',
    en:
      'There has been an error in your confirmation email. Please, get in touch with biglietteria@triennale.org providing your ticket information and the following payment code:',
  },

  postBy: {
    it: 'di',
    en: 'by',
  },

  unavailable: {
    it: 'Non disponibile',
    en: 'Unavailable',
  },

  magazineCategory: {
    it: 'Scopri',
    en: 'Discover',
  },

  ctaMagazineSection: {
    it: 'Cultura, design e nuove idee',
    en: 'Culture, design and new ideas',
  },
  ctaNewsSection: {
    it: 'Leggi le news',
    en: 'Read the news',
  },
  ctaCurrentEventsSection: {
    it: 'Scopri tutti gli eventi',
    en: 'Discover all events',
  },
  ctaArchivesSection: {
    it: 'Esplora centinaia di contenuti',
    en: 'Explore hundreds contents',
  },
  seenByYou: { it: 'Vista da voi', en: 'Seen by you' },
  series: {
    it: 'Serie',
    en: 'Series',
  },
  followInstagram: { it: 'Seguici su Instagram', en: ' Follow us on Instagram' },
  contributors: {
    it: 'Autori',
    en: 'Contributors',
  },
}

export const eventCategoryTranslator = label => (lang = 'it') => {
  const category = find(CATEGORY_LIST, ['it', label])
  return category ? category[lang] : null
}

export const translator = (label = 'default') => (lang = 'it') => {
  return !get(APP_TRANSLATION, label)
    ? get(APP_TRANSLATION, `default.${lang}`)
    : get(APP_TRANSLATION, `${label}.${lang}`)
}

export const translatorUp = (lang = 'it') => (label = 'default') => {
  return !get(APP_TRANSLATION, label)
    ? get(APP_TRANSLATION, `default.${lang}`)
    : get(APP_TRANSLATION, `${label}.${lang}`)
}
