import React from 'react'
import isEmpty from 'lodash/isEmpty'
import throttle from 'lodash/throttle'
import { inject, observer } from 'mobx-react'
import { SectionTitlePlain, SectionTitlePlainWithLink } from './Titles'
import { translator } from '../../lib/translator'
import styles from './MediaSection.module.css'
import { ResponsiveImage } from './Templating'
import { linkResolverNormalizer } from '../../lib/link-resolver'
import { parsePrismicHtml } from '../../lib/survive-prismic'
import { whichTransitionEndEvent } from '../../lib/utils/cross-browser-utils'
import { IframeWithFallback } from './IframeWithFallback'
import { withIframesResizer } from './withIframesResizer'

const IMG_KEYS = {
  artworks: {
    img: 'artwork_image',
    caption: 'artwork_image_caption',
    type: 'artwork_video_source_type',
    uid: 'artwork_video_id',
    html: 'artwork_embed_media_html',
  },
  behindTheScenes: {
    img: 'behind_the_scenes_image',
    caption: 'behind_the_scenes_image_caption',
    type: 'behind_the_scenes_video_source_type',
    uid: 'behind_the_scenes_video_id',
    html: 'behind_the_scenes_embed_media_html',
  },
  seenByYou: {
    img: 'image',
    caption: 'seen_by_you_image_caption',
  },
  specialCommission: {
    img: 'project_gallery_image',
    caption: 'project_gallery_image_caption',
    type: 'project_gallery_video_source_type',
    uid: 'project_gallery_video_id',
    html: 'project_gallery_embed_media_html',
  },
  gallery_expandible: {
    img: 'image',
    caption: 'alt',
  },
}

export const WrappedVideoLayout = ({ forwardedRef, data, contentType }) => {
  return (
    <div ref={forwardedRef}>
      <IframeWithFallback
        uid={data[IMG_KEYS[contentType].uid]}
        type={data[IMG_KEYS[contentType].type]}
        autoplay={false}
        html={data[IMG_KEYS[contentType].html]}
      />
    </div>
  )
}

const WrappedVideo = withIframesResizer(WrappedVideoLayout, 730)

const Figure = ({ media, contentType, children }) => {
  const areCaptionsNotEmpty = captions => captions.findIndex(c => c.text) >= 0
  return (
    <figure>
      <div className={styles.figureImgWrapper}>
        {!isEmpty(media[IMG_KEYS[contentType].img]) && media[IMG_KEYS[contentType].img].url ? (
          <ResponsiveImage img={media[IMG_KEYS[contentType].img]} />
        ) : (
          <WrappedVideo data={media} contentType={contentType} />
        )}
        {children}
      </div>
      {!isEmpty(media[IMG_KEYS[contentType].caption]) &&
        areCaptionsNotEmpty(media[IMG_KEYS[contentType].caption]) && (
          <figcaption
            className={`captionText ${styles.caption}`}
            dangerouslySetInnerHTML={{
              __html: parsePrismicHtml(
                media[IMG_KEYS[contentType].caption],
                linkResolverNormalizer,
              ),
            }}
          />
        )}
    </figure>
  )
}

@inject('rootStore')
@observer
export class ExpandableButton extends React.Component {
  btnRef = React.createRef()

  state = {
    expanded: false,
  }

  componentDidMount() {
    this.attach()
  }

  componentWillUnmount() {
    this.detach()
  }

  attach = () => {
    window.addEventListener('scroll', this.throttled)
    this.btnRef.current.addEventListener('mouseenter', this.buildExpand)
  }

  detach = () => {
    window.removeEventListener('scroll', this.throttled)
    this.btnRef.current.removeEventListener('mouseenter', this.buildExpand)
  }

  throttled = throttle(e => this.onScroll(e), 10)

  getOffsetTop = elem => {
    let offsetTop = 0
    do {
      if (!isNaN(elem.offsetTop)) {
        offsetTop += elem.offsetTop
      }
    } while ((elem = elem.offsetParent))
    return offsetTop
  }

  getBtnOffsetTop = () => {
    return this.getOffsetTop(this.btnRef.current)
  }

  onScroll(_) {
    if (this.btnRef.current) {
      const offsetTop = this.getBtnOffsetTop()
      const actualScrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const shouldExpand = offsetTop <= actualScrollTop + (4 * window.innerHeight) / 5

      if (shouldExpand) {
        this.expand()
      }
    }
  }

  buildExpand = e => this.expand(e)

  expand(e) {
    if (e) e.preventDefault()

    this.setState({ expanded: true }, () => {
      if (this.state.expanded) {
        this.detach()
      }
    })
  }

  render() {
    const { expanded } = this.state
    const { showMore, mediaAmount } = this.props

    return (
      <button
        ref={this.btnRef}
        className={`${styles.expandableButton} ${
          expanded ? styles.expandableButtonExpanded : ''
        } bodyText`}
        onClick={showMore}
      >
        +<span>{mediaAmount} contenuti</span>
      </button>
    )
  }
}

@inject('rootStore')
@observer
export class MediaSectionExpandable extends React.Component {
  slidingBlockRef = React.createRef()

  state = {
    height: 0,
  }

  setToAuto = () => {
    this.setState({ height: 'auto' })
  }

  showMore = e => {
    e.preventDefault()
    this.slidingBlockRef.current.addEventListener(whichTransitionEndEvent(), this.setToAuto)
    this.setState({ height: 1000 })
  }

  render() {
    const { height } = this.state
    const { media, contentType, titleLabel, rootStore } = this.props
    const { currentRouteLang: lang } = rootStore.navigation

    const filteredMedia =
      media &&
      media?.filter(medium => {
        return (
          medium[IMG_KEYS[contentType].img]?.url ||
          medium[IMG_KEYS[contentType].uid] ||
          medium[IMG_KEYS[contentType].html]
        )
      })

    if (!filteredMedia?.length) {
      return null
    }

    const isShowMoreButton = filteredMedia?.length > 1
    const firstMedia = filteredMedia.shift()

    const isEmptyMedia =
      !firstMedia?.artwork_image?.url &&
      firstMedia?.artwork_video_id === null &&
      firstMedia?.artwork_embed_media_html?.length === 0

    if (isEmptyMedia) {
      return null
    }

    return (
      <section>
        {this.props.titleLabel && (
          <SectionTitlePlain title={translator(titleLabel)(lang)} border={true} />
        )}
        <div className={styles.block}>
          <div className={styles.item}>
            <Figure media={firstMedia} contentType={contentType}>
              {isShowMoreButton && height !== 'auto' && (
                <ExpandableButton showMore={this.showMore} mediaAmount={filteredMedia.length} />
              )}
            </Figure>
          </div>

          <div className={styles.slidingBlock} ref={this.slidingBlockRef} style={{ height }}>
            {filteredMedia.map((a, i) => {
              return (
                <div key={i} className={styles.item}>
                  <Figure media={a} contentType={contentType} />
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}

export const MediaSectionGrid = ({
  media,
  contentType = 'artworks',
  titleLabel,
  lang = 'it',
  itemsPerRow = 2,
  linkInfo,
}) => {
  const filteredMedia = media.filter(
    medium => medium[IMG_KEYS[contentType].img].url || medium[IMG_KEYS[contentType].uid],
  )

  if (!filteredMedia?.length) {
    return null
  }

  const title = linkInfo ? (
    SectionTitlePlainWithLink({
      title: linkInfo.title,
      link: linkInfo.link,
      cta: linkInfo.cta,
      externalLink: true,
    })
  ) : (
    <SectionTitlePlain title={translator(titleLabel)(lang)} border={true} />
  )

  return (
    <section>
      <div className={styles.wrapper}>
        {title}
        <div className={`${styles.grid} ${styles[`itemsPerRow${itemsPerRow}`]}`}>
          {filteredMedia.map((a, i) => {
            return (
              <div key={i} className={styles.gridItem}>
                <Figure media={a} contentType={contentType} />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
