import React from 'react'
import { inject, observer } from 'mobx-react'
import noop from 'lodash/noop'
import { Link } from '../../components'
import styles from './SingleEventPreviewLayout.module.css'
import { ResponsiveImage } from '../Shared/Templating'
import { ContentFiltersBlock } from '../Shared/ContentFilters/ContentFiltersBlock'
import { linkResolver } from '../../lib/link-resolver'
import { computeEventDates } from '../../lib/date-utils'
import { EventTitles } from '../Shared/EventTitles'
import { EventLocation } from '../SingleEvent/EventLocation'

function setHover(e, ctx) {
  if (!e.target || (e.target && !Array.from(e.target.classList).includes('location'))) {
    ctx.setState({ hovered: true })
  }
}

function unsetHover(ctx) {
  ctx.setState({ hovered: false })
}

@inject('rootStore')
@observer
export class SingleEventPreviewLayoutSmall extends React.Component {
  state = {
    hovered: false,
  }

  buildSetHover = e => setHover(e, this)
  buildUnsetHover = _ => unsetHover(this)

  render() {
    const { hovered } = this.state
    const { rootStore, event, isFocus = false, textAlignLeft } = this.props
    const { uid, data } = event
    const {
      start_date,
      end_date,
      type,
      title,
      cover_image,
      cover_image_focus_on,
      author,
      specific_dates,
      location,
      location_map_link,
      event_online,
      only_month,
    } = data
    const {
      navigation: { currentRouteLang },
      ui: { labelsColor },
    } = rootStore
    const { dateString } = computeEventDates(
      start_date,
      end_date,
      specific_dates,
      currentRouteLang,
      only_month,
    )

    const image =
      isFocus && cover_image_focus_on.url && cover_image_focus_on.url !== ''
        ? cover_image_focus_on
        : cover_image

    return (
      <article className={`${styles.smallEvent} ${textAlignLeft ? styles.textAlignLeft : ''}`}>
        <Link
          onMouseOver={this.buildSetHover}
          onMouseOut={this.buildUnsetHover}
          onFocus={noop}
          onBlur={noop}
          to={linkResolver({ contentType: 'event', lang: currentRouteLang, uid })}
          className={`outline ${styles.smallEventLink} ${hovered ? styles.hovered : ''}`}
        >
          <div className={styles.smallEventCover}>
            {image.url && (
              <div className={styles.smallEventCoverInner}>
                <div className={styles.smallEventCoverInnerCrop}>
                  <ResponsiveImage img={image} additionalClassName={styles.img} />
                </div>
              </div>
            )}
          </div>

          <div className={styles.smallEventHeader}>
            <div className={styles.smallEventHeaderInner}>
              <div>
                <ContentFiltersBlock
                  areFiltersClickable={false}
                  type={type}
                  currentLang={currentRouteLang}
                  contentType={'events'}
                  isOnlineEvent={event_online}
                />
                <EventLocation location={location} link={location_map_link} />
              </div>
              <div className={styles.smallEventTitlesWrapper}>
                <EventTitles author={author} title={title} titleClass="previewTitleSmall bold" />
              </div>
            </div>
            {dateString && (
              <div className={`${styles.smallEventDates} bold`} style={{ color: labelsColor }}>
                {dateString}
              </div>
            )}
          </div>
        </Link>
      </article>
    )
  }
}
