import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './OpeningHoursResult.module.css'
import { translator } from '../../lib/translator'
import { getSelectedDayOpeningHours, convertHour } from '../../lib/date-utils'

function getOpeningHoursForDay(openingHoursCalendar, selectedDay) {
  return getSelectedDayOpeningHours(openingHoursCalendar, selectedDay)
}

function getFormattedOpeningHours(opening, lang) {
  if (!opening) {
    return undefined
  }

  const possibleOpeningHoursRange = opening.split('–').map(b => convertHour(b, lang, '.'))
  return possibleOpeningHoursRange[0] !== -1 ? possibleOpeningHoursRange.join('–') : opening
}

@inject('rootStore')
@observer
export class OpeningHoursResult extends React.Component {
  render() {
    const { openingTimes, rootStore } = this.props
    const { openingHoursSelectedDay } = rootStore.ui
    const { currentRouteLang } = rootStore.navigation

    if (!openingHoursSelectedDay || !openingTimes) {
      return null
    }

    const { standard, override } = getOpeningHoursForDay(openingTimes, openingHoursSelectedDay)
    const opening = getFormattedOpeningHours(override || standard, currentRouteLang)

    return (
      <div>
        <p className={styles.paragraph}>
          {override ? <span>{translator('specialOpening')(currentRouteLang)}: </span> : null}
          <span>{opening || translator('closedLabel')(currentRouteLang)}</span>
        </p>
      </div>
    )
  }
}
