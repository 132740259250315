import React from 'react'
import { inject, observer } from 'mobx-react'
import { SearchForm, SearchHints } from '../../components'
import styles from './SearchPanel.module.css'
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg'

@inject('rootStore')
@observer
export default class SearchPanel extends React.Component {
  closeSearchPanel = e => {
    e.preventDefault()
    const { hideSearchPanel } = this.props.rootStore.ui
    hideSearchPanel()
  }

  render() {
    const {
      ui: { isSearchPanelVisible },
      navigation: { currentRouteLang },
    } = this.props.rootStore

    return (
      <div
        className={`${styles.wrapper}${isSearchPanelVisible ? ` ${styles.wrapperVisible}` : ''}`}
      >
        <button
          className={styles.closeToggle}
          onClick={this.closeSearchPanel}
          aria-label={
            currentRouteLang === 'it'
              ? 'Chiudi il pannello della ricerca libera'
              : 'Close the free search panel'
          }
        >
          <CloseIcon className={styles.xIcon} aria-hidden="true" />
        </button>
        <div className={styles.searchPanel}>
          <div className={styles.searchPanelInner}>
            <SearchForm shouldFocus={isSearchPanelVisible} />
            <SearchHints />
          </div>
        </div>
      </div>
    )
  }
}
