import React from 'react'
import { SectionTitlePlain } from './Titles'
import { translator } from '../../lib/translator'
import { SingleMagazinePreviewLayout } from '../Magazine/SingleMagazinePreviewLayout'
import styles from './RelatedMagazinesSection.module.css'

export const RelatedMagazinesSection = ({ related_magazines, lang, additionalClasses }) => {
  return (
    <>
      {related_magazines.length > 0 && (
        <section>
          <SectionTitlePlain
            title={translator('relatedMagazinesSectionTitle')(lang)}
            border={true}
          />
          <div className={`${styles.grid} ${additionalClasses}`}>
            {related_magazines.map((magazine, i) => (
              <div className={styles.gridItem} key={i}>
                <SingleMagazinePreviewLayout magazine={magazine} isFocus />
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  )
}
