import { get, getData, getByUids } from 'triennale-prismic-api-wrapper/src/fetcher'
import { openingTimes as contentTypeId } from 'triennale-prismic-api-wrapper/src/constants/contentTypes'
import { openingTimes as fields } from 'triennale-prismic-api-wrapper/src/constants/fields'
import { openingTimes as queries } from 'triennale-prismic-api-wrapper/src/queries'

const contentType = {
  contentTypeId,
  fields,
  queries,
  get: async lang => {
    const data = await get(contentTypeId)(lang)
    return data.results.reduce(
      (acc, r) => ({
        ...acc,
        [r.data.service_name]: {
          week_days: r.data.week_days,
          particular_days: r.data.particular_days,
        },
      }),
      {},
    )
  },
  getByUids: getByUids(fields),
  getForService: async (service, lang) => {
    const data = await getData(queries.getService(service), lang)
    return data.results[0].data
  },
}

export { contentType as openingTimes }
