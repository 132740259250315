import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { inject, Observer } from 'mobx-react'
import { Link } from '../../components'
import { htmlSerializer, ResponsiveImage } from '../Shared/Templating'
import { FormattedDateEvent } from '../Shared/FormattedDateEvent'
import styles from './StickyBlock.module.css'
import { OpeningHoursPicker } from './OpeningHoursPicker'
import { OpeningHoursResult } from './OpeningHoursResult'
import { translator } from '../../lib/translator'
import { linkResolver, linkResolverNormalizer } from '../../lib/link-resolver'
import { filterEmptyPrismicRepeatersContents, parsePrismicHtml } from '../../lib/survive-prismic'
import { decomposeUid } from '../../lib/utils/uid-utils'
import { MediaSectionGrid } from './MediaSection'
import { ContentFiltersBlock } from './ContentFilters/ContentFiltersBlock'
import { AbstractLinks } from '../../components/SingleEvent/AbstractLinks'
import { EventTitles } from '../Shared/EventTitles'
import { IframeWrapper } from './IframeWrapper'

export const StickyVideo = ({ html, caption }) => {
  return (
    <div className={styles.stickyVideo}>
      <IframeWrapper html={html} />
      {caption && <div className={styles.caption} dangerouslySetInnerHTML={{ __html: caption }} />}
    </div>
  )
}

export const StickyImage = ({ image, caption, renderAsPrismic }) => {
  return (
    image && (
      <figure>
        <ResponsiveImage img={image} />
        {caption && !isEmpty(caption) && (
          <figcaption
            className={`${styles.caption} captionText`}
            dangerouslySetInnerHTML={{
              __html: renderAsPrismic ? parsePrismicHtml(caption, linkResolverNormalizer) : caption,
            }}
          />
        )}
      </figure>
    )
  )
}

export const StickyMedia = ({ html, image, caption, renderAsPrismic }) => {
  if (image && image.url) {
    return <StickyImage image={image} caption={caption} renderAsPrismic={renderAsPrismic} />
  } else if (html?.length > 0 && html[0].text !== '') {
    return <StickyVideo html={html} caption={caption} />
  }
  return null
}

export const StickyMediaBlock = ({
  html,
  image,
  caption,
  author,
  renderAsPrismic,
  desktopModifierClass,
  sticky = false,
}) => {
  return (
    <div
      className={`${styles.stickyMedia} ${
        !sticky ? styles.avoidSticky : ''
      } ${desktopModifierClass || ''}`}
    >
      <div>
        {author && <div className={styles.stickyAuthor}>{author[0].text}</div>}
        <StickyMedia
          html={html}
          image={image}
          caption={caption}
          renderAsPrismic={renderAsPrismic}
        />
      </div>
    </div>
  )
}

export function prismicLinkResolver(doc) {
  if (doc.type === 'basic_page') {
    return `/${decomposeUid(doc.uid)}`
  }
  return null
}

export const StickyText = ({ text, renderAsPrismic }) => {
  return (
    <div
      className={`bodyTextBig`}
      dangerouslySetInnerHTML={{
        __html: renderAsPrismic
          ? parsePrismicHtml(text, prismicLinkResolver, htmlSerializer)
          : text,
      }}
    />
  )
}

export const StickyTitle = ({ text, renderAsPrismic }) => {
  return (
    <div
      className={`sectionTitle`}
      dangerouslySetInnerHTML={{
        __html: renderAsPrismic
          ? parsePrismicHtml(text, prismicLinkResolver, htmlSerializer)
          : text,
      }}
    />
  )
}

export const StickyOpeningTimes = ({ opening_times }) => {
  if (isEmpty(opening_times.data)) {
    return null
  }

  return (
    <>
      <OpeningHoursPicker />
      <OpeningHoursResult openingTimes={opening_times.data} />
    </>
  )
}

export const StickyBlock = ({
  image,
  caption,
  title,
  text,
  links,
  html,
  opening_times,
  service_info,
  index = 0,
  renderAsPrismic = false,
  modifierClass = '',
}) => {
  const imageExists = !isEmpty(image?.desktop) || !isEmpty(image.url)

  const _title = filterEmptyPrismicRepeatersContents(title, 'text')
  const _text = filterEmptyPrismicRepeatersContents(text, 'text')
  const _links = filterEmptyPrismicRepeatersContents(links, 'abstract_link_link.url')

  return (
    <div className={`${styles.stickyBlock} ${modifierClass}`} key={index}>
      <div className={styles.stickyBlockInner}>
        <div className={`${styles.stickyText} ${imageExists ? styles.bottomMargin : ''}`}>
          <div className={`${styles.stickyTextInner} ${styles.stickyDescription}`}>
            {_title && <StickyTitle text={_title} renderAsPrismic={renderAsPrismic} />}
            {opening_times && <StickyOpeningTimes opening_times={opening_times} />}
            {service_info && <StickyText text={service_info} renderAsPrismic={renderAsPrismic} />}
            {_text && <StickyText text={_text} renderAsPrismic={renderAsPrismic} />}
            <AbstractLinks links={_links} linkKey="abstract_link" />
          </div>
        </div>
        {imageExists && (
          <StickyMediaBlock
            html={html}
            image={image}
            caption={caption}
            renderAsPrismic={renderAsPrismic}
            sticky={true}
          />
        )}
      </div>
    </div>
  )
}

const StickyBlockDate = inject('rootStore')(({ rootStore, start_date, end_date, lang }) => {
  const {
    ui: { labelsColor },
  } = rootStore
  return (
    <Observer>
      {() => {
        return (
          <span style={{ color: labelsColor }} className={styles.stickyDate}>
            {FormattedDateEvent(start_date, end_date, lang, 'smallText bold')}
          </span>
        )
      }}
    </Observer>
  )
})

export const SingleEventPreviewStickyBlock = (
  {
    cover_image: image,
    cover_image_caption: caption,
    title,
    author,
    event_online,
    description_short,
    start_date,
    end_date,
    type,
  },
  uid,
  { renderAsPrismic, lang, modifierClass } = {
    renderAsPrismic: false,
    lang: 'it',
    modifierClass: '',
  },
  index = 0,
) => {
  return (
    <div
      className={`${styles.stickyBlock} ${styles.stickyBlockSingleEventPreview} ${modifierClass}`}
      key={index}
    >
      <div className={styles.stickyBlockInner}>
        <div className={styles.stickyText}>
          <div className={styles.stickyTextInner}>
            <div className={styles.mediaMobile}>
              <StickyMediaBlock
                image={image}
                caption={caption}
                renderAsPrismic={renderAsPrismic}
                sticky={true}
              />
            </div>
            <div className={`${styles.stickySectionTitle} left sectionTitle`}>
              <ContentFiltersBlock
                type={type}
                currentLang={lang}
                contentType={'events'}
                areFiltersClickable={false}
                isOnlineEvent={event_online}
              />
            </div>
            <EventTitles
              author={author}
              title={title}
              titleClass={`${styles.stickySectionTitle} left sectionTitle`}
            />

            <div className={styles.stickyBlockInfo}>
              <div className={styles.stickyDateWrapper}>
                <StickyBlockDate start_date={start_date} end_date={end_date} lang={lang} />
              </div>
            </div>

            {description_short && (
              <div
                dangerouslySetInnerHTML={{
                  __html: parsePrismicHtml(description_short, linkResolverNormalizer),
                }}
                className={`bodyTextBig`}
              />
            )}

            <div className={styles.stickyLinkWrapper}>
              <Link
                className={`outline ${styles.stickyLink} smallText`}
                to={linkResolver({ contentType: 'event', lang, uid })}
              >
                <span className={`button ${styles.linkLabel}`}>
                  {translator('goToEvent')(lang)}
                  {' →'}
                </span>
              </Link>
            </div>
          </div>
        </div>
        <StickyMediaBlock
          image={image}
          caption={caption}
          renderAsPrismic={renderAsPrismic}
          desktopModifierClass={styles.desktopModifierClass}
        />
      </div>
    </div>
  )
}

export const SpecialCommissionStickyBlock = ({
  project,
  media,
  lang,
  renderAsPrismic = false,
  index = 0,
}) => {
  const { project_author, project_description, project_image, project_name } = project
  return (
    <div className={styles.stickyBlock} key={index}>
      <StickyMediaBlock
        image={project_image}
        caption={undefined}
        renderAsPrismic={renderAsPrismic}
      />
      <div className={styles.stickyText}>
        <div className={styles.stickyTextInner}>
          {project_author && <StickyText text={project_author} renderAsPrismic={renderAsPrismic} />}
          {project_name && <StickyText text={project_name} renderAsPrismic={renderAsPrismic} />}
          {project_description && (
            <StickyText text={project_description} renderAsPrismic={renderAsPrismic} />
          )}
        </div>
        <MediaSectionGrid
          media={media}
          contentType={'specialCommission'}
          titleLabel={'worksSectionTitle'}
          lang={lang}
        />
      </div>
    </div>
  )
}
