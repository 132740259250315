import orderBy from 'lodash/orderBy'

import {
  getPromotionsByUids,
  getCurrentPromotions,
} from 'triennale-prismic-api-wrapper/src/fetchers/promotionsFetcher'
import { searchInText } from 'triennale-prismic-api-wrapper/src/fetcher'
import { promotion as contentTypeId } from 'triennale-prismic-api-wrapper/src/constants/contentTypes'
import { promotion as fields } from 'triennale-prismic-api-wrapper/src/constants/fields'
import { promotion as queries } from 'triennale-prismic-api-wrapper/src/queries'

const sortByDate = data => {
  return orderBy(data, e => [e.data.start_date, e.data.end_date])
}

const contentType = {
  contentTypeId,
  fields,
  queries,
  sortByDate,
  getCurrent: getCurrentPromotions,
  getByUids: getPromotionsByUids,
  searchInText: searchInText(contentTypeId),
}

export { contentType as promotion }
