import React from 'react'
import { inject, observer } from 'mobx-react'
import Cookies from 'js-cookie'
import { Link } from '../../components'
import styles from './CookieConsent.module.css'
import { isSnap } from '../../lib/static-site'
import { initGA4 } from '../../lib/ga'
import { initFB, fbPageViewHit } from '../../lib/facebook'
import { config } from '../../../app.config'

const USER_CONSENT_COOKIE_NAME = 'cookie-user-consent'
@inject('rootStore')
@observer
class CookieConsent extends React.Component {
  constructor(props) {
    super(props)
    const cookieValue = Cookies.get(USER_CONSENT_COOKIE_NAME)
    if (cookieValue) {
      initGA4(config.ga.trackingID)
      initFB(config.facebook.trackingID)
    }
    this.state = {
      isUserConsent: isSnap || cookieValue,
    }
  }

  componentDidMount() {
    /// if (!this.state.isUserContent) window.addEventListener('scroll', this.confirm, { once: true })
  }

  confirm = e => {
    e.preventDefault()
    Cookies.set(USER_CONSENT_COOKIE_NAME, 'true', { expires: 180 })
    this.setState({ isUserConsent: true }, () => {
      initGA4(config.ga.trackingID)
      initFB(config.facebook.trackingID)
      // gaPageViewHit(window.location)
      fbPageViewHit()
    })
  }

  render() {
    const { currentRouteLang } = this.props.rootStore.navigation

    return (
      <div
        className={`${styles.wrapper}${
          this.state.isUserConsent ? '' : ` ${styles.wrapperVisible}`
        } bodyText`}
        style={{ display: 'none' }}
      >
        <div>
          <button
            className={`${styles.confirm} uppercase outline`}
            onClick={this.confirm}
            aria-label={
              currentRouteLang === 'it'
                ? 'Accetta e chiudi la notifica della cookie policy'
                : 'Accept cookie policy and close cookie consent banner'
            }
          />
          <div className={styles.textWrapper}>
            {currentRouteLang === 'it' ? (
              <p>
                Questo sito utilizza cookie di profilazione, anche di terze parti, per inviarti
                messaggi pubblicitari mirati e servizi in linea con le tue preferenze. Se vuoi
                saperne di più o negare il consenso a tutti o ad alcuni cookie{' '}
                <Link className={`link outline`} to={'/legal/cookies'}>
                  clicca qui
                </Link>
              </p>
            ) : (
              <p>
                This website uses profiling cookies, including third-party cookies, to deliver you
                targeted advertising and services in line with your preferences.{' '}
                <Link className={`link outline`} to={'/en/legal/cookies'}>
                  Click here
                </Link>{' '}
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default CookieConsent
