import React from 'react'
import { Link } from '../../components'
import { wrapper } from './HeroCta.module.css'

export const HeroCta = ({ to, children, style }) => {
  return (
    <div className={wrapper}>
      <Link to={to} style={style} className={'heroType link'}>
        {children}
      </Link>
    </div>
  )
}
