import React from 'react'
import { inject, Observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { StickyBlock } from '../../components/Shared/StickyBlock'
import { PageTitle } from '../../components/Shared/Titles'
import styles from './InstitutionalPage.module.css'

export const InstitutionalPage = inject('rootStore')(({ rootStore, institutional }) => {
  if (isEmpty(institutional)) {
    return null
  }

  const {
    data: { title, paragraph },
  } = institutional

  return (
    <Observer>
      {() => {
        return (
          <>
            <PageTitle title={title} />
            <div className={`${styles.grid} institutionalPageTitles`}>
              {paragraph.map((p, i) => {
                return (
                  <StickyBlock
                    key={i}
                    image={p.paragraph_image}
                    caption={p.paragraph_image_caption}
                    text={p.paragraph_text}
                    title={p.paragraph_title}
                    renderAsPrismic={true}
                    modifierClass={styles.stickyBlockInstitutional}
                    index={i}
                  />
                )
              })}
            </div>
          </>
        )
      }}
    </Observer>
  )
})
