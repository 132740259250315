import React from 'react'
import styles from './PressItemsPreview.module.css'
import { getFormattedDate } from '../../lib/date-utils'
import { ContentFiltersBlock } from '../Shared/ContentFilters/ContentFiltersBlock'
import { translator } from '../../lib/translator'
import { filterEmptyPrismicContents, parsePrismicText } from '../../lib/survive-prismic'

export const SinglePressItemPreview = ({ uid, data, first_publication_date }, options) => {
  const { description, type, press_kit_link, press_release_link } = data
  const { lang } = options

  const _press_kit_link = filterEmptyPrismicContents(press_kit_link, 'url')
  const _press_release_link = filterEmptyPrismicContents(press_release_link, 'url')

  return (
    <div key={uid} className={styles.gridItem}>
      <div className={styles.inner}>
        <div className={styles.info}>
          <ContentFiltersBlock
            areFiltersClickable={false}
            type={type}
            currentLang={lang}
            contentType="press"
          />
          <div className={`smallText ${styles.date}`}>
            {getFormattedDate(first_publication_date, { lang, withHours: false })}
          </div>
        </div>

        <h1
          className={`smallText regular ${styles.title}`}
          dangerouslySetInnerHTML={{
            __html: parsePrismicText(description),
          }}
        />

        <div className={`${styles.linkBlock} smallText`}>
          {_press_release_link && (
            <a
              className={`link bold`}
              href={_press_release_link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translator('pressReleasePdfLink')(lang)}
            </a>
          )}
          {_press_kit_link && (
            <a
              className={`link bold`}
              title="press kit link"
              href={_press_kit_link.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {translator('pressReleaseKitLink')(lang)}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default SinglePressItemPreview
