import React from 'react'
import { inject, observer } from 'mobx-react'
import { HeroTitleText } from '../components/Magazine/HeroTitleText'
import { ListContributors } from '../components/Magazine/Contributors/ListContributors'
import { composeUidOut } from '../lib/utils/uid-utils'
import { haveRouteSecondLevel, haveRouteThirdLevel } from '../lib/utils'
import { NotFound } from './NotFound'
import { LoaderOval } from '../components'
import { SingleContributor } from '../components/Magazine/Contributors/SingleContributor'
import { BtnMagazineHome } from '../components/Magazine/BtnMagazineHome'
import { magazineAuthor as type } from '../lib/prismic/magazineAuthor'

const TEXT_INTRO_CONTRIBUTORS = ''

const switcherState = thirdLevel => uid => {
  return {
    notFound: <NotFound />,
    rest: thirdLevel ? (
      <SingleContributor />
    ) : (
      <>
        <BtnMagazineHome />
        <HeroTitleText heroTitle={'Contributors'} heroText={TEXT_INTRO_CONTRIBUTORS} />
        <ListContributors type={type} resultsPerPage={30} />
      </>
    ),
    loading: <LoaderOval />,
  }
}

export const Contributors = inject('rootStore')(
  observer(({ rootStore }) => {
    const {
      appState,
      navigation: {
        currentRouteName,
        router: {
          location: { pathname },
        },
      },
    } = rootStore

    const uid = composeUidOut(pathname)(['magazine'])
    const renderComponent = switcherState(haveRouteThirdLevel(currentRouteName))
    return renderComponent(uid, haveRouteSecondLevel(currentRouteName))[appState]
  }),
)
