import { types as t } from 'mobx-state-tree'

export const LightboxModel = t
  .model('LightboxModel', {
    activeItemId: t.maybe(t.union(t.number, t.string)),
  })
  .actions(self => ({
    setActiveItemId(newId) {
      self.activeItemId = newId
      document.documentElement.classList.add('panel--is-open')
    },
    resetActiveItemId() {
      self.activeItemId = undefined
      document.documentElement.classList.remove('panel--is-open')
    },
  }))
