import React from 'react'
import { inject } from 'mobx-react'
import { Banner, Link } from '../layoutComponents'
import { translator } from '../../lib/translator'

const MarketingTicketBanner = inject('rootStore')(({ rootStore }) => {
  const {
    navigation: { currentRouteLang },
  } = rootStore
  const title = translator('marketingSingleTicket')(currentRouteLang)
  const text = null
  const cta = translator('buyTicketNow')(currentRouteLang)
  const link = { it: '/biglietti/155649', en: '/en/tickets/15564' }
  return (
    <Banner
      sessionStorageItemTitle="ticketingBanner"
      color="#FFF"
      bgColor="#1FB94A"
      ariaBtnText={{ it: 'chiudi', en: 'close' }}
    >
      <>
        {title && <h4>{title}</h4>}
        <p
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        {cta && (
          <Link className={`link linkSizeM`} to={link[currentRouteLang]}>
            {cta}
          </Link>
        )}
      </>
    </Banner>
  )
})

export default MarketingTicketBanner
