import React from 'react'
import { inject } from 'mobx-react'
import { Link } from '../../components'
import styles from './HeroContent.module.css'
import { ResponsiveImage } from '../Shared/Templating'
import { linkResolver } from '../../lib/link-resolver'
import { IframeWithFallback } from '../Shared/IframeWithFallback'

const IS_VIDEO_COVER_OPTION = 'Sì'
export const IS_VIDEO_COVER_AUTOPLAY_OPTION = 'Sì'

export const HeroImageVideo = inject('rootStore')(({ rootStore, data, forwardedRef }) => {
  const { cover_image_desktop, cover_image_mobile, is_video_cover, hero_contents } = data

  const { uid, type: contentType } = hero_contents[0]
  const { currentRouteLang } = rootStore.navigation

  const getVideoData = ({ hero_contents, is_video_cover_autoplay }) => {
    const { description_videos } = hero_contents[0].data
    const video = description_videos[0]

    return video
      ? {
          autoplay:
            is_video_cover_autoplay && is_video_cover_autoplay === IS_VIDEO_COVER_AUTOPLAY_OPTION,
          type: video.description_video_source_type,
          uid: video.description_video_id,
          html: video.description_embed_media_html,
        }
      : null
  }

  return (
    <Link
      to={linkResolver({ uid, contentType, lang: currentRouteLang })}
      className={styles.mediaWrapper}
    >
      {is_video_cover === IS_VIDEO_COVER_OPTION ? (
        <div ref={forwardedRef}>
          <IframeWithFallback {...getVideoData(data)} />
        </div>
      ) : (
        <>
          <ResponsiveImage
            img={cover_image_desktop}
            additionalClassName={`${styles.desktopHeroImg} ${
              cover_image_mobile ? styles.desktopHeroImgHasMobile : ''
            }`}
          />
          <ResponsiveImage img={cover_image_mobile} additionalClassName={styles.mobileHeroImg} />
        </>
      )}
    </Link>
  )
})
