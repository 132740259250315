import React, { useState, useEffect } from 'react'
import { inject, Observer } from 'mobx-react'
import { useBreakpoints } from '../../hooks'
import { homepage } from '../../lib/prismic/homepage'
import styles from './HeroContent.module.css'
import { LoaderOval } from '../../components'
import { HeroStandardLayout, HeroMirroredLayout } from './HeroLayout'

const HERO_LAYOUTS = {
  'Immagine sopra, testo sotto': 'vertical',
  '50/50': 'horizontal',
}

const IS_VIDEO_COVER_OPTION = 'Sì'
export const IS_VIDEO_COVER_AUTOPLAY_OPTION = 'Sì'

const HeroSingleCard = ({ hero, forwardedRef, index }) => {
  const { isXs, isSm, isMd } = useBreakpoints()
  const getLayout = data => {
    return data.is_video_cover === IS_VIDEO_COVER_OPTION
      ? 'vertical'
      : HERO_LAYOUTS[data.hero_layout]
  }
  const layout = getLayout(hero.data)

  return (
    <div
      className={`${styles.heroBlock} ${styles[layout]}`}
      style={{
        background: hero?.data?.background_color ? hero?.data?.background_color : 'transparent',
      }}
    >
      {layout === 'horizontal' && index % 2 !== 0 && !isXs && !isSm && !isMd ? (
        <HeroMirroredLayout hero={hero} forwardedRef={forwardedRef} />
      ) : (
        <HeroStandardLayout hero={hero} forwardedRef={forwardedRef} />
      )}
    </div>
  )
}

export const HeroContentLayout = inject('rootStore')(({ rootStore, forwardedRef }) => {
  const {
    fetchSingle,
    navigation: { currentRouteLangCode },
  } = rootStore

  const [data, setData] = useState([])

  useEffect(() => {
    async function getHero() {
      setData(await fetchSingle(homepage, currentRouteLangCode))
    }
    getHero()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!data) {
    return <LoaderOval />
  }

  if (data === null || !data || !data.length) {
    return <LoaderOval />
  }

  return (
    <Observer>
      {() => (
        <div className={styles.mainSection}>
          {data?.map((hero, i) => {
            return <HeroSingleCard key={i} hero={hero} forwardedRef={forwardedRef} index={i} />
          })}
        </div>
      )}
    </Observer>
  )
})
