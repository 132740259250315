import React from 'react'
import styles from './RegularEvent.module.css'
import { BuyTicketLink } from '../../components/Shared/BuyTicketLink'
import { checkLinkValidity } from '../../lib/survive-prismic'
import { translator } from '../../lib/translator'

export const CTALinks = ({ lang, eventData }) => {
  const {
    registration_link,
    eventType,
    entrance,
    show_id,
    booking_link,
    buy_ticket_link,
  } = eventData
  const isFree = entrance === 'Gratuito'
  const _registration_link = checkLinkValidity(registration_link)
  const _booking_link = checkLinkValidity(booking_link)
  const _buy_ticket_link = checkLinkValidity(buy_ticket_link)

  return (
    <>
      {lang && (show_id || _registration_link || _booking_link || _buy_ticket_link) && (
        <div className={styles.ctaWrapper}>
          <div className={styles.button}>
            <BuyTicketLink eventData={eventData} />
          </div>
          {!_registration_link && isFree && eventType === 'Mostra' && (
            <div className={styles.button}>
              <span className="smallText">{translator('freeEventLabel')(lang)}</span>
            </div>
          )}
        </div>
      )}
    </>
  )
}
