import React from 'react'
import { inject, observer } from 'mobx-react'
// import styles from './BuyTicketLink.module.css'
import { translator } from '../../lib/translator'
import { Link } from '../../components'
import { linkResolver } from '../../lib/link-resolver'

@inject('rootStore')
@observer
export class BuyTicketButton extends React.Component {
  onClick = e => {
    e.stopPropagation()
  }

  render() {
    const {
      rootStore: {
        navigation: { currentRouteLang },
        tickets: { isSubscriptionAccount },
      },
      uid,
      url,
    } = this.props

    // TODO: momentary switch for fog festival events
    if (uid && !url) {
      if (uid === '175696' || uid === '175695') {
        switch (uid) {
          case '175696':
            return (
              <a
                href={'https://www.vivaticket.com/it/biglietto/fog-ten/175696'}
                target={'_blank'}
                className={`button smallText`}
              >
                {isSubscriptionAccount
                  ? `${translator('reserveTicket')(currentRouteLang)} →`
                  : `${translator('buyTicket')(currentRouteLang)} →`}
              </a>
            )
          case '175695':
            return (
              <a
                href={'https://www.vivaticket.com/it/biglietto/fog-seven/175695'}
                target={'_blank'}
                className={`button smallText`}
              >
                {isSubscriptionAccount
                  ? `${translator('reserveTicket')(currentRouteLang)} →`
                  : `${translator('buyTicket')(currentRouteLang)} →`}
              </a>
            )
        }
      } else {
        return (
          <Link
            to={linkResolver({ contentType: 'ticket', uid, lang: currentRouteLang })}
            className={`button smallText`}
          >
            {isSubscriptionAccount
              ? `${translator('reserveTicket')(currentRouteLang)} →`
              : `${translator('buyTicket')(currentRouteLang)} →`}
          </Link>
        )
      }
    } else {
      return (
        <a href={url} className={`button smallText`} target={'_blank'}>
          {isSubscriptionAccount
            ? `${translator('reserveTicket')(currentRouteLang)} →`
            : `${translator('buyTicket')(currentRouteLang)} →`}
        </a>
      )
    }
  }
}
