import React from 'react'
import styles from '../../routes/ArchiveSearchResults.module.css'

const HistoricalArchiveLayoutCard = ({ title, hierarchyIndex, shelfMark, type }) => {
  return (
    <div key={hierarchyIndex} className={`${styles.historicalContents} smallText`}>
      <p>
        <strong>
          {hierarchyIndex}. {title}
        </strong>
      </p>
      <p>Tipologia: {type}</p>
      <p>Collocazione: {shelfMark}</p>
    </div>
  )
}

export default HistoricalArchiveLayoutCard
