import { types as t } from 'mobx-state-tree'
import { isSameDay } from '../lib/date-utils'
import { LightboxModel } from './lightbox'
import { ArchiveSingleTriennaleModel } from './archive-single-triennale'

const NewsUiModel = t.model('NewsUiModel', {
  isFiltersPanelOpen: false,
  offset: 0,
})

const PressUiModel = t.model('PressUiModel', {
  isFiltersPanelOpen: false,
  offset: 0,
})

const EventsUiModel = t.model('EventsUiModel', {
  isFiltersPanelOpen: false,
  offset: 0,
})

const MagazineUiModel = t.model('MagazineUiModel', {
  isFiltersPanelOpen: false,
  offset: 0,
})

export const UiModel = t
  .model('UiModel', {
    mainNavToggle: false,
    activeAccordionItem: -1,
    isSearchPanelVisible: false,
    ongoingFilters: false,
    events: t.optional(EventsUiModel, {}),
    press: t.optional(PressUiModel, {}),
    news: t.optional(NewsUiModel, {}),
    magazine: t.optional(MagazineUiModel, {}),
    openingHoursSelectedDay: t.optional(t.maybeNull(t.Date), new Date()),
    logoColor: t.optional(t.string, '#000000'),
    bodyBgColor: t.optional(t.string, 'transparent'),
    newsletterPanel: t.maybe(t.string),
    isJotFormPanelVisible: false,
    priceNote: t.model('PriceNote', { listIndex: -1, itemIndex: -1, rowIndex: -1 }),
    archiveLightbox: t.optional(LightboxModel, { activeItemId: undefined }),
    archiveSingleTriennale: ArchiveSingleTriennaleModel,
    archiveSingleTriennaleActiveTab: t.maybe(t.string),
    labelsColor: t.maybe(t.string),
  })
  .views(self => ({
    isAccordionActive(id) {
      return this.activeAccordionItem === id
    },
    get isOpeningHoursToday() {
      if (self.openingHoursSelectedDay === null) {
        return false
      }

      return isSameDay(self.openingHoursSelectedDay, new Date())
    },
  }))
  .actions(self => ({
    resetOpeningHoursSelectedDay() {
      self.openingHoursSelectedDay = new Date()
    },
    setLogoColor(color) {
      self.logoColor = color !== null ? color : '#000000'
    },
    resetLogoColor() {
      self.logoColor = '#000000'
    },
    setLabelsColor() {
      self.labelsColor = window.sessionStorage.getItem('color')
    },
    setBodyBgColor(color) {
      self.bodyBgColor = color !== null ? color : 'transparent'
      document.body.style.backgroundColor = self.bodyBgColor
      document.documentElement.classList.add('hasBgColor')
    },
    resetBodyBgColor() {
      self.bodyBgColor = 'transparent'
      document.body.style.backgroundColor = self.bodyBgColor
      document.documentElement.classList.remove('hasBgColor')
    },
    setOpeningHoursSelectedDay(day) {
      self.openingHoursSelectedDay = day
    },
    showFilter(type) {
      self[type].isFiltersPanelOpen = true
    },
    hideFilter(type) {
      self[type].isFiltersPanelOpen = false
    },
    toggleFilter(type) {
      self[type].isFiltersPanelOpen ? self.hideFilter(type) : self.showFilter(type)
    },
    setOffset(type, offset) {
      self[type].offset = offset
    },
    showPressFilters() {
      self.pressFilters = true
    },
    hidePressFilters() {
      self.pressFilters = false
    },
    togglePressFilters() {
      if (self.pressFilters) {
        self.hidePressFilters()
      } else {
        self.showPressFilters()
      }
    },

    showOngoingFilters() {
      self.ongoingFilters = true
    },
    hideOngoingFilters() {
      self.ongoingFilters = false
    },
    toggleOngoingFilters() {
      if (self.ongoingFilters) {
        self.hideOngoingFilters()
      } else {
        self.showOngoingFilters()
      }
    },
    showSearchPanel() {
      self.isSearchPanelVisible = true
    },

    hideSearchPanel() {
      self.isSearchPanelVisible = false
    },

    toggleSearchPanel() {
      self.isSearchPanelVisible = !self.isSearchPanelVisible
    },

    toggleMainNav() {
      if (!self.mainNavToggle) {
        this.showMainNav()
      } else {
        this.hideMainNav()
      }
    },
    showMainNav() {
      self.mainNavToggle = true

      const scrollTop = window.pageYOffset
      document.documentElement.classList.add('header--is-expanded')
      document.body.style.top = `-${scrollTop}px`

      self.lastScrolltop = scrollTop
    },
    hideMainNav() {
      self.mainNavToggle = false

      document.documentElement.classList.remove('header--is-expanded')
      document.body.style.top = null
      ;(document.documentElement || document.body).scrollTop = self.lastScrolltop
      if (window.scrollTo) window.scrollTo(0, self.lastScrolltop)
    },
    toggleActiveAccordionItem(id) {
      if (self.activeAccordionItem === id) {
        self.resetActiveAccordionItem()
      } else {
        self.setActiveAccordionItem(id)
      }
    },

    setActiveAccordionItem(id) {
      self.activeAccordionItem = id
    },

    resetActiveAccordionItem() {
      self.activeAccordionItem = -1
    },

    showNewsletterPanel(type) {
      self.newsletterPanel = type
      document.documentElement.classList.add('panel--is-open')
    },

    hideNewsletterPanel() {
      self.newsletterPanel = undefined
      document.documentElement.classList.remove('panel--is-open')
    },

    showJotFormPanel() {
      self.isJotFormPanelVisible = true
      document.documentElement.classList.add('panel--is-open')
    },

    hideJotFormPanel() {
      self.isJotFormPanelVisible = false
      document.documentElement.classList.remove('panel--is-open')
    },

    showPriceNote(listIndex, itemIndex, rowIndex) {
      self.priceNote = { listIndex, itemIndex, rowIndex }
    },

    hidePriceNote() {
      self.priceNote = { listIndex: -1, itemIndex: -1 }
    },

    setArchiveSingleTriennaleActiveTab(newActiveTab) {
      self.archiveSingleTriennaleActiveTab = newActiveTab
    },
  }))
