import React from 'react'
import { ResponsiveImage } from '../../components/Shared/Templating'
import styles from '../../routes/SingleMagazine.module.css'
import { Caption } from '../../components/Shared/Paragraphs'

export const Image = ({ primary: { single_image, single_image_caption } }, index) => {
  return (
    <div className={styles.contentsWrapper} key={index}>
      <ResponsiveImage img={single_image} />
      <Caption caption={single_image_caption} />
    </div>
  )
}
