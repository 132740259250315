import React from 'react'
import styles from './Cards.module.css'
import { Link } from '../../components'
import { linkResolver, linkResolverNormalizer } from '../../lib/link-resolver'
import { translator } from '../../lib/translator'
import { parsePrismicHtml } from '../../lib/survive-prismic'

export const PromotionCard = ({ promotion, index, options }) => {
  const {
    data: { title, description, events: parentEvents },
  } = promotion
  const { lang } = options

  return (
    <article key={`${title[0].text}-${index}`} className={`bodyTextBig ${styles.promotionArticle}`}>
      <h1 className={`${styles.promotionTitle}`}>{title[0].text}</h1>
      <div
        className={`${styles.promotionDescr}`}
        dangerouslySetInnerHTML={{
          __html: parsePrismicHtml(description, linkResolverNormalizer),
        }}
      />

      {parentEvents && parentEvents?.length > 0 && (
        <div className={styles.promotionParentEventsBlock}>
          <p className={`${styles.promotionParentEventCaption}`}>
            {translator('promotionRelatedToCaption')(lang)}:
          </p>
          {parentEvents.map((parentEvent, i) => (
            <section key={i}>
              <Link
                className={`link ${styles.promotionLink}`}
                to={linkResolver({ contentType: 'event', uid: parentEvent.uid, lang })}
              >
                <div
                  className={`${styles.promotionParentEventTitle}`}
                  dangerouslySetInnerHTML={{
                    __html: parsePrismicHtml(parentEvent.title),
                  }}
                />
              </Link>
            </section>
          ))}
        </div>
      )}
    </article>
  )
}

export const PromotionsCard = ({ results, options }) => {
  return (
    <div className={`${styles.promotionsGrid} ${options.cssNamespace}`}>
      {results.map((p, i) => {
        return <PromotionCard key={i} promotion={p} index={i} options={options} />
      })}
    </div>
  )
}
