import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { ResponsiveImage } from '../../components/Shared/Templating'
import { Credits } from '../../components/SingleEvent/Credits'
import styles from './RegularEvent.module.css'

export const StickyHeroImg = ({ event }) => {
  const {
    data: { cover_image, cover_image_caption, credits_international_participation },
  } = event

  return (
    <div className={styles.stickyLeft}>
      <div className={styles.heroImg}>
        <div className={styles.heroImgInner}>
          {!isEmpty(cover_image) && cover_image.url !== null && (
            <ResponsiveImage img={cover_image} />
          )}
        </div>
        <Credits
          credits={cover_image_caption}
          credits_part2={credits_international_participation}
          hasTitle={false}
        />
      </div>
    </div>
  )
}
