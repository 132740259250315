import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './LoadMore.module.css'
import { translator } from '../../lib/translator'

@inject('rootStore')
@observer
class LoadMore extends React.Component {
  render() {
    return (
      <button onClick={this.props.onClick} className={`smallText outline ${styles.loadMore}`}>
        <div className={styles.loadMoreLabel}>
          <h1>
            {translator(`loadMoreButton`)(this.props.rootStore.navigation.currentRouteLang)}
            <span>+</span>
          </h1>
        </div>
      </button>
    )
  }
}

export default LoadMore
