import React from 'react'
import { observer, inject } from 'mobx-react'
import { STEPS } from '../../routes/Ticket'

export const TicketingStepHandler = inject('rootStore')(
  observer(({ rootStore }) => {
    const {
      tickets: { currentStep },
      navigation: { currentRouteLang },
    } = rootStore
    const Component = STEPS[currentStep]
    return <Component currentRouteLang={currentRouteLang} />
  }),
)
