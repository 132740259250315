import archive1 from 'images/archive-preview/archive_1.png'
import archive2 from 'images/archive-preview/archive_2.png'
import archive3 from 'images/archive-preview/archive_3.png'
import archive4 from 'images/archive-preview/archive_4.png'
import archive5 from 'images/archive-preview/archive_5.png'
import archive6 from 'images/archive-preview/archive_6.png'
import archive7 from 'images/archive-preview/archive_7.png'
import archive8 from 'images/archive-preview/archive_8.png'
import archive9 from 'images/archive-preview/archive_9.png'
import archive10 from 'images/archive-preview/archive_10.png'
import archive11 from 'images/archive-preview/archive_11.png'
import archive12 from 'images/archive-preview/archive_12.png'
import archive13 from 'images/archive-preview/archive_13.png'

export const archivePreviewData = {
  image_showcase_images: [
    {
      image: {
        url: archive1,
        alt: 'Ingresso principale sul fronte ovest del Palazzo dell Arte',
      },
      caption: "Ingresso principale sul fronte ovest del Palazzo dell'Arte",
    },
    {
      image: {
        url: archive2,
        alt: "Veduta notturna del Palazzo dell'Arte ripreso dalla Torre Littoria",
      },
      caption: "Veduta notturna del Palazzo dell'Arte ripreso dalla Torre Lsittoria",
    },
    {
      image: {
        url: archive3,
        alt: 'Veduta della Torre Littoria',
      },
      caption: 'Veduta della Torre Littoria',
    },
    {
      image: {
        url: archive4,
        alt:
          'Installazione luminosa "Luce spaziale" di Lucio Fontana sul soffitto dello Scalone d’onore. Nel vestibolo del primo piano, decorazione parietale di Bruno Cassinari',
      },
      caption:
        'Installazione luminosa "Luce spaziale" di Lucio Fontana sul soffitto dello Scalone d’onore.<br/>Nel vestibolo del primo piano, decorazione parietale di Bruno Cassinari',
    },
    {
      image: {
        url: archive5,
        alt:
          "Mostra sugli Studi delle proporzioni, allestimento dell'architetto Francesco Gnecchi-Ruscone, esposto nella mostra Studi sulle proporzioni",
      },
      caption:
        "Mostra sugli Studi delle proporzioni, allestimento dell'architetto Francesco Gnecchi-Ruscone, esposto nella mostra Studi sulle proporzioni",
    },
    {
      image: {
        url: archive6,
        alt:
          "Una modella posa all'interno della Mostra di oggetti per la casa (o Mostra Oggetti d'uso), nel Padiglione USA progettato dagli architetti Lodovico Barbiano di Belgiojoso, Enrico Peressutti e Ernesto Nathan Rogers",
      },
      caption:
        "Una modella posa all'interno della Mostra di oggetti per la casa (o Mostra Oggetti d'uso), nel Padiglione USA progettato dagli architetti Lodovico Barbiano di Belgiojoso, Enrico Peressutti e Ernesto Nathan Rogers",
    },
    {
      image: {
        url: archive7,
        alt:
          "Caleidoscopio nella Sezione introduttiva a carattere internazionale, nel Salone d'onore",
      },
      caption:
        "Caleidoscopio nella Sezione introduttiva a carattere internazionale, nel Salone d'onore",
    },
    {
      image: {
        url: archive8,
        alt:
          "Tre modelle percorrono il Ponte che collega il Palazzo dell'Arte con l'area verde antistante, progetto degli architetti Aldo Rossi e Luca Meda",
      },
      caption:
        "Tre modelle percorrono il Ponte che collega il Palazzo dell'Arte con l'area verde antistante, progetto degli architetti Aldo Rossi e Luca Meda",
    },
    {
      image: {
        url: archive9,
        alt:
          'Veduta notturna dell’abitazione a cupola geodetica di Füller, progetto dell’allestimento dell’architetto Roberto Mango, realizzata nel parco Sempione per la decima Triennale',
      },
      caption:
        'Veduta notturna dell’abitazione a cupola geodetica di Füller, progetto dell’allestimento dell’architetto Roberto Mango, realizzata nel parco Sempione per la decima Triennale',
    },
    {
      image: {
        url: archive10,
        alt: 'Lampadario "carciofo" di Poul Henningsen, nella sezione della Danimarca',
      },
      caption: 'Lampadario "carciofo" di Poul Henningsen, nella sezione della Danimarca',
    },
    {
      image: {
        url: archive11,
        alt:
          'Sculture piramidali di Lynn Chadwick, nell’allestimento del Grande numero: l’intervento figurativo a grande scala',
      },
      caption:
        'Sculture piramidali di Lynn Chadwick, nell’allestimento del Grande numero: l’intervento figurativo a grande scala',
    },
    {
      image: {
        url: archive12,
        alt:
          'Interno del Tunnel Pneu, progetto di Jonathan De Pas, Donato D’urbino e Paolo Lomazzi',
      },
      caption:
        'Interno del Tunnel Pneu, progetto di Jonathan De Pas, Donato D’urbino e Paolo Lomazzi',
    },
    {
      image: {
        url: archive13,
        alt: 'Modella posa nella sezione del Messico durante un servizio fotografico di moda',
      },
      caption: 'Modella posa nella sezione del Messico durante un servizio fotografico di moda',
    },
  ],
}
