import React from 'react'
import styles from './Home.module.css'
import { ArchivePreview } from '../SingleEvent/ArchivePreview'

const HomeArchiveSection = ({ archive }) => {
  return (
    <section className={styles.section}>
      <ArchivePreview type={'Mostra anteprima di tutti gli archivi'} />
      {/* TODO: FIX IT and remove it hardcoded */}
      {/* <ArchivePreview type={archive} /> */}
    </section>
  )
}

export default HomeArchiveSection
