import { get } from 'triennale-prismic-api-wrapper/src/fetcher'
import { getContainerPagesByRefs } from 'triennale-prismic-api-wrapper/src/fetchers/containerPagesFetcher'
import { ongoing as contentTypeId } from 'triennale-prismic-api-wrapper/src/constants/contentTypes'
import { ongoing as fields } from 'triennale-prismic-api-wrapper/src/constants/fields'
import { ongoing as queries } from 'triennale-prismic-api-wrapper/src/queries'

const contentType = {
  contentTypeId,
  fields,
  queries,
  get: async lang => {
    // Cloning in order to not modify cached value
    const page = await get(contentTypeId)(lang)
    const { data } = page.results[0]
    if (data.container_pages) {
      page.results[0].data.container_pages = await getContainerPagesByRefs(
        data.container_pages,
        lang,
      )
    }

    return page
  },
}

export { contentType as ongoing }
