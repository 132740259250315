import React from 'react'
import { inject, Observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { composeUidOut } from '../../lib/utils/uid-utils'
import { PageHeaderPlain } from '../../components/Shared/Titles'
import { LoaderOval } from '../../components'
import styles from './SpecialCommissionEvent.module.css'
import { parsePrismicText } from '../../lib/survive-prismic'
import { ResponsiveImage } from '../../components/Shared/Templating'
import { computeEventDates } from '../../lib/date-utils'
import { SpecialCommission } from './SpecialCommission'
import { SpecialCommissionEventAbstract } from './SpecialCommissionEventAbstract'
import { translator } from '../../lib/translator'

export const SpecialCommissionEvent = inject('rootStore')(({ rootStore }) => {
  const {
    getBy,
    navigation: {
      router: {
        location: { pathname },
      },
      currentRouteLang,
    },
  } = rootStore
  const uid = composeUidOut(pathname)(['eventi', 'events'])
  const event = getBy('events')({ uid })
  const {
    data: {
      type,
      special_commissions,
      container_page,
      affiliation,
      cover_image,
      title,
      specific_dates,
      start_date,
      end_date,
    },
  } = event
  const { dateString } = computeEventDates(start_date, end_date, specific_dates, currentRouteLang)
  if (isEmpty(event)) {
    return <LoaderOval />
  }

  const renderHeader = () => {
    if (container_page) {
      return (
        <PageHeaderPlain
          title={container_page.title_short}
          link={{
            contentType: 'container_page',
            uid: container_page.uid,
            lang: currentRouteLang,
          }}
        />
      )
    }
    if (
      !container_page &&
      (type === 'Incontro' ||
        type === 'Conferenza' ||
        type === 'Festival' ||
        type === 'Laboratorio' ||
        'Presentazione volume' ||
        type === 'Proiezione' ||
        type === 'Campus estivo' ||
        type === 'Speciale' ||
        type === 'Visita guidata')
    ) {
      return (
        <PageHeaderPlain
          title={translator('publicProgram')(currentRouteLang)}
          link={{
            contentType: 'public_program',
            lang: currentRouteLang,
          }}
        />
      )
    }
    if (affiliation !== null) {
      return <PageHeaderPlain title={affiliation} />
    }
  }
  return (
    <Observer>
      {() => (
        <>
          {renderHeader()}
          {!isEmpty(cover_image) && cover_image.url !== null && (
            <ResponsiveImage img={cover_image} />
          )}
          <div className={styles.headerWrapper}>
            <div className={styles.titleWrapper}>
              <h1
                className={`pageTitle ${styles.title}`}
                dangerouslySetInnerHTML={{ __html: parsePrismicText(title) }}
              />
            </div>
            <SpecialCommissionEventAbstract dateString={dateString} event={event} />
          </div>
          <div className={styles.projectsWrapper}>
            {special_commissions &&
              special_commissions.map((item, i) => <SpecialCommission key={i} data={item} />)}
          </div>
          <SpecialCommissionEventAbstract event={event} />
        </>
      )}
    </Observer>
  )
})
