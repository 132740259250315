import { find, map, findIndex, pickBy, omitBy } from 'lodash/fp'
import qs from 'query-string'
import isEmpty from 'lodash/isEmpty'
import { getRoot } from 'mobx-state-tree'
import { event } from 'triennale-prismic-api-wrapper'

import { getToday } from './date-utils'
import {
  TYPE_STATE,
  ALL_FILTERS,
  ALL_FILTERS_BY_CONTENT_TYPE,
  TOP_LEVEL_ARCHIVES_ENDPOINTS,
} from './const'
import { langCodeToLang } from './utils/lang-utils'

export const getActiveFilters = f => pickBy(v => !isEmpty(v))(f)

const translateSearchData = (filters, lang) => {
  const translator = k => {
    const getTranslated = d => find(['it', d])(ALL_FILTERS[k])['it']
    if (k === 'start_date' || k === 'end_date') {
      return null
    }
    return typeof filters[k] === 'string'
      ? { [k]: getTranslated(filters[k]) }
      : { [k]: map(getTranslated)(filters[k]) }
  }
  if (lang === 'en') {
    return filters
  }
  if (lang === 'it') {
    return filters
  }
  const activeFilters = getActiveFilters(filters)
  const translatedSearchData = map(translator)(Object.keys(activeFilters))
  const reducePredicate = (acc, item) => ({ ...acc, ...item })
  return translatedSearchData.reduce(reducePredicate, {})
}

const getFilters = self => type => {
  const {
    navigation: {
      router: {
        location: { search },
      },
    },
  } = self
  const typeName = TYPE_STATE[type.contentTypeId]
  self[typeName].injectFilterToStore(qs.parse(search))
  return self[typeName].filter.toJSON()
}

const composePrismicFilters = f => typeObj => {
  const activeFilters = getActiveFilters(f)
  const activeFiltersKeys = Object.keys(activeFilters)
  const transformer = k => ({ [typeObj[k.toUpperCase()]]: activeFilters[k] })
  const prismicFilters = map(transformer)(activeFiltersKeys)
  const reducePredicate = (acc, item) => ({ ...acc, ...item })
  return prismicFilters.reduce(reducePredicate, {})
}

const filterFetcherFn = (type, { start_date, end_date }) => ({
  today: type.getFromDateRange(getToday(), getToday()),
  thisweekend: type.getThisWeekend,
  past: type.getPast,
  upcoming: type.getFuture,
  dateRange: type.getFromDateRange(start_date, end_date || start_date),
  default: type.contentTypeId === event.contentTypeId ? type.getCurrent : type.get,
})

const timeRelatedConvertString = (timeRelated = ['_'], langCode = 'it-it') => {
  const options = ALL_FILTERS['time_related']
  const index = findIndex([langCodeToLang(langCode), timeRelated[0]])(options)

  return options[index].en
    .split(' ')
    .join('')
    .toLowerCase()
}

export const withFilters = lang => (self, pagination, options, contentType) => {
  const { contentTypeId, fields } = contentType
  const {
    navigation: { currentRouteLang },
  } = self

  const getFetcherType = (start_date, time_related) => {
    if (!isEmpty(time_related)) {
      return timeRelatedConvertString(time_related, lang)
    }
    return start_date ? 'dateRange' : 'default'
  }

  const { time_related, start_date, end_date, ...filters } = getFilters(self)(contentType)
  const { fetcherType } = {
    fetcherType: options?.fetcherType
      ? options?.fetcherType
      : getFetcherType(start_date, time_related),
  }
  const prismicFilters = composePrismicFilters(translateSearchData(filters, currentRouteLang))

  return {
    contentTypeId,
    pagination,
    filters: prismicFilters(fields),
    options: options,
    lang,
    fn: filterFetcherFn(contentType, { start_date, end_date })[fetcherType],
  }
}

export function getTranslatedFilter(key, label, contentType) {
  const allFiltersForContentType = ALL_FILTERS_BY_CONTENT_TYPE[contentType]
  const currentFilterBlock = allFiltersForContentType[key]
  const filterToTranslate = currentFilterBlock.find(f => f.it === label)
  if (!filterToTranslate) {
    return null
  }
  return filterToTranslate.en
}

const paramHandler = (key, filter) => {
  const getFreeTextParam = f => {
    return {
      freeText: Array.isArray(f) ? f.join(' ') : f,
    }
  }
  const getTimerangeParam = () => ({
    yearFrom: filter[0],
    yearTo: filter[1],
  })
  if (key === 'timerange') return getTimerangeParam(filter)
  if (key === 'freeText') return getFreeTextParam(filter)
  return {
    [key]: filter,
  }
}

const handleParams = query => {
  return !isEmpty(query)
    ? Object.keys(query).reduce((acc, key) => {
        const filter = query[key]
        if (!isEmpty(filter) || typeof filter === 'boolean') {
          acc = {
            ...acc,
            ...paramHandler(key, filter),
          }
        }
        return acc
      }, {})
    : {}
}

export const searchArchiveQueryHandler = (archiveId, query, page, ctx, archivesToSearch) => {
  const { filteredArchives } = getRoot(ctx)
  const queryParams = omitBy(p => !p || (Array.isArray(p) && !p.length > 0), query)

  if (archiveId === 'global') {
    queryParams.archiveId = [...TOP_LEVEL_ARCHIVES_ENDPOINTS, ...archivesToSearch].join(',')
  } else if (archiveId === 'collezioni') {
    queryParams.archiveId =
      queryParams.archives && queryParams.archives.length
        ? queryParams.archives.join(',')
        : filteredArchives.map(({ slug }) => slug).join(',')
  } else {
    queryParams.archiveId = queryParams.archiveId ? queryParams.archiveId : archiveId

    if (queryParams.archiveId === 'archivio-storico') {
      queryParams.archiveId = 'archimista-triennale'
    }
  }
  delete queryParams.archives

  const normalizedParams = { ...handleParams(queryParams), page }
  return `results?${qs.stringify(normalizedParams)}`
}

export const removeDuplicates = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export const getFilterColor = ({ key, value, type }) => {
  let color = ''
  if (key === 'type' && type === 'events') {
    switch (value) {
      case 'Mostra':
      case 'Collezione':
      case 'Installazione':
      case 'Mostra in viaggio':
        color = 'typeRed'
        break
      case 'Performance':
      case 'Concerto':
      case 'Spettacolo':
      case 'Danza':
      case 'Dj set':
        color = 'typePink'
        break
      case 'Incontro':
      case 'Proiezione':
      case 'Conferenza':
      case 'Presentazione volume':
        color = 'typeGreen'
        break
      case 'Visita guidata':
      case 'Festival':
        color = 'typeBlue'
        break
      case 'Laboratorio':
      case 'Campus estivo':
        color = 'typePurple'
        break
      case 'Speciale':
      case 'Triennale Estate':
        color = 'typeYellow'
        break
    }
  }
  return color
}

export const composeUrl = ({ type, value, contentType, currentLang }) => {
  const contentTypeSlug = {
    events: { it: 'whats-on', en: 'whats-on' },
    news: { it: 'news', en: 'news' },
    press: { it: 'press', en: 'press' },
  }

  if (currentLang === 'it') {
    return `/${contentTypeSlug[contentType][currentLang]}/?${type}=${value}`
  }

  return `/en/${contentTypeSlug[contentType][currentLang]}/?${type}=${getTranslatedFilter(
    type,
    value,
    contentType,
  )}`
}

export const buildToggleFilter = ({ filterType, filterValue, rootStore, contentType }) => e => {
  e.preventDefault()
  const {
    navigation: { router },
  } = rootStore
  const { resetAllFilter } = rootStore[contentType]
  const url = composeUrl({ type: filterType, value: filterValue })
  resetAllFilter()
  router.push(url)
}

export const remapFilters = arr => {
  return arr.filter(item => item.value !== null).map(item => item.value)
}

export const allEmptyFilters = filters => {
  return !Object.keys(filters).filter(key => filters[key].length > 0).length
}

export const getSubCategories = ({ type, audiences, special, fields, languages, entrance }) => {
  return {
    type: type ? [type] : [],
    audience: audiences ? remapFilters(audiences) : [],
    special: special ? remapFilters(special) : [],
    field: fields ? remapFilters(fields) : [],
    language: languages ? remapFilters(languages) : [],
    entrance: entrance ? [entrance] : [],
  }
}
