import React from 'react'
import { inject, observer } from 'mobx-react'
import { expoArchivePage as expoArchivePageFn } from 'triennale-prismic-api-wrapper'
import { SectionTitlePlain } from '../components/Shared/Titles'
import { translator } from '../lib/translator'
import { StickyBlock } from '../components/Shared/StickyBlock'
import { linkResolver } from '../lib/link-resolver'
import { Link, ArchiveDisclaimer } from '../components'
import { ResponsiveImage } from '../components/Shared/Templating'
import styles from './Archives.module.css'
import { TopPart } from '../components/layoutComponents/TopPart'

function sortById(a, b) {
  return Number(a.data.id_triennale) > Number(b.data.id_triennale) ? 1 : -1
}

@inject('rootStore')
@observer
export class Archives extends React.Component {
  async componentDidMount() {
    const { rootStore } = this.props
    const { fetchAnythingByUids, navigation, servicePages, expoArchivePages } = rootStore
    const { currentRouteLang, currentRouteLangCode } = navigation

    const data = await fetchAnythingByUids(
      [currentRouteLang === 'it' ? 'visita_biblioteca' : 'en_visit_library'],
      currentRouteLangCode,
    )
    servicePages.setResults(data)

    const expoArchiveData = await expoArchivePageFn.get()
    expoArchivePages.setResults(expoArchiveData.results.sort(sortById))
  }

  render() {
    const { navigation, servicePages, expoArchivePages } = this.props.rootStore
    const { currentRouteLang } = navigation

    return (
      <div>
        <TopPart />

        <section className={`${styles.expoSection}`}>
          <SectionTitlePlain
            title={translator('triennaleExpoSectionTitle')(currentRouteLang)}
            border={false}
          />

          <ul className={`${styles.grid}`}>
            {expoArchivePages.results.map((item, i) => {
              const { id_triennale, title_brief, cover_image_group } = item.data

              return (
                <li className={`${styles.gridItem}`} key={i}>
                  <Link
                    className={`${styles.gridItemLink} link noIcon bold expoArchiveTitle`}
                    to={linkResolver({
                      contentType: 'triennale-archives',
                      uid: id_triennale,
                      lang: currentRouteLang,
                    })}
                  >
                    <span className={styles.gridItemText}>{title_brief}</span>
                    <ResponsiveImage img={cover_image_group[0].cover_image} />
                  </Link>
                </li>
              )
            })}
          </ul>
        </section>

        <section className={styles.biblio}>
          {servicePages?.results?.length > 0 && (
            <StickyBlock
              title={[
                {
                  type: 'heading2',
                  text: translator('librarySectionTitle')(currentRouteLang),
                  spans: [],
                },
              ]}
              image={servicePages.results[0].data.paragraphs[0].paragraph_image}
              caption={servicePages.results[0].data.paragraphs[0].paragraph_image_caption}
              text={servicePages.results[0].data.paragraphs[0].paragraph_text}
              opening_times={servicePages.results[0].data.paragraphs[0].opening_times}
              service_info={servicePages.results[0].data.paragraphs[0].service_info}
              renderAsPrismic={true}
              modifierClass={0}
            />
          )}
        </section>
        <ArchiveDisclaimer />
      </div>
    )
  }
}
