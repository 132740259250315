import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import styles from './SingleArchiveTriennaleTabs.module.css'
import {
  ARCHIVE_HISTORICAL_KEY,
  ARCHIVE_MEDIA_KEY,
  TABS,
  TABS_LABELS,
  getArchive,
  ARCHIVE_API_LIMIT,
} from '../../lib/const'
import { translator } from '../../lib/translator'
import { LOAD_STATE_LOADING } from '../../routes/SingleArchiveTriennale'
import {
  LoadMore,
  LoaderOval,
  ArchiveLayoutCard,
  HistoricalArchiveLayoutCard,
} from '../../components'

const NoResults = ({ lang }) => {
  return (
    <div className={`${styles.noResults}`}>
      <div className={`${styles.noResultsInner}`}>{translator('noResultsMessage')(lang)}</div>
    </div>
  )
}

const ArchiveGridLayout = inject('rootStore')(({ data, rootStore }) => {
  const { ui, navigation } = rootStore
  const { archiveSingleTriennaleActiveTab } = ui
  const { currentRouteLang } = navigation
  const buildOpenLightbox = id => e => {
    e.preventDefault()
    const { setActiveItemId } = rootStore.ui.archiveLightbox
    setActiveItemId(id)
  }

  return (
    <ul className={`${styles.accordionContents}`}>
      {data.map((d, index) => {
        return archiveSingleTriennaleActiveTab === ARCHIVE_HISTORICAL_KEY
          ? HistoricalArchiveLayoutCard(d)
          : ArchiveLayoutCard(d, index, buildOpenLightbox, currentRouteLang)
      })}
    </ul>
  )
})

@inject('rootStore')
@observer
export class AccordionListHierarchy extends React.Component {
  buildSetAccordionGroup = slug => e => {
    if (e) e.preventDefault()

    const { rootStore, triennaleIndex } = this.props
    const { archiveSingleTriennale } = rootStore.ui
    const { isInAccordionKeys, removeAccordionKey, updateAccordion } = archiveSingleTriennale
    isInAccordionKeys(slug) ? removeAccordionKey(slug) : updateAccordion(slug, triennaleIndex)
  }

  hasMaybeMoreResults = accordionEntry => {
    return accordionEntry.contents.length === accordionEntry.pageIndex * ARCHIVE_API_LIMIT
  }

  buildUpdatePage = slug => e => {
    if (e) e.preventDefault()

    const { rootStore, triennaleIndex } = this.props
    const { archiveSingleTriennale } = rootStore.ui
    const { updateAccordion } = archiveSingleTriennale
    updateAccordion(slug, triennaleIndex, true)
  }

  render() {
    const { rootStore, parentLoadState, data } = this.props
    const { navigation, ui } = rootStore
    const { currentRouteLang } = navigation
    const { archiveSingleTriennale } = ui
    const { isInAccordionKeys, getAccordionEntryByKey } = archiveSingleTriennale

    return data.length ? (
      <ul className={`${styles.accordion}`} ref={this.ref}>
        {data.map(({ slug, label, hierarchyIndex }, i) => {
          const isInAccordion = isInAccordionKeys(slug)
          const accordionEntry = getAccordionEntryByKey(slug)

          return (
            <li
              key={i}
              className={`${styles.accordionGroup} ${
                isInAccordion ? styles.accordionGroupIsExpanded : ''
              }`}
            >
              <div className={`${styles.accordionSummary}`}>
                <button
                  className={`${styles.accordionSummaryBtn} bold smallTitle`}
                  onClick={this.buildSetAccordionGroup(slug)}
                >
                  {hierarchyIndex ? `${hierarchyIndex}. ` : ''}
                  {label}
                </button>
              </div>
              <div className={`${styles.accordionDetails}`}>
                <div className={`${styles.accordionDetailsInner}`}>
                  {isInAccordion &&
                  accordionEntry.loadState === LOAD_STATE_LOADING &&
                  accordionEntry.pageIndex === 1 ? (
                    <LoaderOval isCompact={true} />
                  ) : isInAccordion && accordionEntry.contents.length > 0 ? (
                    <>
                      <ArchiveGridLayout data={accordionEntry.contents} />
                      {this.hasMaybeMoreResults(accordionEntry) && (
                        <LoadMore onClick={this.buildUpdatePage(slug)} />
                      )}
                    </>
                  ) : (
                    <NoResults lang={currentRouteLang} />
                  )}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    ) : parentLoadState === LOAD_STATE_LOADING ? (
      <LoaderOval isCompact={true} />
    ) : (
      <NoResults lang={currentRouteLang} />
    )
  }
}

@inject('rootStore')
@observer
class SingleArchiveTriennaleTabs extends React.Component {
  componentDidMount() {
    this.props.rootStore.ui.archiveSingleTriennale.setActiveTab(TABS[0])
  }

  buildSetTab = tabKey => e => {
    e.preventDefault()
    const { setActiveTab } = this.props.rootStore.ui.archiveSingleTriennale
    setActiveTab(tabKey)
  }

  render() {
    const { rootStore, triennaleIndex, parentLoadState } = this.props
    const { expoArchivePages, navigation, ui } = rootStore
    const { currentRouteLang } = navigation
    const { activeTab } = ui.archiveSingleTriennale

    if (!expoArchivePages.results.length || !activeTab) {
      return null
    }

    const accordionData = this.props[getArchive(activeTab)]
    const tabsContents = TABS.map(tabKey => this.props[getArchive(tabKey)])
    const moreThanOneTab = tabsContents.filter(t => t.length > 0).length > 1

    return (
      <div className={`${styles.wrapper}`}>
        <ul className={`${styles.tabs} ${moreThanOneTab ? styles.tabsHasMoreThanOneChild : ''}`}>
          {TABS.map((tabKey, i) => {
            if (isEmpty(tabsContents[i])) {
              return null
            }

            return (
              <li key={i} className={`${styles.tab}`}>
                <button
                  className={`${styles.tabBtn} ${
                    activeTab === tabKey ? styles.tabBtnIsActive : ''
                  } bold`}
                  onClick={this.buildSetTab(tabKey)}
                >
                  {TABS_LABELS[currentRouteLang][tabKey]}
                </button>
              </li>
            )
          })}
        </ul>
        {activeTab === ARCHIVE_MEDIA_KEY ? (
          <ArchiveGridLayout data={accordionData} />
        ) : (
          <AccordionListHierarchy
            data={accordionData}
            triennaleIndex={triennaleIndex}
            parentLoadState={parentLoadState}
          />
        )}
      </div>
    )
  }
}

export default SingleArchiveTriennaleTabs
