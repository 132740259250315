import React from 'react'
import { inject, observer } from 'mobx-react'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import { computeEventDates } from '../../lib/date-utils'
import { Link } from '../../components'
import { linkResolver } from '../../lib/link-resolver'
import { ResponsiveImage } from '../Shared/Templating'
import styles from './SingleMagazinePreview.module.css'
import { EventTitles } from '../Shared/EventTitles'
import { ContentFiltersBlock } from '../Shared/ContentFilters/ContentFiltersBlock'
import { MagazineAuthor } from '../../components/Magazine/MagazineAuthor'
import { MagazineSerie } from '../../components/Magazine/MagazineSerie'
import MagazineDate from '../Magazine/MagazineDate'
import EventDate from '../../components/Events/EventDate'

function setHover(e, ctx) {
  if (!e.target || (e.target && !Array.from(e.target.classList).includes('location'))) {
    ctx.setState({ hovered: true })
  }
}

function unsetHover(ctx) {
  ctx.setState({ hovered: false })
}

@inject('rootStore')
@observer
export class SingleMagazineFocusPreview extends React.Component {
  state = {
    hovered: false,
  }

  buildSetHover = e => setHover(e, this)
  buildUnsetHover = _ => unsetHover(this)
  getSeriesAuthorsRelatedInfo = data => {
    const {
      options: { series = [], authors = [] },
    } = this.props
    if (isEmpty(data)) return
    const options = [...series, ...authors]
    if (isEmpty(options) && !('value' in data?.[0])) {
      return data.map(d => {
        const {
          uid,
          data: { name, background_color },
        } = d
        return { uid, name: name[0]?.text, background_color }
      })
    } else {
      return data.map(d => options.find(o => o.uid === d?.value.uid))
    }
  }

  render() {
    const {
      rootStore: {
        navigation: { currentRouteLang },
      },
      data: card,
      width,
    } = this.props
    const { hovered } = this.state

    const { uid, first_publication_date, data, type: typeOfPost } = card

    const {
      title,
      cover_image,
      cover_image_focus_on,
      author,
      start_date,
      end_date,
      specific_dates,
      location,
      location_map_link,
      type,
      magazine_series,
      magazine_authors,
      event_online,
      only_month,
    } = data

    const image =
      cover_image_focus_on.url && cover_image_focus_on.url !== ''
        ? cover_image_focus_on
        : cover_image

    const { dateString } = computeEventDates(
      start_date,
      end_date,
      specific_dates,
      currentRouteLang,
      only_month,
    )

    const seriesRelatedInfo = magazine_series
      ? this.getSeriesAuthorsRelatedInfo(magazine_series)
      : []

    const authorsRelatedInfo = magazine_authors
      ? this.getSeriesAuthorsRelatedInfo(magazine_authors)
      : []

    return (
      <article className={`${width || ''}`}>
        <Link
          onMouseOver={this.buildSetHover}
          onMouseOut={this.buildUnsetHover}
          onFocus={noop}
          onBlur={noop}
          to={linkResolver({ contentType: typeOfPost, lang: currentRouteLang, uid })}
          className={`outline ${styles.previewLink} ${hovered ? styles.hovered : ''}`}
        >
          <div className={styles.magazineCoverGrid}>
            {image.url && (
              <div className={styles.cover_img}>
                <ResponsiveImage img={image} additionalClassName={styles.img} />
              </div>
            )}
          </div>
          <div className={styles.magazineHeaderGrid}>
            <div className={styles.magazineHeaderInner}>
              {!isEmpty(seriesRelatedInfo) &&
                seriesRelatedInfo.map((serieInfo, index) => {
                  return (
                    <MagazineSerie
                      key={index}
                      {...serieInfo}
                      lang={currentRouteLang}
                      link={false}
                    />
                  )
                })}
              <ContentFiltersBlock
                areFiltersClickable={false}
                type={type}
                currentLang={currentRouteLang}
                contentType={'events'}
                isOnlineEvent={event_online}
              />
              <EventTitles typeOfPost={typeOfPost} author={author} title={title} width={width} />
              {/* //! Refactor me */}
              {typeOfPost === 'event' && (
                <EventDate
                  dateString={dateString}
                  data={data}
                  location={location}
                  location_map_link={location_map_link}
                  width={width}
                />
              )}
              {typeOfPost === 'magazine' && (
                <div className={styles.magazineInfo}>
                  {!isEmpty(authorsRelatedInfo) &&
                    authorsRelatedInfo.map(authorInfo => {
                      return (
                        <>
                          {authorInfo && (
                            <MagazineAuthor
                              key={authorInfo.uid}
                              name={authorInfo.name}
                              id={authorInfo.id}
                              lang={currentRouteLang}
                              link={false}
                              isPreview={true}
                            />
                          )}
                        </>
                      )
                    })}
                  {
                    <MagazineDate
                      first_publication_date={first_publication_date}
                      width={width}
                      isFocus
                    />
                  }
                </div>
              )}
            </div>
          </div>
        </Link>
      </article>
    )
  }
}
