const ENV = process.env.NODE_ENV
const BRANCH = process.env.BRANCH
const BRANCH_PROD = 'master'
// Staging routes have a prefix which is the name of the branch,
// e.g. /develop/whats-on or /magazine-test/en/interview
export const SSR_INTERNAL_PREFIX = BRANCH || (ENV === 'production' ? BRANCH_PROD : '')

export const filterOutPrefix = (parts) => {
  // Cleanup public or internal staging routing prefix parts
  return parts.filter((p) => !['build', SSR_INTERNAL_PREFIX].includes(p))
}

export const fixPath = (path, withSlash = false) => {
  const prefix = withSlash ? '/' : ''
  return (
    (path || '')
      // Cleanup internal staging routing prefix
      .replace(new RegExp(`^/?build/${SSR_INTERNAL_PREFIX}/?`), prefix)
      // Cleanup public staging routing prefix
      .replace(new RegExp(`^/?${SSR_INTERNAL_PREFIX}/?`), prefix)
  )
}

export const addSsrPrefix = (path, stagingOnly = true) => {
  const fixedPath = /^\//.test(path) ? path : `/${path}`
  let prefix = SSR_INTERNAL_PREFIX
  // stagingOnly should be true for links that are displayed publicly
  // and false only for internal routes.
  prefix = prefix && (!stagingOnly || prefix !== BRANCH_PROD) ? `/${prefix}` : ''
  return `${prefix}${fixedPath}`
}
