import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './SearchToggleDesktop.module.css'
import { translator } from '../../lib/translator'

@inject('rootStore')
@observer
class SearchToggleDesktop extends React.Component {
  toggleSearchbar = e => {
    e.preventDefault()
    const { toggleSearchPanel } = this.props.rootStore.ui
    toggleSearchPanel()
  }

  render() {
    const {
      ui: { isSearchPanelVisible },
      navigation: { currentRouteLang },
    } = this.props.rootStore

    return (
      <button
        className={`navText ${styles.searchToggleWrapper} ${
          isSearchPanelVisible ? ` ${styles.active}` : ''
        }`}
        onClick={this.toggleSearchbar}
      >
        {translator('searchLabel')(currentRouteLang)}
      </button>
    )
  }
}

export default SearchToggleDesktop
