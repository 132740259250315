import React, { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { magazineSeries } from '../../lib/prismic/magazineSeries'
import { magazineAuthor } from '../../lib/prismic/magazineAuthor'
import styles from './OngoingListCards.module.css'
import { SinglePostPreviewLayout } from './SinglePostPreviewLayout'

export const OngoingListCards = ({ results, options: { typeOfList, limit = 0 } }) => {
  const [series, setSeries] = useState([])
  const [authors, setAuthors] = useState([])

  const haveRelatedSeriesAuthorsInfo = results => type => {
    const info = results.find(({ data }) => {
      if (isEmpty(data?.[type]) || !data?.[type]) return
      return 'value' in data?.[type]?.[0]
    })
    return isEmpty(info)
  }

  useEffect(() => {
    const haveRelated = haveRelatedSeriesAuthorsInfo(results)
    async function fetchSeries() {
      const s = await magazineSeries.get()?.results?.map(s => {
        return {
          id: s.id,
          uid: s.uid,
          background_color: s.data.background_color,
          name: s.data.name[0]?.text,
        }
      })
      setSeries(s)
    }

    async function fetchAuthors() {
      const a = await magazineAuthor.get()?.results?.map(s => {
        return { uid: s.uid, name: s.data.name[0]?.text }
      })
      setAuthors(a)
    }

    if (!haveRelated('magazine_series')) {
      fetchAuthors()
      fetchSeries()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleContentWidth = number => {
    if (
      (number % 9 === 0 && number < 10) ||
      (number % 9 === 1 && number < 10) ||
      (number % 9 === 2 && number < 10) ||
      (number % 9 === 3 && number < 10) ||
      (number % 9 === 4 && number < 10) ||
      (number % 9 === 5 && number < 10) ||
      (number % 9 === 6 && number < 10)
    ) {
      return styles.bigPost
    } else {
      return styles.smallPost
    }
  }

  const createEmptyPositionalElements = length => {
    if (length < 10) {
      const elementsLength = (10 - length) % 2
      const emptyElementsArray = Array.from(Array(elementsLength).fill())
      return emptyElementsArray.map((e, i) => {
        return (
          <article
            key={`empty-${i}`}
            className={handleContentWidth(i)}
            style={{ marginBottom: 0 }}
          />
        )
      })
    }
    if (length > 10 && (length - 10) % 12 !== 0) {
      const elementsLength = 4 - ((length - 10) % 4)
      const emptyElementsArray = Array.from(Array(elementsLength).fill())
      return emptyElementsArray.map((e, i) => {
        return (
          <article key={`empty-${i}`} className={styles.smallPost} style={{ marginBottom: 0 }} />
        )
      })
    }
    return null
  }

  if (results && typeOfList >= 0) {
    return (
      <div
        className={`${styles.grid} ${limit ? styles.gridLimit : ''} ${
          !typeOfList ? styles.gridTypeL : ''
        }`}
      >
        {results
          .slice(0, results.length - (typeOfList === 3 ? 1 : typeOfList === 1 ? 0 : typeOfList))
          .map((result, i) => {
            return (
              <SinglePostPreviewLayout
                key={result.id + i}
                data={result}
                options={{ series, authors }}
                width={handleContentWidth(i)}
              />
            )
          })}
        {createEmptyPositionalElements(results.length)}
      </div>
    )
  }

  return (
    <>
      {results.map((result, i) => {
        return (
          <SinglePostPreviewLayout
            key={result.id + i}
            data={result}
            options={{ series, authors }}
            width={handleContentWidth(i)}
          />
        )
      })}
    </>
  )
}
