import React from 'react'
import isEmpty from 'lodash/isEmpty'
import styles from './PartnerBlock.module.css'
import { filterEmptyPrismicRepeatersContents, parsePrismicHtml } from '../../lib/survive-prismic'

export class PartnerBlockExpanded extends React.Component {
  render() {
    const { partners } = this.props

    return (
      <div className={styles.itemsWrapperExpanded}>
        {partners.map((partner, i) => {
          const { partner_image, partner_link, partner_type } = partner
          const _partner_type = filterEmptyPrismicRepeatersContents(partner_type, 'text')

          const partnerContent = (
            <React.Fragment>
              <div
                className={`verySmallText ${styles.partnerText}`}
                dangerouslySetInnerHTML={{
                  __html: parsePrismicHtml(_partner_type) || `<p>&nbsp;</p>`,
                }}
              />
              <div className={styles.imgWrapper}>
                <img src={partner_image.url} alt={partner_image.alt} />
              </div>
            </React.Fragment>
          )

          return (
            <div key={i} className={styles.item}>
              <div className={styles.inner}>
                {partner_link.url ? (
                  <a
                    className={`${styles.link} ${styles.isVisible}`}
                    href={partner_link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {partnerContent}
                  </a>
                ) : (
                  <div className={`${styles.link} ${styles.isVisible}`}>{partnerContent}</div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export const PartnersSectionExpanded = (partners, lang) => {
  if (!partners || !partners.length || isEmpty(partners[0].partner_image)) {
    return null
  }

  return (
    <section className={`${styles.partnerBlock} ${styles.sectionExpanded}`}>
      <PartnerBlockExpanded partners={partners} />
    </section>
  )
}
