export const archivesPages = {
  it: [
    {
      id: 'collezioni',
      uid: 'collezioni',
      data: {
        title: [
          {
            text: 'Collezione',
          },
        ],
      },
    },
    {
      id: 'archivio-fotografico',
      uid: 'archivio-fotografico',
      data: {
        title: [
          {
            text: 'Archivio Fotografico',
          },
        ],
      },
    },
    {
      id: 'archivio-audiovisivo',
      uid: 'archivio-audiovisivo',
      data: {
        title: [
          {
            text: 'Archivio Audiovisivo',
          },
        ],
      },
    },
    {
      id: 'archivio-storico',
      uid: 'archivio-storico',
      data: {
        title: [
          {
            text: 'Archivio Storico',
          },
        ],
      },
    },
  ],
  en: [
    {
      id: 'collezioni',
      uid: 'collections',
      data: {
        title: [
          {
            text: 'Collection',
          },
        ],
      },
    },
    {
      id: 'archivio-fotografico',
      uid: 'photographic-archive',
      data: {
        title: [
          {
            text: 'Photographic Archive',
          },
        ],
      },
    },
    {
      id: 'archivio-audiovisivo',
      uid: 'audiovisual-archive',
      data: {
        title: [
          {
            text: 'Audiovisual Archive',
          },
        ],
      },
    },
    {
      id: 'archivio-storico',
      uid: 'historical-archive',
      data: {
        title: [
          {
            text: 'Historical Archive',
          },
        ],
      },
    },
  ],
}
