export const archivioAudiovisivoData = {
  it: {
    data: {
      title: [
        {
          text: 'Archivio audiovisivo',
        },
      ],
      description: [
        {
          type: 'paragraph',
          text:
            'L’archivio audiovisivo raccoglie circa 1.000 filmati, 600 registrazioni audio, una serie di servizi giornalistici e film.',
          spans: [],
        },
      ],
    },
  },
  en: {
    data: {
      title: [
        {
          text: 'Audiovisual Archive',
        },
      ],
      description: [
        {
          type: 'paragraph',
          text:
            'L’archivio audiovisivo raccoglie circa 1.000 filmati, 600 registrazioni audio, una serie di servizi giornalistici e film.',
          spans: [],
        },
      ],
    },
  },
}
