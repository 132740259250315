import React from 'react'
import isEmpty from 'lodash/isEmpty'
import styles from './PressItemsPreview.module.css'
import { SinglePressItemPreview } from './SinglePressItemPreview'

class PressItemsPreview extends React.Component {
  render() {
    const { results, options } = this.props
    return (
      <div className={styles.grid}>
        {!isEmpty(results) ? results.map(p => SinglePressItemPreview(p, options)) : null}
      </div>
    )
  }
}

export default PressItemsPreview
