import React from 'react'
import { inject, observer } from 'mobx-react'
import map from 'lodash/fp/map'
import styles from './OngoingFiltersWrapper.module.css'
import { OngoingFiltersSelect } from './OngoingFiltersSelect'
import { AGGREGATED_FILTERS } from './lib/filters'

// TODO: filters select
@inject('rootStore')
@observer
class OngoingFiltersWrapper extends React.Component {
  wrapperRef = React.createRef()

  componentDidMount() {
    this.wrapperRef.current.scrollTop = this.props.offset
    this.wrapperRef.current.addEventListener('scroll', this.updateOffset, false)
  }

  componentWillUnmount() {
    this.wrapperRef.current.removeEventListener('scroll', this.updateOffset, false)
  }

  updateOffset = e => {
    this.props.setOffset(this.props.id, e.target.scrollTop)
  }

  getFiltersValues = filters => {
    const predicate = k => ({ label: k, values: filters[k] })
    return map(predicate)(Object.keys(filters))
  }

  render() {
    const {
      filters,
      isOpen,
      type: { isFilterActive, setSingleFilter },
      rootStore: {
        navigation: { currentRouteLang },
        ui: { labelsColor },
      },
    } = this.props

    const filtersToApply = {}
    for (let filterKey in filters) {
      filtersToApply[filterKey] = filters[filterKey]
    }

    const { getActiveFiltersValues } = this.props.rootStore[this.props.id]

    return (
      <div
        className={!isOpen ? styles.filtersWrapper : styles.filtersWrapperOpen}
        ref={this.wrapperRef}
        style={{ display: 'inline-block' }}
      >
        {this.getFiltersValues(filtersToApply).map(({ label, values }, i) => {
          const options = []

          values.map(value => {
            options.push({
              value: value[currentRouteLang],
              label: value[currentRouteLang],
              color: 'white',
              isFixed: true,
              filter: AGGREGATED_FILTERS[currentRouteLang][value[currentRouteLang]],
            })
          })

          return (
            <div className={styles.filtersKeysWrapper} key={label}>
              <OngoingFiltersSelect
                options={options}
                setSingleFilter={setSingleFilter}
                isFilterActive={isFilterActive}
                type={label}
                currentRouteLang={currentRouteLang}
                getActiveFiltersValues={getActiveFiltersValues}
                labelsColor={labelsColor}
              />
            </div>
          )
        })}
      </div>
    )
  }
}

export default OngoingFiltersWrapper
