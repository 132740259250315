import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './Filter.module.css'
import { ReactComponent as CloseIcon } from '../../../images/icons/close.svg'

@inject('rootStore')
@observer
export class Filter extends React.Component {
  toggleFilter = e => {
    e.preventDefault()
    const { type, value, toggleFilter } = this.props
    toggleFilter({ type, value })
  }

  render() {
    const { type, value, isFilterActive } = this.props

    return (
      <button
        className={`smallText ${
          isFilterActive({ type, value }) ? styles.filterActive : styles.filter
        }`}
        onClick={this.toggleFilter}
        type="button"
      >
        {value}
        {isFilterActive({ type, value }) && (
          <CloseIcon className={styles.xIcon} aria-hidden="true" />
        )}
      </button>
    )
  }
}
