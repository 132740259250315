import React from 'react'
import { render } from 'react-dom'
import App from 'components/App'
import 'modern-normalize'
import './lib/modernizr-custom-3.6.0'
import 'reset.css'
import 'style.css'
import 'typography.css'
import 'forms.css'

const rootElement = document.getElementById('root')

function renderApp() {
  render(<App />, rootElement)
}

renderApp()

if (module.hot) {
  module.hot.accept('components/App', () => {
    console.clear()
    renderApp()
  })
}
