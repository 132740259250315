import { get } from 'triennale-prismic-api-wrapper/src/fetcher'
import {
  getEvents,
  getEventsByUids,
} from 'triennale-prismic-api-wrapper/src/fetchers/eventsFetcher'
import { getMagazinesByRefs } from 'triennale-prismic-api-wrapper/src/fetchers/magazinesFetcher'
import {
  getContainerPagesByUids,
  getContainerPagesByRefs,
} from 'triennale-prismic-api-wrapper/src/fetchers/containerPagesFetcher'
import { homepage as contentTypeId } from 'triennale-prismic-api-wrapper/src/constants/contentTypes'
import { homepage as fields } from 'triennale-prismic-api-wrapper/src/constants/fields'
import { homepage as queries } from 'triennale-prismic-api-wrapper/src/queries'

const getHeroContent = async (heroContentRef, lang) => {
  try {
    const options = {
      shouldFetchRelatedContents: false,
      shouldFetchContainerPage: true,
      shouldFetchPriceLists: false,
    }
    const fetchers = {
      event: getEventsByUids,
      container_page: getContainerPagesByUids,
    }
    const getByUids = fetchers[heroContentRef.type]
    if (getByUids) {
      return (await getByUids([heroContentRef.uid], lang, options))[0]
    }
    return null
  } catch (err) {
    console.log('Error', err)
  }
}

const getScheduledHeros = (heros = []) => {
  const publishedHeros = heros

  return { publishedHero: publishedHeros[0], publishedHeros }
}

const contentType = {
  contentTypeId,
  fields,
  queries,
  get: async lang => {
    const page = await get(contentTypeId)(lang)
    if (!page || (page.results || []).length === 0) {
      return { results: [] }
    }
    const { data } = page.results[0]

    if (data.container_pages) {
      page.results[0].data.container_pages = await getContainerPagesByRefs(
        data.container_pages,
        lang,
      )
    }

    //! data.events - events key is uncorrect.
    //! It should need a refactor. Now @FocusOn component is related to different content types
    if (data.events) {
      const options = {
        shouldFetchRelatedContents: false,
        shouldFetchContainerPage: true,
        shouldFetchPriceLists: false,
      }

      const eventsRefs = data.events.filter(d => d.value.type === 'event')
      const magazinesRefs = data.events.filter(d => d.value.type === 'magazine')
      page.results[0].data.events = [
        ...(await getEvents(eventsRefs, lang, options)),
        ...(await getMagazinesByRefs(magazinesRefs, lang, options)),
      ]
    }

    if (data.performances) {
      const options = {
        shouldFetchRelatedContents: false,
        shouldFetchContainerPage: true,
        shouldFetchPriceLists: false,
      }

      const performancesRefs = data.performances.filter(d => d.value.type === 'event')

      page.results[0].data.performances = [...(await getEvents(performancesRefs, lang, options))]
    }

    if (data.public_program) {
      const options = {
        shouldFetchRelatedContents: false,
        shouldFetchContainerPage: true,
        shouldFetchPriceLists: false,
      }

      const publicProgramRefs = data.public_program.filter(d => d.value.type === 'event')

      page.results[0].data.public_program = [...(await getEvents(publicProgramRefs, lang, options))]
    }

    const heros = getScheduledHeros(data.heros)

    const results = []
    heros.publishedHeros.map(async hero => {
      let heroContent = await getHeroContent(hero.hero_content, lang)
      const p = page.results[0]
      results.push({
        title: heroContent.data.title[0].text,
        href: p.href,
        id: p.id,
        lang: p.lang,
        type: p.type,
        uid: p.uid,
        data: {
          performances: p.data.performances,
          public_program: p.data.public_program,
          title_performances: p.data.title_performances,
          cta_performances: p.data.cta_performances,
          cta_link_performances: p.data.cta_link_performances,
          background_color: hero.background_color,
          category_cta_color: hero.category_cta_color,
          cover_image_caption: hero.cover_image_caption,
          cover_image_desktop: hero.cover_image_desktop,
          cover_image_mobile: hero.cover_image_mobile,
          hero_content: hero.hero_content,
          hero_layout: hero.hero_layout,
          is_video_cover: hero.is_video_cover,
          is_video_cover_autoplay: hero.is_video_cover_autoplay,
          scheduled_publish_date: hero.scheduled_publish_date,
          text_color: hero.text_color,
          hero_contents: [heroContent],
          start_date: heroContent.data.start_date,
          end_date: heroContent.data.end_date,
          specific_dates: heroContent.data.specific_dates,
          ...hero,
        },
      })
    })

    const formatted = {
      results: results,
    }

    if (heros.publishedHero) {
      const heroContent = await getHeroContent(heros.publishedHero.hero_content, lang)
      page.results[0].data = {
        ...page.results[0].data,
        ...heros.publishedHero, // copy hero contents in results data
        hero_contents: [heroContent],
      }
    }

    return formatted.results.length <= 3 ? formatted.results : formatted.results.slice(0, 3)
  },
}

export { contentType as homepage }
