import React from 'react'
import { inject, observer } from 'mobx-react'
import map from 'lodash/fp/map'
import { translator } from '../../lib/translator'
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg'
import { OngoingFilterDayPicker } from './OngoingFilterDayPicker'
import styles from './OngoingFilters.module.css'

@inject('rootStore')
@observer
export class OngoingFiltersBlock extends React.Component {
  wrapperRef = React.createRef()

  componentDidMount() {
    this.wrapperRef.current.scrollTop = this.props.offset
    this.wrapperRef.current.addEventListener('scroll', this.updateOffset, false)
  }

  componentWillUnmount() {
    this.wrapperRef.current.removeEventListener('scroll', this.updateOffset, false)
  }

  updateOffset = e => {
    this.props.setOffset(this.props.id, e.target.scrollTop)
  }

  getFiltersValues = filters => {
    const predicate = k => ({ label: k, values: filters[k] })
    return map(predicate)(Object.keys(filters))
  }

  render() {
    const { filters, filterTypesToExclude, isOpen, type, closePanel } = this.props
    const {
      navigation: { currentRouteLang },
    } = this.props.rootStore

    const filtersToApply = {}
    for (let filterKey in filters) {
      const isExcluded = (filterTypesToExclude || []).includes(filterKey)

      if (!isExcluded) {
        filtersToApply[filterKey] = filters[filterKey]
      }
    }

    return (
      <div
        className={!isOpen ? styles.filtersWrapper : styles.filtersWrapperOpen}
        ref={this.wrapperRef}
      >
        <div className={styles.header}>
          <CloseIcon aria-hidden="true" className={styles.closeIcon} onClick={closePanel} />
        </div>
        <div className={styles.filtersKeysWrapper}>
          <div className={`smallText bold ${styles.keyTitle} ${styles.datePickerTitle}`}>
            {translator('dateFilterTitle')(currentRouteLang)}
          </div>
          <OngoingFilterDayPicker contentType={type} closePanel={closePanel} />
        </div>
      </div>
    )
  }
}
