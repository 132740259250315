import React from 'react'
import { inject, observer } from 'mobx-react'
import Media from 'react-media'
import Masonry from 'react-masonry-component'
import {
  SearchForm,
  LoaderOval,
  Link,
  SinglePressItemPreview,
  SingleNewsItemPreview,
} from '../components'
import styles from './SearchResults.module.css'
import { SectionTitlePlain } from '../components/Shared/Titles'
import { translator } from '../lib/translator'
import { PromotionCard } from '../components/Shared/Cards'
import { SingleEventPreviewLayoutSearchResults } from '../components/Ongoing/SingleEventPreviewLayoutSearchResults'
import { linkResolver } from '../lib/link-resolver'
import { over480 } from '../styles/media-queries.css'
import { ContainerPagesBlockItem } from '../components/Shared/ContainerPagesBlock'
import { parsePrismicHtml } from '../lib/survive-prismic'

@inject('rootStore')
@observer
export class SearchResults extends React.Component {
  componentDidMount() {
    const {
      navigation: {
        router: { location },
      },
      searchResults,
    } = this.props.rootStore
    const { updateResults, queryString, updateQueryString } = searchResults

    // avoid the browser back button to break
    const currentQueryString = decodeURIComponent(location.search.replace('?', ''))
    if (queryString !== currentQueryString) {
      updateQueryString(currentQueryString)
    }

    updateResults()
  }

  render() {
    const {
      navigation: { currentRouteLang },
      searchResults: { data, isLoading },
    } = this.props.rootStore

    let manuallySortedData, hasData
    if (data) {
      manuallySortedData = {
        container_page: data.container_page,
        event: data.event,
        promotion: data.promotion,
        press: data.press,
        news: data.news,
        pages: [
          ...data.visit,
          ...data.service_page,
          ...data.institutional_page,
          ...data.basic_page,
        ],
      }

      hasData =
        Object.keys(manuallySortedData).reduce(
          (acc, next) => acc + manuallySortedData[next].length,
          0,
        ) > 0
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.searchPanel}>
          <div className={styles.searchPanelInner}>
            <div className={styles.input}>
              <SearchForm />

              <div className={styles.resultsWrapper}>
                {isLoading ? (
                  <LoaderOval />
                ) : (
                  <div className={styles.results}>
                    {hasData ? (
                      Object.keys(manuallySortedData).map((resultType, i) => {
                        const resultsByType = manuallySortedData[resultType]

                        if (!resultsByType.length) {
                          return null
                        }

                        const title = (
                          <SectionTitlePlain
                            title={translator(resultType)(currentRouteLang)}
                            border={false}
                          />
                        )

                        const content = resultsByType.map((result, j) => {
                          const contentType = result.type

                          if (contentType === 'promotion') {
                            return PromotionCard(result, j, { lang: currentRouteLang })
                          }

                          if (contentType === 'news') {
                            return SingleNewsItemPreview(result, {
                              lang: currentRouteLang,
                              areFiltersClickable: false,
                            })
                          }

                          if (contentType === 'press') {
                            return SinglePressItemPreview(result, {
                              lang: currentRouteLang,
                              areFiltersClickable: false,
                            })
                          }

                          if (contentType === 'event') {
                            return (
                              <SingleEventPreviewLayoutSearchResults
                                event={result}
                                options={{}}
                                key={j}
                              />
                            )
                          }

                          if (contentType === 'container_page') {
                            return <ContainerPagesBlockItem key={j} page={result} />
                          }

                          // regular page
                          return (
                            <article className={styles.page} key={j}>
                              <Link
                                className={`bodyTextBig regular outline ${styles.pageLink}`}
                                to={linkResolver({
                                  contentType: result.type,
                                  uid: result.uid,
                                  lang: currentRouteLang,
                                })}
                                dangerouslySetInnerHTML={{
                                  __html: parsePrismicHtml(result.data.title),
                                }}
                              />
                            </article>
                          )
                        })

                        if (resultType === 'event') {
                          return (
                            <div className={styles[resultType]} key={i}>
                              {title}
                              <Media query={over480}>
                                {matches =>
                                  matches ? (
                                    <Masonry options={{ gutter: 20 }}>{content}</Masonry>
                                  ) : (
                                    <div className={styles.eventContents}>{content}</div>
                                  )
                                }
                              </Media>
                            </div>
                          )
                        }

                        return (
                          <div className={styles[resultType]} key={i}>
                            {title}
                            <div className={styles[`${resultType}Content`]}>{content}</div>
                          </div>
                        )
                      })
                    ) : (
                      <div className={`bodyTextBig grey ${styles.noResults}`}>
                        {currentRouteLang === 'it'
                          ? 'Spiacenti, questa ricerca non ha prodotto risultati'
                          : 'Sorry, there are no results for this search'}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
