import React, { useEffect } from 'react'
import { inject, Observer } from 'mobx-react'
import { promotion } from '../../lib/prismic/promotion'
import { SwitcherDataState } from '../../components/Shared/DataState'
import { PromotionsCard } from '../../components/Shared/Cards'

const CurrentPromotions = inject('rootStore')(({ rootStore }) => {
  const {
    fetchFn,
    promotions: { results, dataState },
    navigation: { currentRouteLangCode, currentRouteLang },
  } = rootStore
  const type = promotion
  useEffect(() => {
    async function fetchData() {
      await fetchFn(type.getCurrent, type, currentRouteLangCode, null)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Observer>
      {() => SwitcherDataState(PromotionsCard, results, { lang: currentRouteLang })[dataState]}
    </Observer>
  )
})

export default CurrentPromotions
