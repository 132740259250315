import React from 'react'
import styles from './heroSerieContributor.module.css'

export const HeroSerieContributor = ({ data }) => {
  const {
    description,
    name,
    // background_color: backgroundColor
  } = data
  return (
    <div className={styles.hero} style={{ backgroundColor: '#F7F7F7' }}>
      {name[0]?.text && (
        <div className={styles.col1}>
          <h1>{name[0]?.text}</h1>
        </div>
      )}
      {description[0]?.text && (
        <div className={styles.col3}>
          <p>{description[0].text}</p>
        </div>
      )}
    </div>
  )
}
