import sortBy from 'lodash/sortBy'

import { getPaginatedData, searchInText } from 'triennale-prismic-api-wrapper/src/fetcher'
import { magazineAuthor as contentTypeId } from 'triennale-prismic-api-wrapper/src/constants/contentTypes'
import { magazineAuthor as fields } from 'triennale-prismic-api-wrapper/src/constants/fields'
import { magazineAuthor as queries } from 'triennale-prismic-api-wrapper/src/queries'
import { sortByDate as sortMagazinesByDate } from 'triennale-prismic-api-wrapper/src/contentTypes/magazine'
import { getMagazineAuthorsByUids } from 'triennale-prismic-api-wrapper/src/fetchers/magazinesFetcher'

const sortByName = data => sortBy(data, author => author.data.name[0].text)

const contentType = {
  contentTypeId,
  fields,
  queries,
  get: (filters, options, lang, pagination = null) => {
    return getPaginatedData(queries.getWithFilters(filters), lang, pagination, sortByName)
  },
  getByUids: getMagazineAuthorsByUids(fields, sortMagazinesByDate),
  searchInText: searchInText(contentTypeId),
}

export { contentType as magazineAuthor }
