import React from 'react'
import { Link } from '../../components'
import { translator } from '../../lib/translator'
import { SectionTitlePlain } from '../Shared/Titles'
import styles from './InternationalParticipation.module.css'
import { linkResolver } from '../../lib/link-resolver'

export const InternationalParticipation = ({
  participation,
  lang,
  currentPath = '/',
  index = 0,
}) => {
  const { uid, nation } = participation
  const linkForParticipation = linkResolver({ contentType: 'event', uid, lang })
  const isActive = linkForParticipation === currentPath

  return (
    <div className={styles.itemWrapper} key={index}>
      <Link
        to={linkResolver({ contentType: 'event', uid, lang })}
        className={`internationalParticipationTitle bold ${styles.item}${
          isActive ? ` ${styles.activeItem}` : ''
        }`}
      >
        <span>{nation}</span>
      </Link>
    </div>
  )
}

export const InternationalParticipationsSection = ({ data, lang, path }) => {
  return (
    <>
      {data?.length > 0 && (
        <section className={styles.wrapper}>
          <SectionTitlePlain title={translator('internationalParticipationsSectionTitle')(lang)} />
          <div className={styles.items}>
            {data.map((p, i) => {
              return (
                <InternationalParticipation
                  key={i}
                  participation={p}
                  lang={lang}
                  currentPath={path}
                  index={i}
                />
              )
            })}
          </div>
        </section>
      )}
    </>
  )
}
