import React from 'react'
import { inject } from 'mobx-react'
import { capitalizeString } from '../../lib/utils'
import { computeEventDatesWithTicketingFallback } from '../../lib/date-utils'
import { EventTitles } from '../Shared/EventTitles'
import { ContentFiltersBlock } from '../Shared/ContentFilters/ContentFiltersBlock'
import styles from './EventTicketHeader.module.css'

export const EventTicketHeader = inject('rootStore')(
  ({
    rootStore: {
      events,
      tickets: { showId, performance, ticketByShowId },
      navigation: { currentRouteLang },
      ui: { labelsColor },
    },
  }) => {
    const getInfoHeader = () => {
      const { name, description, prismicEventId } = ticketByShowId
      return prismicEventId
        ? {
            ...events.results.find(e => e.data.show_id === showId).data,
            description: null,
          }
        : {
            title: name,
            start_date: null,
            end_date: null,
            description,
            specific_dates: [],
            type: null,
          }
    }

    if (!ticketByShowId) return null
    const {
      title,
      author,
      start_date,
      end_date,
      specific_dates,
      description,
      type,
    } = getInfoHeader()
    const { dateString } = computeEventDatesWithTicketingFallback(
      start_date,
      end_date,
      specific_dates,
      currentRouteLang,
      performance,
    )
    return (
      <div className={styles.headerTicketInfo}>
        <EventTitles
          author={author}
          title={typeof title === 'string' ? capitalizeString(title) : title}
          titleClass="sectionTitle bold"
        />
        {description && <p className={styles.description}>{description}</p>}

        <ContentFiltersBlock
          areFiltersClickable={false}
          type={type}
          contentType={'events'}
          currentLang={currentRouteLang}
        />
        {dateString && (
          <span className={`smallText ${styles.dates} bold`} style={{ color: labelsColor }}>
            {dateString}
          </span>
        )}
      </div>
    )
  },
)
