import { get, post } from '../temp/ticketing-api-utils'

export function getEventsList() {
  return get('events')
}

export function getScheduledTours(id) {
  return get('educations/schedule', { params: { id } })
}

export function getSinglePerformancePrices({ pcode, vcode }) {
  return get(`events/${pcode}/prices`, { params: { vcode } })
}

export function postOrder({ cart, customer, subscription }) {
  return post(`orders`, { params: { cart, customer, subscription } })
}

export function postNotification({ amount, seats, barcodes, payment_code, show, customer }) {
  return post(`notifications/customers`, {
    params: {
      amount,
      seats,
      barcodes,
      payment_code,
      show,
      customer,
    },
  })
}

export function getSubscriptionInfo({ barcode }) {
  return get(`subscriptions/${barcode}`)
}
