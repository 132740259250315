import React from 'react'
import { inject, observer } from 'mobx-react'
import { PageTitlePlain } from '../../components/Shared/Titles'
import { translator } from '../../lib/translator'
import { archivesPages } from '../../lib/temp/archives-pages'
import { SearchBox, ArchivePagesBlock } from '../../components'

export const TopPart = inject('rootStore')(
  observer(props => {
    const {
      rootStore: {
        navigation: { currentRouteLang },
      },
    } = props
    return (
      <>
        <PageTitlePlain title={translator('archivesTitle')(currentRouteLang)} border={true} />
        <ArchivePagesBlock pages={archivesPages[currentRouteLang]} />
        <SearchBox global={true} />
      </>
    )
  }),
)
