import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './Credits.module.css'
import { filterEmptyPrismicRepeatersContents, parsePrismicHtml } from '../../lib/survive-prismic'
import { translator } from '../../lib/translator'
import { linkResolverNormalizer } from '../../lib/link-resolver'

@inject('rootStore')
@observer
export class Credits extends React.Component {
  state = {
    isCreditsOpen: false,
  }

  toggleCredits = e => {
    e.preventDefault()
    this.setState(state => ({ isCreditsOpen: !state.isCreditsOpen }))
  }

  render() {
    const { credits, credits_part2, rootStore, hasTitle = true } = this.props
    const { currentRouteLang } = rootStore.navigation

    const _credits = filterEmptyPrismicRepeatersContents(credits, 'text')
    const _credits_part2 = filterEmptyPrismicRepeatersContents(credits_part2, 'text')

    return (
      <div className={styles.creditsBlock}>
        {_credits?.length > 0 && (
          <>
            {hasTitle && (
              <p className={`captionText bold ${styles.title}`}>
                {translator('creditsSectionTitle')(currentRouteLang)}
              </p>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: parsePrismicHtml(_credits, linkResolverNormalizer),
              }}
              className={`captionText ${styles.credits}`}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: parsePrismicHtml(_credits_part2, linkResolverNormalizer),
              }}
              className={`captionText ${styles.credits}`}
            />
          </>
        )}
      </div>
    )
  }
}
