import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'
import { getFilteredContentsForType } from 'triennale-prismic-api-wrapper'
import { Banner, Link } from '../../components'
import { linkResolver } from '../../lib/link-resolver'

const getBannerContent = async lang => {
  const bannersByType = await getFilteredContentsForType('banner', {}, lang)
  if (!bannersByType.length) return null

  return last(bannersByType).data
}

const emptyContent = { title: '', text: '', cta: '', link: '', type: '' }

const AlertBannerContent = ({ currentRouteLang }) => {
  const [content, setContent] = useState(emptyContent)
  const { title, text, cta, link } = content || emptyContent
  const { lang, uid, type, url } = link
  const langs = { it: 'it-it', en: 'en-gb' }
  const isContentEmpty = isEqual(content, emptyContent)
  useEffect(() => {
    getBannerContent(langs[currentRouteLang]).then(d => {
      setContent(d || emptyContent)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouteLang])
  return (
    !isContentEmpty && (
      <Banner
        sessionStorageItemTitle="alertBanner"
        color="white"
        bgColor="#6400AA"
        ariaBtnText={{ it: 'chiudi il banner covid-19', en: 'close the covid-19 banner' }}
      >
        <>
          {title && <h4>{title}</h4>}
          <p
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          {cta && !url && (
            <Link
              className="link outline"
              to={linkResolver({
                contentType: type,
                uid,
                lang,
              })}
            >
              {cta}
            </Link>
          )}
          {cta && url && (
            <a className="link outline" href={url} target="_blank" rel="noopener noreferrer">
              {cta}
            </a>
          )}
        </>
      </Banner>
    )
  )
}

const AlertBanner = inject('rootStore')(
  observer(({ rootStore }) => {
    const {
      navigation: { currentRouteLang },
    } = rootStore
    return <AlertBannerContent currentRouteLang={currentRouteLang} />
  }),
)

export default AlertBanner
