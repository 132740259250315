export const archivioStoricoData = {
  it: {
    data: {
      title: [
        {
          text: 'Archivio storico',
        },
      ],
      description: [
        {
          type: 'paragraph',
          text:
            'L’archivio fotografico include lorem ipsum 1.600 oggetti di design italiano, dal 1927 ad oggi: arredo, complementi, elettronica. La collezione vanta: oltre 300 modelli della collezione Giovanni Sacchi, oltre 3.000 disegni di Alessandro Mendini, oltre 500 artefatti grafici dalla Collezione della Grafica della storia della Triennale di Milano e delle sue 21 edizioni, fondi fotografici che documentano la storia della Triennale di Milano, le sue edizioni e mostre, 60 tra capi e cartamodelli della Collezione Nanni Strada, 1.265 tra libri, materiali, documenti intorno al tema del colore del fondo Clino Trini Castelli Color Library, 566 disegni e progetti grafici di comunicazione dalla Collezione Sirio Galli, materiali grafici relativi al design italiano degli anni Settanta-Novanta.',
          spans: [],
        },
      ],
    },
  },
  en: {
    data: {
      title: [
        {
          text: 'Archivio storico',
        },
      ],
      description: [
        {
          type: 'paragraph',
          text:
            'L’archivio fotografico include lorem ipsum 1.600 oggetti di design italiano, dal 1927 ad oggi: arredo, complementi, elettronica. La collezione vanta: oltre 300 modelli della collezione Giovanni Sacchi, oltre 3.000 disegni di Alessandro Mendini, oltre 500 artefatti grafici dalla Collezione della Grafica della storia della Triennale di Milano e delle sue 21 edizioni, fondi fotografici che documentano la storia della Triennale di Milano, le sue edizioni e mostre, 60 tra capi e cartamodelli della Collezione Nanni Strada, 1.265 tra libri, materiali, documenti intorno al tema del colore del fondo Clino Trini Castelli Color Library, 566 disegni e progetti grafici di comunicazione dalla Collezione Sirio Galli, materiali grafici relativi al design italiano degli anni Settanta-Novanta.',
          spans: [],
        },
      ],
    },
  },
}
