import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './Footer.module.css'
import { filterEmptyPrismicRepeatersContents, parsePrismicHtml } from '../../lib/survive-prismic'
import { linkResolverNormalizer } from '../../lib/link-resolver'

const HOMEPAGE_TYPE = 'homepage'
const CONTAINER_PAGE_TYPE = 'container_page'

@inject('rootStore')
@observer
class Footer extends React.Component {
  get heroCaption() {
    const { cover_image_caption } = this.props.rootStore.currentView.data
    const _cover_image_caption = filterEmptyPrismicRepeatersContents(cover_image_caption, 'text')

    return (
      <>
        {_cover_image_caption?.length > 0 && (
          <div
            className={`verySmallText ${styles.heroCaption}`}
            dangerouslySetInnerHTML={{
              __html: parsePrismicHtml(_cover_image_caption, linkResolverNormalizer),
            }}
          />
        )}
      </>
    )
  }

  render() {
    const { navigation, currentView } = this.props.rootStore
    const { currentRouteLang } = navigation
    const { type } = currentView

    const shouldDisplayHeroCaption = type === HOMEPAGE_TYPE || type === CONTAINER_PAGE_TYPE

    return (
      <footer className={styles.footer}>
        <div className={`${styles.copyright} verySmallText`}>
          {currentRouteLang === 'it' ? (
            <div className={styles.copyrightInfo}>
              © {new Date().getFullYear()} Fondazione La Triennale di Milano - Tutti i Diritti
              Riservati - PIVA 12939180159 - CF 01423890159 - MI 1683641 - viale Emilio Alemagna 6,
              20121 Milano - T{' '}
              <a
                className={`outline`}
                href="tel:+390272434-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                +39 02 72434-1
              </a>{' '}
              <a
                className={`outline`}
                href="mailto:info@triennale.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@triennale.org
              </a>
            </div>
          ) : (
            <div className={styles.copyrightInfo}>
              © {new Date().getFullYear()} Fondazione La Triennale di Milano - Tutti i Diritti
              Riservati - PIVA 12939180159 - CF 01423890159 - MI 1683641 - viale Emilio Alemagna 6,
              20121 Milano - T{' '}
              <a
                className={`outline`}
                href="tel:+390272434-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                +39 02 72434-1
              </a>{' '}
              <a
                className={`outline`}
                href="mailto:info@triennale.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@triennale.org
              </a>
            </div>
          )}
        </div>
        {shouldDisplayHeroCaption && currentView.data ? this.heroCaption : null}
      </footer>
    )
  }
}

export default Footer
