import React from 'react'
import { inject, Observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { HeroSerieContributor } from '../HeroSerieContributor'
import { BtnMagazineHome } from '../BtnMagazineHome'
import { Divider } from '../../../components'
import { MagazineListCards } from '../MagazineListCards'

export const SingleContributor = inject('rootStore')(({ rootStore }) => {
  const {
    magazineAuthor: { results },
  } = rootStore
  const { data } = results[0] || {}
  return (
    <Observer>
      {() => (
        <div>
          <BtnMagazineHome />
          {!isEmpty(data) && <HeroSerieContributor data={data} />}
          <Divider margin={'both'} />
          {!isEmpty(data?.magazines) && (
            <MagazineListCards results={data?.magazines} options={{ typeOfList: 0 }} />
          )}
        </div>
      )}
    </Observer>
  )
})
