import React from 'react'
import isEmpty from 'lodash/isEmpty'
import styles from '../../routes/SingleMagazine.module.css'
import { Description } from '../../components/Shared/Paragraphs'
import { AbstractLinks } from '../../components/SingleEvent/AbstractLinks'

export const ParagraphBasic = ({ items, primary }, index) => {
  const { paragraph_image } = primary
  const hasImage = paragraph_image !== undefined && Object.keys(paragraph_image).length !== 0
  const hasCaption = hasImage && paragraph_image.alt !== null

  return (
    <div
      className={`${styles.contentsWrapper} ${hasImage ? styles.withImage : styles.withoutImage}`}
      key={index}
    >
      {hasImage ? (
        <>
          <div className={styles.paragraphImage}>
            <img src={paragraph_image.url} alt={hasCaption ? paragraph_image.alt : ''} />
            {hasCaption && (
              <figcaption className={`captionText ${styles.paragraphImageCaption}`}>
                {paragraph_image.alt}
              </figcaption>
            )}
          </div>
          <div className={styles.description}>
            <Description description={primary ? primary.paragraph_text : ''} />
            {!isEmpty(items) &&
              items.map((item, i) => (
                <AbstractLinks key={i} links={[item]} linkKey="paragraph_link" />
              ))}
          </div>
        </>
      ) : (
        <div className={styles.description}>
          <Description description={primary ? primary.paragraph_text : ''} />
          {!isEmpty(items) &&
            items.map((item, i) => (
              <AbstractLinks key={i} links={[item]} linkKey="paragraph_link" />
            ))}
        </div>
      )}
    </div>
  )
}
