import React from 'react'
import { observer, inject } from 'mobx-react'
import { TicketShowDate } from '../components/ticketsComponents/TicketShowDate'
import { TicketsPayment } from '../components/ticketsComponents/TicketsPayment'
import { TicketingStepHandler } from '../components/ticketsComponents/TicketingStepHandler'

export const STEPS = [TicketShowDate, TicketsPayment]

@inject('rootStore')
@observer
export class Ticket extends React.Component {
  async setPerformance() {
    const {
      tickets: { setPerformanceAndFetchData, ticketByShowId },
    } = this.props.rootStore
    await setPerformanceAndFetchData(ticketByShowId?.performance[0].idEvento)
  }

  componentDidMount() {
    const { match, rootStore } = this.props
    const { resource } = match.params
    const {
      tickets: { showId, setShowId, setCurrentStep },
    } = rootStore
    if (resource !== showId) {
      setCurrentStep(0)
    }
    setShowId(resource)
    // await fetchScheduledTours()
  }

  componentWillUnmount() {
    const { cart } = this.props.rootStore
    const { clearCart } = cart
    clearCart()
  }

  render() {
    return (
      <>
        <TicketingStepHandler />
      </>
    )
  }
}
