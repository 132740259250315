import orderBy from 'lodash/orderBy'

import {
  getByUids,
  getPaginatedData,
  getFromDateRange,
  searchInText,
} from 'triennale-prismic-api-wrapper/src/fetcher'
import { news as contentTypeId } from 'triennale-prismic-api-wrapper/src/constants/contentTypes'
import { news as fields } from 'triennale-prismic-api-wrapper/src/constants/fields'
import { news as queries } from 'triennale-prismic-api-wrapper/src/queries'

const sortByDate = data => {
  return orderBy(data, e => [e.first_publication_date], ['desc'])
}

const contentType = {
  contentTypeId,
  fields,
  queries,
  sortByDate,
  get: (filters, options, lang, pagination = null) => {
    return getPaginatedData(queries.getWithFilters(filters), lang, pagination)
  },
  getByUids: getByUids(fields),
  getFromDateRange: getFromDateRange(queries),
  searchInText: searchInText(contentTypeId),
}

export { contentType as news }
