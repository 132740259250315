import React from 'react'
import isEmpty from 'lodash/isEmpty'
import styles from '../../routes/SingleMagazine.module.css'
import { ImageShowcase } from '../../components/Shared/ImageShowcase'

export const ImageGallery = ({ items }, index) => {
  const images = items.map(i => ({
    image: i.gallery_image,
    caption: !isEmpty(i.image_captions) ? i.image_captions[0].text : '',
  }))

  return (
    <div className={styles.imagesGallery} key={index}>
      <ImageShowcase images={images} />
    </div>
  )
}
