import React from 'react'

const areValid = (className = '_') => {
  const requiredFields = document.getElementsByClassName(className)
  const isValid = []
  Array.from(requiredFields).forEach(field => {
    const fieldValue = field.type === 'checkbox' ? field.checked : field.value
    !fieldValue ? field.classList.add('validate-error') || isValid.push(false) : isValid.push(true)
  })
  return isValid.indexOf(false) < 0
}

export const withValidation = (formHTML = '_') => {
  window.validateForm = event => {
    const requiredDiv = document.getElementsByClassName('indicates-required')
    const areInputValid = areValid('required')
    const isGDPRComplaint = areValid('gdpr-required')

    if (!areInputValid || !isGDPRComplaint) {
      event.preventDefault()
      requiredDiv[0].style.color = 'red'
    }
  }
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: formHTML,
      }}
    />
  )
}
