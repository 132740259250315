import compact from 'lodash/compact'
import drop from 'lodash/drop'
import { remove } from 'lodash/fp'

import { config } from '../../../app.config'

export const splitPathLang = (path, delimiter = '/') => {
  const split = compact(path.split(delimiter))
  return config.locales[split[0]] ? drop(split) : split
}

export const splitPathOut = (path, delimiter = '/') => (strings = ['_']) => {
  const split = splitPathLang(path, delimiter)
  const havStringInPath = _s => strings.find(string => _s === string)
  return remove(havStringInPath)(split)
}

export const splitPath = (path, delimiter = '/') => {
  return compact(path.split(delimiter))
}

export const isPathContained = (path, string) => {
  return splitPath(path).indexOf(string) >= 0
}
