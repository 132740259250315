import React from 'react'
import styles from './Quotes.module.css'
import { filterEmptyPrismicRepeatersContents, parsePrismicHtml } from '../../lib/survive-prismic'
import { linkResolverNormalizer } from '../../lib/link-resolver'

export class Quotes extends React.Component {
  render() {
    const _quotes = filterEmptyPrismicRepeatersContents(this.props.quotes, 'quote_content[0].text')

    return (
      <>
        {_quotes?.length > 0 &&
          _quotes.map((quote, i) => {
            const { quote_content, quote_author } = quote
            const author = filterEmptyPrismicRepeatersContents(quote_author, 'text')
            return (
              <blockquote className={`${styles.blockquote}`} key={i}>
                <div
                  className={`${styles.blockquoteContent} bodyTextBig bold`}
                  dangerouslySetInnerHTML={{
                    __html: parsePrismicHtml(quote_content, linkResolverNormalizer),
                  }}
                />
                {author?.length > 0 && (
                  <cite className={`${styles.cite} bodyTextBig bold`}>{author[0].text}</cite>
                )}
              </blockquote>
            )
          })}
      </>
    )
  }
}
