import React from 'react'
import styles from './Modal.module.css'

const Modal = ({ show = false, children }) => {
  const showHideClassName = show ? styles.modalShow : styles.modalHidden
  return (
    <div className={showHideClassName}>
      <div className={styles.modal}>{children}</div>
      <div className={styles.overlay} />
    </div>
  )
}

export default Modal
