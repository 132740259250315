import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'

import { getPaginatedData, searchInText } from 'triennale-prismic-api-wrapper/src/fetcher'
import { magazineSeries as contentTypeId } from 'triennale-prismic-api-wrapper/src/constants/contentTypes'
import { magazineSeries as fields } from 'triennale-prismic-api-wrapper/src/constants/fields'
import { magazineSeries as queries } from 'triennale-prismic-api-wrapper/src/queries'
import { sortByDate as sortMagazinesByDate } from 'triennale-prismic-api-wrapper/src/contentTypes/magazine'
import { getMagazineSeriesByUids } from 'triennale-prismic-api-wrapper/src/fetchers/magazinesFetcher'

const sortByName = data => sortBy(data, author => author.data.name[0].text)

const sortByDate = data => {
  return orderBy(data, e => [e.first_publication_date], ['desc'])
}

const contentType = {
  contentTypeId,
  fields,
  queries,
  sortByDate,
  get: (filters, options, lang, pagination = null) => {
    return getPaginatedData(queries.getWithFilters(filters), lang, pagination, sortByName)
  },
  getByUids: getMagazineSeriesByUids(fields, sortMagazinesByDate),
  searchInText: searchInText(contentTypeId),
}

export { contentType as magazineSeries }
