import { types as t, flow, applySnapshot, getParent, getRoot } from 'mobx-state-tree'
import qs from 'query-string'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import remove from 'lodash/remove'
import { searchArchiveQueryHandler } from '../lib/filters-utils'
import { handlerUpdateURL } from '../lib/routes-utils'
import {
  ARCHIVE_API_URL,
  ARCHIVE_API_LIMIT,
  ARCHIVE_PERMANENT_COLLECTION,
  ARCHIVE_HISTORICAL_KEY,
  ARCHIVE_FILTERS_TYPES,
} from '../lib/const'
import { isObjectNull } from '../lib/utils'

const isArchiveSearchUrl = path =>
  path.indexOf('risultati-archivio') > -1 || path.indexOf('archive-results') > -1

const convertQuery = query => {
  return Object.keys(query).reduce((q, key) => {
    if (key === 'timerange') {
      q[key] =
        typeof query[key] === 'string' ? query[key].split(',').map(p => parseInt(p)) : query[key]
    } else if (key === 'archiveId') {
      q[key] = query[key]
    } else q[key] = typeof query[key] === 'string' ? query[key].split(',') : query[key]
    return q
  }, {})
}

const QueryModel = t.model({
  expoId: t.array(t.string),
  freeText: t.array(t.string),
  archives: t.array(t.string),
  timerange: t.array(t.number),
  title: t.array(t.string),
  author: t.array(t.string),
  year: t.array(t.string),
  company: t.array(t.string),
  isPresentInMuseum: false,
})

const resultsDecorator = (results, ctx) => {
  const { filteredArchives } = getRoot(ctx)
  const archives = filteredArchives.map(
    ({ collection_filter_archives_slug }) => collection_filter_archives_slug,
  )

  const remapArchiveSlug = k => {
    if (archives.includes[k]) {
      return ARCHIVE_PERMANENT_COLLECTION
    }
    if (k === 'archimista-triennale') return ARCHIVE_HISTORICAL_KEY
    return k
  }

  const addArchive = (data, k) =>
    data.map(d => ({
      ...d,
      archive: remapArchiveSlug(k),
    }))

  // This order was decided by the Triennale team.
  // The contents of archivio-storico must be displayed last,
  // since they don't have images
  const sortByDisplayOrder = data => {
    const keysToPutLast = ['archimista-triennale']
    return [...remove(Object.keys(data), item => !keysToPutLast.includes(item)), ...keysToPutLast]
  }

  return sortByDisplayOrder(results).reduce((acc, key) => {
    if (!isEmpty(results[key])) {
      if (key === 'archimista-triennale') acc.push(...addArchive(results[key], key))
      else {
        acc.unshift(...addArchive(results[key], key))
      }
    }
    return acc
  }, [])
}

export const ArchiveModel = t
  .model({
    archiveId: t.optional(t.string, ''),
    query: t.optional(QueryModel, {}),
    pathString: t.optional(t.string, ''),
    page: 1,
    data: t.optional(t.array(t.frozen()), []),
    state: 'loading',
    tempKeyword: '',
    tempSearchKey: t.optional(
      t.enumeration('SearchKey', ['freeText', 'title', 'author', 'year', 'company']),
      'freeText',
    ),
  })
  .actions(self => ({
    setTempKeyword(text) {
      self.tempKeyword = text
    },
    resetTempKeyword() {
      self.tempKeyword = ''
    },
    setTempSearchKey(newSearchKey) {
      self.tempSearchKey = newSearchKey
    },
    resetTempSearchKey() {
      self.tempSearchKey = 'freeText'
    },
  }))
  .views(self => ({
    get querySelected() {
      return Object.keys(self.query).reduce((selected, k) => {
        if (
          !isEmpty(self.query[k]) ||
          (ARCHIVE_FILTERS_TYPES[k] &&
            ARCHIVE_FILTERS_TYPES[k].type === 'checkbox' &&
            self.query[k])
        ) {
          selected[k] = self.query[k]
        }
        return selected
      }, {})
    },
    get queryPath() {
      return qs.stringify({
        ...self.querySelected,
        archiveId: self.archiveId,
      })
    },
    get isQueryEmpty() {
      return isObjectNull(self.query.toJSON())
    },
  }))
  .actions(self => ({
    setArchive(archiveId) {
      self.archiveId = archiveId
    },
    setPage(current) {
      self.page = current
    },
    resetPage() {
      self.page = 1
    },
    resetQuery() {
      if (!self.isQueryEmpty()) {
        self.query = QueryModel.create()
        return true
      } else return false
    },
  }))
  .actions(self => ({
    // prettier-ignore
    fetchFromArchive: flow(function * fetchFromArchive(apiUrl) {
      try {
        const { data } = yield axios.get(`${ARCHIVE_API_URL}/${apiUrl}`, {
          params: { resultsPerPage: ARCHIVE_API_LIMIT },
        })
        if (!isEmpty(data)) {
          const newData = Array.isArray(data) ? data : resultsDecorator(data, self)
          self.data = self.page > 1 ? [...self.data, ...newData] : newData
        }
        self.state = 'rest'
        return data
      } catch (error) {
        console.log(error)
        self.state = 'error'
      }
    }),
    async getDataResult() {
      const { archiveId, query, page } = self
      const { filteredArchivesSlugs } = getRoot(self)

      const api = searchArchiveQueryHandler(archiveId, query, page, self, filteredArchivesSlugs)
      if (page === 1) self.data = []
      self.state = 'loading'
      await self.fetchFromArchive(api)
    },
    setSearchArchiveUrl(archiveId, possibleQuery) {
      const { currentRouteLang } = getRoot(self).navigation
      self.archiveId = archiveId
      const previousSearch = self.queryPath

      const query = { ...possibleQuery }

      const { tempSearchKey, tempKeyword } = self
      const freeTextField = Object.keys(query).find(p =>
        ['freeText', 'title', 'author', 'year', 'company'].includes(p),
      )

      if (freeTextField) {
        if (freeTextField !== tempSearchKey) {
          delete query[freeTextField]
          query[tempSearchKey] = [tempKeyword]
        } else if (query[freeTextField] !== tempKeyword) {
          query[freeTextField] = [tempKeyword]
        }
      }

      const isEmptyQuery = Object.keys(query).every(k => {
        if (Array.isArray(query[k]) && query[k].length === 0) return true
        return false
      })

      applySnapshot(self.query, query)
      isEmptyQuery ? (self.data = []) : self.getDataResult()

      handlerUpdateURL(
        getParent(self),
        {
          pathname: currentRouteLang === 'it' ? 'risultati-archivio' : 'en/archive-results',
          search: self.queryPath,
        },
        previousSearch,
      )
    },
    setSearchArchiveFromPathUrl({ pathname, search }) {
      if (isArchiveSearchUrl(pathname)) {
        const { archiveId, ...queryParams } = convertQuery(
          qs.parse(search, { parseBooleans: true }),
        )
        applySnapshot(self.query, queryParams)
        self.setArchive(archiveId)
      } else {
        applySnapshot(self.query, {})
      }
    },
  }))
  .views(self => ({
    get result() {
      const { activeItemId } = getRoot(self).ui.archiveLightbox

      if (!activeItemId || !self.data.length) {
        return null
      }

      const current = self.data.find(r => r.id === activeItemId)
      if (!current) return null

      const index = self.data.findIndex(r => r.id === activeItemId)
      let next, prev

      if (self.data.length > index + 1) {
        next = self.data[index + 1]
      }

      if (index > 0) {
        prev = self.data[index - 1]
      }

      return { current, next, prev }
    },
  }))
