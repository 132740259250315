import { types as t } from 'mobx-state-tree'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'

import { CustomerModel } from './cart-customer'
import { TICKET_TRANSACTION_KEY, TICKET_ORDER_KEY } from '../lib/const'

export const APP_STATE = {
  REST: 'rest',
  LOADING: 'loading',
  ERROR: 'error',
}

export const SeatModel = t
  .model('SeatModel', {
    zoneId: t.string,
    reductionId: t.string,
    amount: 0,
    relatives: '0',
  })
  .actions(self => ({
    add(amount) {
      self.amount += amount
    },
    subtract(amount) {
      if (self.amount - amount >= 0) {
        self.amount -= amount
      }
    },
  }))

export const CartModel = t
  .model('CartModel', {
    showId: t.maybe(t.string),
    date: t.maybe(t.string),
    time: t.maybe(t.string),
    seats: t.optional(t.array(SeatModel), []),
    transactions: t.optional(t.array(t.frozen()), []),
    transactionState: t.optional(
      t.enumeration('TransactionState', Object.values(APP_STATE)),
      'loading',
    ),
    customer: t.optional(CustomerModel, {}),
  })
  .actions(self => ({
    setShowId(showId) {
      self.showId = showId
    },
    resetShowId() {
      self.showId = undefined
    },
    setDate(date) {
      self.date = date
    },
    resetDate() {
      self.date = undefined
    },
    setTime(time) {
      self.time = time
    },
    resetTime() {
      self.time = undefined
    },

    clearSeats() {
      self.seats.replace([])
    },
    addSeat(zoneId, reductionId) {
      const match = self.seats.find(
        seat => seat.zoneId === zoneId && seat.reductionId === reductionId,
      )
      if (match) {
        // add seat
        self.seats.map(seat => {
          if (seat.zoneId === zoneId && seat.reductionId === reductionId) {
            seat.add(1)
          }
          return seat
        })
      } else {
        // push
        self.seats.push({ zoneId, reductionId, amount: 1 })
      }
    },
    subtractSeat(zoneId, reductionId) {
      isEmpty(self.seats.toJSON()) && self.removeSeat(zoneId, reductionId)
      self.seats.map(seat => {
        if (seat.zoneId === zoneId && seat.reductionId === reductionId) {
          if (seat.amount === 1) {
            self.removeSeat(zoneId, reductionId)
          } else {
            seat.subtract(1)
          }
        }
      })
    },
    removeSeat(zoneId, reductionId) {
      self.seats = self.seats.filter(
        seat => !(seat.zoneId === zoneId && seat.reductionId === reductionId),
      )
    },
    setSeats(seats) {
      self.seats = seats
    },
    clearCart() {
      self.resetShowId()
      self.resetDate()
      self.resetTime()
    },
    updateRelatives(zoneId, value) {
      self.seats.replace(
        self.seats.reduce((acc, seat) => {
          acc.push(seat.zoneId === zoneId ? { ...seat, relatives: value } : seat)
          return acc
        }, []),
      )
    },
    updateTransactionsAndSeats(transactionId, seats) {
      const { seat } = seats
      const transaction = {
        ...self.getTransactionById(transactionId),
        seats: seat,
        amount: '0',
      }
      self.setTransactions([transaction])
    },
    setTransactions(transactions) {
      self.transactions = transactions
      window.localStorage.setItem(
        TICKET_TRANSACTION_KEY,
        JSON.stringify({
          transactions,
          showId: self.showId,
          date: self.date,
          time: self.time,
        }),
      )
    },
    resetTransactions() {
      self.transactions = []
      self.clearSeats()
      window.localStorage.removeItem(TICKET_TRANSACTION_KEY)
      window.localStorage.removeItem(TICKET_ORDER_KEY)
    },
    setTransactionState(state) {
      self.transactionState = state
    },
    resetTransactionState() {
      self.transactionState = APP_STATE.LOADING
    },
  }))
  .views(self => {
    const getSeat = (zoneId, reductionId) => {
      return self.seats.find(seat => seat.zoneId === zoneId && seat.reductionId === reductionId)
    }

    const getSeatsAmount = (zoneId, reductionId) => {
      const match = getSeat(zoneId, reductionId)
      return match ? match.amount : 0
    }

    const getSeatsAmountPerZone = (zoneId, reductions) => {
      return reductions.reduce((acc, { id }) => {
        const seat = getSeat(zoneId, id)
        return acc + (seat ? seat.amount : 0)
      }, 0)
    }
    const getTransactionById = transactionId => {
      return self.transactions.find(r => r.transaction_code === transactionId)
    }
    const getBestSeatParams = zoneId => {
      const seats = self.seats.filter(s => s.zoneId === zoneId)
      const reductions = seats.map(seat => seat.reductionId)
      const nTickets = seats.map(seat => seat.amount)
      const relatives = head(seats.map(seat => seat.relatives))
      return {
        zone: zoneId,
        nTickets: nTickets.join(','),
        reduction: reductions.join(','),
        relatives,
      }
    }
    return {
      getSeatsAmount,
      getSeatsAmountPerZone,
      getTransactionById,
      getBestSeatParams,
    }
  })
  .views(self => ({
    haveMultipleSeatForZone: zoneId => {
      const seats = self.seats.filter(s => s.zoneId === zoneId)
      return (
        seats.reduce((sum, { amount }) => {
          return amount + sum
        }, 0) > 1
      )
    },
  }))
  .views(self => ({
    get orders() {
      const { transactions } = self
      const { seats, amount } = transactions.reduce(
        (acc, next) => {
          acc.seats += next.seats.length
          acc.amount += Number(next.amount)
          return acc
        },
        { seats: 0, amount: 0 },
      )
      const transactionsCodes = transactions
        .map(transaction => transaction.transaction_code)
        .join(',')
      // return `transactionCode=${transactionsCodes}&tNumSeats=${seats.toString()}&tAmount=${amount.toString()}`
      return {
        transactionCode: transactionsCodes,
        tNumSeats: seats,
        tAmount: amount,
      }
    },
    get seatsGroupedByZoneId() {
      return groupBy(self.seats, 'zoneId')
    },
  }))
