import React from 'react'
import styles from './SingleMagazine.module.css'
import { withIframesResizer } from '../components/Shared/withIframesResizer'
import { MediaSectionExpandable } from '../components/Shared/MediaSection'
import { ParagraphBasic } from '../components/Magazine/ParagraphBasic'
import { Quote } from '../components/Magazine/Quote'
import { EmbedMedia } from '../components/Magazine/EmbedMedia'
import { Image } from '../components/Magazine/Image'
import { ImageGallery } from '../components/Magazine/ImageGallery'
import { CreditsBlock } from '../components/Magazine/CreditsBlock'
import { SingleMagazineLayout } from '../components/Magazine/SingleMagazineLayout'

const SwitcherContent = ({ type, data, index }) => {
  const TEMPLATE_COMPONENTS = {
    paragraph_basic: ParagraphBasic,
    embed_media: EmbedMedia,
    image_single: Image,
    image_gallery: ImageGallery,
    credits: CreditsBlock,
    quote: Quote,
    gallery_expandible: data => (
      <div className={styles.contentsWrapper}>
        <MediaSectionExpandable type={type} media={data?.items} contentType="gallery_expandible" />
      </div>
    ),
  }
  return TEMPLATE_COMPONENTS[type] ? TEMPLATE_COMPONENTS[type](data, index) : null
}

export const Content = ({ data }) => {
  return (
    <>
      {data.map((d, i) => {
        return <SwitcherContent key={i} type={d.slice_type} data={d} index={i} />
      })}
    </>
  )
}

export const SingleMagazine = withIframesResizer(SingleMagazineLayout, 730)
