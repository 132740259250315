import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './ArchivePagesBlock.module.css'
import { Link } from '../../components'
import { linkResolver } from '../../lib/link-resolver'
import { translator } from '../../lib/translator'

const ArchivePagesBlockItem = inject('rootStore')(
  observer(props => {
    const {
      rootStore: {
        navigation: { currentRouteLang },
      },
      page: {
        id,
        uid,
        data: { title },
      },
      counter,
    } = props

    // Temporarily removed archivio-storico Link
    if (id === 'archivio-storico') {
      return null
    }
    return (
      <article className={styles.article}>
        <Link
          className={`outline ${styles.link}`}
          to={linkResolver({
            contentType: 'archive',
            uid,
            lang: currentRouteLang,
          })}
        >
          <h1
            className={`${styles.title} letterSpaced uppercase`}
            dangerouslySetInnerHTML={{ __html: title[0].text }}
          />
          <p className={`${styles.content} letterSpaced uppercase bold`}>
            {counter} {translator('contentsLabel')(currentRouteLang)}
          </p>
        </Link>
      </article>
    )
  }),
)

export default ArchivePagesBlockItem
