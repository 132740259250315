import React from 'react'
import { inject, observer } from 'mobx-react'
import { translator } from '../lib/translator'
import { PageTitlePlain } from '../components/Shared/Titles'
import { SearchBox, ArchiveDisclaimer } from '../components'
import { TABS_LABELS } from '../lib/const'
import { ArchiveResults } from '../components/archiveComponents/ArchiveResults'

export const ArchiveSearchResults = inject('rootStore')(
  observer(props => {
    const {
      rootStore: {
        archive: { archiveId },
        navigation: { currentRouteLang },
      },
    } = props

    return (
      <div>
        <PageTitlePlain
          title={`${translator('archiveSearchResultsTitle')(currentRouteLang)}: ${
            TABS_LABELS[currentRouteLang][archiveId]
          }`}
        />
        <SearchBox archiveId={archiveId} />
        <ArchiveResults />
        <ArchiveDisclaimer />
      </div>
    )
  }),
)
