import React from 'react'
import { inject, Observer } from 'mobx-react'
import styles from './ContentFiltersBlock.module.css'
import {
  getTranslatedFilter,
  buildToggleFilter,
  allEmptyFilters,
  getFilterColor,
  getSubCategories,
} from '../../../lib/filters-utils'
import { NotClickableCategory } from './NotClickableCategory'
import { WithOnlineLabelFilters } from './WithOnlineLabelFilters'

export const ContentFiltersBlock = inject('rootStore')(
  ({
    type,
    audiences,
    special,
    fields,
    languages,
    entrance,
    areFiltersClickable,
    currentLang,
    contentType,
    isOnlineEvent,
  }) => {
    const filters = getSubCategories({
      type,
      audiences,
      special,
      fields,
      languages,
      entrance,
    })
    const allEmpty = allEmptyFilters(filters)

    return (
      <Observer>
        {() => (
          <>
            {!allEmpty && (
              <object className={styles.filtersWrapper}>
                {Object.keys(filters).map((key, i) => {
                  return filters[key].map(filterValue => {
                    if (filterValue === null) {
                      return null
                    }

                    return areFiltersClickable ? (
                      <a
                        className={`outline bold ${styles.filterLink} ${getFilterColor({
                          key,
                          value: filterValue,
                          type: contentType,
                        })}`}
                        href="#0"
                        key={filterValue}
                        onClick={buildToggleFilter({
                          contentType,
                          filterType: key,
                          filterValue,
                          lang: currentLang,
                        })}
                      >
                        <WithOnlineLabelFilters
                          isOnlineEvent={isOnlineEvent}
                          currentLang={currentLang}
                        >
                          <span>
                            {currentLang !== 'it'
                              ? getTranslatedFilter(key, filterValue, contentType)
                              : filterValue}
                          </span>
                        </WithOnlineLabelFilters>
                      </a>
                    ) : (
                      <NotClickableCategory
                        key={filterValue}
                        currentLang={currentLang}
                        isOnlineEvent={isOnlineEvent}
                        label={
                          currentLang !== 'it'
                            ? getTranslatedFilter(key, filterValue, contentType)
                            : filterValue
                        }
                        color={getFilterColor({
                          key,
                          value: filterValue,
                          type: contentType,
                        })}
                      />
                    )
                  })
                })}
              </object>
            )}
          </>
        )}
      </Observer>
    )
  },
)
