import orderBy from 'lodash/orderBy'
import { getFromDateRange, searchInText } from 'triennale-prismic-api-wrapper/src/fetcher'
import {
  getMagazines,
  getFocusMagazines,
  getNonFocusMagazines,
  getMagazinesByUids,
  getMagazinesRelatedContents,
  getMagazinesByAuthorUid,
  getMagazinesBySeriesUid,
} from 'triennale-prismic-api-wrapper/src/fetchers/magazinesFetcher'
import { magazine as contentTypeId } from 'triennale-prismic-api-wrapper/src/constants/contentTypes'
import { magazine as fields } from 'triennale-prismic-api-wrapper/src/constants/fields'
import { magazine as queries } from 'triennale-prismic-api-wrapper/src/queries'

const sortByDate = data => {
  return orderBy(data, e => [e.first_publication_date], ['desc'])
}

const contentType = {
  contentTypeId,
  fields,
  queries,
  sortByDate,
  get: getMagazines,
  getFocus: getFocusMagazines,
  getNonFocus: getNonFocusMagazines,
  getByUids: async (uids, lang) => {
    let magazines = await getMagazinesByUids(uids, lang)
    magazines = await getMagazinesRelatedContents(magazines, lang)
    return magazines
  },
  getFromDateRange: getFromDateRange(queries),
  searchInText: searchInText(contentTypeId),
  getByAuthorId: getMagazinesByAuthorUid(sortByDate),
  getBySeriesId: getMagazinesBySeriesUid(sortByDate),
}

export { contentType as magazine }
