import React from 'react'
import { inject, observer } from 'mobx-react'
import noop from 'lodash/noop'
import { Link } from '../../components'
import styles from './SingleEventPreviewLayout.module.css'
import { ResponsiveImage } from '../Shared/Templating'
import { ContentFiltersBlock } from '../Shared/ContentFilters/ContentFiltersBlock'
import { linkResolver } from '../../lib/link-resolver'
import { computeEventDates } from '../../lib/date-utils'
import { EventTitles } from '../Shared/EventTitles'
import { EventLocation } from '../SingleEvent/EventLocation'

function setHover(e, ctx) {
  if (!e.target || (e.target && !Array.from(e.target.classList).includes('location'))) {
    ctx.setState({ hovered: true })
  }
}

function unsetHover(ctx) {
  ctx.setState({ hovered: false })
}

@inject('rootStore')
@observer
export class SingleEventPreviewLayoutSearchResults extends React.Component {
  state = {
    hovered: false,
  }

  buildSetHover = e => setHover(e, this)
  buildUnsetHover = _ => unsetHover(this)

  render() {
    const { rootStore, event } = this.props
    const { uid, data } = event
    const {
      start_date,
      end_date,
      type,
      title,
      cover_image,
      author,
      specific_dates,
      container_page,
      affiliation,
      location,
      location_map_link,
      event_online,
      only_month,
    } = data
    const {
      navigation: { currentRouteLang },
      ui: { labelsColor },
    } = rootStore
    const { dateString } = computeEventDates(
      start_date,
      end_date,
      specific_dates,
      currentRouteLang,
      only_month,
    )
    const { hovered } = this.state

    return (
      <article className={styles.searchResultsEvent}>
        <Link
          onMouseOver={this.buildSetHover}
          onMouseOut={this.buildUnsetHover}
          onFocus={noop}
          onBlur={noop}
          to={linkResolver({ contentType: 'event', lang: currentRouteLang, uid })}
          className={`outline ${styles.searchResultsEventLink} ${hovered ? styles.hovered : ''}`}
        >
          <div className={styles.searchResultsEventCover}>
            {cover_image.url && (
              <div className={styles.searchResultsEventCoverInner}>
                <ResponsiveImage img={cover_image} additionalClassName={styles.img} />
              </div>
            )}
          </div>

          <div className={styles.searchResultsEventHeader}>
            <div className={styles.searchResultsEventHeaderInner}>
              {container_page && container_page.title_short !== null && (
                <p className={`smallText ${styles.searchResultsEventContainerTitle}`}>
                  {container_page.title_short}
                </p>
              )}
              {affiliation !== null && (
                <p className={`smallTitle ${styles.searchResultsEventAffiliation}`}>
                  {affiliation}
                </p>
              )}
              <EventTitles author={author} title={title} titleClass="smallText bold left" />
              <ContentFiltersBlock
                areFiltersClickable={false}
                type={type}
                currentLang={currentRouteLang}
                contentType={'events'}
                isOnlineEvent={event_online}
              />
              {dateString && (
                <div
                  className={`smallText ${styles.searchResultsEventDates} bold`}
                  style={{ color: labelsColor }}
                >
                  {dateString}
                </div>
              )}
              <EventLocation location={location} link={location_map_link} />
            </div>
          </div>
        </Link>
      </article>
    )
  }
}
