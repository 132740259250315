import React from 'react'
import { IframeWithFallback } from '../../components/Shared/IframeWithFallback'
import { withIframesResizer } from '../../components/Shared/withIframesResizer'

const WrappedVideoLayout = ({ forwardedRef, type, uid, html }) => {
  return (
    <div ref={forwardedRef}>
      <IframeWithFallback type={type} autoplay={false} uid={uid} html={html} />
    </div>
  )
}

export const WrappedVideo = withIframesResizer(WrappedVideoLayout)
