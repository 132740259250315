import React from 'react'
import { inject, Observer } from 'mobx-react'
import styles from './RegularEvent.module.css'
import { parsePrismicHtml } from '../../lib/survive-prismic'
import { EventTitles } from '../../components/Shared/EventTitles'
import { EventLocation } from '../../components/SingleEvent/EventLocation'
import { linkResolverNormalizer } from '../../lib/link-resolver'
import { CTALinks } from './CTALinks'

const StickyHeroTextTitle = inject('rootStore')(
  ({ rootStore, dateString, event, information, additional_info }) => {
    const {
      navigation: { currentRouteLang },
      ui: { labelsColor },
    } = rootStore
    const {
      data,
      data: { title, author, location, location_map_link },
    } = event
    return (
      <Observer>
        {() => (
          <>
            <EventTitles author={author} title={title} titleClass="pageTitle" />
            <div className={styles.info}>
              {dateString && (
                <div className={`smallText ${styles.dates} bold`} style={{ color: labelsColor }}>
                  {dateString}
                </div>
              )}
            </div>
            {additional_info && (
              <div className={styles.info}>
                <div className={`smallText ${styles.dates} bold`} style={{ color: labelsColor }}>
                  {additional_info}
                </div>
              </div>
            )}
            <EventLocation location={location} link={location_map_link} />
            <CTALinks lang={currentRouteLang} eventData={data} />
            <div
              dangerouslySetInnerHTML={{
                __html: parsePrismicHtml(information, linkResolverNormalizer),
              }}
              className={`${styles.infoText} smallText bold`}
            />
          </>
        )}
      </Observer>
    )
  },
)

export default StickyHeroTextTitle
