import React from 'react'
import { inject, Observer } from 'mobx-react'
import styles from './SpecialCommissionEvent.module.css'
import { Descriptions } from '../../components/SingleEvent/Descriptions'
import { Credits } from '../../components/SingleEvent/Credits'
import { Quotes } from '../../components/SingleEvent/Quotes'
import { AbstractLinks } from '../../components/SingleEvent/AbstractLinks'
import { DescriptionVideos } from '../../components/SingleEvent/DescriptionVideos'

export const SpecialCommissionEventAbstract = inject('rootStore')(
  ({ rootStore, dateString, event }) => {
    const {
      ui: { labelsColor },
    } = rootStore
    const {
      data: {
        location,
        location_map_link,
        credits_others,
        credits_international_participation,
        description,
        description_part2,
        description_links,
        description_videos,
        quotes,
      },
    } = event
    return (
      <Observer>
        {() => (
          <div className={styles.abstract}>
            {dateString && (
              <div
                className={`smallText ${styles.dates} bold`}
                style={{ color: labelsColor, fontSize: '30px', lineHeight: '32px' }}
              >
                {dateString}
              </div>
            )}
            {location !== null && (
              <p className={`eventLocation ${styles.location}`}>
                {location_map_link.url ? (
                  <a
                    className={`link smallText ${styles.locationLink}`}
                    href={location_map_link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {location}
                  </a>
                ) : (
                  <>{location}</>
                )}
              </p>
            )}

            <Quotes quotes={quotes} />
            <Descriptions description={description} description_part2={description_part2} />
            <Credits credits={credits_others} credits_part2={credits_international_participation} />
            <div className={styles.abstractLinksWrapper}>
              <AbstractLinks links={description_links} linkKey="description_link" />
            </div>
            <div className={styles.descriptionVideosWrapper}>
              <DescriptionVideos videos={description_videos} />
            </div>
          </div>
        )}
      </Observer>
    )
  },
)
