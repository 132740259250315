import React from 'react'

// Components
import { PromotionsCard } from '../Shared/Cards'
import { SingleEventPreviewLayoutSmall } from '../Ongoing/SingleEventPreviewLayoutSmall'
import { SectionTitlePlain } from '../Shared/Titles'

// Libs
import { translator } from '../../lib/translator'

// Styles
import styles from './ParagraphEvent.module.css'

export const ParagraphEvent = ({ currentEvents, currentPromotions, currentRouteLang }) => {
  return (
    <>
      {currentEvents?.length > 0 && (
        <div className={styles.eventsWrapper}>
          <div className={styles.eventsWrapperInner}>
            <SectionTitlePlain
              title={translator('plannedEvents')(currentRouteLang)}
              border={false}
            />
            <div className={styles.eventsGrid}>
              {currentEvents.map((event, j) => (
                <SingleEventPreviewLayoutSmall event={event} key={j} />
              ))}
            </div>
          </div>
        </div>
      )}
      {currentPromotions?.length > 0 && (
        <div className={styles.promotionGrid}>
          <div className={styles.promotionGridInner}>
            <SectionTitlePlain
              title={translator('plannedPromotions')(currentRouteLang)}
              border={false}
            />
            <PromotionsCard results={currentPromotions} options={{ lang: currentRouteLang }} />
          </div>
        </div>
      )}
    </>
  )
}
