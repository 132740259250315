import React from 'react'
import { inject, observer } from 'mobx-react'
import { translator } from '../../lib/translator'
import styles from '../../routes/ArchiveSearchResults.module.css'
import { LoadMore, LoaderOval } from '../layoutComponents'
import { ArchiveLayoutCard, ArchiveLightbox } from '../archiveComponents'
import { ARCHIVE_API_LIMIT } from '../../lib/const'

export const ArchiveResults = inject('rootStore')(
  observer(props => {
    const {
      rootStore: {
        archive: { setPage, page, getDataResult, state, data },
        ui: { archiveLightbox },
        navigation: { currentRouteLang },
      },
    } = props

    const buildOpenLightbox = id => e => {
      e.preventDefault()
      const { setActiveItemId } = archiveLightbox
      setActiveItemId(id)
    }

    const pageHandler = async callback => {
      setPage(page + 1)
      await getDataResult()
    }

    if (data.length > 0) {
      return (
        <div>
          <ul className={`${styles.grid}`}>
            {data.map((d, i) => {
              return ArchiveLayoutCard(d, i, buildOpenLightbox, currentRouteLang)
            })}
          </ul>

          <div>
            {state === 'rest' ? (
              !data.length < ARCHIVE_API_LIMIT * page && <LoadMore onClick={pageHandler} />
            ) : state === 'loading' ? (
              <LoaderOval isCompact={true} />
            ) : null}
          </div>
          <ArchiveLightbox
            resultsArr={data}
            updateFn={pageHandler}
            loadState={state}
            isLoadMorePossible={data.length >= ARCHIVE_API_LIMIT}
          />
        </div>
      )
    }

    if (state === 'loading') {
      return <LoaderOval isCompact={true} />
    }

    return (
      <div className={styles.noResults}>{translator('noResultsMessage')(currentRouteLang)}</div>
    )
  }),
)
