import React from 'react'
import { inject, Observer } from 'mobx-react'
import styles from './HamburgerToggle.module.css'

const HamburgerToggle = inject('rootStore')(({ rootStore }) => {
  const {
    navigation: { currentRouteLang },
    ui: { toggleMainNav },
  } = rootStore

  return (
    <Observer>
      {() => (
        <button
          className={styles.hamburger}
          onClick={toggleMainNav}
          aria-label={currentRouteLang === 'it' ? 'Bottone del menù principale' : 'Menu toggle'}
        >
          <span className={styles.line} />
        </button>
      )}
    </Observer>
  )
})

export default HamburgerToggle
