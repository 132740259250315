import React from 'react'
import { inject, observer } from 'mobx-react'
import sampleSize from 'lodash/sampleSize'
import axios from 'axios'
import { SectionTitlePlainWithLink } from './Titles'
import { translator } from '../../lib/translator'
import styles from './ArchivePreviewSection.module.css'
import { ImageShowcase, ImageShowcaseArchive } from './ImageShowcase'
import { archivePreviewData } from '../../lib/fake-data/archive-preview'
import { ARCHIVE_API_URL } from '../../lib/const'
import { linkResolver } from '../../lib/link-resolver'

const { image_showcase_images } = archivePreviewData
const fourItems = sampleSize(image_showcase_images, 4)

export const AllArchivesPreviewSection = inject('rootStore')(({ rootStore }) => {
  const { currentRouteLang } = rootStore.navigation
  return (
    <section className={styles.section}>
      <SectionTitlePlainWithLink
        title={translator('allArchivesPreviewSection')(currentRouteLang)}
        cta={translator('ctaArchivesSection')(currentRouteLang)}
        border={true}
        link={{
          href: linkResolver({
            contentType: 'archive',
            lang: currentRouteLang,
          }),
          title: translator('allarchivesPreviewLinkTitle')(currentRouteLang),
        }}
      />
      <ImageShowcase images={fourItems} />
    </section>
  )
})

@inject('rootStore')
@observer
export class CollectionArchivesPreviewSection extends React.Component {
  state = {
    previewItems: [],
  }

  async componentDidMount() {
    const { data } = await axios.get(
      `${ARCHIVE_API_URL}/results?archiveId=permanente&random&resultsPerPage=4`,
    )
    this.setState({
      previewItems: data.map(({ title, pictureFileUrl, authors, company, projectYear }) => ({
        title,
        src: pictureFileUrl,
        authors,
        company,
        projectYear,
      })),
    })
  }

  render() {
    const { shouldHideLink, rootStore } = this.props
    const { currentRouteLang } = rootStore.navigation

    const link = !shouldHideLink
      ? {
          href:
            currentRouteLang === 'it'
              ? `/risultati-archivio?archiveId=collezioni&archives=permanente`
              : `/en/archive-results/?archiveId=collezioni&archives=permanente`,
          title: translator('collectionsArchivePreviewLinkTitle')(currentRouteLang),
        }
      : {}
    return (
      <div className={styles.ArchivePreviewWrapper}>
        <SectionTitlePlainWithLink
          title={translator('collectionsArchivePreviewSection')(currentRouteLang)}
          cta={translator('ctaArchivesSection')(currentRouteLang)}
          border={true}
          link={link}
        />
        <ImageShowcaseArchive data={this.state.previewItems} />
      </div>
    )
  }
}
