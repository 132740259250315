import React from 'react'
import isEmpty from 'lodash/isEmpty'

// Libs
import { filterEmptyPrismicRepeatersContents } from '../../lib/survive-prismic'

// Components
import { IframeWrapper } from '../../components/Shared/IframeWrapper'
import { SectionTitle } from '../../components/Shared/Titles'

// Style
import styles from './EmbedMedia.module.css'

export const EmbedMedia = (
  { primary: { embed_media_title, embed_media_caption, embed_media_html } },
  index,
) => {
  const _embed_media_title = filterEmptyPrismicRepeatersContents(embed_media_title, 'text')
  const _embed_media_caption = filterEmptyPrismicRepeatersContents(embed_media_caption, 'text')
  const _embed_media_html = filterEmptyPrismicRepeatersContents(embed_media_html, 'text')
  const hasTitle = !isEmpty(_embed_media_title)

  return (
    <section
      className={`${styles.contentsWrapper} ${styles.embedMediaContentsWrapper}`}
      key={index}
    >
      <div className={`${styles.sectionInner} ${hasTitle ? '' : styles.sectionWithoutTitle}`}>
        {hasTitle && <SectionTitle title={_embed_media_title} border={false} />}
        <IframeWrapper html={_embed_media_html} caption={_embed_media_caption} />
      </div>
    </section>
  )
}
