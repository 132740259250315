import React from 'react'
import isEmpty from 'lodash/isEmpty'
import styles from '../../routes/SingleMagazine.module.css'
import { filterEmptyPrismicRepeatersContents, parsePrismicText } from '../../lib/survive-prismic'
import { IframeWrapper } from '../../components/Shared/IframeWrapper'

export const EmbedMedia = (
  { primary: { embed_media_title, embed_media_caption, embed_media_html } },
  index,
) => {
  const _embed_media_title = filterEmptyPrismicRepeatersContents(embed_media_title, 'text')
  const _embed_media_caption = filterEmptyPrismicRepeatersContents(embed_media_caption, 'text')
  const _embed_media_html = filterEmptyPrismicRepeatersContents(embed_media_html, 'text')

  return (
    <div className={`${styles.contentsWrapper} ${styles.embedMediaContentsWrapper}`} key={index}>
      {!isEmpty(_embed_media_title) && (
        <div
          className={`${styles.mediaTitle} sectionTitle`}
          dangerouslySetInnerHTML={{ __html: parsePrismicText(_embed_media_title) }}
        />
      )}
      <IframeWrapper html={_embed_media_html} caption={_embed_media_caption} />
    </div>
  )
}
