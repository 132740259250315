import React, { useState, useEffect, useReducer } from 'react'
import { reaction } from 'mobx'
import isEmpty from 'lodash/isEmpty'
import without from 'lodash/without'
import concat from 'lodash/concat'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import Dropdown from 'react-dropdown-aria'
import axios from 'axios'
import { expoArchivePage as expoArchivePageFn } from 'triennale-prismic-api-wrapper'

import {
  TABS_LABELS,
  SEARCH_PLACEHOLDER,
  ARCHIVE_FILTERS,
  ARCHIVE_FILTERS_TYPES,
  ARCHIVE_API_URL,
} from '../../lib/const'
import { translator } from '../../lib/translator'
import { SliderCompound } from '../Slider/Slider'
import { ReactComponent as SearchIcon } from '../../images/icons/search.svg'
import styles from './SearchBox.module.css'
import { isObjectNull } from '../../lib/utils'

const POSSIBLE_TEXT_INPUT_FIELDS = ['author', 'company', 'title', 'year', 'freeText']

const isIn = (array, s) => (isEmpty(array) ? false : array.indexOf(s) > -1)

const SelectOptions = ({ query, slug, onClickHandler, options }) => {
  const handleUpdate = value => () => {
    const isInQuery = isIn(query[slug], value)

    if (isInQuery) {
      onClickHandler({
        type: 'CLEAN_QUERY',
        key: slug,
        value: value,
      })
    } else {
      onClickHandler({
        type: 'ADD_QUERY',
        key: slug,
        value: value,
      })
    }
  }

  return (
    <ul className={`${styles.select} smallTitle`}>
      {options.map(option => (
        <li className={styles.selectItem} key={option.slug}>
          <button
            className={`${styles.selectBtn} ${
              isIn(query[slug], option.slug) ? styles.optionSelected : ''
            }`}
            onClick={handleUpdate(option.slug)}
          >
            {option.label}
          </button>
        </li>
      ))}
    </ul>
  )
}

const handleUpdate = ({ onClickHandler, query, slug }) => e => {
  e.preventDefault()

  onClickHandler({
    type: 'ADD_QUERY',
    key: slug,
    value: !query[slug],
  })
}

const CheckboxOptions = inject('rootStore')(
  observer(props => {
    const { query, slug, rootStore } = props
    const { currentRouteLang } = rootStore.navigation
    const { type, label } = ARCHIVE_FILTERS_TYPES[slug]

    return (
      <div className={styles.isInMuseumCheckbox}>
        <div className={`${styles.prettyCheckbox}`}>
          <input type="checkbox" name={type} onChange={handleUpdate(props)} checked={query[slug]} />
          <div className={`${styles.prettyCheckboxState}`}>
            <svg className={`${styles.checkSvg}`} viewBox="0 0 20 20">
              <path
                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                style={{ stroke: 'black', fill: 'black' }}
              />
            </svg>
            <label className={`${styles.prettyCheckboxLabel}`} htmlFor={type}>
              {label[currentRouteLang]}
            </label>
          </div>
        </div>
      </div>
    )
  }),
)

const OptionsFilter = ({ slug, query, fireUpdate, options, timerange }) => {
  if (!slug) return null
  switch (ARCHIVE_FILTERS_TYPES[slug].type) {
    case 'select':
      return options ? (
        <SelectOptions query={query} slug={slug} onClickHandler={fireUpdate} options={options} />
      ) : null
    case 'checkbox':
      return <CheckboxOptions query={query} slug={slug} onClickHandler={fireUpdate} />
    case 'range':
      // return timerange ? (
      return <SliderCompound query={query} onClickHandler={fireUpdate} timerange={timerange} />
    // ) : null
    default:
      return null
  }
}

const updateSearchKey = ({ onChangeHandler }, mappedOptions) => value => {
  const newSearchKey = mappedOptions.find(({ title }) => title === value).searchKey
  onChangeHandler(newSearchKey)
}

const SpecificSearchSelect = inject('rootStore')(props => {
  const { selectOptions, searchKey, rootStore } = props
  const { currentRouteLang } = rootStore.navigation

  if (!selectOptions) {
    return null
  }

  const style = {
    DropdownWrapper: _ => ({
      minWidth: '130px',
      width: '100%',
    }),
    DropdownButton: _ => ({
      background: 'transparent',
      overflow: 'visible',
      position: 'relative',
      outline: '1px solid transparent',
      cursor: 'pointer',
      padding: 0,
      border: 0,
      width: 'calc(100% - 20px)',
      display: 'block',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:focus': {
        boxShadow: 'none',
      },
    }),
    DisplayedValue: base => ({
      ...base,
      borderRight: 0,
      fontWeight: 'bold',
      fontSize: '12px',
    }),
    Arrow: (_, { open }) => {
      return {
        border: 0,
        position: 'absolute',
        right: document.documentElement.lang === 'it' ? 0 : 5,
        top: '50%',
        '&::before': {
          position: 'absolute',
          left: 0,
          content: `''`,
          width: '8px',
          height: '2px',
          background: 'black',
          display: 'block',
          transition: '0.3s transform ease-in-out',
          transformOrigin: '50% 50%',
          transform: open
            ? 'translate(-3px, 0) rotate(-35deg)'
            : 'translate(-3px, 0) rotate(35deg)',
        },
        '&::after': {
          position: 'absolute',
          left: 0,
          content: `''`,
          width: '8px',
          height: '2px',
          background: 'black',
          display: 'block',
          transition: '0.3s transform ease-in-out',
          transformOrigin: '50% 50%',
          transform: open ? 'translate(3px, 0) rotate(35deg)' : 'translate(3px, 0) rotate(-35deg)',
        },
      }
    },
    OptionItem: (base, _, { selected }) => ({
      ...base,
      fontSize: '14px',
      transition: '0.3s all ease-in-out',
      color: selected ? 'white' : 'black',
      backgroundColor: selected ? 'black' : 'white',
      '&:hover': {
        color: 'white',
        backgroundColor: selected ? 'black' : '#959595',
      },
    }),
  }

  const mappedOptions = selectOptions.map(option => {
    const title = translator(`archiveFullTextSearch.${option}`)(currentRouteLang)
    return {
      searchKey: option,
      value: title,
      title,
      ariaLabel: title,
    }
  })

  const getSelectedOption = newSearchKey => {
    return mappedOptions.find(o => o.searchKey === newSearchKey).title
  }

  return (
    <div className={styles.specificSearch}>
      <Dropdown
        options={mappedOptions}
        style={style}
        selectedOption={getSelectedOption(searchKey)}
        setSelected={updateSearchKey(props, mappedOptions)}
      />
    </div>
  )
})

@inject('rootStore')
@observer
class KeywordInput extends React.Component {
  componentDidMount() {
    const { setTempKeyword, setTempSearchKey } = this.props.rootStore.archive
    reaction(
      () => this.props.query,
      _query => {
        const compiledTextField = POSSIBLE_TEXT_INPUT_FIELDS.find(field => _query[field].length > 0)
        if (compiledTextField) {
          setTempKeyword(_query[compiledTextField][0])
          setTempSearchKey(compiledTextField)
        } else {
          setTempKeyword('')
          setTempSearchKey('freeText')
        }
      },
    )
  }

  onChangeHandler = e => {
    const { setTempKeyword } = this.props.rootStore.archive
    setTempKeyword(e.target.value)
  }

  onSelectChangeHandler = value => {
    const { setTempSearchKey } = this.props.rootStore.archive
    setTempSearchKey(value)
  }

  onSubmit = e => {
    const { onSubmitHandler, rootStore } = this.props
    const { tempKeyword, tempSearchKey } = rootStore.archive
    e.preventDefault()

    onSubmitHandler({
      type: 'REPLACE_TEXT',
      key: tempSearchKey,
      value: tempKeyword,
    })
  }

  render() {
    const { selectOptions, noPresentInMuseumCheckbox, placeholder = '', rootStore } = this.props
    const { tempKeyword, tempSearchKey } = rootStore.archive

    return (
      <>
        <form
          className={`${styles.keywordInput} ${
            !selectOptions ? styles.noSpecificSearchSelect : ''
          } ${noPresentInMuseumCheckbox ? styles.noPresentInMuseumCheckbox : ''}`}
          onSubmit={this.onSubmit}
        >
          <div className={styles.keywordInputInner}>
            <input
              className={styles.keywordInputInput}
              type="search"
              onChange={this.onChangeHandler}
              value={tempKeyword}
              name="keyword"
              placeholder={placeholder}
            />

            <input className={styles.keywordInputSubmit} type="submit" value="Search" />
            <div
              role="button"
              className={styles.searchIconWrapper}
              tabIndex={0}
              onClick={this.onSubmit}
              onKeyDown={this.onSubmit}
            >
              <SearchIcon className={styles.searchIcon} />
            </div>
          </div>
          <SpecificSearchSelect
            selectOptions={selectOptions}
            onChangeHandler={this.onSelectChangeHandler}
            searchKey={tempSearchKey}
          />
        </form>
      </>
    )
  }
}

const queryReducer = (state, { type, key, value }) => {
  switch (type) {
    case 'ADD_QUERY':
      return {
        ...state,
        [key]:
          key === 'timerange' || key === 'isPresentInMuseum'
            ? value
            : concat(state[key] || [], value),
      }
    case 'CLEAN_QUERY':
      return {
        ...state,
        [key]: key === 'timerange' ? [] : without(state[key], value),
      }
    case 'REPLACE':
      return value
    case 'REPLACE_TEXT':
      return {
        ...state,
        ...POSSIBLE_TEXT_INPUT_FIELDS.reduce((acc, field) => {
          return { ...acc, [field]: key === field ? (Array.isArray(value) ? value : [value]) : [] }
        }, {}),
      }
    default:
      return state
  }
}

function setTimerangeToLocalStorage(archiveId, timerange) {
  window.localStorage.setItem(archiveId, JSON.stringify(timerange))
}

function getTimerangeFromLocalStorage(archiveId) {
  if (!archiveId) {
    return undefined
  }
  const timerange = window.localStorage.getItem(archiveId)
  return timerange ? JSON.parse(timerange) : undefined
}

const fireUpdate = (queryComposer, resetPage) => action => {
  queryComposer(action)
  resetPage()
}

const SearchBox = inject('rootStore')(({ archiveId, rootStore, global }) => {
  const {
    navigation: { currentRouteLang },
    archive,
    archive: { setSearchArchiveUrl, resetQuery, resetPage },
    expoArchivePages,
    filteredArchives,
    filteredArchivesSlugs,
  } = rootStore
  const [options, setOptions] = useState({})
  const [timerange, setTimerange] = useState()

  const id = global ? 'global' : archiveId || rootStore.archive.archiveId

  const [query, dispach] = useReducer(queryReducer, getSnapshot(archive.query))

  const queryComposer = action => dispach(action)
  const _fireUpdate = fireUpdate(queryComposer, resetPage)

  // avoids search results being rendered on first load if it's an archive page
  const [isFirstTime, updateIsFirstTimeFlag] = useState(true)

  useEffect(() => {
    if (isFirstTime) {
      updateIsFirstTimeFlag(false)
      return undefined
    }

    if (isObjectNull(query)) {
      // eslint-disable-next-line no-unused-expressions
      resetQuery
      return
    }

    setSearchArchiveUrl(id, query)
    return function cleanup() {
      // eslint-disable-next-line no-unused-expressions
      resetQuery
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    _fireUpdate({
      type: 'REPLACE',
      value: getSnapshot(archive.query),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSnapshot(archive.query)])

  useEffect(() => {
    async function fetchExpos() {
      function sortById(a, b) {
        return Number(a.data.id_triennale) > Number(b.data.id_triennale) ? 1 : -1
      }

      if (archiveId !== 'collezioni') {
        const expoArchiveData = await expoArchivePageFn.get()
        expoArchivePages.setResults(expoArchiveData.results.sort(sortById))
        setOptions(prevOptions => ({
          ...prevOptions,
          expoId: expoArchivePages.results.map(({ data }) => ({
            label: data.title_brief,
            slug: data.id_triennale,
          })),
        }))
      }
    }

    fetchExpos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveId]) // this is important because archiveId can be undefined on componentDidMount

  useEffect(() => {
    async function fetchArchivesYears() {
      try {
        const archivesId = archiveId === 'collezioni' ? filteredArchivesSlugs : archiveId
        const { data } = await axios.get(`${ARCHIVE_API_URL}/years-range?archiveId=${archivesId}`)
        const _timerange = [Number(data.yearStart), Number(data.yearEnd)]
        setTimerangeToLocalStorage(archiveId, _timerange)
        setTimerange(_timerange)
      } catch (e) {}
    }

    const timerangeFromLocalStorage = getTimerangeFromLocalStorage(archiveId)

    if (
      id &&
      !timerangeFromLocalStorage &&
      ARCHIVE_FILTERS[archiveId] &&
      ARCHIVE_FILTERS[archiveId]['fields'].includes('timerange')
    ) {
      fetchArchivesYears()
    } else {
      setTimerange(timerangeFromLocalStorage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const noPresentInMuseumCheckbox =
    !id || !ARCHIVE_FILTERS[id] || !ARCHIVE_FILTERS[id]['fields'].includes('isPresentInMuseum')

  return (
    <div className={styles.searchBox}>
      <div className={styles.searchBoxInner}>
        <div className={styles.searchBoxTopPart}>
          <KeywordInput
            placeholder={`${translator(SEARCH_PLACEHOLDER[id])(currentRouteLang)} ${
              TABS_LABELS[currentRouteLang][id]
            }`}
            onSubmitHandler={_fireUpdate}
            selectOptions={ARCHIVE_FILTERS[id] && ARCHIVE_FILTERS[id]['fullTextFields']}
            query={query}
            noPresentInMuseumCheckbox={noPresentInMuseumCheckbox}
          />
        </div>

        {id &&
          ARCHIVE_FILTERS[id] &&
          ARCHIVE_FILTERS[id]['fields'].map((filter, i) => {
            const title = translator(`archiveOptionTypesTitle[${filter}]`)(currentRouteLang)
            return (
              <div key={i} className={`${styles.optionsWrapper} ${styles[filter]}`}>
                {title !== '' && <p className={styles.optionTypeTitle}>{title}</p>}
                <OptionsFilter
                  query={query}
                  fireUpdate={_fireUpdate}
                  slug={filter}
                  options={filter !== 'archives' ? options[filter] : filteredArchives}
                  timerange={timerange}
                />
              </div>
            )
          })}
      </div>
    </div>
  )
})

export default SearchBox
