import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { NotFound } from './NotFound'
import { BasicPage } from '../components/pagesComponents/BasicPage'
import { InstitutionalPage } from '../components/pagesComponents/InstitutionalPage'
import { ServicePage } from '../components/pagesComponents/ServicePage'
import { TakePartPage } from '../components/pagesComponents/TakePartPage'
import { VisitPage } from '../components/pagesComponents/VisitPage'
import { TransparencyPage } from '../components/pagesComponents/TransparencyPage'
import { ContainerPage } from '../components/pagesComponents/ContainerPage'
import { LoaderOval } from '../components'
import { haveRouteSecondLevel } from '../lib/utils'
import { composeUid } from '../lib/utils/uid-utils'

const switcherSecondLevel = data => ({
  basic_page: <BasicPage basic={data} />,
  institutional_page: <InstitutionalPage institutional={data} />,
  service_page: <ServicePage service={data} />,
  take_part_page: <TakePartPage takePart={data} />,
  transparency: <TransparencyPage transparency={data} />,
  visit: <VisitPage visit={data} />,
  // event: <SingleEvent />,
  // magazine: <SingleMagazine />,
})

const switcherState = renderSecondLevel => {
  return {
    notFound: <NotFound />,
    rest: renderSecondLevel || <ContainerPage />,
    loading: <LoaderOval />,
  }
}

export const DynamicPage = inject('rootStore')(
  observer(({ rootStore }) => {
    const {
      getAllByUid,
      appState,
      navigation: {
        currentRouteName,
        router: {
          location: { pathname },
        },
      },
    } = rootStore

    let renderSecondLevel = null
    if (haveRouteSecondLevel(currentRouteName)) {
      const data = getAllByUid([composeUid(pathname)])[0]
      renderSecondLevel = isEmpty(data) ? null : switcherSecondLevel(data)[data.type]
    }
    const renderComponent = switcherState(renderSecondLevel)
    return renderComponent[appState]
  }),
)
