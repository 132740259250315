import { types as t } from 'mobx-state-tree'

export const FilterModel = t
  .model('FilterModel', {
    type: t.optional(t.array(t.string), []),
    text: t.optional(t.array(t.string), []),
    audience: t.optional(t.array(t.string), []),
    special: t.optional(t.array(t.string), []),
    field: t.optional(t.array(t.string), []),
    language: t.optional(t.array(t.string), []),
    entrance: t.optional(t.array(t.string), []),
    switch: t.optional(t.array(t.string), []),
    time_related: t.optional(t.array(t.string), []),
    start_date: t.maybeNull(t.string),
    end_date: t.maybeNull(t.string),
  })
  .actions(self => {
    const replace = (key, value) => {
      self[key] = value
    }
    return { replace }
  })
  .views(self => ({}))
