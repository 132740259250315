import React from 'react'
import { linkResolverNormalizer } from '../../lib/link-resolver'
import { parsePrismicHtml } from '../../lib/survive-prismic'

// bodyTextBig

export const Description = ({ description }) => {
  return description ? (
    <div
      className={'bodyTextBig'}
      dangerouslySetInnerHTML={{
        __html: parsePrismicHtml(description, linkResolverNormalizer),
      }}
    />
  ) : null
}

export const Caption = ({ caption }) => {
  return (
    <figcaption
      className={`captionText`}
      dangerouslySetInnerHTML={{
        __html: parsePrismicHtml(caption),
      }}
    />
  )
}
