// TODO: trasparenza eng

export const transparencyNav = {
  it: [
    {
      label: 'Attività e procedimenti',
      link: '/trasparenza/attivita-procedimenti',
    },
    {
      label: 'Bandi di gara e contratti',
      link: '/trasparenza/bandi',
    },
    {
      label: 'Sovvenzioni, contributi, sussidi, vantaggi economici',
      link: '/trasparenza/contributi',
    },
    {
      label: 'Bilanci',
      link: '/trasparenza/bilanci',
    },

    {
      label: "Controlli e rilievi sull'amministrazione",
      link: '/trasparenza/controlli',
    },

    {
      label: 'Servizi erogati',
      link: '/trasparenza/servizi-erogati',
    },
    {
      label: 'Opere pubbliche',
      link: '/trasparenza/opere-pubbliche',
    },
    {
      label: 'Informazioni ambientali',
      link: '/trasparenza/informazioni-ambientali',
    },
    {
      label: 'Altri contenuti',
      link: '/trasparenza/altri-contenuti',
    },
    {
      label: 'Organizzazione',
      link: '/trasparenza/organizzazione',
    },
    {
      label: 'Disposizioni generali',
      link: '/trasparenza/disposizioni-generali',
    },
    {
      label: 'Albo fornitori',
      link: '/trasparenza/albo-fornitori',
    },
    {
      label: 'Consulenti e collaboratori',
      link: '/trasparenza/consulenti-e-collaboratori',
    },
    {
      label: 'Personale',
      link: '/trasparenza/personale',
    },
  ],
  en: [
    {
      label: 'Disposizioni generali',
      link: '/en/transparency/disposizioni-generali',
    },
    {
      label: 'Administrative Structure',
      link: '/en/transparency/administrative-structure',
    },
    {
      label: 'Call for tenders',
      link: '/en/transparency/call-for-tenders',
    },
    {
      label: 'Statutes',
      link: '/en/transparency/statutes',
    },
    {
      label: 'Consultants and collaborators',
      link: '/en/transparency/consultants-and-collaborators',
    },
    {
      label: 'Missions',
      link: '/en/transparency/missions',
    },
    {
      label: 'Albo fornitori',
      link: '/en/transparency/albo-fornitori',
    },
    {
      label: 'Competition announcements',
      link: '/en/transparency/competition-announcements',
    },
    {
      label: 'Sponsorships',
      link: '/en/transparency/sponsorships',
    },
    {
      label: 'Annual reports',
      link: '/en/transparency/annual-reports',
    },
    {
      label: 'Società controllate',
      link: '/en/transparency/societa-controllate',
    },
    {
      label: 'Staff',
      link: '/en/transparency/staff',
    },
  ],
}
