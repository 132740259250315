import React from 'react'
import dayjs from 'dayjs'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { composeUidOut } from '../../lib/utils/uid-utils'
import { PageHeaderPlain } from '../../components/Shared/Titles'
import LoaderOval from '../../components/layoutComponents/LoaderOval'
import styles from './RegularEvent.module.css'
import { InternationalParticipationsSection } from '../../components/ContainerPage/InternationalParticipation'
import { MediaSectionGrid, MediaSectionExpandable } from '../../components/Shared/MediaSection'
import { PriceTableSection } from '../../components/Shared/PriceTableSection'
import { filterEmptyPrismicRepeatersContents } from '../../lib/survive-prismic'
import { translator } from '../../lib/translator'
import { ArchivePreview } from '../../components/SingleEvent/ArchivePreview'
import { SOCIALS } from '../../lib/const'
import { RelatedBlock } from './RelatedBlock'
import { StickyHeroImg } from './StickyHeroImg'
import { StickyHeroText } from './StickyHeroText'

@inject('rootStore')
@observer
export class RegularEvent extends React.Component {
  render() {
    const {
      getBy,
      navigation: {
        router: {
          location: { pathname },
        },
        currentRouteLang,
      },
    } = this.props.rootStore
    const uid = composeUidOut(pathname)(['eventi', 'events'])
    const event = getBy('events')({ uid })

    if (isEmpty(event)) {
      return <LoaderOval />
    }

    const { data, first_publication_date } = event
    const {
      container_page,
      affiliation,
      entrance,
      type,
      artworks,
      behind_the_scenes,
      seen_by_you,
      related_events,
      related_magazines,
      promotions,
      archive_preview,
    } = data
    const { international_participations } = container_page || {}

    const hasParent = container_page || affiliation !== null

    /*
     * This horrible hack shows the archive_preview block on all
     * old events. This is because the default option was changed
     * in Prismic, and we didn't want to change it manually in
     * over 300 entries in the event data model in Prismic.
     */
    let archive_preview_with_fallback_for_older_events = archive_preview
    if (dayjs(first_publication_date).isBefore(dayjs('2020-01-14'))) {
      archive_preview_with_fallback_for_older_events =
        type !== 'Collezione'
          ? 'Mostra anteprima di tutti gli archivi'
          : "Mostra anteprima dell'archivio delle collezioni"
    }

    // Price lists table are temporarly hidden
    // This value comes from event.data.price_lists mobX state
    const price_lists = []

    const renderHeader = () => {
      if (container_page) {
        return (
          <PageHeaderPlain
            title={container_page.title_short}
            link={{
              contentType: 'container_page',
              uid: container_page.uid,
              lang: currentRouteLang,
            }}
          />
        )
      }
      if (affiliation !== null) {
        return <PageHeaderPlain title={affiliation} />
      } else if (
        !container_page &&
        (affiliation === null || !affiliation) &&
        (type === 'Incontro' ||
          type === 'Conferenza' ||
          type === 'Festival' ||
          type === 'Laboratorio' ||
          'Presentazione volume' ||
          type === 'Proiezione' ||
          type === 'Campus estivo' ||
          type === 'Speciale' ||
          type === 'Visita guidata')
      ) {
        return (
          <PageHeaderPlain
            title={translator('publicProgram')(currentRouteLang)}
            link={{
              contentType: 'public_program',
              lang: currentRouteLang,
            }}
          />
        )
      }
    }

    return (
      <>
        {renderHeader()}
        <div
          className={`${styles.bodyWrapper}${!hasParent ? ` ${styles.bodyWrapperNoParent}` : ''}`}
        >
          <StickyHeroImg event={event} />
          <div className={styles.right}>
            <StickyHeroText event={event} currentRouteLang={currentRouteLang} />
            <MediaSectionExpandable
              type={type}
              media={artworks}
              contentType="artworks"
              titleLabel="worksSectionTitle"
            />

            <RelatedBlock
              related_events={related_events}
              related_magazines={related_magazines}
              promotions={promotions}
              lang={currentRouteLang}
            />
          </div>
        </div>
        <MediaSectionGrid
          media={behind_the_scenes}
          contentType={'behindTheScenes'}
          titleLabel={'behindTheScenesSectionTitle'}
          lang={currentRouteLang}
          itemsPerRow={4}
        />
        <MediaSectionGrid
          media={seen_by_you}
          contentType={'seenByYou'}
          titleLabel={'behindTheScenesSectionTitle'}
          lang={currentRouteLang}
          itemsPerRow={4}
          linkInfo={{
            link: { href: SOCIALS.instagram.url },
            title: translator('seenByYou')(currentRouteLang),
            cta: translator('followInstagram')(currentRouteLang),
            border: true,
          }}
        />
        <div className={styles.relatedInfoWrapper}>
          <InternationalParticipationsSection
            data={international_participations}
            lang={currentRouteLang}
            path={pathname}
          />

          {entrance !== 'Gratuito' && (
            <div className={styles.pricesWrapper}>
              {filterEmptyPrismicRepeatersContents(price_lists, 'rates_names').map(
                (price_list, i) => (
                  <PriceTableSection key={i} table={price_list} lang={currentRouteLang} index={i} />
                ),
              )}
            </div>
          )}
          <ArchivePreview type={archive_preview_with_fallback_for_older_events} />
        </div>
      </>
    )
  }
}
