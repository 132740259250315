import React from 'react'
import { inject, observer } from 'mobx-react'
import { press as type } from '../lib/prismic/press'
import { NotFound } from './NotFound'
import { LoaderOval, PressItemsPreview, CurrentContentList } from '../components'
import { haveRouteSecondLevel } from '../lib/utils'
import { PRESS_FILTERS, PRESS_FILTERS_KEYS } from '../lib/const'

const switcherState = secondLevel => press => {
  return {
    notFound: <NotFound />,
    rest: secondLevel ? null : (
      <CurrentContentList
        contentType={'press'}
        type={type}
        renderComponent={PressItemsPreview}
        filters={PRESS_FILTERS}
        filtersKeys={PRESS_FILTERS_KEYS}
        titleLabel="pressPageTitle"
      />
    ),
    loading: <LoaderOval />,
  }
}

export const Press = inject('rootStore')(
  observer(({ rootStore }) => {
    const {
      appState,
      press,
      navigation: { currentRouteName },
    } = rootStore
    const renderComponent = switcherState(haveRouteSecondLevel(currentRouteName))
    return renderComponent(press)[appState]
  }),
)
