import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { PageTitle } from '../../components/Shared/Titles'
import styles from './BasicPage.module.css'
import { linkResolverNormalizer } from '../../lib/link-resolver'
import { parsePrismicHtml } from '../../lib/survive-prismic'

export const BasicPage = inject('rootStore')(
  observer(({ basic }) => {
    if (isEmpty(basic)) {
      return null
    }
    const { title, text } = basic.data
    return (
      <div className={styles.wrapper}>
        <PageTitle title={title} />
        <div
          className={`bodyText basicPageTitles ${styles.content}`}
          dangerouslySetInnerHTML={{
            __html: parsePrismicHtml(text, linkResolverNormalizer),
          }}
        />
      </div>
    )
  }),
)
