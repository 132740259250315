import React, { useEffect } from 'react'
import { createBrowserHistory } from 'history'
import { Provider, inject } from 'mobx-react'
import { Router } from 'react-router-dom'
import { RouterModel, syncHistoryWithStore } from 'mst-react-router'
import { getSnapshot } from 'mobx-state-tree'
import { RootStore } from '../store'
import { BannersWrapper, Footer, CookieConsent, Main, Header, Meta } from '../components'
import { randomColor } from '../lib/utils/colors'

const initialState = window.initialState || {
  navigation: { router: RouterModel.create() },
  appState: 'rest',
  ui: {
    mainNavToggle: false,
    priceNote: { listIndex: -1, itemIndex: -1 },
    archiveSingleTriennale: { activeTab: undefined, accordion: {} },
  },
  ongoing: { name: 'ongoing' },
}

const rootStore = RootStore.create(initialState)

// just for debug
window.GLOBAL_STATE = rootStore

// Serialize global state and write it in the built page when using react-snap.
window.snapSaveState = () => {
  const stateSnapshot = JSON.stringify(getSnapshot(rootStore))
  const jsContent = `window.initialState = ${stateSnapshot};`
  document.querySelector('#initial-state').textContent = jsContent
}

const IdentityColor = inject('rootStore')(({ rootStore, children }) => {
  const {
    ui: { setLabelsColor },
  } = rootStore
  useEffect(() => {
    window.sessionStorage.setItem('color', randomColor)
    setLabelsColor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <>{children}</>
})

const history = syncHistoryWithStore(createBrowserHistory(), rootStore.navigation.router)

export default class App extends React.Component {
  componentDidMount() {
    document.body.classList.remove('preload')
  }

  render() {
    return (
      <Provider rootStore={rootStore}>
        <Router history={history}>
          <IdentityColor>
            <Meta />
            <Header />
            <Main />
            <Footer />
            <BannersWrapper>
              {/* <AlertBanner /> */}
              <CookieConsent />
            </BannersWrapper>
          </IdentityColor>
        </Router>
      </Provider>
    )
  }
}
