import axios from 'axios'
import * as qs from 'query-string'
import { TICKETS_SERVER_URL } from '../lib/const'

const API_BASE = `${TICKETS_SERVER_URL}/api`

export const get = async (endpoint, { auth, params } = {}) => {
  try {
    const { data } = await axios.get(`${API_BASE}/${endpoint}`, {
      auth,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma' })
      },
    })
    return data
  } catch (errorAxios) {
    errorHandler(errorAxios)
  }
}

export const post = async (endpoint, { auth, params } = {}) => {
  try {
    const { data } = await axios.post(`${API_BASE}/${endpoint}`, {
      auth,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma' })
      },
    })
    return data
  } catch (errorAxios) {
    errorHandler(errorAxios)
  }
}

function errorHandler(err) {
  console.log(err)
}
