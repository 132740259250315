/* eslint-disable */
// import Cookies from 'js-cookie'
// import { handlerGaPageView } from './utils'

// const USER_CONSENT_COOKIE_NAME = 'cookie-user-consent'

// export function initGA(trackingId) {
//   ;(function(i, s, o, g, r, a, m) {
//     i['GoogleAnalyticsObject'] = r
//     ;(i[r] =
//       i[r] ||
//       function() {
//         ;(i[r].q = i[r].q || []).push(arguments)
//       }),
//       (i[r].l = Number(new Date()))
//     ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
//     a.async = 1
//     a.src = g
//     m.parentNode.insertBefore(a, m)
//   })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')
//   window.ga('create', trackingId, 'auto')
//   window.ga('set', 'anonymizeIp', true)
// }

// export const gaPageViewHit = location => {
//   const cookieValue = Cookies.get(USER_CONSENT_COOKIE_NAME)
//   if (cookieValue) {
//     setTimeout(() => {
//       window.gtag('send', {
//         hitType: 'pageview',
//         page: handlerGaPageView(location),
//       })
//     }, 1000)
//   }
// }

export const initGA4 = trackingId => {
  ;(function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r
    ;(i[r] =
      i[r] ||
      function() {
        ;(i[r].q = i[r].q || []).push(arguments)
      }),
      (i[r].l = Number(new Date()))
    ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
  })(
    window,
    document,
    'script',
    `https://www.googletagmanager.com/gtag/js?id=${trackingId}`,
    'gtag',
  )

  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', trackingId)
  return null
}
