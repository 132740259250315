import React from 'react'
import { inject, observer } from 'mobx-react'
import dayjs from 'dayjs'
import 'dayjs/locale/it'
import { openingTimes } from '../../lib/prismic/openingTimes'
import styles from './OpenHoursWidget.module.css'
import { nowIsOpen, getSelectedDayOpeningHours, getFormattedDate } from '../../lib/date-utils'
import { isSnap } from '../../lib/static-site'

let timer

@inject('rootStore')
@observer
class OpenHoursWidget extends React.Component {
  state = { isNowOpen: false, hasOpeningTimes: false, openTime: null }

  async componentDidMount() {
    const { currentRouteLangCode } = this.props.rootStore.navigation
    const _openingTimes = await openingTimes.getForService('Generali', currentRouteLangCode)
    this.setState({ hasOpeningTimes: true }, () => {
      this.getOpeningTimes(_openingTimes)
      window.clearInterval(timer)
      timer = window.setInterval(() => this.getOpeningTimes(_openingTimes), 2000)
    })
  }

  componentWillUnmount() {
    window.clearInterval(this.timer)
    timer = undefined
  }

  getOpeningTimes(_openingTimes) {
    const { standard, override } = getSelectedDayOpeningHours(
      _openingTimes,
      dayjs(new Date())
        .locale('it')
        .toDate(),
    )

    const timeToday = override || standard
    const openHoursRegex = /(\d{1,2})[.:](\d{2})[\W]+(\d{1,2})[.:](\d{2})/

    const match = timeToday.match(openHoursRegex)

    if (match) {
      const [, opensAtH, opensAtM, closesAtH, closesAtM] = match
      const openTime = `${opensAtH}.${opensAtM}`
      const closeTime = `${closesAtH}.${closesAtM}`
      this.setState({ isNowOpen: nowIsOpen({ openTime, closeTime }), openTime })
    } else {
      this.setState({ isNowOpen: false })
    }
  }

  render() {
    const { hasOpeningTimes } = this.state
    const { currentRouteLang } = this.props.rootStore.navigation

    if (!hasOpeningTimes || isSnap) {
      return <div className={styles.wrapper}>&nbsp;</div>
    }

    return (
      <div className={`navText ${styles.wrapper}`}>
        <div className={styles.backToSite}>
          <a href="https://triennale.org/">{`← ${
            currentRouteLang === 'it' ? 'Torna a' : 'Back to'
          } triennale.org`}</a>
        </div>
        <div className={`${styles.formattedNow}`}>
          <Clock />
        </div>
      </div>
    )
  }
}

export default OpenHoursWidget

@inject('rootStore')
@observer
class Clock extends React.Component {
  constructor(props) {
    super(props)
    this.state = { date: new Date() }
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 5000)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  tick() {
    this.setState({
      date: new Date(),
    })
  }

  render() {
    const { currentRouteLang } = this.props.rootStore.navigation

    return getFormattedDate(this.state.date, {
      lang: currentRouteLang,
      withHours: true,
      removeYear: true,
    })
  }
}
