import React from 'react'
import { inject } from 'mobx-react'
import { PageTitlePlain } from '../Shared/Titles'
import { ResponsiveImage } from '../Shared/Templating'
import styles from '../../routes/SingleArchiveTriennale.module.css'
import { translator } from '../../lib/translator'
import { parsePrismicHtml } from '../../lib/survive-prismic'

export const HeaderSection = inject('rootStore')(({ rootStore }) => {
  const {
    expoArchivePages,
    navigation: { currentRouteLang },
  } = rootStore
  const {
    title,
    description,
    description_bg_color,
    cover_image_group,
  } = expoArchivePages.results[0].data

  return (
    <React.Fragment>
      <PageTitlePlain title={translator('triennaleExpoSectionTitle')(currentRouteLang)} />
      <section className={`${styles.expoDescription}`}>
        {cover_image_group?.length > 0 && (
          <div className={`${styles.left}`}>
            <ResponsiveImage img={cover_image_group[0].cover_image} />
          </div>
        )}

        <div
          className={`${styles.right}`}
          style={{ backgroundColor: description_bg_color || 'transparent' }}
        >
          <div className={`${styles.leftInner}`}>
            <div className={`${styles.topPart}`}>
              <div
                className={`${styles.title} bold`}
                dangerouslySetInnerHTML={{ __html: parsePrismicHtml(title) }}
              />
              <div
                className={`${styles.description} bodyTextBig bold`}
                dangerouslySetInnerHTML={{ __html: parsePrismicHtml(description) }}
              />
            </div>
            {cover_image_group?.length && (
              <div className={`${styles.bottomPart}`}>
                <div
                  className={`captionText ${styles.caption}`}
                  dangerouslySetInnerHTML={{
                    __html: parsePrismicHtml(cover_image_group[0].cover_image_caption),
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
})
