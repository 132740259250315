import uniqBy from 'lodash/uniqBy'

export const PAGES = {
  events: { it: '/whats-on', en: '/en/whats-on' },
  news: { it: '/news', en: '/en/news' },
  press: { it: '/press', en: '/en/press' },
  tickets: { it: '/biglietti', en: '/en/tickets' },
}

export const TYPE_STATE = {
  archives_expo: 'expoArchivePages',
  basic_page: 'basicPages',
  collection: 'collection',
  container_page: 'containerPages',
  homepage: 'homePage',
  event: 'events',
  institutional_page: 'institutionalPages',
  magazine_homepage: 'magazineHomepage',
  magazine: 'magazine',
  magazine_series: 'magazineSeries',
  magazine_author: 'magazineAuthor',
  news: 'news',
  ongoing: 'ongoing',
  press: 'press',
  promotion: 'promotions',
  service_page: 'servicePages',
  take_part_page: 'takePartPages',
  transparency: 'transparencyPages',
  visit: 'visitPages',
}

export const ARCHIVE_FILTERS_TYPES = {
  archives: { type: 'select', label: { it: 'Altre collezioni', en: 'Other collections' } },
  expoId: { type: 'select', label: { it: 'Esposizioni', en: 'Exhibits' } },
  isPresentInMuseum: { type: 'checkbox', label: { it: 'In mostra', en: 'In museum' } },
  timerange: { type: 'range', label: { it: 'Anni', en: 'Years' } },
}

export const ARCHIVE_FILTERS = {
  collezioni: {
    fields: ['archives', 'isPresentInMuseum', 'timerange'],
    fullTextFields: ['freeText', 'title', 'author', 'year', 'company'],
  },
  'archivio-storico': { fields: ['expoId'], fullTextFields: ['freeText'] },
  'archivio-fotografico': {
    fields: ['expoId', 'timerange'],
    fullTextFields: ['freeText', 'title', 'author'],
  },
  'archivio-audiovisivo': {
    fields: ['expoId'],
    fullTextFields: ['freeText', 'title', 'author', 'year'],
  },
}

export const MAGAZINE_FILTERS = {}
export const MAGAZINE_FILTERS_KEYS = {}

export const NEWS_FILTERS = {
  type: [
    { it: 'Mostra', en: 'Exhibition' },
    { it: 'Teatro', en: 'Theater' },
    { it: 'Performance', en: 'Performance' },
    { it: 'Concerto', en: 'Concert' },
    { it: 'Incontro', en: 'Talk' },
    { it: 'Proiezione', en: 'Screening' },
    { it: 'Laboratorio', en: 'Workshop' },
    { it: 'Conferenza', en: 'Conference' },
    { it: 'Jobs', en: 'Jobs' },
  ],
}

export const NEWS_FILTERS_KEYS = {
  type: { it: 'Tipologia', en: 'Type' },
}

export const PRESS_FILTERS = {
  type: [
    { it: 'Mostra', en: 'Exhibition' },
    { it: 'Istituzionale', en: 'Institutional' },
    { it: 'Teatro', en: 'Theater' },
    { it: 'Festival', en: 'Festival' },
    // { it: 'Concerto', en: 'Concert' },
    // { it: 'Conferenza', en: 'Conference' },
    // { it: 'Incontro', en: 'Talk' },
    // { it: 'Laboratorio', en: 'Workshop' },
    // { it: 'Performance', en: 'Performance' },
    // { it: 'Proiezione', en: 'Screening' },
  ],
}

export const PRESS_FILTERS_KEYS = {
  type: { it: 'Tipologia', en: 'Type' },
}

export const EVENT_FILTERS = {
  type: [
    { it: 'Mostra', en: 'Exhibition' },
    { it: 'Installazione', en: 'Installation' },
    { it: 'Performance', en: 'Performance' },
    { it: 'Spettacolo', en: 'Show' },
    { it: 'Concerto', en: 'Concert' },
    { it: 'Incontro', en: 'Talk' },
    { it: 'Festival', en: 'Festival' },
    { it: 'Presentazione volume', en: 'Book presentation' },
    { it: 'Conferenza', en: 'Conference' },
    { it: 'Proiezione', en: 'Screening' },
    { it: 'Laboratorio', en: 'Workshop' },
    { it: 'Collezione', en: 'Collection' },
    { it: 'Speciale', en: 'Special' },
    { it: 'Triennale Estate', en: 'Triennale Summer' },
    { it: 'Visita guidata', en: 'Guided tour' },
    /*
    { it: 'Collezione', en: 'Collection' },
    { it: 'Laboratorio', en: 'Workshop' },
    { it: 'Mostra in viaggio', en: 'Travelling exhibition' },
    { it: 'Campus estivo', en: 'Summer camp' },
    { it: 'Teatro', en: 'Theater' },
    { it: 'Visita guidata', en: 'Guided tour' },
    */
  ],
  // field: [
  //   { it: 'Design', en: 'Design' },
  //   { it: 'Architettura', en: 'Architecture' },
  //   { it: 'Arte', en: 'Art' },
  //   { it: 'Fotografia', en: 'Photography' },
  //   { it: 'Teatro', en: 'Theater' },
  //   { it: 'Musica', en: 'Music' },
  // ],
  special: [
    { it: 'Estate in Triennale', en: 'Triennale summer' },
    { it: 'Teatro Stagione 2019-2020', en: 'Theater season 2019-2020' },
    { it: 'Il Tempo delle Donne', en: 'Il Tempo delle Donne' },
    { it: 'Design Lovers', en: 'Design Lovers' },
  ],
  time_related: [
    { it: 'Oggi', en: 'Today' },
    { it: 'Questo Weekend', en: 'This Weekend' },
    // { it: 'Passato', en: 'Past' },
    { it: 'Prossimamente', en: 'Upcoming' },
  ],

  entrance: [{ it: 'Ingresso gratuito', en: 'Free admission' }],
  audience: [
    { it: 'Famiglie e bambini', en: 'Families and kids' },
    { it: 'Scuole', en: 'Schools' },
    { it: 'Giovani', en: 'Young' },
    { it: 'Non-Italian speakers', en: 'Non-Italian speakers' },
  ],
  language: [{ it: 'Italiano', en: 'Italian' }, { it: 'Inglese', en: 'English' }],
}

export const EVENT_FILTERS_KEYS = {
  type: { it: 'Cosa stai cercando?', en: 'What are you looking for?' },
  // field: { it: 'Cosa ti piace?', en: 'What do you like?' },
  special: { it: 'Speciale', en: 'Special' },
  entrance: { it: 'Ingresso', en: 'Entrance Type' },
  time_related: { it: 'Quando?', en: 'When?' },
  audience: { it: 'Audience', en: 'Audience' },
  language: { it: 'Lingua', en: 'Language' },
}

export const ALL_FILTERS = EVENT_FILTERS

export const ACTIVE_FILTERS = ['news', 'press', 'events']

export const ALL_FILTERS_BY_CONTENT_TYPE = {
  news: NEWS_FILTERS,
  press: PRESS_FILTERS,
  events: EVENT_FILTERS,
}

export const CATEGORY_LIST = uniqBy(
  [...EVENT_FILTERS.type, ...PRESS_FILTERS.type, ...NEWS_FILTERS.type],
  'it',
)

export const EXHIBITION_TYPE = ['Collezione', 'Mostra']

export const CATEGORIES_WHERE_PREVIEW_IMAGE_IS_HIDDEN = [
  'Campus estivo',
  'Conferenza',
  'Festival',
  'Incontro',
  'Laboratorio',
  'Presentazione volume',
]
export const ARCHIVE_API_URL = 'https://triennale-archivio-api.accurat.io/api/archives'
export const ARCHIVE_API_LIMIT = 20

export const ARCHIVIO_STORICO_ENDPOINT = 'archimista-triennale'
export const ARCHIVIO_FOTOGRAFICO_ENDPOINT = 'archivio-fotografico'
export const ARCHIVIO_MEDIA_ENDPOINT = 'archivio-audiovisivo'
export const HIERARCHY_ENDPOINT = 'hierarchy'

export const TOP_LEVEL_ARCHIVES_ENDPOINTS = [
  // ARCHIVIO_STORICO_ENDPOINT,
  ARCHIVIO_FOTOGRAFICO_ENDPOINT,
  ARCHIVIO_MEDIA_ENDPOINT,
]

export const ARCHIVE_PERMANENT_COLLECTION = 'collezioni'
export const ARCHIVE_PHOTO_KEY = 'archivio-fotografico'
export const ARCHIVE_HISTORICAL_KEY = 'archivio-storico'
export const ARCHIVE_MEDIA_KEY = 'archivio-audiovisivo'

export const ARCHIVE_ENDPOINTS = {
  [ARCHIVE_PHOTO_KEY]: ARCHIVIO_FOTOGRAFICO_ENDPOINT,
  [ARCHIVE_HISTORICAL_KEY]: ARCHIVIO_STORICO_ENDPOINT,
  [ARCHIVE_MEDIA_KEY]: ARCHIVIO_MEDIA_ENDPOINT,
}

export const TABS_LABELS = {
  it: {
    global: 'Archivi',
    [ARCHIVE_PHOTO_KEY]: 'Archivio Fotografico',
    [ARCHIVE_HISTORICAL_KEY]: 'Archivio Storico',
    [ARCHIVE_MEDIA_KEY]: 'Archivio Audiovisivo',
    [ARCHIVE_PERMANENT_COLLECTION]: 'Collezione',
  },
  en: {
    global: 'Archives',
    [ARCHIVE_PHOTO_KEY]: 'Photo Archive',
    [ARCHIVE_HISTORICAL_KEY]: 'Historical Archive',
    [ARCHIVE_MEDIA_KEY]: 'Media Archive',
    [ARCHIVE_PERMANENT_COLLECTION]: 'Collection',
  },
}

export const SEARCH_PLACEHOLDER = {
  global: 'archiveSearchInputPlaceholderMasculinePlural',
  [ARCHIVE_PERMANENT_COLLECTION]: 'archiveSearchInputPlaceholderFemininePlural',
  [ARCHIVE_PHOTO_KEY]: 'archiveSearchInputPlaceholderMasculineSingular',
  [ARCHIVE_HISTORICAL_KEY]: 'archiveSearchInputPlaceholderMasculineSingular',
  [ARCHIVE_MEDIA_KEY]: 'archiveSearchInputPlaceholderMasculineSingular',
}

export const TABS = [ARCHIVE_PHOTO_KEY, ARCHIVE_HISTORICAL_KEY, ARCHIVE_MEDIA_KEY]

export const getArchive = s => {
  if (!s) return ''
  return s === ARCHIVE_HISTORICAL_KEY ? ARCHIVIO_STORICO_ENDPOINT : s
}

export const TICKETS_SERVER_URL = 'https://triennale-backend.accurat.io'
// export const TICKETS_SERVER_URL = 'http://localhost:3001'

export const GOOGLE_CAPTCHA_KEY = '6LeGw9EZAAAAAB5enILEDHy7SgcRjVYRxOCYuLA1'

export const LOCATION_DEFAULT = {
  name: 'Triennale Milano',
  maps: {
    url: 'https://goo.gl/maps/5WrMxELAfYGxF5Kz6',
  },
}

export const TICKET_TRANSACTION_KEY = 'vivaticketTransaction'
export const TICKET_SUBSCRIPTION = 'vivaticketSubscription'
export const TICKET_ORDER_KEY = 'vivaticketOrder'

export const MAGAZINE_CUSTOM_ROUTE = [
  { label: { it: 'Serie', en: 'Series' }, route: { it: 'serie', en: 'series' } },
  {
    label: { it: 'Collaboratori', en: 'Contributors' },
    route: { it: 'collaboratori', en: 'contributors' },
  },
  {
    label: { it: 'About', en: 'About' },
    route: { it: 'about', en: 'about' },
  },
]

export const DEFAULT_NUMBER_RESULTS_PER_PAGE = 8
export const TYPE_LIST_CARDS = [[], [0], [0, 5], [2]]

export const SOCIALS = {
  facebook: { name: 'Facebook', url: 'https://www.facebook.com/triennalemilano' },
  instagram: { name: 'Instagram', url: 'https://www.instagram.com/triennalemilano' },
  twitter: { name: 'Twitter', url: 'https://twitter.com/triennalemilano' },
  youtube: { name: 'YouTube', url: 'https://www.youtube.com/user/TriennaleVideo' },
}

export const MONTHS = {
  it: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ],
}
