import React from 'react'
import { inject, observer } from 'mobx-react'
import OngoingFiltersWrapper from './OngoingFiltersWrapper'
import { EVENT_FILTERS, FILTER_TYPES_TO_EXCLUDE } from './lib/filters'
import { translator } from '../../lib/translator'
import { getFormattedDate } from '../../lib/date-utils'

@inject('rootStore')
@observer
export class OngoingFilters extends React.Component {
  componentDidMount() {
    document.documentElement.classList.add('hasFilters')
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('hasFilters')
  }

  tooglePanel = () => {
    const {
      id,
      rootStore: { ui },
    } = this.props
    ui.toggleFilter(id)
  }

  closePanel = () => {
    const { id, rootStore } = this.props
    const { ui } = rootStore
    ui.hideFilter(id)
  }

  render() {
    const {
      id,
      type,
      isHistory,
      rootStore: {
        navigation: { currentRouteLang },
        ui,
        ui: { setOffset },
      },
    } = this.props

    const { isFiltersPanelOpen, offset } = ui[id]

    return (
      <h1>
        <div>
          {type?.filter?.start_date
            ? `${translator('filterOngoingTitleDate1')(currentRouteLang)} ${getFormattedDate(
                new Date(type.filter.start_date),
                {
                  lang: currentRouteLang,
                  withHours: false,
                  removeYear: true,
                },
              )} ${translator('filterOngoingTitleDate2')(currentRouteLang)}`
            : translator(isHistory ? 'filterHistoryTitle' : 'filterOngoingTitle')(currentRouteLang)}
          <OngoingFiltersWrapper
            closePanel={this.closePanel}
            setOffset={setOffset}
            filters={EVENT_FILTERS}
            filterTypesToExclude={FILTER_TYPES_TO_EXCLUDE}
            isOpen={isFiltersPanelOpen}
            offset={offset}
            id={id}
            type={type}
            currentRouteLang={currentRouteLang}
          />
        </div>
      </h1>
    )
  }
}
