import React from 'react'
import styles from '../../routes/ArchiveSearchResults.module.css'
import { updateSrc } from '../../lib/utils'
import noImageIt from '../../images/no_image_it.svg'
import noImageEn from '../../images/no_image_en.svg'
import { translator } from '../../lib/translator'

const ArchiveLayoutCard = (
  {
    id,
    title,
    project,
    videoPosterFileUrl,
    pictureFileUrl,
    videoFileUrl,
    authors,
    company,
    projectYear,
  },
  i,
  onClickHandler,
  lang,
) => {
  return (
    <li className={`${styles.gridItem}`} key={i}>
      <a
        className={`${styles.gridItemLink} link noIcon regular verySmallText`}
        href="#0"
        onClick={onClickHandler(id)}
      >
        <div className={`${styles.gridItemImg}`}>
          {pictureFileUrl ? (
            <img
              src={pictureFileUrl}
              alt={`${title}`}
              onError={updateSrc(lang === 'it' ? noImageIt : noImageEn)}
            />
          ) : (
            <img src={lang === 'it' ? noImageIt : noImageEn} alt={translator('noImgText')(lang)} />
          )}
        </div>
        <div className={`${styles.gridItemText}`}>
          <span>{title}</span>
          {authors && authors.length > 0 && <span> - {authors.join(', ')}</span>}
          {company && <span> - {company}</span>}
          {projectYear && <span> - {projectYear}</span>}
        </div>
      </a>
    </li>
  )
}

export default ArchiveLayoutCard
