import dayjs from 'dayjs'
import { formatVivaticketDate } from '../lib/date-utils'
import 'dayjs/locale/it'

export const isASubscriptionOrExhibition = ticket => {
  return (
    ticket?.performance.findIndex(p => {
      return p.abb === '1' || p.subscription === '1'
    }) >= 0
  )
}

const remapReductions = reductions =>
  reductions.map(r => ({
    ...r,
    maxbuy: Number(r.maxbuy),
    price: Number(r.price),
    presale: Number(r.presale),
    commission: Number(r.commission),
    iva: Number(r.iva),
  }))

export const remapZones = zones => {
  return zones
    ? zones.map(z => ({
        ...z,
        availability: Number(z.availability),
        max_buy: Number(z.max_buy),
        reductions: remapReductions(z.reductions),
      }))
    : []
}

export const isEqualBy = (item1, item2, params) =>
  params.every(param => item1[param] === item2[param])

export const keepValueInBounds = arr => Math.min(...arr)

const getFormattedDateTime = (date, time) =>
  dayjs(date)
    .hour(time[0])
    .minute(time[1])

export const isPast = (testDate, testTime) => {
  const now = dayjs().locale('it')
  const nowDate = now.format('DD/MM/YYYY')
  const nowTime = now.format('HH:mm')

  const nowDateFormatted = formatVivaticketDate(nowDate)
  const nowTimeFormatted = nowTime.split(':').map(t => Number(t))
  const nowDateComposed = getFormattedDateTime(nowDateFormatted, nowTimeFormatted)

  const testDateFormatted = formatVivaticketDate(testDate)
  const testTimeFormatted = testTime.split(':').map(t => Number(t))
  const testDateComposed = getFormattedDateTime(testDateFormatted, testTimeFormatted)

  return dayjs(nowDateComposed).isAfter(testDateComposed)
}

export const getIdFromUrl = url => {
  return url.split('/').slice(-1)[0]
}
