import React from 'react'
import { inject, observer } from 'mobx-react'
import remove from 'lodash/remove'
import styles from './Breadcrumb.module.css'
import { Link } from '../../components'
import { linkResolver } from '../../lib/link-resolver'

// Content types excluded from breadcrumbs: Homepage, What's on, News, Press, Promotions
const INSTITUTIONAL_PAGES = [
  'visit',
  'take_part_page',
  'service_page',
  'institutional_page',
  'transparency',
  'basic_page',
]

const EXCLUDED_BITS = ['', 'en']

@inject('rootStore')
@observer
export class Breadcrumb extends React.Component {
  buildEventBreadcrumb({ lang, data }) {
    if (!data || !data.title) {
      return null
    }

    let parent = null

    if (data.affiliation !== null) {
      parent = `${data.affiliation} >`
    } else if (data.container_page) {
      const { uid: containerUid, type: containerType, title_short } = data.container_page
      parent = (
        <>
          <Link
            className={`${styles.link} outline`}
            to={linkResolver({ contentType: containerType, uid: containerUid, lang })}
          >
            {title_short}
          </Link>{' '}
          {'>'}{' '}
        </>
      )
    }

    return (
      <>
        {parent}
        <span>{data.title[0].text}</span>
      </>
    )
  }

  buildContainerPageBreadcrumb(label) {
    return <span>{label}</span>
  }

  buildInstitutionalPageBreadcrumb({ uid, data }) {
    if (!data || !data.title) {
      return null
    }

    let parent
    const urlBits = uid.split('_')

    if (urlBits && urlBits.length > 1) {
      const cleanBits = remove(urlBits, bit => !EXCLUDED_BITS.includes(bit))
      parent = (
        <>
          <span className={styles.capitalized}>{cleanBits[0]}</span> {'>'}{' '}
        </>
      )
    }

    return (
      <>
        {parent}
        <span>{data.title[0].text}</span>
      </>
    )
  }

  get breadcrumb() {
    const { currentView, navigation } = this.props.rootStore
    const { currentRouteLang: lang } = navigation
    const { type, uid, data } = currentView

    const normalizedData = (data[0] && data[0].data) || data // sometimes for institutional pages the result is an object with numbered keys

    if (type === 'event') {
      return this.buildEventBreadcrumb({ lang, data: normalizedData })
    } else if (type === 'container_page') {
      return this.buildContainerPageBreadcrumb(normalizedData.title_short)
    } else if (INSTITUTIONAL_PAGES.includes(type)) {
      return this.buildInstitutionalPageBreadcrumb({ uid, data: normalizedData })
    }

    return null
  }

  render() {
    return this.props.rootStore.currentView.pathname && this.breadcrumb !== null ? (
      <div className={`${styles.breadcrumb} verySmallText regular`}>{this.breadcrumb}</div>
    ) : null
  }
}

export default Breadcrumb
