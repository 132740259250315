import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './PriceNote.module.css'
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg'
import { ReactComponent as PriceInfoIcon } from '../../images/icons/price_info.svg'

@inject('rootStore')
@observer
export class PriceNote extends React.Component {
  open = e => {
    if (e) {
      e.preventDefault()
    }

    const { rootStore, listIndex, itemIndex, rowIndex } = this.props
    rootStore.ui.showPriceNote(listIndex, itemIndex, rowIndex)
  }

  close = e => {
    if (e) {
      e.preventDefault()
    }

    const { rootStore } = this.props
    rootStore.ui.hidePriceNote()
  }

  render() {
    const { note, isLast, listIndex, itemIndex, rowIndex, cssNamespace, rootStore } = this.props
    const { priceNote } = rootStore.ui
    const isVisible =
      listIndex === priceNote.listIndex &&
      itemIndex === priceNote.itemIndex &&
      rowIndex === priceNote.rowIndex
    const isTouch = window.Modernizr && window.Modernizr.touchevents

    return (
      <div className={styles.noteBlock}>
        <button
          className={styles.openToggle}
          onClick={this.open}
          onMouseEnter={this.open}
          onMouseLeave={this.close}
        >
          <PriceInfoIcon />
        </button>
        <div
          className={`${styles.note}${isVisible ? ` ${styles.noteIsVisible}` : ''}${
            isLast ? ` ${styles.noteIsLastLine}` : ''
          } ${cssNamespace}`}
        >
          <div
            className={`${styles.noteInner} ${isTouch ? styles.noteInnerTouch : ''} verySmallText`}
          >
            {note}
          </div>
          {isTouch && (
            <button className={styles.closeToggle} onClick={this.close}>
              <CloseIcon className={styles.xIcon} aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
    )
  }
}
