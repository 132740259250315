import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { event as eventType } from '../../lib/prismic/event'
import { promotion as promotionType } from '../../lib/prismic/promotion'

// Libs
import { linkResolverNormalizer } from '../../lib/link-resolver'
import { parsePrismicHtml } from '../../lib/survive-prismic'

// Components
import { PageTitle } from '../../components/Shared/Titles'
import { AbstractLinks } from '../../components/SingleEvent/AbstractLinks'
import { ResponsiveImage } from '../../components/Shared/Templating'
import { SimpleParagraph } from '../../components/TakePart/SimpleParagraph'
import { ParagraphEvent } from '../../components/TakePart/ParagraphEvent'
import { EmbedMedia } from '../../components/TakePart/EmbedMedia'

// Styles
import styles from './TakePartPage.module.css'

const { getCurrent: getCurrentEvents, fields: EVENT } = eventType
const { getCurrent: getCurrentPromotions, fields: PROMOTION } = promotionType

@inject('rootStore')
@observer
export class TakePartPage extends React.Component {
  state = {
    currentEvents: [],
    currentPromotions: [],
  }

  async componentDidMount() {
    const { currentRouteLangCode } = this.props.rootStore.navigation

    const eventFilters = { [EVENT.AUDIENCE]: 'Famiglie e bambini' }
    const currentEvents = (await getCurrentEvents(eventFilters, {}, currentRouteLangCode)).results

    const promotionFilters = { [PROMOTION.AUDIENCE]: ['Famiglie', 'Bambini'] }
    const currentPromotions = (await getCurrentPromotions(
      promotionFilters,
      {},
      currentRouteLangCode,
    )).results

    this.setState({ currentEvents, currentPromotions })
  }

  render() {
    const { takePart, rootStore } = this.props

    if (isEmpty(takePart)) {
      return null
    }

    const { title, cover_image, description, description_links, body } = takePart.data
    const { currentEvents, currentPromotions } = this.state
    const { currentRouteLang } = rootStore.navigation

    return (
      <div className={styles.takePart}>
        <PageTitle title={title} />
        <ResponsiveImage img={cover_image} />
        <div className={styles.contentsWrapper}>
          <div className={styles.header}>
            <div
              className={`typographicTitle`}
              dangerouslySetInnerHTML={{
                __html: parsePrismicHtml(description, linkResolverNormalizer),
              }}
            />
            <div className="bold">
              <AbstractLinks links={description_links} linkKey="description_link" />
            </div>
          </div>

          <div>
            {body.map((paragraph, i) => {
              switch (paragraph.slice_type) {
                case 'paragraph_events':
                  return (
                    <ParagraphEvent
                      key={i}
                      currentEvents={currentEvents}
                      currentPromotions={currentPromotions}
                      currentRouteLang={currentRouteLang}
                    />
                  )
                case 'embed_media':
                  return <EmbedMedia key={i} primary={paragraph.primary} />
                case 'paragraph_basic':
                  return <SimpleParagraph key={i} paragraph={paragraph} />
                case 'paragraph_guided_tours':
                  return <SimpleParagraph key={i} paragraph={paragraph} />
                default:
                  return undefined
              }
            })}
          </div>
        </div>
      </div>
    )
  }
}
