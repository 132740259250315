import Cookies from 'js-cookie'

const USER_CONSENT_COOKIE_NAME = 'cookie-user-consent'

export function initFB(trackingId) {
  // eslint-disable-next-line no-unused-expressions
  !(function(f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
  window.fbq('init', trackingId)
}

export const fbPageViewHit = () => {
  const cookieValue = Cookies.get(USER_CONSENT_COOKIE_NAME)
  if (cookieValue) {
    setTimeout(() => {
      window.fbq('track', 'PageView')
    }, 1000)
  }
}

export const fbEvent = (category, options = {}) => {
  const cookieValue = Cookies.get(USER_CONSENT_COOKIE_NAME)
  if (cookieValue) {
    window.fbq('track', category, options)
  }
}
