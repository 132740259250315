import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'
import { magazineSeries } from '../../lib/prismic/magazineSeries'
import { magazineAuthor } from '../../lib/prismic/magazineAuthor'
import styles from './MagazineListCards.module.css'
import { TYPE_LIST_CARDS } from '../../lib/const'
import { SingleMagazineFocusPreview } from './SingleMagazineFocusPreview'

const haveRelatedSeriesAuthorsInfo = results => type => {
  const info = results.find(({ data }) => {
    if (isEmpty(data?.[type]) || !data?.[type]) return
    return 'value' in data?.[type]?.[0]
  })
  return isEmpty(info)
}

export class MagazineFocusListCards extends Component {
  state = {
    series: [],
    authors: [],
  }

  async componentDidMount() {
    const { results } = this.props
    const fetchSeries = async () =>
      (await magazineSeries.get()).results.map(s => {
        return {
          id: s.id,
          uid: s.uid,
          background_color: s.data.background_color,
          name: s.data.name[0]?.text,
        }
      })
    const fetchAuthors = async () =>
      (await magazineAuthor.get()).results.map(s => {
        return { uid: s.uid, name: s.data.name[0]?.text }
      })
    const haveRelated = haveRelatedSeriesAuthorsInfo(results)
    !haveRelated('magazine_series') && this.setState({ series: await fetchSeries() })
    !haveRelated('magazine_authors') && this.setState({ authors: await fetchAuthors() })
  }

  render() {
    const {
      results,
      options: { typeOfList, limit = 0 },
    } = this.props
    const { series, authors } = this.state

    return (
      results &&
      (typeOfList >= 0 ? (
        <div
          className={`${styles.grid} ${limit ? styles.gridLimit : ''} ${
            !typeOfList ? styles.gridTypeL : ''
          }`}
        >
          {results
            .slice(0, results.length - (typeOfList === 3 ? 1 : typeOfList === 1 ? 0 : typeOfList))
            .map((result, i) => {
              return (
                <SingleMagazineFocusPreview
                  key={`${result.id}_${result.uid}`}
                  data={result}
                  options={{ series, authors }}
                  width={
                    TYPE_LIST_CARDS[typeOfList].indexOf(i) >= 0 ? styles.bigPost : styles.smallPost
                  }
                />
              )
            })}
        </div>
      ) : (
        results.map(result => {
          return (
            <SingleMagazineFocusPreview
              key={`${result.id}-${result.uid}`}
              data={result}
              options={{ series, authors }}
              width={styles.bigPost}
            />
          )
        })
      ))
    )
  }
}
