import React from 'react'
import { inject, observer } from 'mobx-react'
import qs from 'query-string'
import { isEmpty } from 'lodash'
import { translator } from '../lib/translator'
import { PageTitlePlain } from '../components/Shared/Titles'
import styles from './TicketThankYou.module.css'
import { LoaderOval, Link } from '../components'
import { PAGES } from '../lib/const'
import { transformMailAddressIntoLink } from '../lib/utils'

@inject('rootStore')
@observer
export class TicketThankYou extends React.Component {
  state = {
    mailState: 'success',
  }

  componentDidMount() {
    const {
      tickets: { resetSubscription, cart },
    } = this.props.rootStore

    if (this.orderState === 'success') {
      resetSubscription()
      cart.clear()
    }
  }

  get searchObj() {
    return qs.parse(window.location.search.replace('?', ''))
  }

  get orderState() {
    if (!this.searchObj || isEmpty(this.searchObj)) {
      return 'loading'
    }
    return this.searchObj.xpp_restat === '1' ? 'success' : 'error'
  }

  get lang() {
    if (!this.searchObj || isEmpty(this.searchObj)) {
      return 'unknown'
    }
    return this.searchObj.language === '0' ? 'it' : 'en'
  }

  get mailAndOrderStateMsg() {
    const msgs = {
      loading: 'ticketMailLoadMessage',
      success: 'ticketMailSuccessMessage',
      error: 'ticketMailErrorMessage',
    }

    let msg
    if (this.orderState === 'loading' || this.orderState === 'success') {
      msg = msgs[this.state.mailState]
    } else {
      msg = 'thankYouErrorMessage'
    }

    const translatedMsg = translator(msg)(this.lang)
    return msg === 'ticketMailErrorMessage'
      ? transformMailAddressIntoLink(translatedMsg, 'biglietteria@triennale.org')
      : translatedMsg
  }

  render() {
    const { currentRouteLang } = this.props.rootStore.navigation

    if (!this.orderState) {
      return null
    }

    return (
      <div>
        <PageTitlePlain
          title={translator(
            (this.orderState === 'success' && 'thankYouSuccessTitle') ||
              (this.orderState === 'error' && 'thankYouErrorTitle'),
          )(currentRouteLang)}
        />
        <div className={`${styles.thankyou} bodyTextBig bold`}>
          {this.lang !== 'unknown' ? (
            <p dangerouslySetInnerHTML={{ __html: this.mailAndOrderStateMsg }} />
          ) : (
            <LoaderOval />
          )}
          <Link className={`link outline`} to={PAGES['tickets'][currentRouteLang]}>
            {translator('backTicketsPage')(currentRouteLang)}
          </Link>
        </div>
      </div>
    )
  }
}
