import { types as t } from 'mobx-state-tree'
import find from 'lodash/fp/find'
import { LanguageModel } from './prismic'

export const CurrentModel = t
  .model({
    uid: t.maybeNull(t.string),
    alternate_languages: t.maybeNull(t.array(LanguageModel)),
    type: t.maybeNull(t.string),
    data: t.optional(t.frozen(), {}),
    meta: t.optional(t.frozen(), {}),
    pathname: t.optional(t.string, ''),
  })
  .views(self => {
    const getAlternateUid = lang => {
      const sameLang = alternate => alternate.lang === lang
      const alternateLang = find(sameLang)(self.alternate_languages)
      return alternateLang ? alternateLang.uid : null
    }
    return {
      getAlternateUid,
    }
  })
  .actions(self => ({
    setData(data) {
      self.data = { ...data }
    },
    setMeta(data) {
      self.meta = data.meta ? { ...data.meta } : {}
    },
  }))
