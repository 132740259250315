import React from 'react'
import { EVENT_FILTERS, FILTER_TYPES_TO_EXCLUDE } from './lib/filters'
import { EVENT_FILTERS_KEYS } from '../../lib/const'
import OngoingBasicFilters from '../../components/Ongoing/OngoingBasicFilters'

export const OngoingCalendarFilter = ({ id, type }) => {
  return (
    <OngoingBasicFilters
      id={id}
      filters={EVENT_FILTERS}
      filterTypesToExclude={FILTER_TYPES_TO_EXCLUDE}
      keys={EVENT_FILTERS_KEYS}
      type={type}
    />
  )
}
