import React, { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { magazineSeries } from '../../lib/prismic/magazineSeries'
import { magazineAuthor } from '../../lib/prismic/magazineAuthor'
import styles from './HistoryListCards.module.css'
import { SinglePostPreviewLayout } from '../Ongoing/SinglePostPreviewLayout'

export const HistoryListCards = ({ results, options: { typeOfList, limit = 0 } }) => {
  const [series, setSeries] = useState([])
  const [authors, setAuthors] = useState([])

  const haveRelatedSeriesAuthorsInfo = results => type => {
    const info = results.find(({ data }) => {
      if (isEmpty(data?.[type]) || !data?.[type]) return
      return 'value' in data?.[type]?.[0]
    })
    return isEmpty(info)
  }

  useEffect(() => {
    const haveRelated = haveRelatedSeriesAuthorsInfo(results)
    async function fetchSeries() {
      const s = await magazineSeries.get()?.results?.map(s => {
        return {
          id: s.id,
          uid: s.uid,
          background_color: s.data.background_color,
          name: s.data.name[0]?.text,
        }
      })
      setSeries(s)
    }

    async function fetchAuthors() {
      const a = await magazineAuthor.get()?.results?.map(s => {
        return { uid: s.uid, name: s.data.name[0]?.text }
      })
      setAuthors(a)
    }

    if (!haveRelated('magazine_series')) {
      fetchAuthors()
      fetchSeries()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (results && typeOfList >= 0) {
    return (
      <div
        className={`${styles.grid} ${limit ? styles.gridLimit : ''} ${
          !typeOfList ? styles.gridTypeL : ''
        }`}
      >
        {results
          .slice(0, results.length - (typeOfList === 3 ? 1 : typeOfList === 1 ? 0 : typeOfList))
          .map((result, i) => {
            return (
              <SinglePostPreviewLayout
                key={result.id + i}
                data={result}
                options={{ series, authors }}
                width={styles.smallPost}
              />
            )
          })}
      </div>
    )
  }
}
