import React from 'react'
import { inject, observer } from 'mobx-react'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import { expoArchivePage as expoArchivePageFn } from 'triennale-prismic-api-wrapper'
import { linkResolver } from '../lib/link-resolver'
import { Link, ArchiveDisclaimer, SingleArchiveTriennaleTabs, ArchiveLightbox } from '../components'
import styles from './SingleArchiveTriennale.module.css'
import {
  ARCHIVE_API_URL,
  ARCHIVIO_FOTOGRAFICO_ENDPOINT,
  HIERARCHY_ENDPOINT,
  ARCHIVE_MEDIA_KEY,
  ARCHIVIO_STORICO_ENDPOINT,
} from '../lib/const'
import { HeaderSection } from '../components/layoutComponents/HeaderSection'

export const LOAD_STATE_REST = 'rest'
export const LOAD_STATE_LOADING = 'loading'
export const LOAD_STATE_ERROR = 'error'

const getAllPromises = expoId => {
  return [
    ARCHIVIO_FOTOGRAFICO_ENDPOINT,
    ARCHIVIO_STORICO_ENDPOINT,
    // ARCHIVIO_MEDIA_ENDPOINT,
  ].map(archive =>
    axios.get(`${ARCHIVE_API_URL}/${HIERARCHY_ENDPOINT}?expoId=${expoId}&archiveId=${archive}`),
  )
}

@inject('rootStore')
@observer
export class SingleArchiveTriennale extends React.Component {
  state = {
    prevExpo: undefined,
    nextExpo: undefined,
    loadState: LOAD_STATE_REST,

    archivioFotografico: [],
    archivioStorico: [],
    archivioAudiovisivo: [],
  }

  triennaleIndex = null

  async componentDidMount() {
    const { expoArchivePages } = this.props.rootStore
    const routeBits = compact(window.location.href.split('/'))
    this.triennaleIndex = routeBits[routeBits.length - 1]
    const expoData = await expoArchivePageFn.getByUids([`triennale_expo_${this.triennaleIndex}`])
    expoArchivePages.setResults(expoData.results)

    // here fetch all the other data from Vito's API with this.triennaleIndex
    const { id_triennale_prev, id_triennale_next } = expoArchivePages.results[0].data

    if (id_triennale_prev) {
      const prevExpo = await expoArchivePageFn.getByUids([`triennale_expo_${id_triennale_prev}`])
      this.setState({ prevExpo: prevExpo && prevExpo.results[0] })
    }

    if (id_triennale_next) {
      const nextExpo = await expoArchivePageFn.getByUids([`triennale_expo_${id_triennale_next}`])
      this.setState({ nextExpo: nextExpo && nextExpo.results[0] })
    }

    this.setState({ loadState: LOAD_STATE_LOADING }, () => {
      axios
        .all(getAllPromises(this.triennaleIndex))
        .then(
          axios.spread((archivioFotografico, archivioStorico /* , archivioAudiovisivo */) => {
            this.setState({
              archivioFotografico:
                archivioFotografico.data &&
                archivioFotografico.data.map(d => ({
                  label: d.label,
                  slug: d.slug,
                })),
              archivioStorico:
                archivioStorico.data.length && archivioStorico.data[0].children.length
                  ? archivioStorico.data[0].children.find(c => /carteggi/.test(c.label)).children
                  : [],
              // archivioAudiovisivo: archivioAudiovisivo.data || [],
              loadState: LOAD_STATE_REST,
            })
          }),
        )
        .catch(_ => {
          this.setState({ loadState: LOAD_STATE_ERROR })
        })
    })
  }

  render() {
    const { loadState } = this.state
    const { expoArchivePages, navigation, ui } = this.props.rootStore
    const { archiveSingleTriennale, archiveSingleTriennaleActiveTab } = ui
    const { currentRouteLang } = navigation
    const { getAccordionContentsByKey, lastAddedAccordionKey } = archiveSingleTriennale
    const {
      prevExpo,
      nextExpo,
      archivioFotografico,
      archivioStorico,
      archivioAudiovisivo,
    } = this.state

    if (!expoArchivePages.results.length) {
      return null
    }

    const { id_triennale_prev, id_triennale_next } = expoArchivePages.results[0].data

    return (
      <div>
        <HeaderSection />
        <SingleArchiveTriennaleTabs
          archivio-fotografico={archivioFotografico}
          archivio-audiovisivo={archivioAudiovisivo}
          archimista-triennale={archivioStorico}
          parentLoadState={loadState}
          triennaleIndex={this.triennaleIndex}
        />
        <ArchiveDisclaimer />
        <div className={`${styles.navLinkBlock}`}>
          <div className={`${styles.navLinkWrapper}`}>
            {prevExpo && (
              <Link
                className={`${styles.navLink} navLink link noIcon`}
                to={linkResolver({
                  contentType: 'triennale-archives',
                  uid: id_triennale_prev,
                  lang: currentRouteLang,
                })}
              >
                ← {prevExpo.data.title_brief}
              </Link>
            )}
          </div>
          <div className={`${styles.navLinkWrapper} ${styles.next}`}>
            {nextExpo && (
              <Link
                className={`${styles.navLink} navLink link noIcon`}
                to={linkResolver({
                  contentType: 'triennale-archives',
                  uid: id_triennale_next,
                  lang: currentRouteLang,
                })}
              >
                {nextExpo.data.title_brief} →
              </Link>
            )}
          </div>
        </div>
        {archiveSingleTriennaleActiveTab === ARCHIVE_MEDIA_KEY && (
          <ArchiveLightbox resultsArr={archivioAudiovisivo} />
        )}
        {lastAddedAccordionKey && !isEmpty(getAccordionContentsByKey(lastAddedAccordionKey)) && (
          <ArchiveLightbox resultsArr={getAccordionContentsByKey(lastAddedAccordionKey)} />
        )}
      </div>
    )
  }
}
