import React from 'react'
import styles from '../../routes/SingleMagazine.module.css'

export const Quote = ({ primary }, i) => {
  const { content, abstract } = primary

  return (
    <div key={i} className={`${styles.quote}`}>
      {abstract.map((section, j) => (
        <strong className={styles.quoteAbstract} key={j}>
          {section.text}
        </strong>
      ))}
      {content.map((section, j) => (
        <h2 className={styles.quoteText} key={j}>
          {section.text}
        </h2>
      ))}
    </div>
  )
}
