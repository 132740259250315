import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './GoForwardBtn.module.css'

@inject('rootStore')
@observer
export class GoForwardBtn extends React.Component {
  render() {
    const { label, onClick, disabled } = this.props
    return (
      <div className={styles.buttonWrapper}>
        <button className={`button smallText`} onClick={onClick} disabled={disabled} >
          {label}
        </button>
      </div>
    )
  }
}
