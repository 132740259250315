import React from 'react'
import { observer, inject } from 'mobx-react'
import styles from '../../routes/Tickets.module.css'
import { TicketSubscriptionForm } from './TicketSubscriptionForm'
import { TicketSubscriptionInfo } from './TicketSubscriptionInfo'

export const TicketSubscription = inject('rootStore')(
  observer(props => {
    const {
      rootStore: {
        navigation: { currentRouteLang },
        tickets: { customer, subscription, isSubscriptionAccount, resetSubscription },
      },
    } = props
    return (
      <div className={`sectionTitle ${styles.subscriptionContainer}`}>
        {!isSubscriptionAccount ? (
          <TicketSubscriptionForm {...subscription} />
        ) : (
          <TicketSubscriptionInfo
            {...subscription}
            {...customer}
            onClickHandler={resetSubscription}
            currentLang={currentRouteLang}
          />
        )}
      </div>
    )
  }),
)
