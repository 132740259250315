import React from 'react'
import isEmpty from 'lodash/isEmpty'
import styles from './PriceTableSection.module.css'
import { SectionTitlePlain } from './Titles'
import { translator } from '../../lib/translator'
import {
  filterEmptyPrismicRepeatersContents,
  parsePrismicHtml,
  parsePrismicText,
} from '../../lib/survive-prismic'
import { PriceNote } from './PriceNote'
import { linkResolverNormalizer } from '../../lib/link-resolver'

function getLabelInLang(field, lang) {
  return field ? field[lang] : ''
}

function getActualPriceFormatted(possiblePrice, lang) {
  let _price = possiblePrice.replace('.', ',')

  if (_price === '') {
    return null
  }

  if (_price === 'FREE') {
    _price = translator('freeEventLabel')(lang)
  }

  return _price
}

export const TableTwoCols = (table, lang, index) => {
  return (
    <>
      <div className={styles.singleTable}>
        <div className={`${styles.tbody}`}>
          {table.rates.map((rate, i) => {
            const price = getActualPriceFormatted(rate.prices[0], lang)
            const areRowsOdd = table.rates.length % 2 !== 0
            const isLastRow = areRowsOdd
              ? table.rates.length - 1 === i
              : table.rates.length - 1 === i || table.rates.length - 2 === i

            return (
              <div className={styles.tr} key={i}>
                <div className={styles.td}>
                  {getLabelInLang(rate.target, lang)}
                  {rate.notes && rate.notes[lang] !== '' && (
                    <PriceNote
                      isLast={isLastRow}
                      listIndex={index}
                      itemIndex={i}
                      note={rate.notes[lang]}
                      cssNamespace="twoColsPriceTableNote"
                    />
                  )}
                </div>
                <div className={styles.td}>{price}</div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export const TableMultiCols = (table, lang, index) => {
  return (
    <>
      {/* DESKTOP */}
      <table className={`${styles.multiTable} ${styles.onlyDesktop}`}>
        <tbody className={`${styles.tbody}`}>
          <tr className={`${styles.tr}`}>
            {['', ...table.rates_names].map((name, i) => {
              return (
                <th key={i} className={`bold ${styles.th}`}>
                  {getLabelInLang(name, lang)}
                </th>
              )
            })}
          </tr>

          {table.rates.map((rate, i) => {
            const areAllNull = rate.prices.every(p => p === '')
            if (areAllNull) {
              return null
            }

            const areRowsOdd = table.rates.length % 2 !== 0
            const isLastRow = areRowsOdd && table.rates.length - 1 === i

            return (
              <tr className={styles.tr} key={i}>
                <td className={`${styles.td} ${styles.titleMobile}`}>
                  {getLabelInLang(rate.target, lang)}

                  {rate.notes && rate.notes[lang] !== '' && (
                    <PriceNote
                      isLast={isLastRow}
                      listIndex={index}
                      itemIndex={i}
                      rowIndex={0}
                      note={rate.notes[lang]}
                      cssNamespace="multiColsPriceTableNote"
                    />
                  )}
                </td>
                {rate.prices.map((price, j) => {
                  return (
                    <td className={styles.td} key={j}>
                      <span>{getActualPriceFormatted(price, lang)}</span>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      {/* MOBILE */}
      {table.rates_names.map((name, i) => {
        return (
          <table key={i} className={`${styles.multiTable} ${styles.onlyMobile}`}>
            <tbody className={`${styles.tbody}`}>
              <tr className={`${styles.tr}`}>
                <th className={`bold ${styles.th}`}>{getLabelInLang(name, lang)}</th>
              </tr>

              {table.rates.map((rate, j) => {
                const areAllNull = rate.prices.every(p => p === '')
                if (areAllNull) {
                  return null
                }

                const price = getActualPriceFormatted(rate.prices[i], lang)

                return (
                  price && (
                    <tr key={j} className={styles.tr}>
                      <td className={`${styles.td}`}>
                        {getLabelInLang(rate.target, lang)}

                        {rate.notes && rate.notes[lang] !== '' && (
                          <PriceNote
                            isLast={false}
                            listIndex={index}
                            itemIndex={i}
                            rowIndex={j}
                            note={rate.notes[lang]}
                            cssNamespace="multiColsPriceTableNote"
                          />
                        )}
                      </td>

                      <td className={styles.td} key={j}>
                        <span>{price}</span>
                      </td>
                    </tr>
                  )
                )
              })}
            </tbody>
          </table>
        )
      })}
    </>
  )
}

export const PriceTableSection = ({ table, lang, index }) => {
  if (isEmpty(table)) {
    return null
  }
  let TableElement
  if (table.rates_names.length > 1) {
    TableElement = TableMultiCols(table, lang, index)
  } else {
    TableElement = TableTwoCols(table, lang, index)
  }

  const _note = filterEmptyPrismicRepeatersContents(table.note, 'text')
  const hasNote = _note?.length > 0

  const _links = filterEmptyPrismicRepeatersContents(table.links, 'link_url')
  const hasLinks = _links?.length > 0
  const linkAreEmpty = links => links.findIndex(l => isEmpty(l.link_label)) >= 0

  return (
    <section className={`${styles.section}${hasNote ? ` ${styles.withNote}` : ''}`} key={index}>
      <SectionTitlePlain title={table?.name?.[0]?.text} />
      {hasNote && (
        <div
          className={`verySmallText ${styles.disclaimer}`}
          dangerouslySetInnerHTML={{
            __html: parsePrismicHtml(_note, linkResolverNormalizer),
          }}
        />
      )}
      <div className={`${styles.tableWrapper} ${!hasLinks ? styles.tableWrapperNoLinks : ''}`}>
        {TableElement}
      </div>
      {hasLinks && !linkAreEmpty && (
        <div className={styles.tableLinks}>
          {_links.map(({ link_url, link_label }, i) => {
            return (
              !isEmpty(link_label) && (
                <a key={i} href={link_url.url} className={`link`}>
                  <span dangerouslySetInnerHTML={{ __html: parsePrismicText(link_label) }} />
                </a>
              )
            )
          })}
        </div>
      )}
    </section>
  )
}
