import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { observer, inject } from 'mobx-react'
import { translator } from '../../lib/translator'
import styles from '../../routes/Tickets.module.css'
import { isASubscriptionOrExhibition } from '../../temp/ticketing-utils'
import { TicketEvent } from './TicketEvent'

export const ListTicketsEvents = inject('rootStore')(
  observer(props => {
    const {
      rootStore: {
        navigation: { currentRouteLang },
      },
      tickets,
    } = props
    const ticketEvents = tickets.filter(t => t.prismicEventId && !isASubscriptionOrExhibition(t))
    const ticketSubscription = tickets.filter(
      t => isEmpty(t.prismicEventId) && isASubscriptionOrExhibition(t) && t.id_show !== '179559',
    )
    const ticketExhibition = tickets.filter(
      t => !isEmpty(t.prismicEventId) && isASubscriptionOrExhibition(t),
    )

    const TICKETS = {
      subscription: ticketSubscription,
      exhibition: ticketExhibition,
      event: ticketEvents,
    }

    return Object.keys(TICKETS).map((key, index) => {
      return (
        !isEmpty(TICKETS[key]) && (
          <div className={styles.listTicketsContainer} key={index}>
            <h2 className="sectionTitle">{translator(key)(currentRouteLang)}</h2>
            <ul className={`reset-list ${styles.list}`}>
              {TICKETS[key].map((ticket, i) => {
                return <TicketEvent key={i} {...ticket} />
              })}
            </ul>
          </div>
        )
      )
    })
  }),
)
