import { types as t, flow, getRoot } from 'mobx-state-tree'
import { intersection, orderBy, groupBy, clamp } from 'lodash'
import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import 'dayjs/locale/it'
import {
  getEventsList,
  getScheduledTours,
  getSubscriptionInfo,
  getSinglePerformancePrices,
} from './ticketing-api'
import {
  isASubscriptionOrExhibition,
  isEqualBy,
  isPast,
  keepValueInBounds,
  remapZones,
  // getIdFromUrl,
} from './ticketing-utils'
import { fetchCurrentPrismicEvents } from '../lib/tickets-utils'
import { validateEmail } from '../lib/utils'
import { EventsModel } from '../store/event'
import { TICKET_SUBSCRIPTION } from '../lib/const'

dayjs.extend(dayOfYear)

/*
 * PRIVATE
 */
const ReductionModel = t
  .model({
    id: t.string,
    description: t.string,
    explanation: t.string,
    maxbuy: t.number,
    price: t.number,
    presale: t.number,
    commission: t.number,
    iva: t.number,
  })
  .views(self => ({
    get totalPrice() {
      return self.price + self.presale + self.commission + self.iva
    },
  }))

const ZoneModel = t.model({
  id: t.string,
  description: t.string,
  eng_description: t.string,
  zone_id: t.string,
  availability: t.number,
  max_buy: t.number,
  reductions: t.array(ReductionModel),
})

const PerformanceModel = t
  .model({
    pcode: t.string,
    zone: t.optional(t.array(ZoneModel), []),
    lastMinute: t.string,
    idEvento: t.string,
    stato: t.string,
    time: t.string,
    printHome: t.string,
    abb: t.string,
    dataInizio: t.string,
    dataFine: t.string,
    hasMap: t.string,
    nonRelativeGap: t.maybe(t.string),
  })
  .actions(self => ({
    setZones(zones) {
      self.zone = remapZones(zones)
    },
    clearZones() {
      self.zone = []
    },
    fetchPrices: flow(function* fetchData({ pcode, vcode }) {
      const { setState } = getRoot(self).tickets
      setState('loading')
      try {
        const { data } = yield getSinglePerformancePrices({ pcode, vcode })
        self.setZones(data.zone)
        setState('rest')
      } catch (err) {
        console.log(err)
        setState('error')
      }
    }),
  }))
  .views(self => ({
    get hasZones() {
      return self.zone.length
    },
    get isSoldOut() {
      return self.hasZones && self.zone.every(({ availability }) => availability === 0)
    },
    get isPast() {
      return isPast(self.dataFine, self.time)
    },
  }))

const TicketModel = t
  .model({
    vcode: t.string,
    name: t.string,
    primaData: t.string,
    ultimaData: t.string,
    id_show: t.string,
    description: t.maybeNull(t.string),
    editName: t.maybeNull(t.string),
    performance: t.array(PerformanceModel),
    prismicEventId: t.maybe(t.string),
    url: t.maybe(t.string),
    type: t.maybe(t.string),
  })
  .views(self => ({
    get pcodes() {
      return self.performance.map(({ pcode }) => pcode)
    },
    get isSoldOut() {
      return self.performance.every(({ isSoldOut }) => isSoldOut)
    },
    get performancesWithAvailableTickets() {
      return self.performance.filter(({ isSoldOut }) => !isSoldOut)
    },
    get futurePerformances() {
      return self.performance.filter(({ isPast }) => !isPast)
    },
    get buyablePerformances() {
      return intersection(self.performancesWithAvailableTickets, self.futurePerformances)
    },
    get isAPerformance() {
      return self.prismicEventId && !isASubscriptionOrExhibition(self)
    },
    get isAnExhibition() {
      return self.prismicEventId && isASubscriptionOrExhibition(self)
    },
    get isASubscription() {
      return !self.prismicEventId && isASubscriptionOrExhibition(self)
    },
  }))

const CustomerModel = t
  .model({
    firstname: t.maybeNull(t.string),
    lastname: t.maybeNull(t.string),
    email: t.maybeNull(t.string),
    emailConfirmation: t.maybeNull(t.string),
    phone: t.maybeNull(t.string),
    agreementB: 'No',
    agreementC: 'No',
    agreementD: 'No',
  })
  .actions(self => ({
    setCustomer(key, value) {
      self[key] = value
    },
    setData({
      firstname,
      lastname,
      email,
      emailConfirmation,
      phone,
      agreementB,
      agreementC,
      agreementD,
    }) {
      self.firstname = firstname
      self.lastname = lastname
      self.email = email
      self.emailConfirmation = emailConfirmation || email
      self.phone = phone
      self.agreementB = agreementB
      self.agreementC = agreementC
      self.agreementD = agreementD
    },
  }))
  .views(self => ({
    get isEmailValid() {
      return validateEmail(self.email)
    },
    get doEmailsMatch() {
      return self.email === self.emailConfirmation
    },
  }))

const SubscriptionModel = t
  .model({
    title: '',
    barcode: '',
    remainingaccruals: t.maybe(t.string),
    numaccruals: t.maybe(t.string),
    error: '',
    availablePerformancesPCodes: t.optional(t.array(t.string), []),
  })
  .actions(self => ({
    clearPerformances() {
      self.availablePerformancesPCodes = []
    },
    setError(msg) {
      self.error = msg
    },
    resetError() {
      self.error = ''
    },
  }))
  .views(self => ({}))

const TourModel = t.model({
  Data: t.string,
  Stato: t.string,
  Inizio: t.string,
  Tipologia: t.optional(t.array(t.string), []),
  'ID Vivaticket': t.optional(t.array(t.number), []),
})

const CartItemModel = t
  .model({
    vcode: t.string,
    pcode: t.string,
    reductionId: t.string,
    nTickets: t.number,
    zoneId: t.string,
    price: t.number,
    relatives: t.maybe(t.string),
  })
  .actions(self => ({
    setRelatives(value) {
      self.relatives = value
    },
  }))

const CartModel = t
  .model({
    data: t.optional(t.array(CartItemModel), []),
  })
  .actions(self => ({
    addItem(itemToAdd) {
      const currentItemIndex = self.findItemIndex(itemToAdd)
      const additionalNTickets = currentItemIndex > -1 ? self.data[currentItemIndex].nTickets : 0
      const nTickets = keepValueInBounds([
        itemToAdd.maxbuy,
        itemToAdd.availability,
        additionalNTickets + 1,
      ])
      if (currentItemIndex > -1) {
        self.data.splice(currentItemIndex, 1)
      }
      self.data.push({
        ...itemToAdd,
        nTickets,
      })
    },
    subtractItem(itemToSubtract) {
      const currentItemIndex = self.findItemIndex(itemToSubtract)
      if (currentItemIndex === -1) {
        return
      }
      const nTickets = self.data[currentItemIndex].nTickets
      const amountTickets = nTickets - 1
      if (amountTickets > 0) {
        self.data[currentItemIndex].nTickets = amountTickets
      } else {
        self.data.splice(currentItemIndex, 1)
      }
    },
    subtractReduction(zoneId, reductionId) {
      self.data = self.data.filter(d => d.zoneId !== zoneId || d.reductionId !== reductionId)
    },
    clear() {
      self.data = []
    },
    updateRelatives(zoneId, value) {
      self.data.forEach(d => {
        if (d.zoneId === zoneId) d.setRelatives(value)
      })
    },
  }))
  .views(self => ({
    findItemIndex(item) {
      return self.data.findIndex(storeItem =>
        isEqualBy(storeItem, item, ['vcode', 'pcode', 'reductionId', 'zoneId']),
      )
    },
    get amountByZone() {
      const group = groupBy(self.data, 'zoneId')
      const sumNTickets = (acc, value) => value.nTickets + acc
      return Object.entries(group).map(([zoneId, reduction]) => ({
        amount: reduction.reduce(sumNTickets, 0),
        zoneId,
      }))
    },
    get selectedReductions() {
      const cartItems = self.data
      const zone = getRoot(self).tickets.selectedPerformance?.zone ?? []
      const reductionsInCart = curr => {
        const { reductionId, zoneId, nTickets } = curr
        const reductions = zone.find(z => z.zone_id === zoneId)?.reductions ?? []
        const reductionByReductionId = reductions.find(r => r.id === reductionId)
        return { ...reductionByReductionId, zoneId, reductionId, nTickets }
      }
      return cartItems.map(reductionsInCart)
    },
  }))

/*
 * PUBLIC
 */
export const TicketsModel = t
  .model('TicketsModel', {
    state: t.optional(
      t.enumeration('dataState', ['rest', 'loading', 'error', 'notFound']),
      'loading',
    ),
    data: t.optional(t.array(TicketModel), []), // don't use tickets.data to get the tickets, use tickets.availableTickets
    subscription: t.optional(SubscriptionModel, {}),
    customer: t.optional(CustomerModel, {}),
    cart: t.optional(CartModel, { data: [] }),
    performanceId: t.maybe(t.string),
    showId: t.maybe(t.string),
    showTimesIds: t.optional(t.array(t.string), []),
    // showTimes: t.frozen(),
    showDate: t.frozen(),
    scheduledTours: t.optional(t.array(TourModel), []),
    currentStep: 0,
  })
  .actions(self => ({
    afterCreate() {
      const subscription = JSON.parse(window.localStorage.getItem(TICKET_SUBSCRIPTION)) || {}
      self.setSubscription(subscription)
      self.customer.setData({
        ...subscription.customer,
        phone: subscription.customer?.telephone,
      })
    },
    setPerformanceId(performanceId) {
      self.performanceId = performanceId
    },
    async setPerformanceAndFetchData(performanceId) {
      self.setPerformanceId(performanceId)
      const ticket = self.ticketByShowId
      const pcode = self.selectedPerformance.pcode
      await self.selectedPerformance.fetchPrices({ pcode, vcode: ticket.vcode })
    },
    setShowId(showId) {
      self.showId = showId
    },
    setTickets(data) {
      self.data = orderBy(self.withPrismicEvents(data), z => dayjs(z.primaData, 'DD-MM-YYYY'))
    },
    setScheduledTours(data) {
      self.scheduledTours = data
    },
    setState(state) {
      self.state = state
    },
    setShowTimesIds(performancesIds) {
      // update name showTimes
      self.showTimesIds = performancesIds
    },
    setCurrentStep(currentStep) {
      self.currentStep = currentStep
    },
    setNextStep() {
      const step = clamp(self.currentStep + 1, 0, 2)
      self.setCurrentStep(step)
    },
    setSubscription(subscription) {
      self.subscription = subscription
      window.localStorage.setItem(TICKET_SUBSCRIPTION, JSON.stringify({ ...subscription }))
    },
    resetSubscription() {
      self.subscription = {}
      window.localStorage.removeItem(TICKET_SUBSCRIPTION)
    },
  }))
  .views(self => ({
    get ticketSubscriptionOrExhibition() {
      return self.data.filter(isASubscriptionOrExhibition)
    },
    get performanceTickets() {
      return self.data.filter(({ isAPerformance }) => isAPerformance)
    },
    get exhibitionTickets() {
      return self.data.filter(({ isAnExhibition }) => isAnExhibition)
    },
    get subscriptionTickets() {
      return self.data.filter(({ isASubscription }) => isASubscription)
    },
    /*
     * This is weird, so this is how it works. When a user enters his/hers subscription barcode,
     * the VivaTicket API is called on the /subscription endpoint and it answers with everything
     * in the SubscriptionModel. In that model, there is a "performance" key. That "performance"
     * is an array of pcodes with the performances, belonging to vivaticket events, for which
     * the user can reserve tickets. So the function below filters out the events and performances
     * for which the user can reserve a ticket. If the user is logged in and the page is "Tickets",
     * this is the list of tickets displayed instead of the generic self.data.
     */
    get subscribedUserAvailableTickets() {
      const mappedVivaticketEvents = self.data.map(vivaticketEvent => {
        const performance = vivaticketEvent.performance.filter(prfmc =>
          self.subscription.availablePerformancesPCodes.includes(prfmc.pcode),
        )

        return {
          ...vivaticketEvent,
          performance,
        }
      })

      return mappedVivaticketEvents.filter(
        vivaticketEvent => vivaticketEvent.performance.length > 0,
      )
    },
    get availableTickets() {
      return self.subscription.availablePerformancesPCodes.length > 0
        ? self.subscribedUserAvailableTickets
        : self.data
    },
    get isSubscriptionAccount() {
      const { barcode } = self.subscription
      const { firstname, lastname } = self.customer
      return Boolean(barcode && firstname && lastname)
    },
    get timeSelectedByPerformancesIds() {
      return (
        self.ticketByShowId &&
        self.ticketByShowId.performance.filter(p => self.showTimesIds.indexOf(p.idEvento) >= 0)
      )
    },
    get groupedPerformances() {
      const ticket = self.ticketByShowId
      const performancesTicket = ticket?.performance || []
      return groupBy(performancesTicket, 'dataInizio')
    },
    get calendarEvents() {
      // Check if a subscription. Subscription have performance at first day of the year. T B UPDATED
      const isASubsciption =
        Object.keys(self.groupedPerformances).length === 1 &&
        dayjs(Object.keys(self.groupedPerformances)[0]).dayOfYear() === 1
      return isASubsciption
        ? []
        : Object.keys(self.groupedPerformances).map(date => {
            const [day, month, year] = date.split('/')
            return new Date(year, month - 1, day)
          })
    },
  }))
  .views(self => ({
    get ticketByShowId() {
      return self.availableTickets.find(ticket => ticket.id_show === self.showId)
    },
    get selectedPerformance() {
      return self.ticketByShowId?.performance.find(p => p.idEvento === self.performanceId)
    },
    getTicketFromPcode(pcode) {
      return self.data.find(({ pcodes }) => pcodes.includes(pcode))
    },
  }))
  .views(self => ({
    withPrismicEvents(vivaticketEvents) {
      const { events: prismicEvents } = getRoot(self)

      return vivaticketEvents.map(vivaticketEvent => {
        const associatedPrismicEvent = prismicEvents.results.find(prismicEvent => {
          if (prismicEvent.data.show_id) {
            return prismicEvent.data.show_id === vivaticketEvent.id_show
          }
          /* else if (prismicEvent.data.buy_ticket_link.url) {
            return getIdFromUrl(prismicEvent.data.buy_ticket_link.url) === vivaticketEvent.id_show
          } */
        })
        return {
          ...vivaticketEvent,
          url: associatedPrismicEvent?.data?.buy_ticket_link?.url,
          prismicEventId: associatedPrismicEvent?.id,
          type: (associatedPrismicEvent && associatedPrismicEvent.data.type) || 'Subscription',
        }
      })
    },
  }))
  .actions(self => ({
    fetchScheduledTours: flow(function* fetchData() {
      try {
        const { data } = yield getScheduledTours(self.showId)
        self.setScheduledTours(data)
      } catch (err) {
        console.log(err)
      }
    }),
    fetchTickets: flow(function* fetchData() {
      self.state = 'loading'
      try {
        const { events } = yield getEventsList()
        self.setTickets(events)
        self.state = 'rest'
      } catch (err) {
        console.log(err)
        self.state = 'error'
      }
    }),
    fetchSubscriptionInfo: flow(function*(barcode) {
      const { data, type } = yield getSubscriptionInfo({ barcode })
      if (type !== 'error') {
        self.customer.setData({
          ...data.customer,
          phone: data.telephone,
        })
        self.setSubscription({
          ...data,
          availablePerformancesPCodes: data.performance.map(p => p.pcode),
        })
      } else {
        self.subscription.setError(data.message)
      }
    }),
    async fetchPrismicAndVivaticketEvents() {
      const { setResults } = getRoot(self).events
      const currentPrismicEvents = await fetchCurrentPrismicEvents()
      setResults(currentPrismicEvents)
      await self.fetchTickets()
    },
  }))

export const StoreModel = t.model('StoreModel', {
  tickets: TicketsModel,
  events: EventsModel,
})
