import React from 'react'
import styles from './Titles.module.css'
import { linkResolver } from '../../lib/link-resolver'
import { Link } from '../../components'
import { parsePrismicHtml, parsePrismicText } from '../../lib/survive-prismic'

export const SectionTitle = ({ title, border = false }) => {
  return (
    <h1
      className={`sectionTitle ${styles.sectionTitle}
      ${border ? styles.titleBorder : ''}`}
      dangerouslySetInnerHTML={{ __html: parsePrismicText(title) }}
    />
  )
}

export const SectionTitlePlain = ({ title, border = false, justify = 'left' }) => {
  return (
    <h1
      className={`sectionTitle ${styles.sectionTitle}
      ${border ? styles.titleBorderTop : ''}
      ${justify === 'center' ? ` ${styles.sectionTitleCenter}` : ''}`}
    >
      {title}
    </h1>
  )
}

export const SectionTitlePlainWithLink = ({
  title,
  cta,
  justify = 'left',
  link,
  border = false,
  externalLink,
} = {}) => {
  return (
    <h1
      className={`sectionTitle ${styles.sectionTitle}
      ${justify === 'center' ? ` ${styles.sectionTitleCenter}` : ''}
      ${border ? styles.titleBorderTop : ''}
      `}
    >
      {link && link.href ? (
        <a
          className={`${styles.pageTitleLink} sectionLink`}
          href={link.href}
          title={link.title}
          target={externalLink ? '_blank' : ''}
        >
          {title}
          {cta && (
            <>
              <br />
              {cta} →
            </>
          )}
        </a>
      ) : (
        <>{title}</>
      )}
    </h1>
  )
}

export const PageTitle = ({ title, border = true }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: parsePrismicHtml(title) }}
      className={`${styles.pageTitle} ${border ? styles.titleBorder : ''} mainTitle bold `}
    />
  )
}

export const PageTitlePlain = ({ title, border = true }) => {
  return (
    <h1
      className={`${styles.pageTitle} mainTitle
  ${border ? styles.titleBorder : ''}
  `}
    >
      {title}
    </h1>
  )
}

export const PageTitleClickableTemp = ({ title, link }) => {
  return link ? (
    <Link className={`internalLink ${styles.pageTitleLink} ${styles.tempLink}`} to={link}>
      <div
        dangerouslySetInnerHTML={{ __html: parsePrismicHtml(title) }}
        className={`${styles.pageTitle} mainTitle uppercase regular letterSpaced`}
      />
    </Link>
  ) : (
    <div
      className={`${styles.tempLinkWrapper} ${styles.pageTitle} mainTitle uppercase regular letterSpaced`}
    >
      {title}
    </div>
  )
}

export const PageHeaderPlain = ({ title, link }) => {
  return (
    <div className={styles.header}>
      <p className={`${styles.pageTitle} mainTitle bold letterSpaced`}>
        {link ? (
          <Link className={`internalLink ${styles.pageTitleLink}`} to={linkResolver(link)}>
            {title}
          </Link>
        ) : (
          <>{title}</>
        )}
      </p>
    </div>
  )
}
