import React from 'react'
import { inject, Observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { composeUidOut } from '../../lib/utils/uid-utils'
import { LoaderOval } from '../../components'
import { RegularEvent } from './RegularEvent'
import { SpecialCommissionEvent } from './SpecialCommissionEvent'

export const SingleEvent = inject('rootStore')(({ rootStore }) => {
  const {
    getBy,
    navigation: {
      router: {
        location: { pathname },
      },
    },
  } = rootStore
  const uid = composeUidOut(pathname)(['eventi', 'events'])
  const event = getBy('events')({ uid })

  if (isEmpty(event)) {
    return <LoaderOval />
  }

  const {
    data: { special_commissions },
  } = event

  return (
    <Observer>
      {() => {
        if (!special_commissions?.length > 0) {
          return <RegularEvent event={event} />
        }
        return <SpecialCommissionEvent event={event} />
      }}
    </Observer>
  )
})
