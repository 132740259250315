import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import { composeUidOut } from '../../lib/utils/uid-utils'
import { LoaderOval } from '../../components'
import styles from '../../routes/SingleMagazine.module.css'
import { BtnMagazineHome } from '../../components/Magazine/BtnMagazineHome'
import { StickyMediaBlock } from '../../components/Shared/StickyBlock'
import { PublishingInfo } from './PublishingInfo'
import { RelatedEvents } from './RelatedEvents'
import { RelatedArticles } from './RelatedArticles'
import { MagazineTitle } from './MagazineTitle'
import { MagazineSerie } from './MagazineSerie'
import { Content } from '../../routes/SingleMagazine'

@inject('rootStore')
@observer
export class SingleMagazineLayout extends React.Component {
  render() {
    const { forwardedRef, rootStore } = this.props
    const {
      getBy,
      navigation: {
        currentRouteLang,
        router: {
          location: { pathname },
        },
      },
    } = rootStore
    const uid = composeUidOut(pathname)(['magazine'])
    const singlePost = getBy('magazine')({ uid })

    if (isEmpty(singlePost)) {
      return <LoaderOval />
    }

    const {
      first_publication_date,
      data: {
        title,
        cover_image: image,
        cover_image_caption: caption,
        body,
        magazine_series,
        magazine_authors,
        related_events,
        related_articles,
      },
    } = singlePost

    const hasRelatedEvents = () => related_events[0] && related_events[0].hasOwnProperty('id')
    const hasRelatedArticles = () => related_articles[0] && related_articles[0].hasOwnProperty('id')

    const stickyEndSectionIndex = body.findIndex(
      s =>
        s.slice_type === 'image_gallery' ||
        (s.primary.paragraph_image && !isEmpty(s.primary.paragraph_image)) ||
        s.slice_type === 'quote',
    )
    const hasDoubleColumnContent = stickyEndSectionIndex > -1
    const serieRelatedInfo = series =>
      series.map(({ uid, data }) => ({
        name: data?.name?.[0].text,
        background_color: data?.background_color,
        uid,
      }))[0]

    return (
      <>
        <BtnMagazineHome />
        <div className={styles.doubleColumnWrapper}>
          <div className={styles.imgStickyContainer}>
            <StickyMediaBlock
              image={image}
              caption={caption}
              renderAsPrismic={true}
              sticky={true}
            />
          </div>
          <div className={styles.magazineCopyContainer}>
            <div className={styles.magazineHeader}>
              <MagazineSerie {...serieRelatedInfo(magazine_series)} />
              {title && <MagazineTitle title={title} />}
              {magazine_authors && first_publication_date && (
                <PublishingInfo data={magazine_authors} publication_date={first_publication_date} />
              )}
            </div>
            {hasDoubleColumnContent ? (
              <Content data={body.slice(0, stickyEndSectionIndex)} />
            ) : (
              <Content data={body} />
            )}
          </div>
        </div>
        {hasDoubleColumnContent && (
          <div ref={forwardedRef} className={styles.mainMagazineContent}>
            <Content data={body.slice(stickyEndSectionIndex, body.length)} />
          </div>
        )}
        {hasRelatedEvents() && <RelatedEvents data={related_events} lang={currentRouteLang} />}
        {hasRelatedArticles() && (
          <RelatedArticles data={related_articles} lang={currentRouteLang} />
        )}
      </>
    )
  }
}
