import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import slice from 'lodash/slice'
import { event as contentType } from '../../lib/prismic/event'
import { fetchWithFilters } from '../../lib/routes-utils'
import { SwitcherDataState } from '../Shared/DataState'
import { Events } from '../Shared/Events'
import { LoadMore } from '../layoutComponents'

@inject('rootStore')
@observer
class CurrentEvents extends React.Component {
  state = { page: 0, resultsPerPage: 12 }
  async componentDidMount() {
    await this.fetchEvents()
  }

  componentWillUnmount = () => {
    this.setState({ page: 0 })
  }

  fetchEvents = async () => {
    const pagination = this.state
    const { rootStore } = this.props
    const {
      navigation: { currentRouteLangCode },
    } = rootStore
    const options = { ongoing_whitelist: true }
    await fetchWithFilters(rootStore, currentRouteLangCode, pagination, options, contentType)
  }

  handlePagination = () => {
    const { page } = this.state
    this.setState({ page: page + 1 }, async () => {
      await this.fetchEvents()
    })
  }

  isLastPage = () => {
    const {
      events: { totalPages },
    } = this.props.rootStore
    const { page } = this.state
    return page + 1 === totalPages
  }

  render() {
    const { limit, rootStore, areFiltersClickable } = this.props
    const { results, dataState } = rootStore.events

    const _events = !isEmpty(results)
      ? limit
        ? slice(results.toJSON(), 0, limit)
        : results
      : results

    return (
      <>
        {SwitcherDataState(Events, _events, { areFiltersClickable })[dataState]}
        {!limit && !this.isLastPage() && _events?.length > 0 && (
          <LoadMore onClick={this.handlePagination} />
        )}
      </>
    )
  }
}

export default CurrentEvents
