import React from 'react'
import { inject, Observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import styles from './listSeries.module.css'
import { ListSeriesItem } from './ListSeriesItem'

export const ListSeriesFocused = inject('rootStore')(({ rootStore, options, results }) => {
  const {
    navigation: { currentRouteLang },
  } = rootStore

  if (isEmpty(results)) {
    return null
  }

  return (
    <Observer>
      {() => {
        return (
          <>
            {results.map((serie, i) => {
              return (
                <ListSeriesItem
                  key={i}
                  data={serie}
                  uid={serie?.uid}
                  options={{
                    ...options,
                    isFocused: true,
                    lang: currentRouteLang,
                  }}
                />
              )
            })}
          </>
        )
      }}
    </Observer>
  )
})

export const ListSeries = inject('rootStore')(({ rootStore, options }) => {
  const {
    navigation: { currentRouteLang },
    magazineSeries: { getResultsOrderedByTitle },
  } = rootStore

  const results = getResultsOrderedByTitle()

  if (isEmpty(results)) {
    return null
  }

  const CreateEmptyPositionalElements = length => {
    if (length % 4 !== 0) {
      const elementsLength = 4 - (length % 4)
      const emptyElementsArray = Array.from(Array(elementsLength).fill())
      return emptyElementsArray.map((e, i) => {
        return <div key={i} className={[styles.gridItem, styles.empty].join(' ')} />
      })
    }
    return null
  }

  return (
    <Observer>
      {() => {
        return (
          <div className={styles.grid}>
            {results.map((serie, i) => {
              return (
                <ListSeriesItem
                  key={i}
                  data={serie}
                  uid={serie?.uid}
                  options={{ ...options, lang: currentRouteLang }}
                />
              )
            })}
            {CreateEmptyPositionalElements(results.length)}
          </div>
        )
      }}
    </Observer>
  )
})
