import React from 'react'
import { inject, observer } from 'mobx-react'
import upperFirst from 'lodash/upperFirst'
import { Link } from '../layoutComponents'
import styles from './LanguageSelectorMobileArchive.module.css'
import { config } from '../../../app.config'
import { linkResolver } from '../../lib/link-resolver'
import { archivesPages } from '../../lib/temp/archives-pages'

function getOppositeLang(lang) {
  return lang === 'it' ? 'en' : 'it'
}

@inject('rootStore')
@observer
class LanguageSelectorMobileArchive extends React.Component {
  get isArchiveResults() {
    const { currentView } = this.props.rootStore
    return (
      currentView &&
      !currentView.type &&
      (currentView.pathname.indexOf('risultati-archivio') > -1 ||
        currentView.pathname.indexOf('archive-results') > -1)
    )
  }

  get isArchive() {
    const { currentView } = this.props.rootStore
    return (
      currentView &&
      !currentView.type &&
      (currentView.pathname.indexOf('archivi') > -1 ||
        currentView.pathname.indexOf('archives') > -1)
    )
  }

  getArchiveQueryString() {}

  getArchiveAlternateUid() {
    const { currentView, navigation } = this.props.rootStore
    const { currentRouteLang } = navigation
    const { pathname } = currentView

    const routeBits = pathname.split('/').filter(b => b && b !== 'en')
    const archiveSlug = routeBits[1]
    const currentArchivePage = archivesPages[currentRouteLang].find(
      ({ uid }) => uid === archiveSlug,
    )

    if (!currentArchivePage) {
      return undefined
    }

    const alternateArchivePage = archivesPages[getOppositeLang(currentRouteLang)].find(
      ({ id }) => id === currentArchivePage.id,
    )

    return alternateArchivePage.uid
  }

  getPathInOtherLanguage(langCode, locale) {
    const {
      currentView,
      navigation: { isCurrentRouteDynamic, sameRouteInAllLanguagesAsObject },
    } = this.props.rootStore
    const { getAlternateUid } = currentView

    if (isCurrentRouteDynamic) {
      const alternateUid = !this.isArchive
        ? getAlternateUid(langCode)
        : this.getArchiveAlternateUid()
      if (alternateUid && alternateUid !== null) {
        const langRoot = locale === 'it' ? '' : 'en'
        const contentType = !this.isArchive ? currentView.type : 'archive'
        return linkResolver({
          contentType,
          uid: alternateUid,
          lang: langRoot,
        })
      }

      return null
    } else {
      return sameRouteInAllLanguagesAsObject[locale].path
    }
  }

  render() {
    const { rootStore } = this.props
    const { sameRouteInAllLanguagesAsObject, currentRouteLang } = rootStore.navigation
    const { locales } = config

    return (
      <div className={styles.wrapper}>
        <ul className={styles.langSelector}>
          {Object.keys(locales).map((locale, i) => {
            const is404Route = !sameRouteInAllLanguagesAsObject
            const isCurrentLang = locale === currentRouteLang
            const { langCode } = locales[locale]

            if (isCurrentLang || is404Route) {
              return (
                <li
                  key={i}
                  className={`navText ${styles.item}${
                    !sameRouteInAllLanguagesAsObject ? '' : ` ${styles.itemActive}`
                  }`}
                >
                  <span className={styles.linkLabel}>{locale}</span>
                </li>
              )
            }

            let pathInOtherLang = this.getPathInOtherLanguage(langCode, locale)
            if (this.isArchiveResults) {
              pathInOtherLang += rootStore.navigation.router.location.search
            }

            return (
              <li key={i} className={`navText ${styles.item}`}>
                {pathInOtherLang && pathInOtherLang !== null ? (
                  <Link className={`${styles.link} outline`} to={pathInOtherLang}>
                    <span>{locale}</span>
                  </Link>
                ) : (
                  <span className={styles.notLink}>{upperFirst(locale)}</span>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default LanguageSelectorMobileArchive
