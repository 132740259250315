import React, { useEffect } from 'react'
import { inject, Observer } from 'mobx-react'
import { ongoing as ongoingFn } from '../lib/prismic/ongoing'
import styles from './Ongoing.module.css'
import { CurrentEvents } from '../components'
import { translator } from '../lib/translator'
import { PageTitlePlain } from '../components/Shared/Titles'
import { FiltersToggle } from '../components/Shared/Filters/FiltersToggle'
import { OngoingFilters } from '../components/Ongoing/OngoingFilters'
import { OngoingCalendarFilter } from '../components/Ongoing/OngoingCalendarFilter'

export const Ongoing = inject('rootStore')(({ rootStore }) => {
  const {
    fetchSingle,
    navigation: { currentRouteLang, currentRouteLangCode },
    events,
  } = rootStore
  const id = 'events'
  const TYPE = ongoingFn

  useEffect(() => {
    async function fetchData() {
      await fetchSingle(TYPE, currentRouteLangCode)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Observer>
      {() => (
        <div className={styles.wrapper}>
          <OngoingCalendarFilter id={id} type={events} />
          <header className={styles.header}>
            <div className={styles.containerTitle}>
              <div className={styles.ongoingTitlePage}>
                <PageTitlePlain title={translator('onGoingPageTitle')(currentRouteLang)} />
              </div>
              <FiltersToggle id={id} />
            </div>
            <div className={styles.filtersTitle}>
              <OngoingFilters id={id} type={events} />
            </div>
          </header>
          <CurrentEvents />
        </div>
      )}
    </Observer>
  )
})
