import React from 'react'
import styles from './heroTitleText.module.css'

export function HeroTitleText({ heroTitle, heroText }) {
  return (
    <div className={styles.heroSection}>
      <div className={styles.heroSeparator}>
        <h1>{heroTitle}</h1>
      </div>
      <div className={styles.heroContent}>
        <p>{heroText}</p>
      </div>
    </div>
  )
}
