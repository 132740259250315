import React from 'react'
import styles from './Slider.module.css'

export const Tick = ({ tick, count, format }) => {
  return (
    <div>
      <div
        className={styles.tick}
        style={{
          left: `${tick.percent}%`,
        }}
      />
      <div
        className={styles.tickLabel}
        style={{
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  )
}

Tick.defaultProps = {
  format: d => d,
}
