import React from 'react'
import { inject, Observer } from 'mobx-react'
import { linkResolver } from '../../lib/link-resolver'
import { translator, eventCategoryTranslator } from '../../lib/translator'
import { EventTitles as HeroTitle } from '../Shared/EventTitles'
import { HeroCta } from './HeroCta'
import { HeroCategory } from './HeroCategory'
import { HeroCard } from './HeroCard'
import { ContentDate } from '../Shared/ContentDate'

export const Hero = inject('rootStore')(({ rootStore, data }) => {
  const {
    navigation: { currentRouteLang },
  } = rootStore
  const { background_color, text_color, category_cta_color, hero_contents } = data
  const {
    uid,
    type: contentType,
    data: { title, author, type, start_date, end_date, specific_dates, event_online },
  } = hero_contents[0]
  const eventCategory = eventCategoryTranslator(type)(currentRouteLang)
  const ctaStyle = { color: category_cta_color }
  const categoryStyle = { color: category_cta_color, textTransform: 'uppercase' }
  const ctaLink = translator('exploreCTA')(currentRouteLang)

  return (
    <Observer>
      {() => (
        <HeroCard background_color={background_color} text_color={text_color}>
          <div>
            <HeroCategory
              style={categoryStyle}
              currentLang={currentRouteLang}
              isOnlineEvent={event_online}
            >
              {eventCategory}
            </HeroCategory>
            <HeroTitle author={author} title={title} titleClass="heroType" />
            <ContentDate
              type="hero"
              start_date={start_date}
              end_date={end_date}
              specific_dates={specific_dates}
              currentRouteLang={currentRouteLang}
              only_month={false}
            />
          </div>
          <HeroCta
            to={linkResolver({ uid, contentType: contentType, lang: currentRouteLang })}
            style={ctaStyle}
          >
            {ctaLink}
          </HeroCta>
        </HeroCard>
      )}
    </Observer>
  )
})
