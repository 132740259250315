import React from 'react'
import { inject } from 'mobx-react'
import styles from '../../routes/SingleMagazine.module.css'
import { linkResolver } from '../../lib/link-resolver'
import { Link } from '../../components'

export const MagazineSerie = inject('rootStore')(({ rootStore, uid, name, link = true }) => {
  const {
    ui: { labelsColor },
    navigation: { currentRouteLang },
  } = rootStore
  return (
    <div
      key={uid}
      style={{
        color: labelsColor,
      }}
      className={`${styles.titleSerie} bold`}
    >
      {link && name ? (
        <Link
          to={linkResolver({
            contentType: 'magazine_series',
            uid: uid,
            currentRouteLang,
          })}
        >
          {name}
        </Link>
      ) : (
        <span>{name}</span>
      )}
    </div>
  )
})
