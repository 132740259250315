import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './Descriptions.module.css'
import { linkResolverNormalizer } from '../../lib/link-resolver'
import { translator } from '../../lib/translator'
import { parsePrismicHtml } from '../../lib/survive-prismic'

@inject('rootStore')
@observer
export class Descriptions extends React.Component {
  state = {
    isDescriptionOpen: false,
  }

  toggleDescription = e => {
    e.preventDefault()
    this.setState(state => ({ isDescriptionOpen: !state.isDescriptionOpen }))
  }

  render() {
    const { description, description_part2, rootStore } = this.props
    const { currentRouteLang } = rootStore.navigation

    return (
      <div className={styles.descriptionsBlock}>
        <div
          dangerouslySetInnerHTML={{
            __html: parsePrismicHtml(description, linkResolverNormalizer),
          }}
          className={`bodyTextBig ${styles.description}`}
        />
        {description_part2?.length > 0 && description_part2[0].text && (
          <>
            <div
              className={`${styles.descriptionExpandable} ${
                this.state.isDescriptionOpen ? ` ${styles.active}` : ''
              }`}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: parsePrismicHtml(description_part2, linkResolverNormalizer),
                }}
                className={`bodyTextBig ${styles.description}`}
              />
            </div>
            {!this.state.isDescriptionOpen && (
              <a
                href="#0"
                onClick={this.toggleDescription}
                className={`link noIcon bodyTextBig ${styles.toggle} ${
                  this.state.isDescriptionOpen ? ` ${styles.active}` : ''
                }`}
              >
                ...{translator('more')(currentRouteLang)}
              </a>
            )}
          </>
        )}
      </div>
    )
  }
}
