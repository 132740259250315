import React, { useCallback } from 'react'

const sendAnalyticsEvent = (category, label, value) => {
  window.gtag('event', value, {
    eventCategory: category,
    dimension5: label,
  })
}

const AnalyticsWrapper = ({ eventCategory, eventLabel, eventValue, children }) => {
  const sendEvent = useCallback(() => sendAnalyticsEvent(eventCategory, eventLabel, eventValue), [
    eventCategory,
    eventLabel,
    eventValue,
  ])
  return (
    <div role="button" aria-hidden="true" onClick={sendEvent}>
      {children}
    </div>
  )
}

export default AnalyticsWrapper
