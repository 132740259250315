import { get, getByUids } from 'triennale-prismic-api-wrapper/src/fetcher'
import { navLinks as contentTypeId } from 'triennale-prismic-api-wrapper/src/constants/contentTypes'
import { navLinks as fields } from 'triennale-prismic-api-wrapper/src/constants/fields'
import { navLinks as queries } from 'triennale-prismic-api-wrapper/src/queries'

const contentType = {
  contentTypeId,
  fields,
  queries,
  get: get(contentTypeId),
  getByUids: getByUids(fields),
}

export { contentType as navLinks }
