import { inject, observer } from 'mobx-react'
import React from 'react'
// import { MarketingTicketBanner } from '../../components'
import styles from './BannersWrapper.module.css'

@inject('rootStore')
@observer
class BannersWrapper extends React.Component {
  render() {
    // const { ticketByShowId } = this.props.rootStore.tickets
    return (
      <div className={styles.wrapper}>
        {/* {ticketByShowId?.type === 'Mostra' && <MarketingTicketBanner />} */}
        {this.props.children}
      </div>
    )
  }
}

export default BannersWrapper
