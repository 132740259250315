import React from 'react'
import Headroom from 'react-headroom'
import { inject, observer } from 'mobx-react'
import {
  Logo,
  MainNav,
  OpenHoursWidget,
  LanguageSelector,
  LanguageSelectorMobileArchive,
} from '../../components'
import styles from './Header.module.css' // no css module, needs to be regular css

const Header = inject('rootStore')(
  observer(props => {
    const {
      rootStore: {
        ui: { mainNavToggle },
      },
    } = props
    return (
      <>
        <header className={styles.headerDesktop}>
          <Logo />
          <div className={styles.flexContainer} />
          <LanguageSelector />
          <OpenHoursWidget />
        </header>
        <div className={styles.headerMobile}>
          <Headroom className="headerHeadroom" disableInlineStyles disable={mainNavToggle}>
            <Logo />
            <LanguageSelectorMobileArchive />
          </Headroom>
          <div className={styles.mobileOverlay}>
            <MainNav />
            <div className={styles.mobileAbsolute}>
              <OpenHoursWidget />
            </div>
          </div>
        </div>
      </>
    )
  }),
)

export default Header
