import React from 'react'
import styles from './RegularEvent.module.css'
import { parsePrismicHtml } from '../../lib/survive-prismic'
import { translator } from '../../lib/translator'
import { linkResolverNormalizer } from '../../lib/link-resolver'

const EventInformationSecondary = ({ _info, lang }) => {
  return (
    <>
      <p className={`captionText bold ${styles.secondaryInfoTitle}`}>
        {translator('secondaryInfoSectionTitle')(lang)}
      </p>
      <div
        dangerouslySetInnerHTML={{
          __html: parsePrismicHtml(_info, linkResolverNormalizer),
        }}
        className={`captionText`}
      />
    </>
  )
}

export default EventInformationSecondary
