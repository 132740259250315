import React from 'react'
import { translator } from '../../lib/translator'
import styles from '../../routes/Tickets.module.css'
import { capitalizeString } from '../../lib/utils'

export const TicketSubscriptionInfo = ({
  barcode,
  title,
  remainingaccruals,
  firstname,
  lastname,
  email,
  onClickHandler,
  currentLang,
}) => {
  return (
    <>
      <div className={styles.subscriptionInfo}>
        <small>
          {firstname} {lastname} {email}
        </small>
        <br />
        <small>
          {capitalizeString(title)} {barcode} {remainingaccruals}
        </small>
      </div>
      <button className="button smallText" onClick={onClickHandler}>
        {translator('displayAll')(currentLang)}
      </button>
    </>
  )
}
