import React from 'react'
import { inject } from 'mobx-react'
import { LoaderOval } from '../../components'
import { translator } from '../../lib/translator'
import styles from './DataState.module.css'

export const NotFoundData = inject('rootStore')(
  ({
    rootStore: {
      navigation: { currentRouteLang },
    },
  }) => (
    <div className={styles.wrapper}>
      <span className={`${styles.notFoundMessage} bodyTextBig`}>
        {translator('noResultsMessage')(currentRouteLang)}
      </span>
    </div>
  ),
)

const ErrorData = inject('rootStore')(({ rootStore: { navigation: { currentRouteLang } } }) => (
  <div className={styles.wrapper}>
    <span className={`${styles.notFoundMessage} bodyTextBig`}>
      {translator('errorMessage')(currentRouteLang)}
    </span>
  </div>
))

export const SwitcherDataState = (Component, results, options) => ({
  notFound: <NotFoundData />,
  rest: <Component results={results} options={options} />,
  loading: <LoaderOval />,
  error: <ErrorData />,
})
