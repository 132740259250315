const EVENT_FILTERS = {
  type: [
    { it: 'Tutto', en: 'All' },
    { it: 'Mostre', en: 'Exhibitions' },
    { it: 'Performance', en: 'Performances' },
    { it: 'Eventi', en: 'Events' },
  ],
}

const AGGREGATED_FILTERS = {
  it: {
    Tutto: [],
    Mostre: ['Mostra', 'Collezione', 'Installazione'],
    Performance: ['Spettacolo', 'Concerto', 'Performance', 'Dj set'],
    Eventi: [
      'Conferenza',
      'Festival',
      'Incontro',
      'Laboratorio',
      'Presentazione volume',
      'Proiezione',
      'Speciale',
      'Triennale Estate',
      'Visita Guidata',
    ],
  },
  en: {
    All: [],
    Exhibitions: ['Mostra', 'Collezione', 'Installazione'],
    Performances: ['Spettacolo', 'Concerto', 'Performance', 'Dj set'],
    Events: [
      'Conferenza',
      'Festival',
      'Incontro',
      'Laboratorio',
      'Presentazione volume',
      'Proiezione',
      'Speciale',
      'Triennale Estate',
      'Visita Guidata',
    ],
  },
}

const FILTER_TYPES_TO_EXCLUDE = ['audience', 'language', 'special', 'entrance', 'time_related']

export { EVENT_FILTERS, AGGREGATED_FILTERS, FILTER_TYPES_TO_EXCLUDE }
