export const filtersStyle = labelsColor => {
  const breakpoints = [480, 768, 1024]
  const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)
  return {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? 0 : 0,
      boxShadow: 'none',
      cursor: 'pointer',
      marginLeft: 0,
      [mq[1]]: {
        marginLeft: '8px',
      },
    }),
    menu: provided => ({
      ...provided,
      borderRadius: 0,
      boxShadow: 'none',
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        background: '#FFF',
        cursor: 'pointer',
      },
      marginTop: 0,
      boxShadow: 'none',
      borderBottomStyle: 'solid',
      borderBottomWidth: '2px',
      borderBottomColor: '#111',
      // background: state.isFocused ? '#F0F0F0' : 'white',
      background: '#FFF',
      // color: !state.isFocused ? labelsColor : '#000',
      color: state.isFocused ? labelsColor : '#000',
      textTransform: 'uppercase',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: 0,
      color: labelsColor,
      '& svg': {
        width: '24px',
        height: '24px',
        marginLeft: '-6px',
        [mq[1]]: {
          width: '50px',
          height: '50px',
          marginLeft: '-6px',
        },
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: labelsColor,
    }),
    input: provided => ({
      ...provided,
      minWidth: '200px',
      [mq[1]]: {
        minWidth: '400px',
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      padding: 0,
      borderBottomStyle: 'solid',
      borderBottomWidth: '2px',
      borderBottomColor: '#111',
    }),

    valueContainer: provided => ({
      ...provided,
      padding: 0,
      borderBottomStyle: 'solid',
      borderBottomWidth: '2px',
      borderBottomColor: '#111',
      textTransform: 'uppercase',
    }),
  }
}
