import React from 'react'
import PrismicDOM from 'prismic-dom'

import styles from './Templating.module.css'
import { linkResolver } from '../../lib/link-resolver'
import { convertImageUrl, updateSrc } from '../../lib/utils'

export const htmlSerializer = (type, element, content, children) => {
  const Elements = PrismicDOM.RichText.Elements
  switch (type) {
    case Elements.heading2:
      return `<h2>${element.text}</h2>`
    case Elements.heading6:
      return `<blockquote>${element.text}</blockquote>`
    case Elements.paragraph:
      return `<p>${children.join('')}</p>`
    case Elements.image:
      return `<img class={${styles.imgResponsive}} src=${element.url} alt=${element.alt} />`
    case Elements.hyperlink:
      if (
        element.data.url &&
        (element.data.url.indexOf('mailto:') > -1 || element.data.url.indexOf('http') > -1)
      ) {
        return `<a href="${element.data.url}" target="${
          element.data.url.indexOf('mailto:') > -1 ? '_self' : '_blank'
        }" onClick="window.ga('send',{ hitType: 'event', eventCategory: '${
          element.data.link_type
        }', eventAction: '${element.data.name || element.data.url}', eventLabel: '${
          element.data.url
        }' })">${content}</a>`
      }
      return `<a href="${linkResolver({
        contentType: element.data.type,
        uid: element.data.uid,
        lang: element.data.lang,
      })}" onClick="window.ga('send',{ hitType: 'event', eventCategory: '${
        element.data.link_type
      }', eventAction: '${element.data.slug}', eventLabel: '${linkResolver({
        contentType: element.data.type,
        uid: element.data.uid,
        lang: element.data.lang,
      })}' })">${content}</a>`
    default:
      return null
  }
}

const POSSIBLE_IMAGE_FORMATS = ['mobile', 'tablet', 'laptop', 'desktop']

export const ResponsiveImage = ({ img, additionalClassName }) => {
  if (!img?.url) {
    return null
  }

  const retina = { ...img, s3Url: convertImageUrl(img?.url), prismicUrl: img?.url }
  const otherFormats = POSSIBLE_IMAGE_FORMATS.reduce((acc, next) => {
    if (img?.[next]) {
      acc[next] = {
        ...img?.[next],
        s3Url: convertImageUrl(img?.[next]?.url),
        prismicUrl: img?.[next]?.url,
      }
    }
    return acc
  }, {})

  const buildSrcSet = (formats, urlField) =>
    Object.keys(formats).reduce(
      (acc, next) => `${acc}${formats[next][urlField]} ${formats[next].dimensions.width}w, `,
      '',
    )

  const buildSizes = formats =>
    Object.keys(formats).reduce(
      (acc, next) =>
        `${acc}(max-width: ${formats[next].dimensions.width}px) ${formats[next].dimensions.width}px, `,
      '',
    )

  const urlField = 'prismicUrl'

  return (
    <img
      className={`${additionalClassName} ${styles.imgResponsive}`}
      alt={retina.alt}
      srcSet={`${buildSrcSet(otherFormats, urlField)} ${retina[urlField]} ${
        retina.dimensions.width
      }w`}
      sizes={`${buildSizes(otherFormats)} ${retina.dimensions.width}px`}
      src={retina[urlField]}
      onError={updateSrc(retina.prismicUrl)} // only retina to avoid checking the viewport size
    />
  )
}
