import React from 'react'
import { times } from 'lodash'
import styles from './Squares.module.css'

export const Squares = ({ count }) => {
  if (!count) return null
  const squareWidth = 4
  const squareHeight = 4
  const width = count * squareWidth + squareWidth

  return (
    <div className={styles.daySquare}>
      <svg
        className={styles.svgSquare}
        width={width}
        height={squareHeight}
        xmlns="http://www.w3.org/2000/svg"
      >
        {times(count).map((d, i) => (
          <rect key={i} x={i * squareHeight + i} y={0} width={squareWidth} height={squareHeight} />
        ))}
      </svg>
    </div>
  )
}
