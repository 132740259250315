import React from 'react'
import { WithOnlineLabelFilters as Category } from '../Shared/ContentFilters/WithOnlineLabelFilters'

export const HeroCategory = ({ style, currentLang, isOnlineEvent, children }) => {
  return (
    <Category
      textClasses="heroType bold"
      style={style}
      currentLang={currentLang}
      isOnlineEvent={isOnlineEvent}
    >
      <span style={style} className="heroType bold">
        {children}
      </span>
    </Category>
  )
}
