import { types as t, flow, getRoot } from 'mobx-state-tree'
import { searchInAnyText } from 'triennale-prismic-api-wrapper'

export const SearchResultsModel = t
  .model('SearchResultsModel', {
    data: t.maybeNull(t.frozen()),
    queryString: '',
    lastQueryString: '',
    isLoading: false,
  })
  .actions(self => ({
    updateQueryString(val) {
      self.queryString = val
    },

    resetQueryString() {
      self.queryString = ''
    },

    resetLastQueryString() {
      self.lastQueryString = ''
    },

    emptyResults() {
      self.data = undefined
    },

    updateResults: flow(function * updateResults() {
      const { navigation } = getRoot(self)
      const { currentRouteLangCode } = navigation

      self.isLoading = true
      try {
        self.data = yield searchInAnyText(self.queryString, currentRouteLangCode)
        self.lastQueryString = self.queryString
        self.isLoading = false
      } catch (err) {
        console.log(err)
        self.isLoading = false
      }
    }),

    afterCreate() {
      const {
        navigation: {
          router: { location },
        },
      } = getRoot(self)

      if (location.pathname === '/risultati' || location.pathname === '/en/results') {
        if (location.search !== '') {
          self.updateQueryString(decodeURIComponent(location.search.replace('?', '')))
        }
      }
    },
  }))
