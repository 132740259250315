import React from 'react'
import { SectionTitlePlain } from './Titles'
import { translator } from '../../lib/translator'
import { SingleEventPreviewLayoutSmall } from '../Ongoing/SingleEventPreviewLayoutSmall'
import { relatedEvents } from './RelatedEventsSection.module.css'

export const RelatedEventsSection = ({ related_events, lang, additionalClasses }) => {
  return (
    <>
      {related_events?.length > 0 && (
        <section>
          <SectionTitlePlain title={translator('relatedEventsSectionTitle')(lang)} border={true} />
          <div className={`${additionalClasses} ${relatedEvents}`}>
            {related_events.map((event, i) => (
              <SingleEventPreviewLayoutSmall key={i} event={event} isFocus textAlignLeft />
            ))}
          </div>
        </section>
      )}
    </>
  )
}
