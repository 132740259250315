import { types as t, flow, isStateTreeNode } from 'mobx-state-tree'
import { reaction } from 'mobx'
import { find, filter, forEach, map, findIndex } from 'lodash/fp'
import isEmpty from 'lodash/isEmpty'
import union from 'lodash/union'
import countBy from 'lodash/countBy'
import { getAnythingByUids, getFilteredContentsForType } from 'triennale-prismic-api-wrapper'

import { DataModel } from './data'
import { NavigationModel } from './navigation'
import { SearchResultsModel } from './search-results'
import { CurrentModel } from './current'
import { UiModel } from './ui'
import { ArchiveModel } from './archive'

import { TYPE_STATE, ACTIVE_FILTERS } from '../lib/const'

import { haveRouteThirdLevel } from '../lib/utils'
import { fbPageViewHit } from '../lib/facebook'
import { splitPathLang } from '../lib/utils/path-utils'
import { composeUid } from '../lib/utils/uid-utils'
import { handlerFirstLevel, handlerSecondLevel, handlerThirdLevel } from '../lib/routes-utils'

import mainNav from '../data/main-nav'
import archivesParams from '../data/archives-params'
import { isPrerenderedVersion } from '../lib/static-site'

// import { TicketsModel } from './tickets'
import { TicketsModel } from '../temp/ticketing-store'

import { CartModel } from './cart'
import { EventsModel } from './event'
import { fixPath } from '../lib/ssr'

export const RootStore = t
  .model('RootStore', {
    appState: t.enumeration('AppState', ['rest', 'loading', 'error', 'notFound']),
    navigation: NavigationModel,
    mainNav: t.frozen(),
    archive: t.optional(ArchiveModel, {}),
    ui: UiModel,
    homePage: t.optional(DataModel, {}),
    transparencyPages: t.optional(DataModel, {}),
    servicePages: t.optional(DataModel, {}),
    institutionalPages: t.optional(DataModel, {}),
    takePartPages: t.optional(DataModel, {}),
    containerPages: t.optional(DataModel, {}),
    basicPages: t.optional(DataModel, {}),
    visitPages: t.optional(DataModel, {}),
    ongoing: t.optional(DataModel, {}),
    events: t.optional(EventsModel, {}),
    news: t.optional(DataModel, {}),
    magazineSeries: t.optional(DataModel, {}),
    magazineAuthor: t.optional(DataModel, {}),
    press: t.optional(DataModel, {}),
    promotions: t.optional(DataModel, {}),
    currentView: t.optional(CurrentModel, {}),
    searchResults: t.optional(SearchResultsModel, {}),
    collection: t.optional(DataModel, {}),
    magazineHomepage: t.optional(DataModel, {}),
    magazine: t.optional(DataModel, {}),
    expoArchivePages: t.optional(DataModel, {}),
    tickets: t.optional(TicketsModel, {}),
    cart: t.optional(CartModel, {}),
  })
  .views(self => ({
    get filteredArchives() {
      return archivesParams.length
        ? archivesParams
            .filter(c => c.collection_filter_archives_is_enabled === 'Sì')
            .map(({ collection_filter_archives_slug, collection_filter_archives_label }) => ({
              label: collection_filter_archives_label,
              slug: collection_filter_archives_slug,
            }))
        : []
    },
    get filteredArchivesSlugs() {
      return self.filteredArchives.map(({ slug }) => slug)
    },
  }))
  .actions(self => ({
    async fetchDataForRoute() {
      const {
        isCurrentRouteDynamic,
        currentRouteName,
        currentRouteLangCode,
        router: { location },
      } = self.navigation

      const lang = currentRouteLangCode
      // setLivelinessChecking('error')
      let dataView
      const pathname = fixPath(location.pathname)
      const uids = [composeUid(pathname)]
      const param = haveRouteThirdLevel(currentRouteName)
        ? [splitPathLang(pathname).pop()]
        : undefined

      if (!currentRouteName) {
        return
      }

      const isRouteLevel = route => level => (level === 0 ? true : countBy(route)['|'] >= level - 1)
      const isLevel = isRouteLevel(currentRouteName)

      // ⚠️ Need a super refactor, please!
      if (isCurrentRouteDynamic) {
        if (isLevel(3)) {
          let secondLevel = splitPathLang(pathname)[1]
          dataView = handlerThirdLevel(secondLevel)(self, param, lang)
        } else if (isLevel(2)) {
          const firstLevel = splitPathLang(pathname).shift()
          dataView = handlerSecondLevel(firstLevel)
            ? await handlerSecondLevel(firstLevel)(self, uids, lang)
            : await handlerSecondLevel()(self, uids, lang)
        } else {
          dataView = await handlerFirstLevel['default'](self, uids, lang)
        }
      } else {
        if (handlerFirstLevel[currentRouteName]) {
          dataView = await handlerFirstLevel[currentRouteName](self, undefined, lang)
        } else if (isLevel(2)) {
          let secondLevel = splitPathLang(pathname)[1]
          dataView = await handlerSecondLevel(secondLevel === 'about' ? null : secondLevel)(
            self,
            uids,
            lang,
          )
        }
      }

      self.currentView.setData(dataView)
      return dataView
    },
  }))
  .actions(self => {
    const fetchEventsByUids = flow(function*(type, uids = ['_']) {
      if (isPrerenderedVersion) {
        return
      }
      if (!isEmpty(self.getByMultipleUids(type.name)(uids))) {
        return self.getByMultipleUids(type.name)(uids)
      } else {
        try {
          const data = yield type.getByUids(uids)
          const results = data.results || data
          if (!isEmpty(results)) {
            const update = r => self[type.name].push(r)
            forEach(update)(results)
          }
        } catch (error) {
          console.log(error)
        }
      }
    })

    const fetchFilteredData = flow(function*({
      contentTypeId = '_',
      pagination = null,
      filters = {},
      options = {},
      lang = 'it-it',
      fn,
    }) {
      const typeName = TYPE_STATE[contentTypeId]
      if (!pagination || pagination.page === 0) self[typeName].dataState = 'loading'
      self.appState = 'rest'
      try {
        const { results, total_pages } = yield fn(filters, options, lang, pagination)

        const hasResults = !isEmpty(results)
        const isLastPage =
          pagination.page + 1 === self[typeName].totalPages && self[typeName].totalPages > 1
        const isLastPageEmpty = isLastPage && !hasResults // in case the number of posts === number of posts per page

        if (hasResults) {
          const update = r => {
            const name = typeName
            const isNotExist = isEmpty(self.getBy(name)({ uid: r.uid }))
            if (isNotExist) {
              self[name].results.push(r)
            }
          }
          if (pagination && pagination.page !== 0) {
            forEach(update)(results)
          } else {
            self[typeName].results = []
            forEach(update)(results)
          }

          self[typeName].totalPages = total_pages
          self[typeName].dataState = 'rest'
        } else if (isLastPageEmpty) {
          self[typeName].dataState = 'rest'
        } else {
          self[typeName].results = []
          self[typeName].dataState = 'notFound'
        }
      } catch (error) {
        console.log(error)
        self[typeName].dataState = 'error'
      }
    })

    const fetchFn = flow(function*(
      fn,
      type,
      lang = 'it-it',
      pagination = {
        page: 0,
        resultsPerPage: 6,
      },
      typeId,
    ) {
      const typeName = TYPE_STATE[type.contentTypeId]
      if (!pagination || pagination.page === 0) self[typeName].dataState = 'loading'
      self.appState = 'rest'

      try {
        let fetchedData
        switch (typeName) {
          case 'promotions':
            fetchedData = yield fn({}, {}, lang)
            break

          case 'magazine':
            // Workaround for missing type 'magazine_nonFocus'
            if (typeId === 'magazine') {
              fetchedData = yield fn({}, lang, {}, pagination)
            } else {
              fetchedData = yield fn(lang, pagination)
            }
            break

          default:
            fetchedData = yield fn(lang, pagination)
            break
        }

        const hasResults = !isEmpty(fetchedData.results)
        const isLastPage =
          pagination.page + 1 === self[typeName].totalPages && self[typeName].totalPages > 1
        const isLastPageEmpty = isLastPage && !hasResults // in case the number of posts === number of posts per page

        if (hasResults) {
          const update = r => {
            const name = typeName
            const isNotExist = isEmpty(self.getBy(name)({ uid: r.uid }))
            if (isNotExist) {
              self[name].results.push(r)
            }
          }
          if (pagination && pagination.page !== 0) {
            forEach(update)(fetchedData.results)
          } else {
            self[typeName].results = []
            forEach(update)(fetchedData.results)
          }
          self[typeName].totalPages = fetchedData.total_pages
          self[typeName].dataState = 'rest'
        } else if (isLastPageEmpty) {
          self[typeName].dataState = 'rest'
        } else {
          self[typeName].results = []
          self[typeName].dataState = 'notFound'
        }
      } catch (error) {
        self[typeName].dataState = 'error'
      }
    })

    const fetchSingle = flow(function*(
      type,
      lang = 'it-it',
      pagination = {
        page: 0,
        resultsPerPage: 6,
      },
    ) {
      if (isPrerenderedVersion && type.contentTypeId !== 'homepage') {
        return
      }
      self.appState = 'loading'
      try {
        const data = yield type.get(lang, pagination)
        const results = data.results || data

        if (!isEmpty(results)) {
          const typeName = TYPE_STATE[type.contentTypeId]
          const update = r => self[typeName].results.push(r)
          self[typeName].results = []
          forEach(update)(results)
          self.appState = 'rest'
          return results
        } else {
          self.appState = 'notFound'
        }
      } catch (error) {
        console.log(error)
        self.appState = 'error'
      }
    })

    const fetchDataByUids = flow(function*(type, uids = [], lang = 'it-it') {
      self.appState = isPrerenderedVersion ? 'rest' : 'loading'
      const typeName = TYPE_STATE[type.contentTypeId]
      try {
        const res = yield type.getByUids(uids, lang)
        const results = res.results || res
        if (!isEmpty(results)) {
          self[typeName].setResults([])
          self[typeName].setResults(results)
          self.appState = 'rest'
          return results
        } else {
          self.appState = 'notFound'
        }
      } catch (error) {
        console.log(error)
        self.appState = 'error'
      }
    })

    const fetchAnythingByUids = flow(function*(uids = [], lang = 'it-it') {
      self.appState = 'loading'
      if (!isEmpty(self.getAllByUid(uids))) {
        self.appState = 'rest'
        return self.getAllByUid(uids)
      } else {
        try {
          const results = yield getAnythingByUids(uids, lang)
          if (!isEmpty(results)) {
            const typeName = TYPE_STATE[results[0].type]
            self[typeName].results.push(results[0])
            self.appState = 'rest'
            return results
          } else {
            self.appState = 'notFound'
          }
        } catch (error) {
          console.log(error)
          self.appState = 'error'
        }
      }
    })

    const fetchAnythingByType = flow(function*(
      type = '',
      lang = 'it-it',
      filters = {},
      pageSize = 10,
    ) {
      self.appState = 'loading'
      try {
        const results = yield getFilteredContentsForType(type, filters, lang, pageSize)

        if (!isEmpty(results)) {
          const typeName = TYPE_STATE[results[0].type]
          self[typeName].setResults(results)
          self.appState = 'rest'
          const typeModel = self[TYPE_STATE[type]]
          typeModel.dataState = 'rest'
          return results
        } else {
          self.appState = 'notFound'
        }
      } catch (error) {
        console.log(error)
        self.appState = 'error'
      }
    })

    const fetchMainNav = lang => {
      const { currentRouteLang } = self.navigation
      self.mainNav = mainNav[currentRouteLang]
    }

    return {
      // fetchData,
      fetchAnythingByType,
      fetchAnythingByUids,
      fetchSingle,
      fetchFn,
      fetchFilteredData,
      fetchMainNav,
      fetchDataByUids,
      fetchEventsByUids,
    }
  })
  .actions(self => {
    const clearAllFilters = () => {
      const clean = type => {
        self[type].filter = {}
      }
      forEach(clean)(ACTIVE_FILTERS)
    }
    const setCurrentView = (view, location) => {
      const { pathname } = location
      if (!isEmpty(view)) {
        const current = Array.isArray(view) ? view[0] : view.slice()
        const _current = isStateTreeNode(current) ? current.toJSON() : current
        self.currentView = { ..._current, pathname }
      } else {
        self.currentView = { pathname }
      }
      self.archive.setSearchArchiveFromPathUrl(location)
      // gaPageViewHit(location)
      fbPageViewHit()
    }
    const replaceByUid = from => (uid, data) => {
      const index = self.getIndex(from)(uid)
      self[from].results.splice(index, 1, data)
    }
    return {
      setCurrentView,
      clearAllFilters,
      replaceByUid,
    }
  })
  .actions(self => ({
    setAppState(state) {
      self.appState = state
    },

    resetAppState() {
      self.appState = 'rest'
    },

    afterCreate() {
      // without setTimeout 0, the router seems not to be initialized yet
      // when the afterCreate hook executes
      setTimeout(async () => {
        const { fetchMainNav, fetchDataForRoute, setCurrentView, navigation, ui } = self
        const {
          currentRouteLang,
          updatePreviousRouteLang,
          updatePreviousRoute,
          router: { location },
        } = navigation

        fetchMainNav(currentRouteLang)
        const fetchedData = await fetchDataForRoute()
        setCurrentView(fetchedData, location)
        updatePreviousRoute(location)
        updatePreviousRouteLang(currentRouteLang)

        mainNav[currentRouteLang].map((item, i) => {
          item.items.map((subItem, j) => {
            const { link } = subItem
            const linkWithTrailingSlash = link[link.length - 1] === '/' ? link : `${link}/`
            if (linkWithTrailingSlash === window.location.pathname) {
              ui.setActiveAccordionItem(i)
            }
          })
        })

        reaction(
          () => self.navigation.router.location,
          async _location => {
            const {
              clearAllFilters,
              navigation: { hasLangChanged, isDifferentRoute },
            } = self
            if (hasLangChanged || isDifferentRoute) {
              clearAllFilters()
            }
            if (hasLangChanged) {
              fetchMainNav(self.navigation.currentRouteLang)
            }
            self.ui.hideMainNav()
            self.ui.hideSearchPanel()
            window.scrollTo(0, 0)
            const _fetchedData = await fetchDataForRoute()
            setCurrentView(_fetchedData, _location)
            updatePreviousRoute(_location)
            updatePreviousRouteLang(self.navigation.currentRouteLang)
          },
        )
      }, 0)
    },
  }))
  .views(self => {
    const getIndex = from => (uid = '') => {
      const isEqualUid = r => r.uid === uid
      return findIndex(isEqualUid)(self[from].results)
    }
    const getBy = from => (value = { uid: '_' }) => {
      const keyValue = Object.entries(value).shift()
      const equalKey = type => type[keyValue[0]] === keyValue[1]
      return find(equalKey)(self[from].results) || {}
    }
    const getAllByUid = (values = ['_']) => {
      const predicate = type => self.getByMultipleUids(type)(values)
      return union(...map(predicate)(Object.values(TYPE_STATE)))
    }
    const getByMultipleUids = from => (values = ['_']) => {
      const equalUids = type => values.indexOf(type.uid) >= 0
      return filter(equalUids)(self[from].results) || null
    }
    const getAll = from => {
      return self[from].results.toJSON()
    }
    return {
      getAll,
      getAllByUid,
      getBy,
      getByMultipleUids,
      getIndex,
    }
  })
