import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { isPrerenderedVersion } from '../../lib/static-site'

const StaticLink = props => (
  <a href={props.to} {...props}>
    {props.children}
  </a>
)

const Link = isPrerenderedVersion ? StaticLink : RouterLink

export default Link
