export const LANG_CODES = {
  it: 'it-it',
  en: 'en-gb',
}

export const LANGS = {
  'it-it': 'it',
  'en-gb': 'en',
}

export function langCodeToLang(langCode) {
  return LANGS[langCode]
}

export function langToLangCode(lang) {
  return LANG_CODES[lang]
}
