export const config = {
  locales: {
    it: { urlPrefix: '', langCode: 'it-it', paymentLangFormat: 'ita' },
    en: { urlPrefix: 'en/', langCode: 'en-gb', paymentLangFormat: 'en' },
  },
  defaultLang: 'it',
  // notFoundRouteName: 'not-found',

  apiEndpoint:
    process.env.NODE_ENV === 'development'
      ? 'https://triennale-testing.prismic.io/api/v2'
      : 'https://triennale-testing.prismic.io/api/v2',

  ga: {
    trackingID: 'G-L9LJH21EEP',
  },

  facebook: {
    trackingID: '1137971772946419',
  },
}
