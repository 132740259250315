import React from 'react'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash/isEmpty'
import styles from './HeroMagazine.module.css'
import { SecondaryMenu } from './SecondaryMenu'

@inject('rootStore')
@observer
export class HeroMagazine extends React.Component {
  render() {
    const {
      rootStore: {
        magazineHomepage: { results },
      },
    } = this.props

    if (isEmpty(results)) {
      return null
    }

    const {
      abstract_title: abstractTitle,
      background_color: backgroundColor,
      text_color: titleColor,
    } = results[0].data

    const [{ text }] = abstractTitle

    return (
      <div
        className={styles.heroMagazineContainer}
        style={{ backgroundColor: backgroundColor || 'transparent' }}
      >
        <div className={styles.message} style={{ color: titleColor || 'black' }}>
          <h1>{text}</h1>
          <SecondaryMenu />
        </div>
      </div>
    )
  }
}
