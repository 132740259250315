import { getTranslatedFilter } from './filters-utils'
import { decomposeUid } from './utils/uid-utils'
import { LANGS } from './utils/lang-utils'
import { PAGES } from './const'
import { addSsrPrefix } from './ssr'

const isDefaultLang = l => l === 'it' || l === 'it-it' || !l

export const linkResolver = ({ contentType, uid, lang }) => {
  const EN_LANG = 'en'

  if (!uid) {
    if (contentType === 'public_program') {
      const path = isDefaultLang(lang) ? '/whats-on' : `/${EN_LANG}/whats-on`
      return addSsrPrefix(path)
    }
    if (contentType === 'archive') {
      const path = isDefaultLang(lang) ? `/archivi/` : `/${EN_LANG}/archives/`
      return addSsrPrefix(path)
    }
    return addSsrPrefix(isDefaultLang(lang) ? '/' : `/${EN_LANG}`)
  }

  let path
  // ⚠️ Refactor please!
  switch (contentType) {
    case 'magazine_home':
      path = isDefaultLang(lang) ? `/magazine/` : `/${EN_LANG}/magazine/`
      break
    case 'magazine':
      path = (isDefaultLang(lang) ? `/magazine/` : `/${EN_LANG}/magazine/`) + uid
      break
    case 'magazine_series':
      path = (isDefaultLang(lang) ? `/magazine/serie/` : `/${EN_LANG}/magazine/series/`) + uid
      break
    case 'magazine_author':
      path =
        (isDefaultLang(lang) ? `/magazine/collaboratori/` : `/${EN_LANG}/magazine/contributors/`) +
        uid
      break
    case 'event':
      path = (isDefaultLang(lang) ? `/eventi/` : `/${EN_LANG}/events/`) + uid
      break
    case 'news':
      path = (isDefaultLang(lang) ? `/news/` : `/${EN_LANG}/news/`) + uid
      break
    case 'home':
      path = isDefaultLang(lang) ? '/' : `/${EN_LANG}`
      break
    case 'archive':
      path = (isDefaultLang(lang) ? `/archivi/` : `/${EN_LANG}/archives/`) + uid
      break
    case 'triennale-archives':
      path = (isDefaultLang(lang) ? `/archivi-triennale/` : `/${EN_LANG}/triennale-archives/`) + uid
      break
    case 'ticket':
      path = (isDefaultLang(lang) ? '/biglietti/' : `/${EN_LANG}/tickets/`) + uid
      break
    default:
      path = uid ? `/${decomposeUid(uid)}` : '/'
  }

  return addSsrPrefix(path)
}

export const linkResolverNormalizer = ({ type, uid, lang }) => {
  return linkResolver({ contentType: type, uid: uid, lang: LANGS[lang] })
}

export const linkResolverWithFilters = ({ contentType, filterType, filterValue, lang }) => {
  return `${PAGES[contentType][lang]}?${filterType}=${
    isDefaultLang(lang) ? filterValue : getTranslatedFilter(filterType, filterValue, contentType)
  }`
}

export const getLink = ({ internal_link, internal_link_url, external_link_url }) => {
  if (internal_link && internal_link.uid) {
    const { type, uid, lang } = internal_link
    return linkResolver({ contentType: type, uid, lang })
  }
  if (internal_link_url) {
    return addSsrPrefix(internal_link_url)
  }
  return external_link_url || null
}
