import React from 'react'
import { inject, Observer } from 'mobx-react'
import { Link } from '../../components'
import styles from '../../components/Magazine/BtnMagazineHome.module.css'
import { linkResolver } from '../../lib/link-resolver'

export const BtnMagazineHome = inject('rootStore')(({ rootStore, isHome = false }) => {
  const {
    navigation: { currentRouteLang },
  } = rootStore
  return (
    <Observer>
      {() => (
        <div className={styles.triennaleMagazineBtn}>
          <Link
            to={linkResolver({
              contentType: 'magazine_home',
              uid: '_',
              lang: currentRouteLang,
            })}
          >
            {isHome ? 'Magazine' : 'Magazine →'}
          </Link>
        </div>
      )}
    </Observer>
  )
})
