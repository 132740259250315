import React from 'react'
import { inject, Observer } from 'mobx-react'
import { getFormattedDate } from '../../lib/date-utils'
import styles from './MagazineDate.module.css'

const MagazineDate = inject('rootStore')(
  ({ rootStore, first_publication_date, width, isFocus = false }) => {
    const {
      navigation: { currentRouteLang },
      ui: { labelsColor },
    } = rootStore

    return (
      <Observer>
        {() => (
          <span
            className={
              isFocus
                ? `${styles.stickyMagazineDate} bold ${width}`
                : `${styles.smallEventDates} bold ${width}`
            }
            style={{ color: labelsColor }}
          >
            {getFormattedDate(first_publication_date, {
              lang: currentRouteLang,
            })}
          </span>
        )}
      </Observer>
    )
  },
)

export default MagazineDate
