import React from 'react'
import { IframeWrapper } from './IframeWrapper'
import { DeprecatedIframeWrapper } from './DeprecatedIframeWrapper'

export class IframeWithFallback extends React.Component {
  render() {
    const { type, uid, autoplay, html } = this.props

    return html && html?.length > 0 && html[0].text !== '' ? (
      <IframeWrapper html={html} />
    ) : uid ? (
      <DeprecatedIframeWrapper uid={uid} autoplay={autoplay} type={type} />
    ) : null
  }
}
