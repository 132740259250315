import React from 'react'
import { inject, observer } from 'mobx-react'
// import { PromocodeInput } from './PromocodeInput'
import isEmpty from 'lodash/isEmpty'
import { translator } from '../../lib/translator'
import { computePrice, totalAmount, amountPerPerfomance } from '../../lib/tickets-utils'
import styles from './CartRecap.module.css'
import { scrollToTop } from '../../lib/scroll'

@inject('rootStore')
@observer
export class CartRecap extends React.Component {
  componentDidUpdate() {
    const {
      tickets: {
        setPerformanceId,
        setCurrentStep,
        cart: { selectedReductions },
        subscription: { barcode },
      },
    } = this.props.rootStore
    if (isEmpty(selectedReductions)) {
      setCurrentStep(0)
      scrollToTop()
      barcode && setPerformanceId(undefined)
    }
  }

  render() {
    const { navigation, tickets } = this.props.rootStore
    const { currentRouteLang } = navigation
    const {
      cart: { selectedReductions, subtractReduction },
      subscription: { barcode },
      selectedPerformance,
    } = tickets

    const { dataInizio: date, time } = selectedPerformance || {}

    return (
      <div>
        <ul className={`${styles.list}`}>
          {selectedReductions.map((reduction, i) => {
            const {
              commission,
              description,
              explanation,
              iva,
              nTickets,
              presale,
              price,
              reductionId,
              zoneId,
            } = reduction

            return (
              <li className={`${styles.item} bodyText`} key={i}>
                <div className={`${styles.ticketDescr}`}>
                  {`${nTickets}X - ${explanation} / ${description}`}
                  <div className="bold">
                    {date} {time}
                  </div>
                </div>
                <div className={styles.ticketPrice}>
                  {computePrice(
                    barcode ? [0, 0, 0, 0] : [price, presale, commission, iva],
                    currentRouteLang,
                  )}
                </div>
                <div className={`${styles.removeBtnWrapper}`}>
                  <span className={styles.ticketPrice}>
                    {computePrice(
                      barcode ? [0, 0, 0, 0] : [amountPerPerfomance(reduction)],
                      currentRouteLang,
                    )}
                  </span>
                  <button
                    className="button smallText"
                    onClick={() => {
                      subtractReduction(zoneId, reductionId)
                    }}
                  >
                    {translator('deleteTicket')(currentRouteLang)}
                  </button>
                </div>
              </li>
            )
          })}
        </ul>
        <div className={`${styles.totalRow} bodyText`}>
          <div className={`${styles.totalLabel} bold`}>
            {translator('ticketsTotalLabel')(currentRouteLang)}
          </div>
          <div className={`${styles.totalPrice}`}>
            {computePrice(
              barcode ? [0, 0, 0, 0] : [totalAmount(selectedReductions)],
              currentRouteLang,
            )}
          </div>
        </div>
      </div>
    )
  }
}
