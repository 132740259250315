import React from 'react'
import { inject, observer } from 'mobx-react'
import { NotFound } from '../routes/NotFound'
import { SingleEvent } from '../components/Events/SingleEvent'
import LoaderOval from '../components/layoutComponents/LoaderOval'
import { haveRouteSecondLevel } from '../lib/utils'

const switcherState = secondLevel => () => {
  return {
    notFound: <NotFound />,
    rest: secondLevel ? <SingleEvent /> : <NotFound />,
    loading: <LoaderOval />,
  }
}

export const Events = inject('rootStore')(
  observer(({ rootStore }) => {
    const {
      appState,
      navigation: { currentRouteName },
    } = rootStore
    const renderComponent = switcherState(haveRouteSecondLevel(currentRouteName))
    return renderComponent({})[appState]
  }),
)
