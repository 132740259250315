import React from 'react'
import { inject, observer } from 'mobx-react'
import { HeroTitleText } from '../components/Magazine/HeroTitleText'
import { ListSeries } from '../components/Magazine/Series/ListSeries'
import { composeUidOut } from '../lib/utils/uid-utils'
import { haveRouteThirdLevel, haveRouteSecondLevel } from '../lib/utils'
import { LoaderOval } from '../components'
import { NotFound } from './NotFound'
import { SingleSerie } from '../components/Magazine/Series/SingleSerie'
import { BtnMagazineHome } from '../components/Magazine/BtnMagazineHome'

const switcherState = thirdLevel => uid => {
  return {
    notFound: <NotFound />,
    rest: thirdLevel ? (
      <SingleSerie />
    ) : (
      <>
        <BtnMagazineHome />
        <HeroTitleText heroTitle={'Series'} heroText={''} />
        <ListSeries />
      </>
    ),
    loading: <LoaderOval />,
  }
}

export const Series = inject('rootStore')(
  observer(({ rootStore }) => {
    const {
      appState,
      navigation: {
        currentRouteName,
        router: {
          location: { pathname },
        },
      },
    } = rootStore

    const uid = composeUidOut(pathname)(['magazine'])
    const renderComponent = switcherState(haveRouteThirdLevel(currentRouteName))
    return renderComponent(uid, haveRouteSecondLevel(currentRouteName))[appState]
  }),
)
