import React from 'react'
import { inject, observer } from 'mobx-react'
import { preventingDefault } from '@accurat/event-utils'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import styles from './OpeningHoursPicker.module.css'
import {
  WEEKDAYS_LONG,
  MONTHS,
  WEEKDAYS_SHORT,
  FIRST_DAY_OF_WEEK,
  LABELS,
} from '../../lib/date-picker-locales'
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg'
import { ReactComponent as CalendarIcon } from '../../images/icons/calendar.svg'
import { translator } from '../../lib/translator'
import { getFormattedDate } from '../../lib/date-utils'

@inject('rootStore')
@observer
export class OpeningHoursPicker extends React.Component {
  state = {
    isPanelOpen: false,
  }

  container = React.createRef()

  componentDidMount() {
    const { resetOpeningHoursSelectedDay } = this.props.rootStore.ui
    resetOpeningHoursSelectedDay()
    document.body.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleOutsideClick)
  }

  handleOutsideClick = e => {
    if (this.container.current.contains(e.target)) {
      return
    }
    this.closePanel()
  }

  togglePanel = () => {
    this.setState(state => ({ isPanelOpen: !state.isPanelOpen }))
  }

  openPanel = () => {
    this.setState({ isPanelOpen: true })
  }

  closePanel = () => {
    this.setState({ isPanelOpen: false })
  }

  onChange = day => {
    this.props.rootStore.ui.setOpeningHoursSelectedDay(day)
    this.closePanel()
  }

  get timeBoundaries() {
    const today = new Date()
    const sixMonthsFromToday = new Date()
    sixMonthsFromToday.setMonth(sixMonthsFromToday.getMonth() + 6)

    return {
      today,
      sixMonthsFromToday,
    }
  }

  render() {
    const { navigation, ui } = this.props.rootStore
    const { currentRouteLang } = navigation
    const { openingHoursSelectedDay, isOpeningHoursToday } = ui
    const { isPanelOpen } = this.state
    const { today, sixMonthsFromToday } = this.timeBoundaries

    if (!openingHoursSelectedDay) {
      return null
    }

    return (
      <div className={styles.openingHoursBlock} ref={this.container}>
        {isPanelOpen && (
          <div className={styles.dayPickerPanel}>
            <div style={{ position: 'relative' }}>
              <CloseIcon
                className={styles.closeIcon}
                onClick={this.closePanel}
                aria-hidden="true"
              />
              <DayPicker
                className={styles.dayPicker}
                locale={currentRouteLang}
                months={MONTHS[currentRouteLang]}
                weekdaysLong={WEEKDAYS_LONG[currentRouteLang]}
                weekdaysShort={WEEKDAYS_SHORT[currentRouteLang]}
                firstDayOfWeek={FIRST_DAY_OF_WEEK[currentRouteLang]}
                labels={LABELS[currentRouteLang]}
                onDayClick={this.onChange}
                value={openingHoursSelectedDay}
                selectedDays={openingHoursSelectedDay}
                disabledDays={[{ before: today, after: sixMonthsFromToday }]}
                fromMonth={today}
                toMonth={sixMonthsFromToday}
              />
            </div>
          </div>
        )}

        <p>
          {translator('openingHoursFor')(currentRouteLang)}:{' '}
          <button
            onClick={preventingDefault(this.togglePanel)}
            className={`${styles.chooseDayBtn} outline bodyText`}
          >
            {openingHoursSelectedDay
              ? isOpeningHoursToday
                ? currentRouteLang === 'it'
                  ? 'oggi'
                  : 'Today'
                : getFormattedDate(openingHoursSelectedDay, { lang: currentRouteLang })
              : 'Please select a day'}

            <CalendarIcon className={styles.calendarIcon} aria-hidden="true" tabIndex="0" />
          </button>
        </p>
      </div>
    )
  }
}
