import { types as t, flow, getRoot } from 'mobx-state-tree'
import { ARCHIVE_ENDPOINTS } from '../lib/const'

export const ArchiveSingleTriennaleModel = t
  .model('ArchiveSingleTriennaleModel', {
    activeTab: t.maybe(t.string),
    lastAddedAccordionKey: t.maybe(t.string),
    accordion: t.map(
      t.model({
        id: t.identifier,
        pageIndex: t.number,
        contents: t.frozen(),
        loadState: t.optional(
          t.enumeration('AccordionKeyState', ['rest', 'loading', 'error', 'notFound']),
          'rest',
        ),
      }),
    ),
  })
  .actions(self => ({
    setActiveTab(key) {
      self.activeTab = key
    },
  }))
  .actions(self => ({
    updateAccordionKeyPage(id) {
      const accordionEntry = self.getAccordionEntryByKey(id)
      self.accordion.put({ ...accordionEntry, pageIndex: accordionEntry.pageIndex + 1 })
    },
    updateAccordion(id, triennaleIndex, shouldUpdatePage) {
      !shouldUpdatePage ? self.addAccordionKey(id) : self.updateAccordionKeyPage(id)
      self.loadData(id, triennaleIndex, 'replace')
    },
    addAccordionKey(id) {
      self.accordion.set(id, { id, pageIndex: 1, contents: [], loadState: 'loading' })
      self.lastAddedAccordionKey = id
    },
    removeAccordionKey(id) {
      self.accordion.delete(id)
    },
    clearAccordion() {
      self.accordion.clear()
    },
  }))
  .views(self => ({
    getAccordionEntryByKey(key) {
      return self.accordion.get(key)
    },
    getAccordionContentsByKey(key) {
      const entry = self.getAccordionEntryByKey(key)
      return entry ? entry.contents : []
    },
    get accordionKeys() {
      return [...self.accordion.keys()]
    },
    isInAccordionKeys(id) {
      return self.accordion.has(id)
    },
  }))
  .actions(self => ({
    loadData: flow(function* fetchFromArchive(id, triennaleIndex) {
      const currentEntry = self.accordion.get(id)
      const url = `results?expoId=${triennaleIndex}&archiveId=${
        ARCHIVE_ENDPOINTS[self.activeTab]
      }&slug=${id}&page=${currentEntry.pageIndex}`

      const data = yield getRoot(self).archive.fetchFromArchive(url)

      self.accordion.set(id, {
        ...currentEntry,
        contents: [...currentEntry.contents, ...(data || [])],
        loadState: 'rest',
      })
      try {
      } catch (error) {
        self.accordion.set(id, { ...currentEntry, loadState: 'error' })
      }
    }),
  }))
