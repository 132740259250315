import { splitPath, splitPathOut } from './path-utils'
import { filterOutPrefix, fixPath } from '../ssr'

export const composeUid = (path) => {
  const split = splitPath(fixPath(path))
  return split.join('_')
}

export const composeUidOut = (path) => (s) => {
  const split = splitPathOut(fixPath(path))(s)
  return split.join('_')
}

export const decomposeUid = (uid) => {
  const split = splitPath(uid, '_')
  return filterOutPrefix(split).join('/')
}

export const getLastUrlParam = pathname => {
  return pathname.substring(pathname.lastIndexOf('/') + 1)
}
