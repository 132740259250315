import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './ArchiveDisclaimer.module.css'

const ArchiveDisclaimer = inject('rootStore')(
  observer(props => {
    const {
      rootStore: {
        navigation: { currentRouteLang },
      },
    } = props
    return (
      <div className={`${styles.disclaimer}`}>
        <p className={`verySmallText`}>
          Per tutte le richieste relative alla Collezione Permanente contattare{' '}
          <a
            className={`link`}
            href="mailto:collezione@triennale.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            collezione@triennale.org
          </a>
          .
          <br />
          Per tutte le richieste relative all’archivio audiovisivo, di licenza audio o video, per
          l’accesso alle immagini di filmati, e per ricevere ulteriori informazioni contattare{' '}
          <a
            className={`link`}
            href="mailto:archivi@triennale.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            archivi@triennale.org
          </a>
          .<br />
          Non è possibile concedere in licenza i fermi immagine in movimento o le pellicole dei film
          non di proprietà di Fondazione La Triennale di Milano.
        </p>
        <div className={styles.backToSite}>
          <a href="https://triennale.org/">{`← ${
            currentRouteLang === 'it' ? 'Torna a' : 'Back to'
          } triennale.org`}</a>
        </div>
      </div>
    )
  }),
)

export default ArchiveDisclaimer
