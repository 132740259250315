import React from 'react'
import isNull from 'lodash/isNull'
import { SectionTitle } from '../../components/Shared/Titles'
import { AbstractLinks } from '../SingleEvent/AbstractLinks'
import styles from './SimpleParagraph.module.css'
import { Newsletter } from '../Shared/Newsletter'
import { linkResolverNormalizer } from '../../lib/link-resolver'
import { parsePrismicHtml } from '../../lib/survive-prismic'
import { AnalyticsWrapper } from '../../components'

export const TypesAndPrices = items => {
  return (
    <div className={styles.typesAndPricesBlock}>
      {items &&
        items.items.map((item, i) => {
          const { type, price } = item

          if (isNull(type) || isNull(price)) {
            return null
          }

          return (
            <div className={styles.typesAndPricesItem} key={i}>
              <div className={styles.typesAndPricesInner}>
                {type && <div>{type}</div>}
                {price && <div>{price}</div>}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export class SimpleParagraph extends React.Component {
  render() {
    const { paragraph } = this.props
    const { primary, items, slice_type } = paragraph
    const { paragraph_title, paragraph_text, newsletter_form_type } = primary

    const guidedToursItems = () => {
      const { primary } = paragraph

      const {
        guided_tour_type_1,
        guided_tour_type_2,
        guided_tour_price_1,
        guided_tour_price_2,
      } = primary

      return [
        { type: guided_tour_type_1, price: guided_tour_price_1 },
        { type: guided_tour_type_2, price: guided_tour_price_2 },
      ]
    }

    const ticketsItems = () => {
      const { primary } = paragraph

      const {
        ticket_type_1,
        ticket_type_2,
        ticket_type_3,
        ticket_type_4,
        ticket_type_5,
        ticket_type_6,
        ticket_type_7,
        ticket_type_8,
        ticket_type_9,
        ticket_type_10,
        ticket_price_1,
        ticket_price_2,
        ticket_price_3,
        ticket_price_4,
        ticket_price_5,
        ticket_price_6,
        ticket_price_7,
        ticket_price_8,
        ticket_price_9,
        ticket_price_10,
      } = primary

      return [
        { type: ticket_type_1, price: ticket_price_1 },
        { type: ticket_type_2, price: ticket_price_2 },
        { type: ticket_type_3, price: ticket_price_3 },
        { type: ticket_type_4, price: ticket_price_4 },
        { type: ticket_type_5, price: ticket_price_5 },
        { type: ticket_type_6, price: ticket_price_6 },
        { type: ticket_type_7, price: ticket_price_7 },
        { type: ticket_type_8, price: ticket_price_8 },
        { type: ticket_type_9, price: ticket_price_9 },
        { type: ticket_type_10, price: ticket_price_10 },
      ]
    }

    return (
      <section className={styles.section}>
        <div className={styles.sectionInner}>
          <SectionTitle title={paragraph_title} border={false} />
          <div
            className={`bodyTextBig ${styles.paragraphText}`}
            dangerouslySetInnerHTML={{
              __html: parsePrismicHtml(paragraph_text, linkResolverNormalizer),
            }}
          />
          {slice_type === 'paragraph_guided_tours' && (
            <div className={styles.typesAndPricesWrapper}>
              <TypesAndPrices items={guidedToursItems()} />
              <div className={`${styles.plus} plus bold`}>+</div>
              <TypesAndPrices items={ticketsItems()} />
            </div>
          )}
          {items?.length > 0 &&
            items.map((item, i) => (
              <AnalyticsWrapper
                eventCategory={item.paragraph_link_link?.link_type}
                eventLabel={item.paragraph_link_link?.url}
                eventValue={paragraph_title[0]?.text}
                key={i}
              >
                <AbstractLinks links={[item]} linkKey="paragraph_link" />
              </AnalyticsWrapper>
            ))}

          {slice_type === 'paragraph_newsletter' && <Newsletter type={newsletter_form_type} />}
        </div>
      </section>
    )
  }
}
