import { getRoot } from 'mobx-state-tree'
import { DataModel } from './data'

export const EventsModel = DataModel.views(self => ({
  get eventsWithTicket() {
    return self.results.filter(r => r.data.show_id !== null)
  },
})).views(self => {
  const getEventFromShowId = () => {
    const { showId } = getRoot(self).tickets
    return self.eventsWithTicket.find(e => e.data.show_id === showId)
  }
  const prismicEventById = prismicEventId => {
    return self.results.find(d => d.id === prismicEventId)
  }

  return {
    getEventFromShowId,
    prismicEventById,
  }
})
