import React from 'react'
import { inject, observer } from 'mobx-react'
import { CartRecap } from './CartRecap'
import { PaymentInfoForm } from './PaymentInfoForm'

export const TicketsPayment = inject('rootStore')(
  observer(() => {
    return (
      <>
        <CartRecap />
        <PaymentInfoForm />
      </>
    )
  }),
)
