import React from 'react'
import { inject, Observer } from 'mobx-react'
import { computeEventDates, computeEventDatesWithTicketingFallback } from '../../lib/date-utils'
import styles from './ContentDate.module.css'

const ContentDate = inject('rootStore')(
  ({ rootStore, type, start_date, end_date, specific_dates, only_month }) => {
    const {
      navigation: { currentRouteLang },
      ui: { labelsColor },
      tickets: { performance },
    } = rootStore

    const renderDate = () => {
      switch (type) {
        case 'hero':
          const { dateString: heroDate } = computeEventDates(
            start_date,
            end_date,
            specific_dates,
            currentRouteLang,
            false,
          )
          return (
            <div className={`smallText bold heroDate`} style={{ color: 'white', fontSize: '30px' }}>
              {heroDate}
            </div>
          )
        case 'single-event-preview':
          const { dateString: eventDate } = computeEventDates(
            start_date,
            end_date,
            specific_dates,
            currentRouteLang,
            only_month,
          )
          return (
            <div className={`smallText bold`} style={{ color: labelsColor }}>
              {eventDate}
            </div>
          )
        case 'search-result':
          const { dateString: searchResultsDate } = computeEventDates(
            start_date,
            end_date,
            specific_dates,
            currentRouteLang,
            only_month,
          )
          return (
            <div
              className={`smallText ${styles.searchResultsEventDates} bold`}
              style={{ color: labelsColor }}
            >
              {searchResultsDate}
            </div>
          )
        case 'single-event-preview-small':
          const { dateString } = computeEventDates(
            start_date,
            end_date,
            specific_dates,
            currentRouteLang,
            only_month,
          )
          return (
            <div className={`${styles.smallEventDates} bold`} style={{ color: labelsColor }}>
              {dateString}
            </div>
          )
        case 'single-post-preview-small':
          const { dateString: postPreviewDate } = computeEventDates(
            start_date,
            end_date,
            specific_dates,
            currentRouteLang,
            only_month,
          )

          return (
            <div className={`${styles.smallEventDates} bold`} style={{ color: labelsColor }}>
              {postPreviewDate}
            </div>
          )
        case 'event-ticket-header':
          const { dateString: eventTicketHeaderDate } = computeEventDatesWithTicketingFallback(
            start_date,
            end_date,
            specific_dates,
            currentRouteLang,
            performance,
          )
          return (
            <span className={`smallText ${styles.dates} bold`} style={{ color: labelsColor }}>
              {eventTicketHeaderDate}
            </span>
          )
        case 'ticket-event':
          const { dateString: ticketEventDate } = computeEventDates(
            start_date,
            end_date,
            specific_dates,
            currentRouteLang,
          )
          return (
            <div className={`smallText ${styles.dates} bold`} style={{ color: labelsColor }}>
              {ticketEventDate}
            </div>
          )
        case 'event-date':
          // TODO add eventDate.js
          return null
        case 'special-commission':
          // TODO add specialCommissionEvent.js
          return null
        case 'special-commission-event-abstract':
          // TODO add specialCommissionEventAbstract.js
          return null
        case 'sticky-hero-text-event':
          // TODO add stickyHeroText.js
          return null
        case 'sticky-hero-text-title-event':
          // TODO add stickyHeroTextTitle.js
          return null
        default:
          return null
      }
    }

    return <Observer>{() => <>{renderDate()}</>}</Observer>
  },
)

export { ContentDate }
