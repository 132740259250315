import React from 'react'
import { inject, observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import isEmpty from 'lodash/isEmpty'
import slice from 'lodash/slice'
import { SwitcherDataState } from '../../components/Shared/DataState'
import { fetchWithFilters } from '../../lib/routes-utils'
import { LoadMore } from '../../components'
import { DEFAULT_NUMBER_RESULTS_PER_PAGE } from '../../lib/const'

const fnSwitcher = (store, typeId, lang, pagination, options, type) => {
  const SWITCH = {
    default: () => fetchWithFilters(store, lang, pagination, options, type),
    magazine: () => store.fetchFn(type.get, type, lang, pagination, typeId),
    magazine_nonFocus: () => store.fetchFn(type.getNonFocus, type, lang, pagination, typeId),
    magazine_series: () => store.fetchAnythingByType(type.contentTypeId, lang, options),
  }
  return typeId in SWITCH ? SWITCH[typeId] : SWITCH['default']
}

@inject('rootStore')
@observer
class CurrentContent extends React.Component {
  state = { page: 0, resultsPerPage: this.props.resultsPerPage || DEFAULT_NUMBER_RESULTS_PER_PAGE }
  async componentDidMount() {
    await this.fetchContents()
  }

  componentWillUnmount = () => {
    this.setState({ page: 0 })
  }

  getType(typeId) {
    const { chronological } = this.props
    if (typeId === 'magazine') {
      return chronological ? 'magazine' : 'magazine_nonFocus'
    }
    return typeId
  }

  fetchContents = async () => {
    const {
      rootStore,
      type,
      type: { contentTypeId: typeId },
      options,
    } = this.props
    const {
      navigation: { currentRouteLangCode: lang },
    } = rootStore
    const pagination = this.state
    await fnSwitcher(rootStore, this.getType(typeId), lang, pagination, options, type)()
  }

  handlePagination = () => {
    const { page } = this.state
    this.setState({ page: page + 1 }, async () => {
      await this.fetchContents()
    })
  }

  isLastPage = () => {
    const { rootStore, contentType } = this.props
    const { page } = this.state
    return page + 1 === rootStore[contentType].totalPages
  }

  render() {
    const {
      limit,
      rootStore,
      areFiltersClickable,
      contentType,
      renderComponent,
      // type,
      typeOfList,
    } = this.props

    const {
      navigation: { currentRouteLang },
    } = rootStore

    const { results, dataState } = rootStore[contentType]
    const _results = !isEmpty(results)
      ? limit
        ? slice(orderBy(results.toJSON(), e => [e.first_publication_date], ['desc']), 0, limit)
        : results
      : results

    return (
      <>
        {
          SwitcherDataState(renderComponent, _results, {
            areFiltersClickable,
            lang: currentRouteLang,
            typeOfList,
            limit,
          })[dataState]
        }
        {!limit && !this.isLastPage() && _results?.length > 0 && (
          <LoadMore onClick={this.handlePagination} />
        )}
      </>
    )
  }
}

export default CurrentContent
