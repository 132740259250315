import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import styles from './Slider.module.css'
import { Tick } from './Tick'
import { Track } from './Track'
import { Handle } from './Handle'

export function SliderRail({ getRailProps }) {
  return (
    <>
      <div className={styles.railOuter} {...getRailProps()} />
      <div className={styles.railInner} />
    </>
  )
}

@observer
export class SliderCompound extends React.Component {
  state = {
    tooltipValues: [],
  }

  get values() {
    const { query, timerange } = this.props
    return isEmpty(query['timerange']) ? timerange : query['timerange']
  }

  componentDidMount() {
    this.setState({ tooltipValues: this.values || [0, 0] })
    reaction(
      () => this.values,
      values => this.setState({ tooltipValues: values }),
    )
  }

  updateQuery = values => {
    this.props.onClickHandler({
      type: 'ADD_QUERY',
      key: 'timerange',
      value: values,
    })
    return null
  }

  updateTooltips = tooltipValues => {
    this.setState({ tooltipValues })
  }

  getNiceTicks = ticks => {
    const { timerange } = this.props
    const _ticks = [...ticks]

    if (_ticks[0].value !== timerange[0]) {
      _ticks.unshift({
        id: `$$-${timerange[0]}`,
        value: timerange[0],
        percent: 0,
      })
    }
    if (_ticks[_ticks.length - 1].value !== timerange[1]) {
      _ticks.push({
        id: `$$-${timerange[1]}`,
        value: timerange[1],
        percent: 100,
      })
    }

    return _ticks
  }

  render() {
    const { timerange } = this.props

    if (!timerange) {
      return null
    }

    return (
      <div className={styles.sliderWrapper}>
        <Slider
          mode={1}
          step={1}
          domain={timerange}
          reversed={false}
          className={styles.slider}
          onChange={this.updateQuery}
          onUpdate={this.updateTooltips}
          values={this.values}
        >
          <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className={styles.sliderHandles}>
                {handles.map((handle, i) => (
                  <React.Fragment key={handle.id}>
                    <div className={styles.tooltip} style={{ left: `${handle.percent}%` }}>
                      {this.state.tooltipValues[i]}
                    </div>
                    <div className={styles.handleWrapper}>
                      <Handle
                        active={true}
                        handle={handle}
                        domain={timerange}
                        getHandleProps={getHandleProps}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className={styles.sliderTracks}>
                {tracks.map(({ id, source, target }) => (
                  <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks count={10}>
            {({ ticks }) => {
              return (
                <div className={styles.sliderTicks}>
                  {this.getNiceTicks(ticks).map(tick => (
                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                  ))}
                </div>
              )
            }}
          </Ticks>
        </Slider>
      </div>
    )
  }
}
