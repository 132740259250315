import React from 'react'
import { inject } from 'mobx-react'
import styles from './EventLocation.module.css'
import { LOCATION_DEFAULT } from '../../lib/const'

export const EventLocation = inject('rootStore')(
  ({ rootStore, location = LOCATION_DEFAULT?.name, link = LOCATION_DEFAULT?.maps, width }) => {
    const {
      ui: { labelsColor },
    } = rootStore

    const handleStop = e => {
      e.stopPropagation()
    }

    return (
      <p className={`smallText ${styles.location}`}>
        {link?.url ? (
          <object>
            <a
              onClick={handleStop}
              className={`location link ${styles.locationLink} ${width}`}
              href={link.url}
              style={{ color: labelsColor }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {location}
            </a>
          </object>
        ) : (
          <object>
            <span className={`bold`}>{location}</span>
          </object>
        )}
      </p>
    )
  },
)
