import React from 'react'
import dayjs from 'dayjs'
import { translator } from '../../lib/translator'
import {
  getFormattedDate,
  getFormattedDateRange,
  getFormattedDateMonth,
} from '../../lib/date-utils'

export const FormattedDateEvent = (start = {}, end = {}, lang, cssModuleClass, only_month) => {
  const startDateAsObj = start && dayjs(start).toObject()
  const endDateAsObj = end && dayjs(end).toObject()

  const notEndDateAsObj = !endDateAsObj
  const permanentEvent = endDateAsObj?.years > 2100
  const dateRangeEvent = startDateAsObj && endDateAsObj

  const formattedDate = only_month
    ? getFormattedDateMonth(start, end, lang)
    : permanentEvent
    ? translator('permanentEvent')(lang)
    : notEndDateAsObj
    ? getFormattedDate(start, { lang })
    : dateRangeEvent
    ? getFormattedDateRange(start, end, lang)
    : null

  return <time className={cssModuleClass}>{formattedDate}</time>
}
