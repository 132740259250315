import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './Banner.module.css'
import { isSnap } from '../../lib/static-site'

@inject('rootStore')
@observer
class Banner extends React.Component {
  state = {
    closed: true,
  }

  componentDidMount() {
    if (!isSnap) {
      this.setState({
        closed: window.sessionStorage.getItem(this.props.sessionStorageItemTitle) === 'true',
      })
    }
  }

  confirm = e => {
    e.preventDefault()
    this.setState({ closed: true })
    window.sessionStorage.setItem(this.props.sessionStorageItemTitle, 'true')
  }

  render() {
    const { rootStore, bgColor = 'black', color = 'white', children, ariaBtnText } = this.props
    const { currentRouteLang } = rootStore.navigation

    if (this.state.closed) return null

    return (
      <div className={`${styles.wrapper} bodyText`} style={{ color, backgroundColor: bgColor }}>
        <div>
          <button
            className={`${styles.confirm} uppercase outline`}
            onClick={this.confirm}
            aria-label={ariaBtnText[currentRouteLang]}
          />
          <div className={styles.textWrapper}>{children}</div>
        </div>
      </div>
    )
  }
}

export default Banner
