import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './SearchForm.module.css'
import { ReactComponent as SearchIcon } from '../../images/icons/search.svg'

@inject('rootStore')
@observer
class SearchForm extends React.Component {
  inputRef = React.createRef()

  onSearchSubmit = e => {
    e.preventDefault()
    const { ui, searchResults, navigation } = this.props.rootStore
    const { hideSearchPanel } = ui
    const { queryString } = searchResults
    const { router, currentRouteLang } = navigation

    hideSearchPanel()

    if (queryString !== '') {
      router.push(
        currentRouteLang === 'it' ? `/risultati?${queryString}` : `/en/results?${queryString}`,
      )
    }
  }

  emptyQueryString = e => {
    e.preventDefault()
    const { resetQueryString } = this.props.rootStore.searchResults
    resetQueryString()
    this.inputRef.current.focus()
  }

  handleChange = e => {
    const { updateQueryString } = this.props.rootStore.searchResults
    updateQueryString(e.target.value)
  }

  componentDidUpdate() {
    if (this.props.shouldFocus) {
      this.inputRef.current.focus()
    }
  }

  render() {
    const { navigation, searchResults } = this.props.rootStore
    const { currentRouteLang } = navigation
    const { queryString } = searchResults

    return (
      <div className={styles.formWrapper}>
        <form className={styles.form} onSubmit={this.onSearchSubmit}>
          <input
            ref={this.inputRef}
            className={`${styles.input} input bold`}
            onChange={this.handleChange}
            value={queryString}
            type="search"
            name="q"
            aria-label={
              currentRouteLang === 'it'
                ? 'Cerca nei contenuti del sito'
                : 'Search through site content'
            }
            placeholder={currentRouteLang === 'it' ? 'Cerca nel sito' : 'Search in this website'}
          />
          {queryString === '' && (
            <button className={styles.submitBtn}>
              <SearchIcon />
            </button>
          )}
        </form>
        {queryString !== '' && (
          <button
            className={`${styles.submitBtn} ${styles.emptyBtn}`}
            onClick={this.emptyQueryString}
          />
        )}
      </div>
    )
  }
}

export default SearchForm
