import React from 'react'
import { observer, inject } from 'mobx-react'
import { translator } from '../lib/translator'
import { PageTitlePlain } from '../components/Shared/Titles'
import { CurrentPromotions } from '../components'

export const Promotions = inject('rootStore')(
  observer(props => {
    const {
      rootStore: {
        navigation: { currentRouteLang },
      },
    } = props
    return (
      <div>
        <PageTitlePlain title={translator('promotionsTitle')(currentRouteLang)} />
        <CurrentPromotions />
      </div>
    )
  }),
)
