import React from 'react'
import styles from './DeprecatedIframeWrapper.module.css'

const facebookAppId = '224554204617179'

export class DeprecatedIframeWrapper extends React.Component {
  get source() {
    const { uid, type, autoplay = false } = this.props

    if (type === 'YouTube') {
      return {
        url: `https://www.youtube.com/embed/${uid}?rel=0&modestbranding=1${
          autoplay ? '&autoplay=1&mute=1' : ''
        }`,
        title: 'youtube video',
      }
    } else if (type === 'Vimeo') {
      return {
        url: `https://player.vimeo.com/video/${uid}?autoplay=${autoplay ? '1' : '0'}`,
        title: 'vimeo video',
      }
    } else if (type === 'Facebook') {
      return {
        url: `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F${uid}%2F&show_text=false&appId=${facebookAppId}&autoplay=${
          autoplay ? 'true' : 'false'
        }`, // &width=500&height=280`,
        title: 'facebook video',
      }
    }

    return null
  }

  checkErrors() {
    const { uid, type } = this.props

    if (!type) {
      throw new Error('please specify a type for the iframe: youtube, vimeo, iframe')
    }

    if (type && type !== 'iframe' && !uid) {
      throw new Error('please at least put it the uid of the video, e.g.: mYFaghHyMKc')
    }
  }

  render() {
    this.checkErrors()

    if (!this.source) {
      return null
    }

    const { title, url } = this.source

    return (
      <div className={styles.wrapper}>
        <iframe
          title={title}
          className={styles.player}
          type="text/html"
          width="100%"
          height="100%"
          src={url}
          frameBorder="0"
          scrolling="no"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allow="encrypted-media" // just facebook
          allowFullScreen="true" // true passed as string as explained here: https://github.com/facebook/react/issues/7848#issuecomment-334594775
        />
      </div>
    )
  }
}
