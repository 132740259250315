import { useEffect } from 'react'
import qs from 'query-string'
import Prismic from 'prismic-javascript'
import { getAnythingByIds } from 'triennale-prismic-api-wrapper'
import { linkResolver } from '../lib/link-resolver'

const apiEndpoint = 'https://triennale.cdn.prismic.io/api/v2'
const PRISMIC_ACCESS_TOKEN =
  'MC5YR1ZzYkJBQUFMOExoSzMx.Mlvvv70I77-9TO-_ve-_vXvvv70oYe-_vSDvv73vv71WX--_vXvvv71Y77-9S31OHmnvv73vv73vv71x'

const client = Prismic.client(apiEndpoint, { accessToken: PRISMIC_ACCESS_TOKEN })

export const Preview = ({ history, location }) => {
  useEffect(() => {
    const { token, documentId } = qs.parse(location.search.slice(1))
    const fetchDataDoc = async docId => {
      let data = await getAnythingByIds([docId])
      if (data.results.length === 0) {
        data = await getAnythingByIds([docId], 'en-gb')
      }
      return data
    }

    async function getDocById(docId) {
      const docInfo = await fetchDataDoc(docId)

      if (token && docInfo.results.length > 0) {
        const { type, uid, lang } = docInfo.results[0]

        client
          .previewSession(token, () => linkResolver({ contentType: type, uid, lang }), '/')
          .then(url => {
            history.push(url)
          })
          .catch(err => console.log(err))
      }
    }
    getDocById(documentId)
  })
  return null
}
