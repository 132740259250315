import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './FiltersToggle.module.css'
import { translator } from '../../../lib/translator'

@inject('rootStore')
@observer
export class FiltersToggle extends React.Component {
  togglePanel = () => {
    const { id } = this.props
    const { ui } = this.props.rootStore
    ui.toggleFilter(id)
  }

  render() {
    const {
      navigation: { currentRouteLang },
      ui: { labelsColor },
    } = this.props.rootStore

    return (
      <button className={`outline ${styles.wrapper}`} onClick={this.togglePanel} type="button">
        <div className={styles.inner}>
          <div>
            <span style={{ color: labelsColor }}>
              {window.location.search.includes('start_date')
                ? translator('calendarTitleFiltersChangeDate')(currentRouteLang)
                : translator('calendarTitleFilters')(currentRouteLang)}
            </span>
          </div>
        </div>
      </button>
    )
  }
}
