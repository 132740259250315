import React from 'react'
import { HeroImageVideo } from './HeroImageVideo'
import { Hero } from './Hero'

export const HeroStandardLayout = ({ hero, forwardedRef }) => {
  return (
    <>
      <HeroImageVideo
        {...{
          data: hero.data,
          forwardedRef,
        }}
      />
      <Hero
        {...{
          data: hero.data,
        }}
      />
    </>
  )
}

export const HeroMirroredLayout = ({ hero, forwardedRef }) => {
  return (
    <>
      <Hero
        {...{
          data: hero.data,
        }}
      />
      <HeroImageVideo
        {...{
          data: hero.data,
          forwardedRef,
        }}
      />
    </>
  )
}
