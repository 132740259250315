import { types as t } from 'mobx-state-tree'
import { RouterModel } from 'mst-react-router'
import uniq from 'lodash/uniq'
import { routes, notFoundRoute } from '../routes'
import { config } from '../../app.config'
import { parseRoute } from '../lib/utils'
import { splitPath } from '../lib/utils/path-utils'
import { addSsrPrefix, fixPath } from '../lib/ssr'

export const NavigationModel = t
  .model('NavigationModel', {
    router: RouterModel,
    previousRoute: t.frozen(),
    previousRouteLang: t.optional(t.enumeration([...Object.keys(config.locales), 'none']), 'none'),
  })
  .actions(self => ({
    updatePreviousRoute(route) {
      self.previousRoute = route
    },
    updatePreviousRouteLang(lang) {
      self.previousRouteLang = lang
    },
  }))
  .views(self => ({
    getRoute(routeName) {
      const filteredRoutes = routes
        .filter(route => parseRoute(route).name === routeName)
        .map(route => ({
          ...route,
          // Cleanup path and then add prefix (if needed)
          path: addSsrPrefix(fixPath(route.path)),
        }))
      return uniq(filteredRoutes)
    },
    getRouteAsObject(routeName) {
      return self.getRoute(routeName).reduce((acc, next) => {
        const lang = parseRoute(next).lang
        acc[lang] = next
        return acc
      }, {})
    },
    get currentRoute() {
      const { pathname } = self.router.location
      const splitCurrentPathname = splitPath(fixPath(pathname))

      const _currentRoute = routes.find(route => {
        // split the route to evaluate
        const _splitPath = splitPath(fixPath(route.path))

        // if different number of params, it's not the same route
        if (_splitPath.length !== splitCurrentPathname.length) {
          return null
        }

        // otherwise, check for equality param by param
        const paramsBitEquality = _splitPath.map((param, i) => {
          const isLastParam = i === _splitPath.length - 1
          const isButLastParam = i === _splitPath.length - 2
          const isDynamicPath = param[0] === ':'

          // the but last parameter may be dynamic. If that's the case, return true directly
          if (isButLastParam && isDynamicPath) {
            return true
          }

          // the last parameter may be dynamic. If that's the case, return true directly
          if (isLastParam && isDynamicPath) {
            return true
          }

          // otherwise, simply check if they are the same
          return param === splitCurrentPathname[i]
        })

        // check if all items in the array are equal
        // if so, it's the same route
        return paramsBitEquality.every(bit => bit)
      })

      return _currentRoute || notFoundRoute
    },
    get currentRouteName() {
      return parseRoute(self.currentRoute).name
    },
    get currentRouteLang() {
      const { locales, defaultLang } = config
      const { pathname } = self.router.location
      const routeSplit = splitPath(fixPath(pathname))
      const maybeLang = routeSplit[0]
      return locales[maybeLang] ? maybeLang : defaultLang
    },

    get currentRouteLangCode() {
      return config.locales[self.currentRouteLang].langCode
    },

    get currentRouteLangForPayment() {
      return config.locales[self.currentRouteLang].paymentLangFormat
    },

    get hasLangChanged() {
      return self.currentRouteLang !== self.previousRouteLang
    },

    get isDifferentRoute() {
      const { pathname: currentPath } = self.router.location
      return currentPath !== self.previousRoute.pathname
    },

    // returns a single route declined in all languages
    get sameRouteInAllLanguages() {
      const route = routes.filter(r => parseRoute(r).name === self.currentRouteName)
      return route.length && route
    },
    // same as above, for easy access with currentRouteLang key
    // eg sameRouteInAllLanguagesAsObject[currentRouteLang]
    get sameRouteInAllLanguagesAsObject() {
      return self.sameRouteInAllLanguages ? self.getRouteAsObject(self.currentRouteName) : undefined
    },

    get isCurrentRouteDynamic() {
      // the notFound route has no path
      return self.currentRoute.path ? self.currentRoute.path.indexOf(':') > -1 : false
    },
  }))
  .views(self => {
    const getAllParentsRoute = routeToMatch => {
      const matchedRoutes = routes.filter(route => {
        const routeName = parseRoute(route)
        const routeToMatchName = parseRoute(routeToMatch)

        const routeNameBits = routeName.name.split('|')
        const routeToMatchNameBits = routeToMatchName.name.split('|')

        if (routeNameBits.length >= routeToMatchNameBits.length) {
          return false // it's not a parent route
        }

        const bits = routeNameBits.map((bit, i) => {
          return bit === routeToMatchNameBits[i]
        })

        return bits.every(bit => bit)
      })

      return matchedRoutes
    }

    const isParentOfCurrentRoute = route => {
      const { pathname } = self.router.location

      const splitCurrentPathname = splitPath(fixPath(pathname))

      // split the route to evaluate
      const _splitPath = splitPath(fixPath(route.path))

      // if different number of params, it's not the same route
      if (_splitPath.length >= splitCurrentPathname.length) {
        return false
      }

      // otherwise, check for equality param by param
      const paramsBitEquality = _splitPath.map((param, i) => {
        const isLastParam = i < _splitPath.length - 1
        const isButLastParam = i < _splitPath.length - 2
        const isDynamicPath = param[0] === ':'

        // the but last parameter may be dynamic. If that's the case, return true directly
        if (isButLastParam && isDynamicPath) {
          return true
        }

        // the last parameter may be dynamic. If that's the case, return true directly
        if (isLastParam && isDynamicPath) {
          return true
        }

        // otherwise, simply check if they are the same
        return param === splitCurrentPathname[i]
      })

      // check if all items in the array are equal
      // if so, it's the same route
      return paramsBitEquality.every(bit => bit)
    }

    const isCurrentRoute = route => {
      const { pathname: currentPath } = self.router.location
      return currentPath.replace(/\/$/, '') === route.path
    }

    return {
      getAllParentsRoute,
      isParentOfCurrentRoute,
      isCurrentRoute,
    }
  })
