import React, { useEffect, useState } from 'react'
import { inject } from 'mobx-react'
import { event as eventType } from '../../lib/prismic/event'
import { SectionTitlePlainWithLink } from '../Shared/Titles'
import { translator } from '../../lib/translator'
import styles from './Home.module.css'
import { MagazineListCards } from '../Magazine/MagazineListCards'
import { filterEmptyPrismicRepeatersContents } from '../../lib/survive-prismic'
import { removeDuplicates } from '../../lib/filters-utils'

const HomePublicProgramSection = inject('rootStore')(({ rootStore }) => {
  const {
    navigation: { currentRouteLang, currentRouteLangCode },
  } = rootStore
  const { public_program } = rootStore.homePage.results[0].data
  const [currentProgram, setCurrentProgram] = useState([])
  const { getCurrent: getCurrentProgram, fields: EVENT } = eventType
  const eventFilters = {
    [EVENT.TYPE]: [
      'Conferenza',
      'Festival',
      'Campus estivo',
      'Incontro',
      'Laboratorio',
      'Presentazione volume',
      'Proiezione',
      'Speciale',
      'Triennale Estate',
      'Visita guidata',
    ],
  }
  const filteredProgram = filterEmptyPrismicRepeatersContents(public_program, 'id')

  async function getProgram() {
    const current = (await getCurrentProgram(eventFilters, {}, currentRouteLangCode)).results
    setCurrentProgram(current)
  }

  useEffect(() => {
    getProgram()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const PublicProgram = ({ pinned, chronological }) => {
    const filtered = removeDuplicates(pinned.concat(chronological), 'id')
    const results = filtered.slice(0, 3)
    return <MagazineListCards results={results} options={{ typeOfList: 1, limit: 1 }} />
  }

  const publicProgramFilters =
    '?type=Conferenza&type=Festival&type=Incontro&type=Laboratorio&type=Presentazione%20volume&type=Proiezione&type=Speciale&type=Triennale%20Estate&type=Visita%20Guidata'

  return (
    <section className={styles.section}>
      <SectionTitlePlainWithLink
        title={translator('publicProgramSectionTitle')(currentRouteLang)}
        cta={translator('publicProgramSectionCta')(currentRouteLang)}
        border={true}
        link={{
          href:
            currentRouteLang === 'it'
              ? `/whats-on${publicProgramFilters}`
              : `/en/whats-on${publicProgramFilters}`,
          title: translator('performativeSectionCta')(currentRouteLang),
        }}
      />
      <PublicProgram pinned={filteredProgram} chronological={currentProgram} />
    </section>
  )
})

export default HomePublicProgramSection
