import React from 'react'
import { SectionTitlePlain } from '../../components/Shared/Titles'
import styles from './RegularEvent.module.css'
import { translator } from '../../lib/translator'
import { MultipleDatesWithPrice } from './MultipleDatesWithPrice'

const EventMultiDate = ({ dates, data, lang }) => {
  return (
    <div className={`${styles.specificDates}`}>
      <SectionTitlePlain
        title={translator('calendarSectionTitle')(lang)}
        border={{
          border: true,
        }}
      />
      <MultipleDatesWithPrice dates={dates} eventData={data} lang={lang} />
    </div>
  )
}

export default EventMultiDate
