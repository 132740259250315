import { Home } from './routes/Home'
import { NotFound } from './routes/NotFound'
import { Events } from './routes/Events'
import { MagazineHome } from './routes/MagazineHome'
import { News } from './routes/News'
import { Ongoing } from './routes/Ongoing'
import { Press } from './routes/Press'
import { DynamicPage } from './routes/DynamicPage'
import { SearchResults } from './routes/SearchResults'
import { Promotions } from './routes/Promotions'
import { Archives } from './routes/Archives'
import { SingleArchive } from './routes/SingleArchive'
import { ArchiveSearchResults } from './routes/ArchiveSearchResults'
import { SingleArchiveTriennale } from './routes/SingleArchiveTriennale'
import { Tickets } from './routes/Tickets'
import { Ticket } from './routes/Ticket'
import { TicketThankYou } from './routes/TicketThankYou'
import { Series } from './routes/Series'
import { Contributors } from './routes/Contributors'
import { SingleMagazine } from './routes/SingleMagazine'
import { Preview } from './routes/Preview'
import { History } from './routes/History'
import { addSsrPrefix } from './lib/ssr'

// TODO: trasparenza eng

const originalRoutes = [
  {
    name: 'preview|it',
    label: 'preview',
    path: '/preview',
    component: Preview,
  },
  {
    name: 'preview|en',
    label: 'preview',
    path: '/en/preview',
    component: Preview,
  },
  {
    name: 'home|it',
    label: 'Home',
    path: '/',
    component: Home,
    exact: true,
  },
  {
    name: 'home|en',
    label: 'Home',
    path: '/en/',
    component: Home,
    exact: true,
  },
  {
    name: 'in-corso|it',
    label: 'In programma',
    path: '/whats-on',
    component: Ongoing,
    exact: true,
  },
  {
    name: 'in-corso|en',
    label: "What's on",
    path: '/en/whats-on',
    component: Ongoing,
    exact: true,
  },
  {
    name: 'passati|it',
    label: 'Eventi passati',
    path: '/eventi-passati',
    component: History,
    exact: true,
  },
  {
    name: 'passati|en',
    label: 'Eventi',
    path: '/en/past-events',
    component: History,
    exact: true,
  },
  {
    name: 'news|it',
    label: 'News',
    path: '/news',
    component: News,
    exact: true,
  },
  {
    name: 'news|en',
    label: 'News',
    path: '/en/news',
    component: News,
    exact: true,
  },
  {
    name: 'events|singola|it',
    label: 'Events',
    path: '/eventi/:resource',
    component: Events,
    exact: true,
  },
  {
    name: 'events|singola|en',
    label: 'Events',
    path: '/en/events/:resource',
    component: Events,
    exact: true,
  },
  {
    name: 'magazine|it',
    label: 'Magazine',
    path: `/magazine`,
    component: MagazineHome,
    exact: true,
  },
  {
    name: 'magazine|en',
    label: 'Magazine',
    path: `/en/magazine`,
    component: MagazineHome,
    exact: true,
  },
  {
    name: 'magazine|about|it',
    label: 'MagazineAbout',
    path: `/magazine/about`,
    component: DynamicPage,
    exact: true,
  },
  {
    name: 'magazine|about|en',
    label: 'MagazineAbout',
    path: `/en/magazine/about`,
    component: DynamicPage,
    exact: true,
  },
  {
    name: 'magazine|collaboratori|it',
    label: 'MagazineCollaboratori',
    path: `/magazine/collaboratori`,
    component: Contributors,
    exact: true,
  },
  {
    name: 'magazine|collaboratori|en',
    label: 'MagazineContributors',
    path: `/en/magazine/contributors`,
    component: Contributors,
    exact: true,
  },
  {
    name: 'magazine|collaboratori|singola|it',
    label: 'MagazineCollaboratori',
    path: `/magazine/collaboratori/:name`,
    component: Contributors,
    exact: true,
  },
  {
    name: 'magazine|collaboratori|singola|en',
    label: 'MagazineContributors',
    path: `/en/magazine/contributors/:name`,
    component: Contributors,
    exact: true,
  },
  {
    name: 'magazine|collaboratori|singola|it',
    label: 'MagazineCollaboratori',
    path: `/magazine/collaboratori/:name`,
    component: Contributors,
    exact: true,
  },
  {
    name: 'magazine|collaboratori|singola|en',
    label: 'MagazineContributors',
    path: `/en/magazine/contributors/:name`,
    component: Contributors,
    exact: true,
  },
  {
    name: 'magazine|serie|it',
    label: 'MagazineSeries',
    path: `/magazine/serie`,
    component: Series,
    exact: true,
  },
  {
    name: 'magazine|serie|en',
    label: 'MagazineSeries',
    path: `/en/magazine/series`,
    component: Series,
    exact: true,
  },
  {
    name: 'magazine|serie|singola|it',
    label: 'MagazineSeries',
    path: `/magazine/serie/:name`,
    component: Series,
    exact: true,
  },
  {
    name: 'magazine|serie|singola|en',
    label: 'MagazineSeries',
    path: `/en/magazine/series/:name`,
    component: Series,
    exact: true,
  },
  {
    name: 'magazine|singola|it',
    label: 'Magazine',
    path: `/magazine/:resource`,
    component: SingleMagazine,
    exact: true,
  },
  {
    name: 'magazine|singola|en',
    label: 'Magazine',
    path: `/en/magazine/:resource`,
    component: SingleMagazine,
    exact: true,
  },
  {
    name: 'press|it',
    label: 'Press',
    path: '/press',
    component: Press,
    exact: true,
  },
  {
    name: 'press|en',
    label: 'Press',
    path: '/en/press',
    component: Press,
    exact: true,
  },
  {
    name: 'searchresults|it',
    label: 'Risultati della ricerca',
    path: '/risultati',
    component: SearchResults,
  },
  {
    name: 'searchresults|en',
    label: 'Search results',
    path: '/en/results',
    component: SearchResults,
  },
  {
    name: 'promotions|it',
    label: 'Promo',
    path: '/promozioni',
    component: Promotions,
  },
  {
    name: 'promotions|en',
    label: 'Promo',
    path: '/en/promotions',
    component: Promotions,
  },
  {
    name: 'archives|it',
    label: 'Archivi',
    path: '/archivi',
    component: Archives,
    exact: true,
  },
  {
    name: 'archives|en',
    label: 'Archive',
    path: '/en/archives',
    component: Archives,
    exact: true,
  },
  {
    name: 'archives|single|it',
    label: 'Archivio',
    path: '/archivi/:resource',
    component: SingleArchive,
  },
  {
    name: 'archives|single|en',
    label: 'Archives',
    path: '/en/archives/:resource',
    component: SingleArchive,
  },
  {
    name: 'triennale-archives|it',
    label: 'Archivio delle Triennali',
    path: '/archivi-triennale/:resource',
    component: SingleArchiveTriennale,
  },
  {
    name: 'triennale-archives|en',
    label: 'Triennale Archives',
    path: '/en/triennale-archives/:resource',
    component: SingleArchiveTriennale,
  },
  {
    name: 'archive-results|it',
    label: 'Risultati Archivio',
    path: '/risultati-archivio',
    component: ArchiveSearchResults,
  },
  {
    name: 'archive-results|en',
    label: 'Archive Results',
    path: '/en/archive-results',
    component: ArchiveSearchResults,
  },
  {
    name: 'tickets|it',
    label: 'Biglietti',
    path: '/biglietti',
    component: Tickets,
    exact: true,
  },
  {
    name: 'tickets|en',
    label: 'Tickets',
    path: '/en/tickets',
    component: Tickets,
    exact: true,
  },
  {
    name: 'tickets|singola|it',
    label: 'Biglietti',
    path: '/biglietti/:resource',
    component: Ticket,
    exact: true,
  },
  {
    name: 'tickets|singola|en',
    label: 'Tickets',
    path: '/en/tickets/:resource',
    component: Ticket,
    exact: true,
  },
  {
    name: 'thankyou|it',
    label: 'Grazie',
    path: '/grazie',
    component: TicketThankYou,
    exact: true,
  },
  {
    name: 'thankyou|en',
    label: 'Thank you',
    path: '/en/thankyou',
    component: TicketThankYou,
    exact: true,
  },
  {
    name: 'directoryPath|it',
    label: '',
    path: '/:directoryPath',
    component: DynamicPage,
  },
  {
    name: 'directoryPath|en',
    label: '',
    path: '/en/:directoryPath',
    component: DynamicPage,
  },
  {
    name: 'directoryPath|resource|it',
    label: '',
    path: '/:directoryPath/:resource',
    component: DynamicPage,
  },
  {
    name: 'directoryPath|resource|en',
    label: '',
    path: '/en/:directoryPath/:resource',
    component: DynamicPage,
  },
]

export const routes = originalRoutes.reduce((acc, r) => {
  const ssrPath = addSsrPrefix(r.path, false)
  // Add public route (with custom branch)
  acc.push({ ...r, path: ssrPath })
  // Add internal route (for Rendertron)
  acc.push({ ...r, path: `/build${ssrPath}` })
  acc.push(r)
  return acc
}, [])

export const notFoundRoute = {
  name: 'not-found',
  meta: {
    it: { title: '404 - Pagina non trovata', description: 'Triennale' },
    en: { title: '404 - Page not found', description: 'Triennale' },
  },
  component: NotFound,
}
