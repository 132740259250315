import React from 'react'
import { inject, observer } from 'mobx-react'
import { translator } from '../../lib/translator'
import styles from './Newsletter.module.css'
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg'
import newsletterPressIt from '../../forms/newsletter_PRESS.html'
import newsletterBasicIt from '../../forms/newsletter_BASIC.html'
import newsletterSchoolIt from '../../forms/newsletter_SCHOOL.html'
import newsletterPressEn from '../../forms/newsletter_PRESS_EN.html'
import newsletterBasicEn from '../../forms/newsletter_BASIC_EN.html'
import { withValidation } from '../../lib/forms-utils'

const NEWSLETTER_TYPES = {
  basic: { it: newsletterBasicIt, en: newsletterBasicEn },
  school: { it: newsletterSchoolIt, en: newsletterSchoolIt },
  press: { it: newsletterPressIt, en: newsletterPressEn },
}

@inject('rootStore')
@observer
export class Newsletter extends React.Component {
  close = e => {
    if (e) {
      e.preventDefault()
    }
    const { hideNewsletterPanel } = this.props.rootStore.ui
    hideNewsletterPanel()
  }

  open = e => {
    if (e) {
      e.preventDefault()
    }
    const { type, rootStore } = this.props
    const { showNewsletterPanel } = rootStore.ui
    showNewsletterPanel(type)
  }

  render() {
    const { type, rootStore, extended } = this.props
    const { navigation, ui } = rootStore
    const { currentRouteLang } = navigation
    const { newsletterPanel } = ui

    return (
      <div className={styles.newsletterBlock}>
        {extended ? (
          <button className={styles.newsletterUnstyledLink} onClick={this.open}>
            {translator('newsletterSubscribe')(currentRouteLang)}
          </button>
        ) : (
          <div className={styles.openToggleWrapper}>
            <button
              style={{ border: 0 }}
              className={`${styles.openToggle} outline bold`}
              onClick={this.open}
            >
              <div className={`${styles.shining} newsletterButton`}>
                {type === 'press'
                  ? translator('newsletterSubscribePress')(currentRouteLang)
                  : translator('newsletterSubscribe')(currentRouteLang)}
              </div>
            </button>
          </div>
        )}

        <div
          className={`${styles.newsletterOverlay}${
            newsletterPanel && newsletterPanel === type
              ? ` ${styles.newsletterOverlayIsVisible}`
              : ''
          }`}
        >
          <div className={styles.inner}>
            <div className={styles.formContent}>
              <p className={`${styles.title} bodyTextBig regular uppercase letterSpaced`}>
                {translator('newsletterSubscribe')(currentRouteLang)}
              </p>
              {newsletterPanel &&
                newsletterPanel === type &&
                withValidation(NEWSLETTER_TYPES[type][currentRouteLang])}
            </div>
          </div>
          <button className={`${styles.closeToggle} outline`} onClick={this.close}>
            <CloseIcon className={styles.xIcon} aria-hidden="true" />
          </button>
        </div>
      </div>
    )
  }
}
