import React from 'react'
import { inject, observer } from 'mobx-react'
import { event } from '../lib/prismic/event'
import styles from './Ongoing.module.css'
import { CurrentContent } from '../components'
import { translator } from '../lib/translator'
import { PageTitlePlain } from '../components/Shared/Titles'
import { OngoingFilters } from '../components/Ongoing/OngoingFilters'
import { HistoryListCards } from '../components/History/HistoryListCards'

export const History = inject('rootStore')(
  observer(props => {
    const {
      rootStore: {
        events,
        navigation: { currentRouteLang },
      },
    } = props
    const id = 'events'
    const type = event

    return (
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <div className={styles.containerTitle}>
            <div className={styles.ongoingTitlePage}>
              <PageTitlePlain title={translator('historyPageTitle')(currentRouteLang)} />
            </div>
          </div>
          <div className={styles.filtersTitle}>
            <OngoingFilters id={id} type={events} isHistory />
          </div>
        </header>
        <CurrentContent
          type={type}
          contentType={'events'}
          renderComponent={HistoryListCards}
          typeOfList={0}
          resultsPerPage={12}
          options={{
            fetcherType: 'past',
          }}
        />
      </div>
    )
  }),
)
